import styled from 'styled-components';
import { MessageContainer } from '@Core/components/Messages/Messages.styled';

export const Button = styled.button`
	${({ theme }) => ({
		flex: ' unset',
		margin: '0 8px 8px 0',
		padding: '12px 15px',
		borderRadius: ' 50px',
		border: `1px solid ${theme.colors.colorLightBlue}`,
		backgroundColor: theme.colors.white,
		color: theme.colors.colorLightBlue,
		fontSize: '14px',
		'&:not(:disabled)': {
			cursor: 'pointer',
		},
		...theme.elements.messages.Button,
	})};
`;

export const ButtonTitle = styled.div`
	${({ theme }) => ({
		...theme.elements.messages.ButtonTitle,
	})};
`;

export const ButtonContainer = styled(MessageContainer)`
	${({ theme }) => ({
		display: 'flex',
		alignItems: 'flex-start',
		...theme.elements.messages.ButtonContainer,
	})};
`;
