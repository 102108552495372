import { useEffect } from 'preact/hooks';
import { ThemeProvider, withTheme } from 'styled-components';
import ActionBot from '@Core/components/ActionBot/ActionBot';
import { AppStoreProvider, IAppStoreProvider } from '@Core/store/App';
import { Services, ServicesStoreProvider } from '@Core/store/Services';
import Debug from '@Core/components/Debug/Debug';
import { Dictionary, DictionaryProvider } from '@Core/store/Dictionary';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import { setBridge } from '@Core/store/storeBridges';
import { getDictionary } from '@Core/store/Dictionary';
import useUID from '@Core/utils/hooks/useUID';
import smoothscroll from 'smoothscroll-polyfill';
import { IAppConfig } from '@Types/appConfig';
import { ITheme, IThemeExtension } from '@Types/themes';
import { IBehaviorConfig } from '@Types/behaviorConfig';
import { FC, useRef } from 'react';
import { IMessageComponents } from '@Types/messages';
import { IBotMinimized } from '@Core/components/BotMinimized/BotMinimized';
import deepMergeWithReferences from '@Core/utils/objects/deepMergeWithReferences';
import { LiveAnnouncer } from 'react-aria-live';
import isMobileOrTablet from '@Core/utils/mobileAndTabletDetection';
import { useNervousClicking } from '@Core/utils/hooks/useNervousClicking';
import { useNervousScrolling } from '@Core/utils/hooks/useNervousScrolling';
import { useScrollDownToUp } from '@Core/utils/hooks/useScrollDownToUp';
import { useDataLayerListener } from '@Core/utils/hooks/useDataLayerListener';
import usePageHistory from '@Core/utils/hooks/usePageHistory';

//fix for unsupported browsers
smoothscroll.polyfill();

const WebsocketLayer = () => {
	const services = getServices();
	if (services && services.AgentConnectionService) {
		const WebsocketLayerComponent = services.AgentConnectionService.createWebsocketLayer();
		return <WebsocketLayerComponent />;
	}
	// Something went wrong :(
	// When there is no custom AgentConnectionService
	// It should default to core one
	return null;
};

const AppStoreHooksBridge = ({ children }) => {
	const store = getAppStore();
	setBridge('app', store);
	return children;
};

const ServicesStoreHooksBridge = ({ children }) => {
	const store = getServices();
	setBridge('services', store);
	return children;
};

const DictionaryStoreHooksBridge = ({ children }) => {
	const store = getDictionary();
	setBridge('dictionary', store);
	return children;
};

const Behavior = ({ behavior }) => {
	const { BehaviorService } = getServices();

	// Data layer listener
	// It will listen for every push to dataLayer and will trigger the dataLayerPush event
	useDataLayerListener();
	usePageHistory(behavior);

	const [isNervousScrolling, incrementScrollCounter] = useNervousScrolling(behavior);
	const [isNervousClicking, incrementClickCounter] = useNervousClicking(behavior);
	const [hasScrolledDownToUp, handleFullScroll] = useScrollDownToUp(behavior);

	useEffect(() => {
		// Add nervous clicking event listener
		if (isMobileOrTablet(window)) {
			window.addEventListener('touchstart', incrementClickCounter);
		} else {
			window.addEventListener('click', incrementClickCounter);
		}
		// Add nervous scrolling event listener
		window.addEventListener('scroll', incrementScrollCounter);
		// Add full page scroll event listener
		window.addEventListener('scroll', handleFullScroll);
	}, []);

	useEffect(() => {
		if (isNervousClicking) {
			return;
		}
		return () => {
			BehaviorService.dispatchEvent('nervousClicking');
			window.removeEventListener('click', incrementClickCounter);
			window.removeEventListener('touchstart', incrementClickCounter);
		};
	}, [isNervousClicking]);

	useEffect(() => {
		if (isNervousScrolling) {
			return;
		}
		return () => {
			BehaviorService.dispatchEvent('nervousScrolling');
			window.removeEventListener('scroll', incrementScrollCounter);
		};
	}, [isNervousScrolling]);

	useEffect(() => {
		if (hasScrolledDownToUp) {
			return;
		}
		return () => {
			BehaviorService.dispatchEvent('scrollPage');
			window.removeEventListener('scroll', handleFullScroll);
		};
	}, [hasScrolledDownToUp]);

	useEffect(() => {
		new BehaviorService(behavior);
		window.__actionBot.dispatchEvent = BehaviorService.dispatchEvent;

		const pageSpendTimeTimeout = setTimeout(() => {
			BehaviorService.dispatchEvent('pageSpendTime');
		}, behavior.pageSpendTime.delay);

		function onPageLoad(e) {
			BehaviorService.dispatchEvent('pageLoad', e);
		}
		function onFocus(e) {
			BehaviorService.dispatchEvent('formFocus', e);
		}
		function onSubmit(e) {
			BehaviorService.dispatchEvent('formSubmit', e);
		}

		window.addEventListener('DOMContentLoaded', onPageLoad);

		const forms = document.getElementsByTagName('form');

		setTimeout(() => {
			BehaviorService.dispatchEvent('load');
		}, 1000);

		if (forms) {
			for (let i = 0; i < forms.length; i++) {
				forms[i].addEventListener('focus', onFocus, true);
				forms[i].addEventListener('submit', onSubmit, true);
			}
		}
		return () => {
			window.removeEventListener('DOMContentLoaded', onPageLoad);
			clearTimeout(pageSpendTimeTimeout);
			if (forms) {
				for (let i = 0; i < forms.length; i++) {
					forms[i].removeEventListener('focus', onFocus, true);
					forms[i].removeEventListener('submit', onSubmit, true);
				}
			}
		};
	}, []);
	return <></>;
};

const ThemeExtensionsProvider = ({ theme, themeExtensions = [], children }) => {
	const { ThemeService } = getServices();
	const themeExtensionName = ThemeService.getThemeExtension();

	const orginalTheme = useRef(null);

	if (!orginalTheme.current) {
		// save only value without references
		orginalTheme.current = JSON.stringify(theme);
	}

	const currentThemeExtension = themeExtensions.find(
		(themeExtension) => themeExtension.name === themeExtensionName
	)?.theme;

	if (currentThemeExtension) {
		const { _theme } = theme;

		const themePropsExtended = {
			colors: {
				...theme.colors,
				...currentThemeExtension.colors,
			},
			fonts: {
				...theme.fonts,
				...currentThemeExtension.fonts,
			},
		};

		const themeUpdated = typeof _theme === 'function' ? _theme(themePropsExtended) : theme;

		theme = deepMergeWithReferences(theme, themeUpdated, currentThemeExtension);
	} else if (orginalTheme.current) {
		theme = deepMergeWithReferences(theme, JSON.parse(orginalTheme.current));
	}

	return children;
};
interface IRootProps {
	config: IAppConfig;
	theme?: ITheme;
	themeExtensions?: IThemeExtension[];
	services?: Services;
	dictionary?: Dictionary;
	behavior?: IBehaviorConfig;
	MinimizedComponent?: FC<IBotMinimized>;
	MessagesCustomContainer?: FC<any>;
	appStore?: IAppStoreProvider['platformAppStore'];
	WrapperComponent?: FC<any>;
	components?: IMessageComponents;
	minimizedComponentProps?: IBotMinimized['minimizedComponentProps'] & {
		[key: string]: any;
	};
}

export default withTheme<IRootProps, ITheme>(
	({
		config,
		services,
		theme,
		themeExtensions,
		dictionary,
		behavior,
		MinimizedComponent,
		minimizedComponentProps,
		MessagesCustomContainer,
		appStore,
		WrapperComponent,
		components,
	}) => {
		useUID();

		if (WrapperComponent) {
			return (
				<WrapperComponent
					{...{
						config,
						services,
						theme,
						themeExtensions,
						dictionary,
						behavior,
						MinimizedComponent,
						minimizedComponentProps,
						MessagesCustomContainer,
						appStore,
						components,
					}}
				>
					<Debug>
						<WebsocketLayer />
						<Behavior behavior={behavior} />
						<ActionBot
							behavior={behavior}
							components={components}
							MinimizedComponent={MinimizedComponent}
							minimizedComponentProps={minimizedComponentProps}
							MessagesCustomContainer={MessagesCustomContainer}
						/>
					</Debug>
				</WrapperComponent>
			);
		} else {
			return (
				<LiveAnnouncer>
					<AppStoreProvider config={config} platformAppStore={appStore}>
						<AppStoreHooksBridge>
							<ServicesStoreProvider services={services}>
								<ServicesStoreHooksBridge>
									<ThemeExtensionsProvider themeExtensions={themeExtensions} theme={theme}>
										<ThemeProvider theme={theme}>
											<DictionaryProvider dictionary={dictionary}>
												<DictionaryStoreHooksBridge>
													<Debug>
														<WebsocketLayer />
														<Behavior behavior={behavior} />
														<ActionBot
															behavior={behavior}
															components={components}
															MinimizedComponent={MinimizedComponent}
															minimizedComponentProps={minimizedComponentProps}
															MessagesCustomContainer={MessagesCustomContainer}
														/>
													</Debug>
												</DictionaryStoreHooksBridge>
											</DictionaryProvider>
										</ThemeProvider>
									</ThemeExtensionsProvider>
								</ServicesStoreHooksBridge>
							</ServicesStoreProvider>
						</AppStoreHooksBridge>
					</AppStoreProvider>
				</LiveAnnouncer>
			);
		}
	}
);
