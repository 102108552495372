interface IReplacersObj {
	[replacerKey: string]: any;
}

export function urlReplacer(url: string, replacersObj: IReplacersObj) {
	if (typeof replacersObj === 'object') {
		for (const replacerKey in replacersObj) {
			const replacerValue = replacersObj[replacerKey];
			url = url.replace(`{${replacerKey}}`, replacerValue);
		}
		return url;
	}
	return url;
}
