import { Service } from '@Core/utils/services';
import { MessagesGroup } from '@Core/utils/models/messages';
import debug from '@Core/utils/debug';
import { IMessage, IMessagesGroup, OWNER } from '@Types/messages';

export default class MessageService extends Service {
	constructor() {
		super();
	}

	get messages() {
		return this.getAppStore().messages.value;
	}

	_handleActionMessage(message: IMessage) {
		const ActionsService = this.getServices('ActionsService');
		const actionsService = new ActionsService();
		if (message && (message.isAction || (message.isMessageWithAction && !message.isMessageWithActionDone))) {
			actionsService.handleActionFromMessage(message);
		}
	}

	_handleActionMessages(actions: IMessage[]) {
		if (actions && actions.length) {
			actions.forEach((singleAction) => {
				this._handleActionMessage(singleAction);
			});
		}
	}

	removeMessagesOverLimit = () => {
		const defaultLimit = 150;

		const store = this.getAppStore();
		let limit = store.config.PROTECTED.messagesLimit;

		if (!limit || typeof limit !== 'number') {
			limit = defaultLimit;
		}

		const messagesLength = this.getAppStore().messages.value.reduce(
			(sum, current) => [...sum, ...current.messages],
			[]
		).length;

		if (messagesLength > limit) {
			store.messages.set((messagesGroups) => {
				messagesGroups.splice(0, 1);
				return messagesGroups;
			});
		}
	};

	setMessage(newMessagesGroups: IMessagesGroup[]) {
		this.getAppStore().messages.set((messagesGroups) => {
			messagesGroups.splice(0, messagesGroups.length);
			return [...messagesGroups, ...newMessagesGroups];
		});
	}

	editMessageById = (id: number, edit: (message: IMessage) => IMessage) => {
		if (id) {
			this.getAppStore().messages.set((messagesGroups) => {
				return messagesGroups.map((singleGroup) => {
					singleGroup.messages.forEach((singleMessage) => {
						if (singleMessage.id === id) {
							return edit(singleMessage);
						} else {
							return singleMessage;
						}
					});
					return singleGroup;
				});
			});
		}
	};

	editGroupByMessageId = (id: number, edit: (group: IMessagesGroup) => IMessagesGroup) => {
		if (id) {
			this.getAppStore().messages.set((messagesGroups) => {
				return messagesGroups.map((singleGroup) => {
					const isGroupToEdit = singleGroup.messages.some((singleMessage) => singleMessage.id === id);

					if (isGroupToEdit) {
						return edit(singleGroup);
					} else {
						return singleGroup;
					}
				});
			});
		}
	};

	removeMessageById = (id: number) => {
		if (id) {
			const store = this.getAppStore();
			store.messages.set((messagesGroups) => {
				for (let i = 0; i < messagesGroups.length; i++) {
					const indexToRemove = messagesGroups[i].messages.findIndex((message) => message.id === id);
					if (~indexToRemove) {
						messagesGroups[i].messages.splice(indexToRemove, 1);
						break;
					}
				}
				return messagesGroups;
			});
			store._forceRerender();
		}
	};

	addMessage(message: IMessage) {
		setTimeout(() => {
			const store = this.getAppStore();

			function newGroupForMessage(message: IMessage) {
				return new MessagesGroup({ messages: [message], owner: message.owner });
			}

			if (message) {
				this.getServices('BehaviorService').dispatchEvent('addMessage', message);
				if (message.isAction) {
					this._handleActionMessage(message);
				} else {
					if (message.isMessageWithAction && !message.isMessageWithActionDone) {
						this._handleActionMessage(message);
					}
					const owner = message.owner;

					store.messages.set((messagesGroups) => {
						const incognito = store.incognito;

						if (owner === OWNER.user && incognito.isForInput()) {
							return messagesGroups;
						}
						if (owner !== OWNER.user && incognito.isForOutput()) {
							return messagesGroups;
						}

						const lastMessageGroup = messagesGroups[messagesGroups.length - 1];
						try {
							if (lastMessageGroup) {
								const lastOwner = lastMessageGroup.owner;

								if (lastOwner === owner) {
									lastMessageGroup.messages = [...lastMessageGroup.messages, message];
									messagesGroups.splice(messagesGroups.length - 1, 1);
									return [...messagesGroups, lastMessageGroup];
								} else {
									return [...messagesGroups, newGroupForMessage(message)];
								}
							} else {
								return [...messagesGroups, newGroupForMessage(message)];
							}
						} catch (e) {
							debug().error('Message not added', e, message);
							return messagesGroups;
						}
					});
				}
			}
		}, 0);
	}

	addMessageGroup(messageGroup: IMessagesGroup) {
		const store = this.getAppStore();
		if (messageGroup && messageGroup.messages) {
			this.getServices('BehaviorService').dispatchEvent('addMessageGroup', messageGroup);
			const actions = [];
			const messageGroupWithoutActions = new MessagesGroup({ ...messageGroup, messages: [] });

			messageGroup.messages.forEach((singleMessage) => {
				if (singleMessage.isMessageWithAction && !singleMessage.isMessageWithActionDone) {
					actions.push(singleMessage);
				}
				if (singleMessage.isAction) {
					actions.push(singleMessage);
				} else {
					messageGroupWithoutActions.messages.push(singleMessage);
				}
			});

			this._handleActionMessages(actions);

			if (messageGroupWithoutActions.messages.length) {
				store.messages.set((messagesGroup) => {
					const owner = messageGroupWithoutActions.owner;
					const incognito = store.incognito;

					if (owner === OWNER.user && incognito.isForInput()) {
						return messagesGroup;
					}
					if (owner !== OWNER.user && incognito.isForOutput()) {
						return messagesGroup;
					}

					const lastMessageGroup = messagesGroup[messagesGroup.length - 1];

					try {
						if (lastMessageGroup) {
							const lastOwner = lastMessageGroup.owner;
							if (lastOwner === owner) {
								lastMessageGroup.messages = [
									...lastMessageGroup.messages,
									...messageGroupWithoutActions.messages,
								];
								messagesGroup.splice(-1, 1);
								return [...messagesGroup, lastMessageGroup];
							} else {
								return [...messagesGroup, messageGroupWithoutActions];
							}
						} else {
							return [...messagesGroup, messageGroupWithoutActions];
						}
					} catch (e) {
						debug().error('MessageGroup not added', e, messageGroupWithoutActions);
						return messagesGroup;
					}
				});
			}
		}
	}

	setInitialPopup(message) {
		const store = this.getAppStore();
		try {
			store.initialPopup.set(JSON.parse(message));
		} catch (e) {
			console.error(e);
		}
	}
}
