import { Service } from '@Core/utils/services';

export default class AnimationService extends Service {
	static getBotTalkImageElement = () => {
		return document.querySelector('#bot-talk-image');
	};

	startBotTalkImageShaking = () => {
		AnimationService.getBotTalkImageElement().classList.add('shaking');
	};

	stopBotTalkImageShaking = () => {
		AnimationService.getBotTalkImageElement().classList.remove('shaking');
	};
}
