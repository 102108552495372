import coreTheme from '@Core/theme/basic';
import deepMerge from '@Core/utils/objects/deepMerge';
import { ITheme, IThemeExtension, IThemeProps } from '@Types/themes';

export function createTheme(theme: ITheme | ((themeProps?: IThemeProps) => ITheme), themeProps?: IThemeProps) {
	const themeCalculated = typeof theme === 'function' ? theme(themeProps) : theme;

	const merged = deepMerge(coreTheme, themeCalculated, window.__actionBot?.theme || {});

	merged._theme = theme;
	merged._themeProps = themeProps;

	return merged;
}

export function createThemeExtension(name: string, theme: Partial<ITheme>): IThemeExtension {
	return {
		name,
		theme,
	};
}
