import { IMessage } from '@Types/messages';
import { useState } from 'preact/hooks';
import { Image, ImageContainer, ImageWrapper, ImageTitle, ImageDescription } from './Image.styled';

export interface IImageMessage {
	message: IMessage;
}

export default ({ message }: IImageMessage) => {
	const [fullScreen, setFullScreen] = useState(false);
	const { source, title, description } = message.content;
	const owner = message.owner;

	return (
		<ImageContainer image={source} owner={owner} onClick={() => setFullScreen((f) => !f)} fullScreen={fullScreen}>
			<ImageWrapper>
				<Image owner={owner} fullScreen={fullScreen} src={source} />
				{!fullScreen && (
					<>
						{!!title && <ImageTitle>{title}</ImageTitle>}
						{!!description && <ImageDescription>{description}</ImageDescription>}
					</>
				)}
			</ImageWrapper>
		</ImageContainer>
	);
};
