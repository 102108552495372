interface IReplacementsTags {
	[key: string]: RegExp;
}

export const replacementsTags: IReplacementsTags = {
	br: /<br\s*\/?>/gi,
};

export function replaceHTMLtags(text: string, tag: keyof IReplacementsTags, replacement: string): string {
	const regex = replacementsTags[tag];
	if (!regex) {
		throw new Error(`Tag ${tag} not found in replacementsTags`);
	}
	return text.replace(regex, replacement);
}
