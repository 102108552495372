import { IStyledComponentWithTheme } from '@Types/themes';
import styled from 'styled-components';

export const YouTubeIframe = styled.iframe<
	IStyledComponentWithTheme & { type: string; frameborder: string; allowfullscreen: boolean }
>`
	${({ theme }) => ({
		height: '200px',
		width: '100%',
		...theme.elements.messages.YouTubeIframe,
	})};
`;
