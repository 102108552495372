import { IMessage } from '@Types/messages';
import { GifImage } from './Gif.styled';

export interface IGifMessage {
	message: IMessage;
}

export default ({ message }: IGifMessage) => {
	const { url, resizeMode } = message.content;
	return <GifImage src={url} style={{ backgroundSize: resizeMode }} />;
};
