import getStorageByName from '@Core/utils/storage/getStorageByName';
import generateUID from '@Core/utils/uuid/generateUID';
import { useEffect } from 'preact/hooks';

const COOKIE_NAME = `actionbot_uid`;
const cookiesStorage = getStorageByName('cookies');

export function getUID() {
	if (!cookiesStorage.getItem(COOKIE_NAME)) {
		cookiesStorage.setItem(COOKIE_NAME, generateUID());
	}
	return cookiesStorage.getItem(COOKIE_NAME);
}

export default function useUID() {
	useEffect(() => {
		getUID();
	}, []);
}
