import { IStyles } from '@Types/css';
import styled from 'styled-components';

interface IBotChatIcon extends IStyles {
	src: string;
}

export const BotChatIcon = styled.div<IBotChatIcon>`
	${({ src, theme }) => ({
		width: '55%',
		height: '55%',
		backgroundImage: `url("${src}")`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		paddingBottom: '2px',
		...theme.elements.BotChatIcon,
	})};
`;

export const BotTalkImage = styled.div`
	${({ theme }) => ({
		width: '60px',
		height: '60px',
		position: 'absolute',
		display: 'flex',
		borderRadius: '50%',
		right: '-28px',
		top: 'unset',
		bottom: '-9px',
		backgroundColor: theme.colors.primary,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundSize: 'contain',
		zIndex: '100',
		'@media(max-width: 576px)': {
			backgroundColor: theme.colors.primary,
		},
		...theme.elements.BotTalkImage,
	})};
`;

interface IBotMinimizedContainer extends IStyles {
	active: boolean;
}

export const BotMinimizedContainer = styled.div<IBotMinimizedContainer>`
	${({ theme, active }) => {
		return {
			justifyContent: 'space-between',
			alignItems: 'center',
			overflow: 'visible',
			img: {
				display: 'none',
			},
			display: 'block',
			position: 'fixed',
			bottom: '11px',
			right: '17px',
			cursor: 'pointer',
			backgroundColor: 'transparent',
			transition: 'all .3s',
			transform: active ? 'scale(0) !important' : 'scale(1)',
			borderRadius: '0',
			zIndex: '100',
			span: {
				position: 'absolute',
				top: '-5px',
				right: '-5px',
			},
			'@media (max-width: 576px)': {
				right: '17px',
				width: '60px',
				bottom: '11px',
				transition: 'all .3s',
			},

			'& .shaking': {
				transform: 'scale(1.1)',
				transition: 'all .3s',
			},

			'& .shaking .bot-chat-icon': {
				animation: 'shake-head .4s',
			},
			...theme.elements.BotMinimizedContainer,
		};
	}};
`;
