import styled from 'styled-components';
import { getAppStore } from '@Core/exports/store/App';
import sendIcon from '@Core/assets/icons/send_icon.svg';

export const SubmitButton = styled.button`
	${({ theme }) => {
		let SubmitButtonRtl = {};
		try {
			const isRtl = getAppStore().rtl.value;

			if (isRtl) {
				SubmitButtonRtl = theme.elements.SubmitButtonRtl;
			}
		} catch (e) {
			console.warn(e);
		}
		return {
			'&:hover': {
				opacity: 1,
			},
			'&:focus': {
				outline: 'none',
			},
			border: 'none',
			cursor: 'pointer',

			width: '60px',
			height: '60px',
			backgroundColor: theme.colors.colorLightBlue,
			backgroundImage: `url(${sendIcon})`,
			backgroundSize: '25px 25px',
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			opacity: 1,
			transition: 'background-color 0.2s',
			img: {
				display: 'none',
			},
			[`@media (max-width: ${theme.breakpoints.mobile})`]: {
				width: '80px',
				backgroundSize: '30px 30px',
				height: '70px',
			},
			...theme.elements.SubmitButton,
			...SubmitButtonRtl,
		};
	}};
`;

export const SubmitButtonIcon = styled.img`
	${({ theme }) => {
		return {
			margin: '0 10px',
			minWidth: '30px',
			minHeight: '30px',
			...theme.elements.SubmitButtonIcon,
		};
	}};
`;
