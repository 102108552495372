import styled from 'styled-components';
import { MessageWrapper, MessageContainer } from '@Core/components/Messages/Messages.styled';

export const ThinkingContainer = styled(MessageContainer)`
	${({ theme }) => ({
		alignItems: 'flex-start',
		marginTop: '0',
		...theme.elements.messages.ThinkingContainer,
	})};
`;

export const ThinkingMessage = styled(MessageWrapper)`
	${({ theme }) => ({
		backgroundColor: theme.colors.veryLightPink,
		height: '32px',
		'&:after': {
			content: '""',
			display: 'inline-block',
			animation: 'dotty steps(1, end) 1s infinite',
			fontWeight: 'bold',
			fontSize: '16px',
			marginLeft: '12px',
			[`@media (max-width: ${theme.breakpoints.mobile})`]: {
				marginLeft: '15px',
			},
		},
		'*': {
			display: 'none',
		},

		minWidth: '60px',
		minheight: '32px',
		borderRadius: '0 10px 10px 15px',
		color: theme.colors.colorPrimary,
		background: theme.colors.colorWhite,
		boxShadow: `0 0 10px ${theme.colors.boxShadow}`,
		...theme.elements.messages.ThinkingMessage,
	})};
`;
