let bridges = {};

export function setBridge(key: string, value: any) {
	bridges = {
		...bridges,
		[key]: value,
	};
}

export function getBridge(key: string) {
	return bridges[key];
}
