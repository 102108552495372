import { IMessage } from '@Types/messages';
import { YouTubeIframe } from './YouTube.styled';

export interface IYouTubeMessage {
	message: IMessage;
}

export default ({ message }: IYouTubeMessage) => {
	const src = 'https://www.youtube.com/embed/' + message.content.id;
	return <YouTubeIframe id="ytplayer" type="text/html" src={src} frameborder="0" allowfullscreen />;
};
