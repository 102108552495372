import { useEffect, useState } from 'preact/hooks';
import { getServices } from '@Core/store/Services';
import { ButtonShowMore, TextContainer, TextWrapper, ShowMoreWrapper, TextShowMore } from './ReadMore.styled';
import { IMessage } from '@Core/types/messages';
import useDictionary from '@Core/utils/language/useDictionary';

interface IReadMore {
	message: IMessage;
}

export default function ReadMore({ message }: IReadMore) {
	const { translate } = useDictionary();
	const { ControlService } = getServices();
	const { content, owner } = message;
	const { textShort, textLong } = content;
	const [showMore, setShowMore] = useState(false);
	const textExpand = translate('readMore.textExpand');
	const textExpanded = translate('readMore.textExpanded');

	useEffect(() => {
		setTimeout(() => {
			scrollBottom();
		}, 100);
	}, [showMore]);

	function scrollBottom() {
		const controlService = new ControlService();
		controlService.scrollToBottom();
	}
	return (
		<TextContainer owner={owner}>
			<TextWrapper className={owner}>
				<ShowMoreWrapper>
					<TextShowMore>{showMore ? textLong : textShort}</TextShowMore>
					<ButtonShowMore onClick={() => setShowMore((showMore) => !showMore)} showMore={showMore}>
						{showMore ? textExpanded : textExpand}
					</ButtonShowMore>
				</ShowMoreWrapper>
			</TextWrapper>
		</TextContainer>
	);
}
