import SubmitButton from '@Core/components/SubmitButton/SubmitButton';
import { Input, InputContainer } from './MessageInput.styled';
import Menu from '@Core/components/Menu/Menu';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';

import MessageInputAttachment from '@Core/components/MessageInputAttachment/MessageInputAttachment';
import { ChangeEventHandler, ClipboardEventHandler, KeyboardEventHandler } from 'react';
import { useEffect, useState } from 'preact/hooks';
import useDictionary from '@Core/utils/language/useDictionary';

export interface IMessageInput {
	value: string | number;
	onKeyPress: KeyboardEventHandler<HTMLTextAreaElement>;
	onSubmit: () => void;
	onOptionSelect: (payload: any) => void;
	onChange: ChangeEventHandler<HTMLTextAreaElement>;
}

type PasteEvent = ClipboardEventHandler<HTMLTextAreaElement> & any;

export default ({ value, onKeyPress, onSubmit, onChange, onOptionSelect }: IMessageInput) => {
	const config = getAppStore().config;
	const { maxLength } = config.PUBLIC.input;
	const isMenuEnabled = config.PUBLIC.menu.enabled;
	const isDisabled = getAppStore().isInputDisabled.value;
	const remoteConfig = getAppStore().remoteConfig.value;

	const { RemoteConfigService } = getServices();
	const remoteConfigService = new RemoteConfigService();

	function onPaste(e: PasteEvent) {
		onChange(e);
	}

	const [isRemoteConfigLoading, setIsRemoteConfigLoading] = useState(true);
	useEffect(() => {
		setIsRemoteConfigLoading(remoteConfigService.isLoadingActive);
	}, [remoteConfig]);

	const isFullScreen = getAppStore().fullScreen.value;

	const { translate } = useDictionary();

	return (
		<InputContainer loading={isRemoteConfigLoading} className={isFullScreen ? 'fullscreen' : ''}>
			<MessageInputAttachment />
			{isMenuEnabled && <Menu onOptionSelect={onOptionSelect} />}
			<Input
				type="text"
				aria-controls="chatarea"
				placeholder={translate('messageInput.textarea.placeholder')}
				maxLength={maxLength}
				value={value}
				onChange={onChange}
				onKeyDown={onKeyPress}
				onPaste={onPaste}
				disabled={isDisabled}
			/>

			<SubmitButton onClick={onSubmit} />
		</InputContainer>
	);
};
