import { createThemeExtension } from '@Core/exports/theme';
import avatar from '@Platform/assets/icons/ico_avatar_small_black_on_white.svg';
import sendIcon from '@Platform/assets/icons/send_icon.svg';
import avatarBig from '@Platform/assets/icons/avatar_white.svg';
import { ITheme } from '@Core/types/themes';

export default createThemeExtension('nightContrast', {
	colors: {
		primary: '#000000',
		lightPrimary: '#38507b',
		hoverPrimary: '#c9c9c9',
		white: '#c9c9c9',
		shadow: 'rgba(32,37,43, .25)',
		beton: '#000000',
		gray: '#002e5a',
		cream: '#002e5a',
		lightGray: '#c9c9c9',
		silver: '#002e5a',
		red: '#002e5a',
		black: '#000000',
		botTriangleBefore: '#000000',
		userTriangleBefore: '#002e5a',
		myMessageColor: '#ffffff',
		myMessageBackgroundColor: '#002e5a',
		inputContainerBorderTopColor: '#002e5a',
		placeholderColor: '#002e5a',
		messageBotBoxShadow: '#000000',
		myMessageBorderColor: '#002e5a',
		botMessageBorderColor: '#000000',
		headerContainerColor: '#000000',
		headerContainerBackgroundColor: '#002e5a',
		poweredByColor: '#002e5a',
		poweredByBorderTopColor: '#002e5a',
		poweredByAColor: '#002e5a',
		linkColor: '#000000',
		textWithButtonsListColor: '#3363c1',
		headerContainerTitleColor: '#ffffff',
		textWithButtonsBackgroundColor: '#e9ecef',
		userIndicatorColor: '#000000',
		textWithButtonsColor: '#002e5a',
	},
	elements: {
		BotWindow: {
			boxShadow: '0 4px 16px rgba(0, 0, 0, 0.25)',
		},

		HeaderContainer: {
			boxShadow: 'none',
			borderBottom: 'unset',
		},

		HeaderIconContainer: {
			'div:after': {
				color: 'white',
			},
			'&:hover': {
				'div:after': {
					color: 'white',
				},
			},
		},

		MessageInput: {
			'&::placeholder': {
				opacity: 0.7,
			},
		},

		InitialPopupClose: {
			backgroundColor: '#c9c9c9 !important',
			div: {
				color: '#595959 !important',
			},
			'&:hover': {
				backgroundColor: '#595959 !important',
				div: {
					color: '#c9c9c9 !important',
				},
			},
		},

		SubmitButton: {
			backgroundImage: `url("${sendIcon}")`,
			'&:disabled': {
				backgroundImage: `url("${sendIcon}")`,
				opacity: '0.6',
			},
		},

		BotTalkImage: {
			boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1px 6px 0px, rgba(0, 0, 0, 0.16) 0px 2px 32px 0px',
			backgroundColor: '#002e5a',
			'@media (max-width: 576px)': {
				backgroundColor: '#002e5a',
			},
		},

		BotChatIcon: {
			backgroundImage: `url("${avatarBig}")`,
			width: '55%',
			height: '55%',
		},
		messages: {
			MessagesGroupAvatar: {
				'&.bot': {
					backgroundImage: `url(${avatar})`,
				},
			},

			TextWithButtonsTextButton: {
				border: 'unset',
				'&:hover': {
					backgroundColor: '#e9ecef',
					span: {
						color: '#38507b !important',
					},
				},
			},
		},
	},
} as ITheme);
