// ts is not allowed here
const envJSON = require('../../../../.actionbot/env.json');
module.exports = {
	environments: [
		{
			name: `development`,
			value: 'dev',
		},
		{
			name: `production`,
			value: 'prod',
		},
	],

	publicPath: `https://actionbot-proxy-us.12lrn9w809z6.eu-de.codeengine.appdomain.cloud/actionbot/script/${envJSON.env}/`,
	filename: 'actionbot.js',
	sourceMapOnProd: false,
};
