import styled, { keyframes } from 'styled-components';
import { IStyles } from '@Types/css';
import { getAppStore } from '@Core/store/App';

interface IPopupWithImgStyled extends IStyles {
	img: string;
}

export const InitialPopupContainer = styled.div`
	${({ theme }) => {
		// @ts-ignore
		const { y, offsetX, align, isDragging } = getAppStore().position.value;
		const isPopupTop = y !== 0 && y < window.innerHeight / 2;

		const alignStyles =
			align === 'left'
				? {
						...theme.elements.InitialPopupContainerAlignLeft,
					}
				: {
						...theme.elements.InitialPopupContainerAlignRight,
					};

		const topStyles = isPopupTop
			? {
					top: '85px',
					...theme.elements.InitialPopupContainerTopStyles,
				}
			: {};
		const topAndLeftStyles =
			isPopupTop && align === 'left'
				? {
						top: '85px',
						...theme.elements.InitialPopupContainerTopAndLeftStyles,
					}
				: {};
		const isDraggingStyles = isDragging
			? {
					transform: `scale(0) `,
					...theme.elements.InitialPopupContainerIsDragging,
				}
			: {
					transform: `scale(1)`,
					...theme.elements.InitialPopupContainerIsNotDragging,
				};

		return {
			height: 'min-content',
			position: 'absolute',
			bottom: '85px',
			left: 'unset',
			right: 'unset',
			[align]: '0',
			borderRadius: '0 20px 20px 20px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			transition: 'all .3s',
			animation: `${scaleIn} 0.3s`,
			transformOrigin: `bottom ${align}`,
			backgroundImage: 'linear-gradient(150deg, #2d63fb 0%, #e9008f 97%)',
			padding: '32px 35px',
			boxShadow: 'rgba(0,20,73,.25) 0 4px 16px',
			zIndex: 10000,
			width: '280px',
			'@media (max-width: 300px)': {
				maxWidth: 'calc(100vw - 64px)',
				padding: '16px',
			},
			...theme.elements.InitialPopupContainer,
			...alignStyles,
			...isDraggingStyles,
			...topStyles,
			...topAndLeftStyles,
			'@media (max-width: 500px)': {
				right: '-20px',
				left: 'unset',
				transform: `scale(${isDragging ? 0 : 1}) translateX(${-offsetX}px)`,
			},
		};
	}};
`;
const scaleIn = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
`;

export const InitialPopupClose = styled.div`
	${({ theme }) => ({
		userSelect: 'none',
		width: '40px',
		height: '40px',
		position: 'absolute',
		top: '-10px',
		right: '-10px',
		boxShadow: '0 0 8px 0 #dcc2ee',
		backgroundColor: '#fff',
		borderRadius: '50px',
		color: theme.colors.blue,
		transition: 'all .3s',
		transform: 'scale(1)',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#182B60',
			color: '#fff',
		},
		div: {
			position: 'absolute',
			transform: 'rotate(45deg)',
			fontSize: '38px',
			top: '-1px',
			left: '13px',
			lineHeight: '38px',
			zIndex: '999',
		},
		...theme.elements.InitialPopupClose,
	})};
`;

export const InitialPopupResize = styled.div`
	${({ theme }) => ({
		width: '40px',
		height: '40px',
		position: 'absolute',
		top: '-10px',
		right: '40px',
		boxShadow: '0 0 8px 0 #dcc2ee',
		backgroundColor: '#fff',
		borderRadius: '50px',
		color: theme.colors.blue,
		transition: 'all .3s',
		transform: 'scale(1)',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: '#182B60',
			color: '#fff',
			img: {
				filter: 'brightness(0) invert(1)',
			},
		},
		img: {
			width: '15px',
			height: '15px',
		},
		...theme.elements.InitialPopupResize,
	})};
`;

export const InitialPopupHeader = styled.div`
	${({ theme }) => {
		return {
			width: '100%',
			height: 'auto',
			fontSize: '18px',
			fontFamily: 'Barlow',
			fontWeight: '500',
			color: theme.colors.white,
			marginBottom: '6px',
			boxSizing: 'border-box',
			lineHeight: '1.4',
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'left',
			span: {
				position: 'relative',
				top: 'unset',
				right: 'unset',
			},
			h3: {
				textAlign: 'left',
				fontWeight: '800',
				fontSize: '20px',
				color: '#fff',
			},
			...theme.elements.InitialPopupHeader,
		};
	}};
`;

export const InitialPopupButton = styled.div`
	${({ theme }) => ({
		width: '100%',
		height: '36px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#1f60ff',
		fontSize: '14px',
		fontWeight: '800',
		padding: '12px 16px',
		borderRadius: '50px',
		whiteSpace: 'nowrap',
		position: 'relative',
		marginTop: '14px',
		zIndex: '50',
		cursor: 'pointer',
		boxSizing: 'border-box',
		backgroundColor: '#fff',
		marginRight: '10px',
		'&:not(:disabled):hover': {
			color: '#fff',
			backgroundColor: '#182B60',
		},
		...theme.elements.InitialPopupButton,
	})};
`;

export const InitialPopupButtonsContainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		maxWidth: '350px',
		...theme.elements.InitialPopupButtonsContainer,
	})};
`;

export const InitialPopupImage = styled.div`
	${({ theme, img }: IPopupWithImgStyled) => ({
		width: '100%',
		height: '120px',
		position: 'relative',
		marginTop: '14px',
		zIndex: '50',
		cursor: 'pointer',
		boxSizing: 'border-box',
		backgroundImage: `url(${img})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		div: {
			position: 'absolute',
			width: '100%',
			bottom: 0,
			left: 0,
			fontSize: '14px',
			color: '#000',
			backgroundColor: '#fff',
			padding: '2px 10px',
			boxSizing: 'border-box',
		},
		...theme.elements.InitialPopupImage,
	})};
`;

export const InitialPopupSlideContainer = styled.div`
	${({ theme }) => ({
		'.slick-slide': {
			'> div': {
				marginLeft: '6px',
				padding: '22px 0',
			},
		},
		'.slick-slider': {
			margin: '0 -35px',
		},
		width: '300px',
		...theme.elements.InitialPopupSlideContainer,
	})};
`;

export const InitialPopupSlide = styled.div`
	${({ theme }) => ({
		backgroundColor: '#fff',
		boxShadow: '0 0 26px 0 rgba(0, 0, 0, 0.12)',
		marginLeft: '25px',
		img: {
			width: '50px',
			margin: '21px 17px',
		},
		p: {
			fontSize: '17px',
			fontWeight: 'bold',
			marginLeft: '17px',
			marginBottom: '21px',
		},
		...theme.elements.InitialPopupSlide,
	})};
`;

export const PopupCarouselButton = styled.div`
	${({ theme }) => ({
		borderRadius: '15px',
		border: theme.colors.white,
		backgroundColor: theme.colors.whiteGradient,
		fontSize: '0.7rem',
		transition: 'background-color .3s, color .3s',
		position: 'absolute',
		bottom: 0,
		'&:not(:disabled):hover': {
			color: theme.colors.white,
			backgroundColor: theme.colors.veryLightPink,
			borderColor: theme.colors.veryLightPink,
			cursor: 'pointer',
		},
		...theme.elements.messages.PopupCarouselButton,
	})};
`;

export const PopupCarouselButtonLeft = styled(PopupCarouselButton)`
	${({ theme }) => ({
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			display: 'none',
		},
		fontSize: 0,
		background: theme.colors.white,
		height: '36px',
		borderRadius: '50px',
		width: '36px',
		bottom: '88px',
		transform: 'translateY(-50%)',
		left: '10px',
		zIndex: 10,
		transition: '.3s all',
		boxShadow: '2px 2px 30px 0 rgba(61, 61, 61, 0.4)',
		'&:hover': {
			backgroundColor: theme.colors.primary + '!important',
			transition: '.3s all',
			'&:after': {
				borderColor: theme.colors.white,
				transition: 'inherit',
			},
		},
		'&:after': {
			content: '""',
			transition: 'inherit',
			width: '8px',
			height: '8px',
			borderLeft: '2px solid',
			borderTop: '2px solid',
			borderColor: theme.colors.primary,
			position: 'absolute',
			transform: 'translateY(-50%) rotate(-45deg)',
			top: '50%',
			left: '14px',
		},
		...theme.elements.messages.PopupCarouselButtonLeft,
	})};
`;

export const PopupCarouselButtonRight = styled(PopupCarouselButton)`
	${({ theme }) => ({
		right: '30px',
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			display: 'none',
		},
		fontSize: 0,
		background: theme.colors.white,
		height: '36px',
		borderRadius: '50px',
		width: '36px',
		bottom: '88px',
		transform: 'translateY(-50%)',
		zIndex: 10,
		transition: '.3s all',
		boxShadow: '2px 2px 30px 0 rgba(61, 61, 61, 0.4)',
		'&:hover': {
			transition: '.3s all',
			backgroundColor: theme.colors.primary + '!important',
			'&:after': {
				transition: 'inherit',
				borderColor: theme.colors.white,
			},
		},
		'&:after': {
			content: '""',
			transition: 'inherit',
			width: '8px',
			height: '8px',
			borderLeft: '2px solid',
			borderTop: '2px solid',
			borderColor: theme.colors.primary,
			position: 'absolute',
			transform: 'translateY(-50%) rotate(135deg)',
			top: '50%',
			right: '14px',
		},
		...theme.elements.messages.PopupCarouselButtonRight,
	})};
`;
