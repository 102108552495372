import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';
import { IStyledComponentWithTheme } from '@Core/types/themes';

export const TextContainer = styled(MessageContainer)`
	${({ theme }) => ({
		...theme.elements.messages.TextContainer,
	})};
`;

export const TextWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		backgroundColor: theme.colors.veryLightPink,
		a: {
			wordBreak: 'break-word',
		},
		padding: '4px 20px',
		borderRadius: '10px',
		color: theme.colors.colorSecondary,
		background: theme.colors.colorWhite,
		boxShadow: `0 0 18px ${theme.colors.boxShadow}`,
		fontSize: '15px',
		fontWeight: 'light',
		lineHeight: '130%',
		maxWidth: '90%',
		p: {
			textAlign: 'left',
			overflowWrap: 'anywhere',
			margin: '10px 0',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			fontSize: '18px',
		},
		...theme.elements.messages.TextWrapper,
		...theme.elements.messages.TextWithButtonsTextWrapper,
	})};
`;
interface ITextButton extends IStyledComponentWithTheme {
	isButtonActive: boolean;
	isAnyButtonActive: boolean;
}
export const TextButton = styled.div`
	${({ theme, isButtonActive, isAnyButtonActive }: ITextButton) => {
		const isButtonActiveStyle = isButtonActive
			? {
					'&:hover': isAnyButtonActive
						? {}
						: {
								span: {
									color: theme.colors.colorPrimary,
									...theme.elements.messages.TextButtonIsButtonActiveHover,
								},
							},
				}
			: {
					filter: 'saturate(0)',
					...theme.elements.messages.TextButtonButtonInactive,
				};
		const isAnyButtonActiveStyle = isAnyButtonActive
			? {
					cursor: 'default',
					...theme.elements.messages.TextButtonIsAnyButtonActive,
				}
			: {
					cursor: 'pointer',
					...theme.elements.messages.TextButtonIsAnyButtonInactive,
				};
		return {
			width: '100%',
			height: '40px',
			backgroundColor: theme.colors.colorWhite,
			display: 'flex',
			alignItems: 'center',
			margin: '0 auto',
			marginTop: '10px',
			borderRadius: '4px',
			fontSize: '14px',
			justifyContent: 'center',
			cursor: 'pointer',
			border: `solid 1px ${theme.colors.colorPrimary}`,
			fontFamily: theme.fonts.primaryFont,
			'&:hover': {
				backgroundColor: theme.colors.colorWhite,
			},
			'&:active': {
				border: `solid 1px ${theme.colors.colorPrimary}`,
			},
			...isButtonActiveStyle,
			...isAnyButtonActiveStyle,
			...theme.elements.messages.TextWithButtonsTextButton,
		};
	}};
`;

export const ButtonContent = styled.span`
	${({ theme }) => ({
		display: 'block !important',
		fontWeight: 'bold !important',
		color: `${theme.colors.colorPrimary} !important`,
		...theme.elements.messages.TextWithButtonsButtonContent,
	})};
`;

export const TextButtonList = styled.div`
	${({ theme, isButtonActive, isAnyButtonActive }: ITextButton) => {
		const isButtonActiveStyle = isButtonActive
			? {
					'&:hover': isAnyButtonActive
						? {}
						: {
								span: {
									textDecoration: 'underline',
									color: theme.colors.colorPrimary,
									...theme.elements.messages.TextButtonListIsButtonActiveHover,
								},
							},
				}
			: {
					filter: 'saturate(0)',
					...theme.elements.messages.TextButtonListButtonInactive,
				};
		const isAnyButtonActiveStyle = isAnyButtonActive
			? {
					cursor: 'default',
					...theme.elements.messages.TextButtonListIsAnyButtonActive,
				}
			: {
					cursor: 'pointer',
					...theme.elements.messages.TextButtonListIsAnyButtonInactive,
				};
		return {
			width: 'fit-content',
			marginTop: '10px',
			borderRadius: '4px',
			fontSize: '13px',
			fontFamily: theme.fonts.primaryFont,
			...isButtonActiveStyle,
			...isAnyButtonActiveStyle,
			...theme.elements.messages.TextWithButtonsTextButtonList,
		};
	}};
`;

export const ButtonWrapper = styled.div`
	${({ theme }) => ({
		width: '100%',
		marginBottom: '15px',
		...theme.elements.messages.TextWithButtonsButtonWrapper,
	})};
`;

export const UserIndicator = styled.div`
	${({ theme }) => ({
		fontSize: '13px',
		color: theme.colors.colorBlack,
		marginBottom: '4px',
		...theme.elements.messages.TextWithButtonsUserIndicator,
	})};
`;
