import { createContext } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { isRtl } from '@Core/utils/rtl';
import { getPersistKeys } from '@Core/exports/storage/persistKeys';
import getStorageByName from '@Core/utils/storage/getStorageByName';
import useStateWithKey from '@Core/utils/hooks/useStateWithKey';
import getBrowserLanguage from '@Core/utils/language/getBrowserLanguage';
import { getServices } from './Services';
import deepMerge from '@Core/utils/objects/deepMerge';
import { getBridge } from '@Core/store/storeBridges';
import { OWNER } from '@Types/messages';
import { IAppConfig } from '@Types/appConfig';
import { ReactNode } from 'react';
import { WebStorage } from '@Types/storages';
import { IAppStore, IAppStoreElement } from '@Types/appStore';

const AppStore = createContext({} as IAppStore);

export function getAppStore(): IAppStore {
	try {
		const store = useContext(AppStore);
		return store;
	} catch (e) {
		const store = getBridge('app') as IAppStore;
		return store;
	}
}
export interface IAppStoreProvider {
	children: ReactNode;
	config: IAppConfig;
	platformAppStore: (args: {
		useState: typeof useState;
		useStateWithKey: typeof useStateWithKey;
		appStoreElement: (
			useStateArr: ReturnType<typeof useState> | ReturnType<typeof useStateWithKey>
		) => IAppStoreElement<any>;
		persistValue: <Type>(persistKey: string, defaultValue?: Type) => Type;
	}) => {
		[appStoreKey: string]: IAppStoreElement<any>;
	};
}

export const AppStoreProvider = ({ children, config, platformAppStore = () => null }: IAppStoreProvider) => {
	const [configState, setConfigState] = useState<IAppConfig>(
		deepMerge(config, { PUBLIC: window.__actionBot.config.PUBLIC })
	);

	const PERSIST_KEYS = getPersistKeys(config.PROTECTED.projectId);

	function getStorage(persistKey) {
		const defaultStorageName = config.PROTECTED.history.storage;
		const persistKeysConfig = config.PUBLIC.persistKeys;
		let storageName = WebStorage.sessionStorage;
		const persistKeyConfig = persistKeysConfig[persistKey];

		if (typeof persistKeyConfig === 'object' && persistKeyConfig.storage) {
			storageName = persistKeyConfig.storage;
		} else {
			storageName = defaultStorageName;
		}

		return getStorageByName(storageName);
	}

	function appStoreElement(useStateArr) {
		const value = useStateArr[0];
		const persistKey = useStateArr[2];

		return {
			value,
			set: (valueOrFunction) => {
				function saveLocal(value) {
					const storage = getStorage(persistKey);
					storage.setItem(persistKey, JSON.stringify(value));
				}

				function setCallback(prevState) {
					const newState = valueOrFunction(prevState);
					saveLocal(newState);
					return newState;
				}

				if (persistKey) {
					if (typeof valueOrFunction === 'function') {
						return useStateArr[1](setCallback);
					} else {
						saveLocal(valueOrFunction);
						return useStateArr[1](valueOrFunction);
					}
				} else {
					return useStateArr[1](valueOrFunction);
				}
			},
		};
	}

	function persistValue(persistKey: string, defaultValue = undefined) {
		const storageValue = getStorage(persistKey).getItem(persistKey);
		if (storageValue) {
			try {
				return storageValue == 'undefined' ? undefined : JSON.parse(storageValue);
			} catch (e) {
				return storageValue;
			}
		} else {
			return defaultValue;
		}
	}

	const sessionId = useStateWithKey(PERSIST_KEYS.sessionId, persistValue(PERSIST_KEYS.sessionId));
	const receivedSessionId = useState(false);
	const thinking = useState(false);
	const messages = useState([]);
	const modal = useState(null);
	const modalMinimized = useState(true);
	const scrollToBottomRef = useState(undefined);
	const language = useStateWithKey(
		PERSIST_KEYS.language,
		persistValue(PERSIST_KEYS.language, config.PUBLIC.language || getBrowserLanguage())
	);
	const rtl =
		config.PUBLIC.rtl === 'auto'
			? useState(isRtl(language[0]))
			: useState(persistValue(PERSIST_KEYS.rtl, config.PUBLIC.rtl));
	const recipient = useState(OWNER.bot);
	const agentConnection = useStateWithKey(PERSIST_KEYS.agentConnection, persistValue(PERSIST_KEYS.agentConnection));

	const agentInfo = useStateWithKey(
		PERSIST_KEYS.agentInfo,
		persistValue(PERSIST_KEYS.agentInfo, config.PUBLIC.displayMessage.default.agent)
	);
	const botInfo = useStateWithKey(
		PERSIST_KEYS.botInfo,
		persistValue(PERSIST_KEYS.botInfo, config.PUBLIC.displayMessage.default.bot)
	);
	const agentHandover = useState(false);
	const agentLeftChat = useState(false);
	const usernameVisibleToAgent = useStateWithKey(
		PERSIST_KEYS.usernameVisibleToAgent,
		persistValue(PERSIST_KEYS.usernameVisibleToAgent)
	);

	config.PUBLIC.rtl === 'auto' ? useState(isRtl()) : useState(persistValue(PERSIST_KEYS.rtl, config.PUBLIC.rtl));
	const isInputDisabled = useState(false);
	const messagesAfterRedirect = useState({ group: undefined, delayed: [] });
	const incognito = useState(''); // 'input', 'output', 'both',
	const unreadDefaultValue = {
		unreadCount: 0,
		unreadMessages: [],
	};
	let unread;
	if (config.PROTECTED.history.enabled) {
		const key = PERSIST_KEYS.unread;
		unread = useStateWithKey(key, persistValue(key, unreadDefaultValue));
	} else {
		unread = useState(unreadDefaultValue);
	}
	const minimized = useState(config.PUBLIC.minimized);
	const minimizeAvailable = useState(!config.PUBLIC.minimizeAvailableOnlyAfterFirstResponse);
	const sendMessageThreshold = useState(config.PROTECTED.sendMessageThreshold);
	const analytics = useState(config.PUBLIC.analytics);
	const waitingForAgent = useState(false);
	const anonymize = useState(false);
	const userSentFirstMessage = useState(false);
	const replyForFirstUserMessage = useState(false);
	const skipAgentConnection = useState(config.PROTECTED.agent.defaultSkipAgentConnection);
	const input = useState('');
	const mainMenu = useState(null);
	const mainMenuActive = useState(false);
	const initialPopup = useState(null);
	const dragging = useState(config.PUBLIC.dragging);
	const initialPopupSeen = useStateWithKey(
		PERSIST_KEYS.initialPopupSeen,
		persistValue(PERSIST_KEYS.initialPopupSeen)
	);
	const skipEvents = useStateWithKey(PERSIST_KEYS.skipEvents, persistValue(PERSIST_KEYS.skipEvents));
	const captcha = useStateWithKey(
		PERSIST_KEYS.captcha,
		persistValue(PERSIST_KEYS.captcha, {
			isActive: false,
			counter: 0,
		})
	);
	const themeExtension = useStateWithKey(PERSIST_KEYS.themeExtension, persistValue(PERSIST_KEYS.themeExtension));
	const position = useState({
		x: 0,
		y: 0,
		align: 'right',
		isDragging: false,
	});

	const watson = useState({
		context: {},
	});
	const actionbotUser = useStateWithKey(PERSIST_KEYS.actionbotUser, persistValue(PERSIST_KEYS.actionbotUser) || {});

	const fullScreen = useState(false);

	const remoteConfig = useStateWithKey(
		PERSIST_KEYS.remoteConfig,
		persistValue(PERSIST_KEYS.remoteConfig, {
			data: [],
		})
	);

	const platformBasedAppStore = platformAppStore({
		useState,
		useStateWithKey,
		appStoreElement,
		persistValue,
	});

	const rerender = useState(false);

	useEffect(() => {
		setTimeout(() => {
			const MessageService = getServices('MessageService');
			const messageService = new MessageService();

			messageService.removeMessagesOverLimit();
		}, 0);
	}, [messages[0]]);

	useEffect(() => {
		setConfigState((c) => {
			return deepMerge(c, config, { PUBLIC: window.__actionBot.config.PUBLIC });
		});
	}, [config]);

	function createAppStore() {
		return {
			config: configState,
			sessionId: appStoreElement(sessionId),
			receivedSessionId: appStoreElement(receivedSessionId),
			thinking: appStoreElement(thinking),
			messages: appStoreElement(messages),
			modal: appStoreElement(modal),
			modalMinimized: appStoreElement(modalMinimized),
			scrollToBottomRef: appStoreElement(scrollToBottomRef),
			rtl: appStoreElement(rtl),
			recipient: {
				...appStoreElement(recipient),
				isAgent: () => recipient[0] === OWNER.agent,
				isBot: () => recipient[0] === OWNER.bot,
			},
			agentConnection: appStoreElement(agentConnection),
			agentInfo: appStoreElement(agentInfo),
			botInfo: appStoreElement(botInfo),
			agentHandover: appStoreElement(agentHandover),
			agentLeftChat: appStoreElement(agentLeftChat),
			usernameVisibleToAgent: appStoreElement(usernameVisibleToAgent),
			language: appStoreElement(language),
			isInputDisabled: appStoreElement(isInputDisabled),
			messagesAfterRedirect: appStoreElement(messagesAfterRedirect),
			incognito: {
				...appStoreElement(incognito),
				isForInput: () => ['input', 'both'].includes(incognito[0]),
				isForOutput: () => ['output', 'both'].includes(incognito[0]),
			},
			unread: appStoreElement(unread),
			minimized: appStoreElement(minimized),
			minimizeAvailable: appStoreElement(minimizeAvailable),
			analytics: appStoreElement(analytics),
			waitingForAgent: appStoreElement(waitingForAgent),
			sendMessageThreshold: appStoreElement(sendMessageThreshold),
			anonymize: appStoreElement(anonymize),
			userSentFirstMessage: appStoreElement(userSentFirstMessage),
			replyForFirstUserMessage: appStoreElement(replyForFirstUserMessage),
			skipAgentConnection: appStoreElement(skipAgentConnection),
			input: appStoreElement(input),
			mainMenu: appStoreElement(mainMenu),
			mainMenuActive: appStoreElement(mainMenuActive),
			initialPopup: appStoreElement(initialPopup),
			initialPopupSeen: appStoreElement(initialPopupSeen),
			skipEvents: appStoreElement(skipEvents),
			watson: appStoreElement(watson),
			fullScreen: appStoreElement(fullScreen),
			remoteConfig: appStoreElement(remoteConfig),
			themeExtension: appStoreElement(themeExtension),
			position: appStoreElement(position),
			dragging: appStoreElement(dragging),
			actionbotUser: appStoreElement(actionbotUser),
			captcha: appStoreElement(captcha),
			_forceRerender: () => rerender[1]((r) => !r),
			...platformBasedAppStore,
		};
	}

	return <AppStore.Provider value={createAppStore()}>{children}</AppStore.Provider>;
};
