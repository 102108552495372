import { MenuModal, MenuBoxContainer, MenuBox } from './MenuModal.styled';
import { getAppStore } from '@Core/store/App';
import { Message } from '@Core/utils/models/messages';
import { getServices } from '@Core/store/Services';

interface IMenuModal {
	open: boolean;
	toggleMenu: () => void;
	onOptionSelect: (command: string) => void;
}

export default ({ open, toggleMenu, onOptionSelect }: IMenuModal) => {
	const store = getAppStore();
	let menu = null;
	const ActionsService = getServices('ActionsService');
	const actionsService = new ActionsService();

	function handleClick({ action, command }) {
		if (action && actionsService.exists(action)) {
			const actionMessage = new Message({
				isAction: true,
			});

			actionsService.getCaseInsensitiveProp(action)(actionMessage);
		} else {
			onOptionSelect(command);
		}
		toggleMenu();
	}

	const isFullScreen = getAppStore().fullScreen.value;

	if (store.mainMenu.value) {
		return (
			<MenuModal open={open} className={isFullScreen ? 'fullscreen' : ''}>
				{store.mainMenu.value.map((item, i) => (
					<MenuBoxContainer key={i}>
						<MenuBox onClick={() => handleClick(item)}>
							<img src={item.img} />
							<p>{item.text}</p>
						</MenuBox>
					</MenuBoxContainer>
				))}
			</MenuModal>
		);
	}
};
