function additionalZero(number: number) {
	return number > 9 ? number : '0' + number;
}

function getDate(date: Date) {
	if (date instanceof Date) {
		const hours = date.getHours();
		const minutes = date.getMinutes();

		return `${additionalZero(hours)}:${additionalZero(minutes)}`;
	} else {
		return date;
	}
}

export { additionalZero, getDate };
