import { Service } from '@Core/utils/services';
import { IMessage } from '@Types/messages';

export default class ButtonMessageService extends Service {
	message: IMessage;

	constructor({ message }: { message: IMessage }) {
		super();
		this.message = message;
	}

	onClick = () => {
		// configure from platform
	};
}
