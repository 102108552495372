import { IBehaviorConfig } from '@Types/behaviorConfig';
import { Events } from '@Types/events';

export default {
	inactivity: {},
	events: {
		[Events.action]: ({ actionName, message }) => {},
		[Events.command]: (type) => {},
		[Events.messagesChanged]: (messages) => {},
		[Events.load]: () => {},
		[Events.minimize]: () => {},
		[Events.maximize]: () => {},
		[Events.addMessage]: (message) => {},
		[Events.addMessageGroup]: (messageGroup) => {},
		[Events.redirect]: (url) => {},
		[Events.pageSpendTime]: () => {},
		[Events.pageLoad]: (e) => {},
		[Events.formFocus]: (e) => {},
		[Events.formSubmit]: (e) => {},
		[Events.scrollPage]: () => {},
		[Events.nervousScrolling]: () => {},
		[Events.nervousClicking]: () => {},
		[Events.dataLayerPush]: (e) => {},
		[Events.navigationIssue]: () => {},
	},
	pageSpendTime: {},
	scrollPage: {},
	nervousScrolling: {},
	nervousClicking: {},
	navigationIssue: {},
} as IBehaviorConfig;
