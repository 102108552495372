import { IStyles } from '@Types/css';
import styled from 'styled-components';
import actionbotLogo from '@Core/assets/icons/actionbot_logo.svg';
export const BotChatIcon = styled.div`
	${({ theme }) => {
		return {
			width: '100%',
			height: '100%',
			position: 'absolute',
			alignItems: 'center',
			justifyContent: 'center',
			top: 'unset',
			backgroundImage: `url("${actionbotLogo}")`,
			zIndex: '100',
			cursor: 'pointer',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			boxShadow: '1px 1px 10px grey',
			borderRadius: '50%',
			backgroundSize: '60%',
			...theme.elements.BotChatIcon,
		};
	}};
`;
interface IBotMinimizedContainer extends IStyles {
	active: boolean;
	userSentMessage: boolean;
	elementWidth: number;
	elementHeight: number;
	positionRight: number;
	positionBottom: number;
}

export const BotMinimizedContainer = styled.div<IBotMinimizedContainer>`
	${({ theme, active, userSentMessage, elementWidth, elementHeight, positionRight, positionBottom }) => {
		return {
			justifyContent: 'space-between',
			alignItems: 'center',
			overflow: 'visible',
			display: 'block',
			position: 'fixed',
			width: `${elementWidth}px`,
			height: `${elementHeight}px`,
			right: `${positionRight}px`,
			bottom: `${positionBottom}px`,
			backgroundColor: theme.colors.white,
			transition: 'all .3s',
			transform: active ? 'scale(0) !important' : 'scale(1)',
			borderRadius: '0',
			zIndex: '10000',
			span: {
				position: 'absolute',
				top: '-5px',
				right: '-5px',
			},
			img: {
				display: 'none',
			},
			...theme.elements.BotMinimizedContainer,
		};
	}};
`;

interface IUnreadCounter extends IStyles {
	unreadCount: number;
}

export const UnreadCounter = styled.span<IUnreadCounter>`
	${({ theme, unreadCount }) => ({
		background: theme.colors.darkPrimary,
		borderRadius: '100%',
		width: '24px',
		height: '24px',
		textAlign: 'center',
		color: 'white',
		display: 'flex',
		opacity: unreadCount ? 1 : 0,
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '11px',
		animationName: 'show-message',
		animationDuration: '.3s',
		animationIterationCount: 1,
		animationFillMode: 'forwards',
		...theme.elements.UnreadCounter,
	})};
`;

export const NewMinimizedMessages = styled.div`
	${({ theme }) => ({
		position: 'absolute',
		bottom: '60px',
		right: '40px',
		borderRadius: '8px',
		fontSize: '1.6rem',
		color: '#e9008f',
		minWidth: '200px',

		'&.withAnimation': {
			animationName: 'show-message',
			animationDuration: '.3s',
			animationIterationCount: 1,
			animationFillMode: 'forwards',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			right: '10px',
		},
		...theme.elements.NewMinimizedMessages,
	})};
`;

export const NewMinimizedMessage = styled.div`
	${({ theme }) => ({
		margin: '8px',
		lineHeight: '18px',
		background: 'white',
		boxShadow: '0 0 28px rgba(45,99,251,.25)',
		borderRadius: '10px',
		padding: '15px 20px',
		fontWeight: '700',
		'&:after': {
			content: '""',
			display: 'block',
			position: 'absolute',
			bottom: '-5px',
			right: '8px',
			width: '0',
			height: '0',
			border: '14px solid',
			borderRight: '23px solid',
			borderColor: ' transparent white transparent transparent ',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			maxWidth: '160px',
			'&:after': {
				content: '""',
				display: 'block',
				position: 'absolute',
				bottom: '0px',
				right: '32px',
				width: '0',
				height: '0',
				border: '14px solid',
				borderRight: '23px solid',
				borderColor: ' transparent white transparent transparent ',
			},
		},
		...theme.elements.NewMinimizedMessage,
	})};
`;

export const BoxElementWrapper = styled.div`
	${({ theme }) => ({
		position: 'fixed',
		cursor: 'unset',
		userSelect: 'none',
		bottom: '0px',
		right: '0px',
		...theme.elements.BoxElementWrapper,
	})};
`;
