import { IMessage } from '@Types/messages';
import { useState } from 'preact/hooks';
import { VisitingCardButton, VisitingCardContainer, VisitingCardWrapper } from './VisitingCard.styled';

export interface IVisitingCardMessage {
	message: IMessage;
	onOptionSelects: (url: string) => void;
}

export default ({ message, onOptionSelects }: IVisitingCardMessage) => {
	const [isInNewTabDefault, setIsInNewTabDefault] = useState(false);

	const onOptionSelect = ({ url, config }) => {
		let inNewTab = false;
		let isIntent = false;

		const { newTab } = config;
		const { intent } = config;

		if (newTab) {
			if (['true', 'false'].includes(newTab)) {
				inNewTab = newTab === 'true';
			}
		} else if (intent) {
			if (['true', 'false'].includes(intent)) {
				isIntent = intent === 'true';
			}
		} else {
			inNewTab = isInNewTabDefault;
		}

		if (inNewTab) {
			window.open(url, '_blank');
		} else if (isIntent) {
			onOptionSelects(url);
		} else {
			window.location.assign(url);
		}
	};

	if (message.content) {
		const items = message.content;
		const itemsKeys = Object.keys(items);
		const options = [];

		itemsKeys.forEach((singleItemKey) => {
			const value = items[singleItemKey];
			// Support;newTab=true : url - single item config
			// newTab : true - all items config
			// single > all items
			if (singleItemKey === 'newTab') {
				setIsInNewTabDefault(value === 'true');
			} else {
				const keyWithConfig = singleItemKey.split(';');
				const label = keyWithConfig.shift();
				const url = value;
				const config = {};

				keyWithConfig.forEach((singleConfig) => {
					const [param, value] = singleConfig.split('=');
					config[param] = value;
				});

				options.push(
					<VisitingCardButton
						onClick={() => onOptionSelect({ url, config })}
						//disabled={singleOption.disabled}
					>
						{label}
					</VisitingCardButton>
				);
			}
		});

		return (
			<VisitingCardContainer owner={message.owner}>
				<VisitingCardWrapper>{options}</VisitingCardWrapper>
			</VisitingCardContainer>
		);
	}
};
