import { useRef } from 'preact/hooks';
import { IBotWebsocket, IWebsocketMessage } from '@Types/websocket';

interface IUseWebsocket {
	url: string;
	onOpen: () => void;
	onClose: () => void;
	onMessage: (message: IWebsocketMessage) => void;
	onError: (this: WebSocket, ev: Event) => any;
}

export default function useWebsocket({
	url,
	onOpen = () => {},
	onClose = () => {},
	onMessage = () => {},
	onError = () => {},
}: IUseWebsocket) {
	if (!url) {
		return null;
	}

	function getWebSocket() {
		return websocket.current;
	}

	const createWebsocket = () => {
		let ws = new WebSocket(url) as IBotWebsocket;
		ws.isWSOpened = () => {
			return ws.readyState === ws.OPEN;
		};
		ws.sendWS = (data) => {
			ws.send(data);
		};
		ws.onclose = onClose;
		ws.onopen = onOpen;
		ws.onmessage = onMessage;
		ws.onerror = onError;

		ws.closeWS = () => {
			ws.close();
		};
		ws.openWS = () => {
			websocket.current = createWebsocket();
		};
		return ws;
	};

	const websocket = useRef(createWebsocket());
	window.__actionBot.getWebSocket = getWebSocket;

	return getWebSocket;
}
