import { IStyledComponentWithTheme } from '@Types/themes';
import styled from 'styled-components';

const positions = {
	left: 'flex-start',
	right: 'flex-end',
	center: 'center',
};

function getPosition(position) {
	const defaultPosition = 'flex-start';
	try {
		const _positon = position.toLowerCase().trim();
		const flexPosition = positions[_positon];
		return flexPosition || defaultPosition;
	} catch (e) {
		return defaultPosition;
	}
}

export const SurveyContainer = styled.div<IStyledComponentWithTheme & { position: 'left' | 'right' }>`
	${({ theme, position }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: getPosition(position) + ' !important',
		...theme.elements.messages.Survey,
	})};
`;

export const SurveyDescription = styled.p`
	${({ theme }) => ({
		color: theme.colors.colorBlack,
		marginBottom: '10px',
		opacity: 0.6,
		fontSize: '12px !important',
		...theme.elements.messages.SurveyDescription,
	})};
`;

export const SurveyButton = styled.span`
	${({ theme }) => ({
		cursor: 'pointer',
		margin: '8px',
		marginLeft: 0,
		...theme.elements.messages.SurveyButton,
	})};
`;
