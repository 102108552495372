// Dictionary implementation

import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import coreDictionary, { CoreDictionary } from '@Core/dictionary/coreDictionary';
import { getAppStore } from '@Core/store/App';
import { getBridge } from '@Core/store/storeBridges';
import deepMerge from '@Core/utils/objects/deepMerge';
import { ReactNode } from 'react';

export type PlatformDictionary = { [key: string]: { [lang: string]: string | string[] } };
export type Dictionary = CoreDictionary & PlatformDictionary;
export type DictionaryOutput = { [key in keyof Dictionary]: string | string[] };
export type DictionaryKey = string;

interface IDictionaryProvider {
	children: ReactNode;
	dictionary: Dictionary;
}

const DictionaryContext = createContext<Dictionary>({} as Dictionary);

export function getDictionary(): DictionaryOutput {
	const language = getAppStore().language.value;

	let dictionary;

	try {
		dictionary = useContext(DictionaryContext);
	} catch (e) {
		dictionary = getBridge('dictionary');
	}

	const nationalDictionary = {} as DictionaryOutput;
	for (const key in dictionary) {
		if (!dictionary[key][language]) {
			nationalDictionary[key] = dictionary[key];
		} else {
			nationalDictionary[key] = Array.isArray(dictionary[key][language])
				? dictionary[key][language]
				: [dictionary[key][language]];
		}
	}
	return nationalDictionary;
}

export const DictionaryProvider = ({ children, dictionary }: IDictionaryProvider) => {
	function createDictionaryProvider() {
		return {
			...coreDictionary,
			...dictionary,
		};
	}

	return <DictionaryContext.Provider value={createDictionaryProvider()}>{children}</DictionaryContext.Provider>;
};

export function createDictionary(dictionary: PlatformDictionary) {
	return deepMerge(coreDictionary, dictionary, window.__actionBot.dictionary || {});
}
