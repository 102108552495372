import { createThemeExtension } from '@Core/exports/theme';
import avatar from '@Platform/assets/icons/ico_avatar_small_yellow_on_black.svg';
import sendIcon from '@Platform/assets/icons/ico_send_yellow.svg';
import avatarBig from '@Platform/assets/icons/ico_avatar_big_yellow_on_black.svg';
import { ITheme } from '@Core/types/themes';

export default createThemeExtension('yellowOnBlack', {
	colors: {
		primary: '#f7ff02',
		lightPrimary: '#f7ff02',
		hoverPrimary: '#000000',
		white: '#000000',
		shadow: 'rgba(32,37,43, .25)',
		beton: '#000000',
		gray: '#000000',
		cream: '#000000',
		lightGray: '#000000',
		silver: '#f7ff02',
		red: '#f7ff02',
		black: '#f7ff02',
		botTriangleBefore: '#f7ff02',
		userTriangleBefore: '#f7ff02',
		myMessageColor: '#f7ff02',
		myMessageBackgroundColor: '#000000',
		inputContainerBorderTopColor: '#f7ff02',
		placeholderColor: '#f7ff02',
		messageBotBoxShadow: '#000000',
		myMessageBorderColor: '#f7ff02',
		botMessageBorderColor: '#f7ff02',
		headerContainerColor: '#f7ff02',
		headerContainerBackgroundColor: '#000000',
		poweredByColor: '#f7ff02',
		poweredByBorderTopColor: '#f7ff02',
		poweredByAColor: '#f7ff02',
		linkColor: '#f7ff02',
		textWithButtonsListColor: '#f7ff02',
		headerContainerTitleColor: '#f7ff02',
		textWithButtonsBackgroundColor: '#000000',
		textWithButtonsColor: '#f7ff02',
		userIndicatorColor: '#f7ff02',
	},
	elements: {
		BotWindow: {
			boxShadow: '0 4px 16px rgba(0, 0, 0, 0.25)',
			border: '1px solid #f7ff02',
		},

		HeaderContainer: {
			boxShadow: 'none',
			borderBottom: '1px solid #f7ff02',
		},

		HeaderIconContainer: {
			'div:after': {
				color: '#f7ff02',
			},
			'&:hover': {
				'div:after': {
					color: '#f7ff02',
				},
			},
		},

		MessageInput: {
			'&::placeholder': {
				opacity: 0.7,
			},
		},

		SubmitButton: {
			backgroundImage: `url("${sendIcon}")`,
			'&:disabled': {
				backgroundImage: `url("${sendIcon}")`,
				opacity: '0.6',
			},
		},

		InitialPopupClose: {
			backgroundColor: '#000000 !important',
			div: {
				color: '#f7ff02 !important',
			},
			'&:hover': {
				backgroundColor: '#f7ff02 !important',
				div: {
					color: '#000000 !important',
				},
			},
		},

		BotChatIcon: {
			backgroundImage: `url("${avatarBig}")`,
			width: '100%',
			height: '100%',
		},

		BotTalkImage: {
			backgroundColor: 'unset',
			'@media (max-width: 576px)': {
				backgroundColor: 'unset',
			},
		},

		messages: {
			MessagesGroupAvatar: {
				'&.bot': {
					backgroundImage: `url(${avatar})`,
				},
			},

			TextWithButtonsTextButton: {
				border: '1px solid #f7ff02',
				'&:hover': {
					backgroundColor: '#f7ff02',
					span: {
						color: '#000000 !important',
					},
				},
			},

			OptionButton: {
				'&:not(:disabled):hover': {
					backgroundColor: '#f7ff02',
					color: '#000000 !important',
				},
			},
		},
	},
} as ITheme);
