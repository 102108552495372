import { Service } from '@Core/utils/services';
import { Message } from '@Core/utils/models/messages';
import { IMessage, OWNER } from '@Types/messages';
import { FileTypes } from '@Core/utils/consts';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import useDictionary from '@Core/utils/language/useDictionary';

interface ISendUserAttachment {
	fileList: File[];
	message: IMessage;
}
export default class AttachmentService extends Service {
	onError = (e: unknown, message?: IMessage) => {
		const { translate } = useDictionary();
		const { MessageService } = this.getServices();
		const messageService = new MessageService();

		console.error(e);

		const errorMessage = new Message({
			type: 'text',
			owner: OWNER.system,
			content: translate('upload.error.not_sent'),
		});

		messageService.addMessage(errorMessage);
	};

	onIncorrectFileType = () => {
		const { translate } = useDictionary();

		const { MessageService } = this.getServices();
		const messageService = new MessageService();

		messageService.addMessage(
			new Message({
				type: 'text',
				owner: OWNER.system,
				content: translate('upload.error.not_supported_format'),
			})
		);
	};

	sendUserAttachment = ({ fileList, message }: ISendUserAttachment): Promise<boolean> => {
		const { config } = getAppStore();
		const { ConnectionService } = getServices();
		const connectionService = new ConnectionService();

		const { content } = message;
		const { addresseMail } = content;

		const { url, mail } = config.PUBLIC.userAttachment;

		const formData = new FormData();

		fileList.forEach((file) => {
			formData.append('file', file);
		});

		formData.append('mail', addresseMail ? addresseMail : mail);

		return fetch(url, {
			method: 'POST',
			body: formData,
		})
			.then((response) => {
				if (!response || !response.ok) {
					this.onError(response, message);
					connectionService.sendEvent('fileUpload', {
						success: 'false',
					});
					return false;
				} else {
					connectionService.sendEvent('fileUpload', {
						success: 'true',
					});
					return true;
				}
			})
			.catch((e) => {
				this.onError(e);
				return false;
			});
	};

	fileTypes = FileTypes;
}
