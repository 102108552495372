import { ThinkingContainer, ThinkingMessage } from './Thinking.styled';
import { getAppStore } from '@Core/store/App';
import { OWNER } from '@Types/messages';
import useDictionary from '@Core/utils/language/useDictionary';

export default () => {
	const isThinking = getAppStore().thinking.value;
	const { translate } = useDictionary();

	return (
		isThinking && (
			<ThinkingContainer aria-label={translate('aria.chat.is-writing')} owner={OWNER.bot}>
				<ThinkingMessage aria-hidden={true} />
			</ThinkingContainer>
		)
	);
};
