import { Service } from '@Core/utils/services';
import { RefObject } from 'preact';

export default class ControlService extends Service {
	registerScrollToBottomRef(ref: RefObject<HTMLDivElement>) {
		// Used to register the ref of the scrollable container

		// Get the ref from the store
		const scrollToBottomRef = this.getAppStore().scrollToBottomRef;

		// Get value of the ref
		const refInStore = scrollToBottomRef.value;

		// Check if the new ref is the same as the one in store
		const isSameRef = refInStore === ref;

		// If new ref is the same as the one in store, do nothing
		if (isSameRef) return;

		// Update when there is no refInStore
		if (!refInStore) return scrollToBottomRef.set(ref);

		// Update when there is no value in refInStore
		if (!refInStore.current) return scrollToBottomRef.set(ref);
	}

	scrollToBottom = () => {
		// Used to scroll to the bottom of the scrollable container

		// Get the value of the ref from the store
		const scrollToBottomRef = this.getAppStore().scrollToBottomRef.value;

		// If there is no ref, do nothing
		if (!scrollToBottomRef) return;

		// If there is no value in the ref, do nothing
		if (!scrollToBottomRef.current) return;
		scrollToBottomRef.current.scroll({
			top: scrollToBottomRef.current.scrollHeight,
			behavior: 'smooth',
		});
	};

	changeRtl = (isRtl: boolean) => {
		this.getAppStore().rtl.set(isRtl);
	};
}
