import styled from 'styled-components';

export const PostcardSwipeItem = styled.div`
	${({ theme }) => ({
		position: 'relative',
		marginRight: '1.2rem',
		marginLeft: '1.2rem',
		'&:focus': {
			outline: 'none',
		},
		float: 'none',
		margin: '0',
		borderRadius: '0',
		border: 'none',
		background: theme.colors.colorWhite,
		color: theme.colors.carousel,
		cursor: 'pointer',
		boxShadow: `0 0 10px ${theme.colors.boxShadow}`,
		'> div:first-child': {
			color: theme.colors.colorPrimary,
			fontSize: '20px',
			fontWeight: 'bold',
			img: {
				margin: 0,
			},
			div: {
				margin: 0,
			},
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			//	width: '130px !important',
			//	height: '130px',
		},
		...theme.elements.messages.PostcardSwipeItem,
	})};
`;

export const PostcardCarouselContainer = styled.div`
	${({ theme }) => ({
		position: 'relative',
		left: '-25px',
		width: 'calc(100% + 45px)',
		margin: '0',
		paddingBottom: '0',
		borderRadius: '0',
		'.slick-dots': {
			display: 'none !important',
		},
		'.slick-slider:before, .slick-slider:after': {
			content: '""',
			position: 'absolute',
			zIndex: 10,
			top: '0',
			bottom: '0',
			width: '15px',
			pointerEvents: 'none',
		},
		'.slick-slider:before': {
			left: '0',
			background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
		},
		'.slick-slider:after': {
			right: '0',
			background: 'linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
		},
		marginTop: '10px',
		'.slick-track': {
			marginLeft: '35px',
			display: 'flex',
		},
		'.slick-slide': {
			height: 'auto',
		},
		'.slick-slide > div': {
			margin: '10px 0 10px 10px',
			height: 'calc(100% - 20px)',
		},
		'.slick-slide > div > div': {
			borderRadius: '0',
			height: '100%',
		},
		'.slick-slide > div > div:hover': {
			boxShadow: `0 0 0 2px ${theme.colors.primary}`,
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			'.slick-track': {
				marginLeft: '15px',
			},
			'.slick-slide': {
				maxWidth: '240px !important',
			},
		},
		...theme.elements.messages.PostcardCarouselContainer,
	})};
`;

export const PostcardCarouselButton = styled.div`
	${({ theme }) => ({
		padding: '5px 15px',
		borderRadius: '15px',
		border: theme.colors.white,
		backgroundColor: theme.colors.whiteGradient,
		fontSize: '0.7rem',
		transition: 'background-color .3s, color .3s',
		position: 'absolute',
		bottom: 0,
		'&:not(:disabled):hover': {
			color: theme.colors.white,
			backgroundColor: theme.colors.veryLightPink,
			borderColor: theme.colors.veryLightPink,
			cursor: 'pointer',
		},
		...theme.elements.messages.PostcardCarouselButton,
	})};
`;

export const PostcardCarouselButtonLeft = styled(PostcardCarouselButton)`
	${({ theme }) => ({
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			display: 'none',
		},
		fontSize: 0,
		background: theme.colors.white,
		height: '36px',
		borderRadius: '50px',
		width: '36px',
		top: '50%',
		transform: 'translateY(-50%)',
		left: '20px',
		zIndex: 10,
		transition: '.3s all',
		boxShadow: '2px 2px 30px 0 rgba(61, 61, 61, 0.4)',
		'&:hover': {
			backgroundColor: theme.colors.primary + '!important',
			transition: '.3s all',
			'&:after': {
				borderColor: theme.colors.white,
				transition: 'inherit',
			},
		},
		'&:after': {
			content: '""',
			transition: 'inherit',
			width: '8px',
			height: '8px',
			borderLeft: '2px solid',
			borderTop: '2px solid',
			borderColor: theme.colors.primary,
			position: 'absolute',
			transform: 'translateY(-50%) rotate(-45deg)',
			top: '50%',
			left: '14px',
		},
		...theme.elements.messages.PostcardCarouselButtonLeft,
	})};
`;

export const PostcardCarouselButtonRight = styled(PostcardCarouselButton)`
	${({ theme }) => ({
		right: '75px',
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			display: 'none',
		},
		fontSize: 0,
		background: theme.colors.white,
		height: '36px',
		borderRadius: '50px',
		width: '36px',
		top: '50%',
		transform: 'translateY(-50%)',
		zIndex: 10,
		transition: '.3s all',
		boxShadow: '2px 2px 30px 0 rgba(61, 61, 61, 0.4)',
		'&:hover': {
			transition: '.3s all',
			backgroundColor: theme.colors.primary + '!important',
			'&:after': {
				transition: 'inherit',
				borderColor: theme.colors.white,
			},
		},
		'&:after': {
			content: '""',
			transition: 'inherit',
			width: '8px',
			height: '8px',
			borderLeft: '2px solid',
			borderTop: '2px solid',
			borderColor: theme.colors.primary,
			position: 'absolute',
			transform: 'translateY(-50%) rotate(135deg)',
			top: '50%',
			right: '14px',
		},
		...theme.elements.messages.PostcardCarouselButtonRight,
	})};
`;

export const PostcardCarouselImage = styled.img`
	${({ theme }) => ({
		display: 'inline !important',
		width: '100%',
		height: 'auto',
		...theme.elements.messages.PostcardCarouselImage,
	})};
`;

export const PostcardCarouselValue = styled.div`
	${({ theme }) => ({
		display: 'inline',
		float: 'right',
		margin: '1rem',
		fontWeight: 'bold',
		...theme.elements.messages.PostcardCarouselValue,
	})};
`;

export const ClickField = styled.div`
	${({ theme }) => ({
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'transparent',
		...theme.elements.messages.ClickField,
	})};
`;

export const PostcardCarouselTitle = styled.div`
	${({ theme }) => ({
		position: 'relative',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		fontSize: '17px',
		fontWeight: '600',
		lineHeight: '1.3',
		padding: '13px',
		...theme.elements.messages.PostcardCarouselTitle,
	})};
`;
