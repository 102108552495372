import { IMessageMenuMessage } from '@Core/components/Menu/Menu';
import { IMessageInput } from '@Core/components/MessageInput/MessageInput';
import { IAttachmentMessage } from '@Core/components/Messages/Attachment/Attachment';
import { IButtonMessage } from '@Core/components/Messages/Button/Button';
import { ICalendarMessage } from '@Core/components/Messages/Calendar/Calendar';
import { ICarouselMessage } from '@Core/components/Messages/Carousel/Carousel';
import { IEmbeddedMapMessage } from '@Core/components/Messages/EmbededMap/EmbeddedMap';
import { IFormMessage } from '@Core/components/Messages/Form/Form';
import { IGifMessage } from '@Core/components/Messages/Gif/Gif';
import { IIconGridMessage } from '@Core/components/Messages/IconGrid/IconGrid';
import { IImageMessage } from '@Core/components/Messages/Image/Image';
import { IImageGalleryMessage } from '@Core/components/Messages/ImageGallery/ImageGallery';
import { IOptionMessage } from '@Core/components/Messages/Option/Option';
import { ISuggestionMessage } from '@Core/components/Messages/Suggestion/Suggestion';
import { ISurveyMessage } from '@Core/components/Messages/Survey/Survey';
import { ITextMessage } from '@Core/components/Messages/Text/Text';
import { IWaitingMessage } from '@Core/components/Messages/WaitingMessage/WaitingMessage';
import { IVisitingCardMessage } from '@Core/components/Messages/VisitingCard/VisitingCard';
import { IYouTubeMessage } from '@Core/components/Messages/YouTube/YouTube';
import { WatsonCommands } from '@Core/services/ActionsService';
import { FC } from 'react';
import { IAgentInfo } from './appStore';
import { WatsonResponseType } from './watson';

export enum OWNER {
	bot = 'bot',
	user = 'user',
	agent = 'agent',
	system = 'system',
}

export type Recipient = OWNER;

export interface IMessage {
	date: Date;
	owner?: OWNER;
	type?: WatsonCommands | MessageType;
	content?: any;
	isAction?: boolean;
	id: number;
	description?: string;
	isMessageWithAction?: boolean;
	isMessageWithActionDone?: boolean;
	isHidden?: boolean;
	isSent?: boolean;
	existsWhenLast?: boolean;
}

export interface IMessagesAfterRedirect {
	group: IMessagesGroup;
	delayed: {
		message: IMessage;
		typing: boolean;
		orginalDelay: number;
		delay: number;
	}[];
}

export type MessageConstructor = Pick<IMessage, 'owner' | 'type' | 'content'> & {
	isAction?: IMessage['isAction'];
	id?: IMessage['id'];
};

export interface IMessagesGroup {
	owner: OWNER;
	messages?: IMessage[];
	isLastGroup?: boolean;
	withoutAvatar?: boolean;
	withoutDate?: boolean;
	agentInfo?: IAgentInfo;
}

export type MessagesGroupConstructor = Pick<IMessagesGroup, 'owner' | 'messages' | 'agentInfo'>;

export interface IMessageComponents {
	Text?: FC<ITextMessage>;
	Image?: FC<IImageMessage>;
	Option?: FC<IOptionMessage>;
	OnlyOption?: FC<IOptionMessage>;
	PermanentOption?: FC<IOptionMessage>;
	Carousel?: FC<ICarouselMessage>;
	ImageGallery?: FC<IImageGalleryMessage>;
	VisitingCard?: FC<IVisitingCardMessage>;
	Form?: FC<IFormMessage>;
	Suggestion?: FC<ISuggestionMessage>;
	YouTube?: FC<IYouTubeMessage>;
	Gif?: FC<IGifMessage>;
	Button?: FC<IButtonMessage>;
	EmbeddedMap?: FC<IEmbeddedMapMessage>;
	Survey?: FC<ISurveyMessage>;
	Attachment?: FC<IAttachmentMessage>;
	MessageMenu?: FC<IMessageMenuMessage>;
	IconGrid?: FC<IIconGridMessage>;
	MessageInput?: FC<IMessageInput>;
	Calendar?: FC<ICalendarMessage>;
	WaitingMessage?: FC<IWaitingMessage>;
}

export type MessageType =
	| WatsonResponseType
	| 'onlyoption'
	| 'permanentoption'
	| 'carousel'
	| 'imagegallery'
	| 'visitingcard'
	| 'formmerge'
	| 'youtube'
	| 'gif'
	| 'button'
	| 'embeddedmap'
	| 'survey'
	| 'attachment'
	| 'menu'
	| 'icongrid'
	| 'calendar'
	| 'textwithbuttons'
	| 'readmore'
	| 'messageattachment'
	| 'userattachment'
	| 'waitingmessage';
