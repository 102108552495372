export enum WebStorage {
	sessionStorage = 'sessionStorage',
	localStorage = 'localStorage',
}

export type PersistKeyObject = {
	storage: WebStorage;
};

export type PersistKeys = {
	[PERSIST_KEYS: string]: PersistKeyObject | string;
};

export type ActionBotStorage = keyof typeof WebStorage | 'cookies' | 'cookiesExpired' | 'server';
