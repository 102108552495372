import { createTheme } from '@Core/exports/theme';
import avatar from '@Platform/assets/icons/ico_avatar_default.svg';
import sendIcon from '@Platform/assets/icons/send_icon.svg';
import sendIconInactive from '@Platform/assets/icons/send_icon_inactive.svg';
import avatarBig from '@Platform/assets/icons/avatar_white.svg';
import { CSSObject } from '@Core/types/css';
const podyplomoweUrl = 'studiapodyplomowe.us.edu.pl';
interface IStylesObject {
	podyplomowe?: CSSObject;
	default?: CSSObject;
}
interface IStylesValue {
	podyplomowe?: any;
	default?: any;
}
function stylesObject(stylesObject: IStylesObject) {
	let currentTarget = 'default';
	if (window.location.host.includes(podyplomoweUrl)) {
		currentTarget = 'podyplomowe';
	}
	return stylesObject[currentTarget] || {};
}
function stylesValue(stylesObject: IStylesValue) {
	let currentTarget = 'default';
	if (window.location.host.includes(podyplomoweUrl)) {
		currentTarget = 'podyplomowe';
	}
	return stylesObject[currentTarget] || 'inherit';
}

const colors = {
	primary: '#002e5a',
	lightPrimary: '#38507b',
	hoverPrimary: '#dfe2ec',
	white: '#ffffff',
	shadow: 'rgba(32,37,43, .25)',
	beton: '#cccccc',
	gray: '#6f6f6e',
	cream: '#F4E6E3',
	lightGray: '#f5f5f5',
	silver: '#e8e8e8',
	red: '#c11c17',
	black: '#0d0c0e',
	botTriangleBefore: '#ffffff',
	userTriangleBefore: '#002e5a',
	myMessageColor: '#ffffff',
	myMessageBackgroundColor: '#002e5a',
	inputContainerBorderTopColor: '#ececec',
	placeholderColor: '#b3b3b5',
	messageBotBoxShadow: 'rgba(10, 31, 68, 0.15)',
	myMessageBorderColor: 'transparent',
	botMessageBorderColor: 'transparent',
	headerContainerBackgroundColor: '#002e5a',
	headerContainerTitleColor: '#ffffff',
	poweredByColor: '#aeaeae',
	poweredByAColor: '#a4a3a5',
	poweredByBorderTopColor: '#ececec',
	linkColor: '#0095db',
	textWithButtonsListColor: '#3363c1',
	textWithButtonsBackgroundColor: '#e9ecef',
	textWithButtonsColor: '#002e5a',
	userIndicatorColor: '#393a3b',
};
const fonts = {
	primary: 'PT Sans',
};

const shadow = '6px 6px 8px ' + colors.shadow;

export default createTheme(
	({ colors, fonts }) => ({
		colors,
		fonts: {
			primary: 'PT Sans',
			primaryFont: 'PT Sans',
		},

		elements: {
			BotWindow: {
				width: '370px',
				backgroundColor: colors.white,
				boxShadow: '0 4px 16px rgba(0, 0, 0, 0.25)',
				bottom: '35px',
				right: '25px',
				zIndex: 100000,
				height: 'calc(100vh - 90px)',
				maxHeight: '620px',
				borderRadius: '0',
				transform: 'scale(0)',
				transition: 'all .3s',
				transformOrigin: 'bottom right',
				border: 'unset',
				boxSizing: 'border-box',
				a: {
					color: '#e9573d',
				},
				'&.minimized': {
					transform: 'scale(0)',
					opacity: 0,
				},
				'&.maximized': {
					transform: 'scale(1)',
					opacity: 1,
				},
				'@media (max-width: 576px)': {
					maxWidth: '100vw',
					maxHeight: '100vh',
					position: 'fixed',
					top: '0px',
					left: '0px',
					right: '0px',
					width: '100%',
					height: '100%',
					transform: 'none',
					borderRadius: '0px',
				},
			},
			BotTalkImage: {
				position: 'relative',
				top: 'unset',
				bottom: 'unset',
				right: 'unset',
				boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1px 6px 0px, rgba(0, 0, 0, 0.16) 0px 2px 32px 0px',
				backgroundColor: colors.primary,
				'@media (max-width: 576px)': {
					backgroundColor: colors.primary,
				},
			},
			BotChatIcon: {
				backgroundImage: `url("${avatarBig}")`,
				width: '55%',
				height: '55%',
			},
			BotIcon: {},
			BotMinimizedContainer: {
				width: 'auto',
			},
			UnreadCounter: {},
			NewMinimizedMessages: {},
			NewMinimizedMessage: {},
			ActionIcon: {
				'@media (max-width: 576px)': {
					'&:after': {
						fontSize: '40px',
						transform: 'translateY(-7px)',
					},
				},
				'&:after': {
					content: '"_"',
					position: 'absolute',
					top: 'unset',
					bottom: 'unset',
					left: 'unset',
					right: 'unset',
					color: colors.white,
					fontSize: stylesValue({ default: '2.5em', podyplomowe: '1.88em' }),
					lineHeight: '0px',
					fontWeight: 'normal',
				},
				'&:hover:after': {
					color: colors.white,
				},
			},
			InitialPopupContainer: {
				zIndex: 1000,
				position: 'fixed',
				backgroundColor: colors.white,
				maxWidth: '280px',
				bottom: '82px',
				right: '16px',
				padding: '15px 20px 19px 20px',
				backgroundImage: 'none',
				borderRadius: '0px',
				boxShadow: '0 0 26px 0 rgb(0 0 0 / 12%)',
				'> div:not([class])': {
					display: 'flex',
					width: '100%',
					flexDirection: 'row',
					marginTop: '10px',
					justifyContent: 'space-between',
				},
				[`@media (max-width: 576px)`]: {
					right: '16px !important',
				},
				[`@media (max-width: 370px)`]: {
					maxWidth: 'calc(100vw - 100px)',
				},
			},
			InitialPopupHeader: {
				color: colors.black,
				fontSize: '1em',
				marginBottom: 'unset',
				h3: {
					color: colors.black,
				},
				p: {
					fontFamily: '"Open Sans", sans-serif',
					fontSize: stylesValue({ default: '1.08em', podyplomowe: '0.813em' }),
					lineHeight: '1.54',
				},
				'+div': {
					marginTop: 'unset !important',
				},
			},
			InitialPopupClose: {
				backgroundColor: `${colors.white} !important`,
				width: '24px',
				height: '24px',
				boxShadow: '0 0 26px 0 rgb(0 0 0 / 12%)',
				top: '-30px',
				right: '0px',
				div: {
					fontSize: '30px',
					width: '24px',
					height: '24px',
					color: '#595959 !important',
					textAlign: 'center',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'absolute',
					transform: 'translate(-50%, -50%) rotate(45deg)',
					top: '50%',
					left: '50%',
					fontFamily: 'none',
					fontWeight: 'bold',
				},
				'&:hover': {
					backgroundColor: colors.primary,
					div: {
						color: colors.white,
					},
				},
			},
			InitialPopupButton: {
				borderRadius: '0px',
				margin: '0px',
				padding: '10px 25px',
				color: colors.white,
				backgroundColor: colors.primary,
				'&:hover': {
					border: `solid 1px ${colors.primary}`,
					backgroundColor: `${colors.white} !important`,
					color: `${colors.primary} !important`,
				},
			},

			HeaderTitle: {
				color: colors.headerContainerTitleColor,
				fontSize: stylesValue({ default: '1.5em', podyplomowe: '1.13em' }),
				lineHeight: 'normal',
				paddingLeft: 0,
				fontWeight: 600,
				[`@media (max-width: 576px)`]: {
					padding: 0,
					textAlign: 'left',
					width: '100%',
				},
			},
			HeaderContainer: {
				background: colors.headerContainerBackgroundColor,
				minHeight: '60px',
				padding: '0 20px',
				borderBottom: 'unset',
				':before': {
					content: 'unset',
					backgroundImage: 'unset',
				},
				[`@media (max-width: 576px)`]: {
					minHeight: '52px',
				},
			},
			HeaderIconContainer: {
				'div:after': {
					color: colors.white,
				},
				'&:hover': {
					'div:after': {
						color: colors.white,
					},
				},
			},
			MessageInput: {
				padding: '16px 22px 17px 16px',
				lineHeight: '1.71',
				fontSize: stylesValue({ default: '1.1em', podyplomowe: '0.83em' }),
				width: '100%',
				fontFamily: 'Open Sans',
				borderRadius: '4px  !important',
				marginRight: '24px !important',
				color: colors.primary,
				'@media (max-width: 576px)': {
					fontSize: `max(${stylesValue({ default: '1.1em', podyplomowe: '0.83em' })}, 16px)`,
				},
				'&:focus': {
					border: `1px solid ${colors.primary} !important`,
					outline: '0 !important',
					zIndex: 10,
				},
				'&::placeholder': {
					color: colors.placeholderColor,
					fontSize: stylesValue({ default: '1em', podyplomowe: '1em' }),
					overflow: 'hidden',
					lineHeight: '26px',
				},
			},
			InputContainer: {
				margin: '0',
				borderRadius: 'unset',
				height: '57px',
				boxShadow: 'unset',
				borderTop: `1px solid ${colors.inputContainerBorderTopColor}`,
				'@media (max-width: 576px)': {
					minHeight: '56px',
				},
			},
			messages: {
				Button: {},
				SurveyButton: {
					display: 'inline-block',
				},
				ButtonTitle: {},
				ButtonContainer: {},
				SwipeItem: {},
				CarouselContainer: {
					marginTop: '10px',
					'.slick-track': {
						marginLeft: '35px',
						display: 'flex',
					},
					'.slick-slide': {
						height: 'auto',
						backgroundColor: 'red',
					},
					'.slick-slide > div': {
						margin: '10px 0 10px 10px',
						height: 'calc(100% - 20px)',
					},
					'.slick-slide > div > div': {
						borderRadius: '16px',
						height: '100%',
					},
					'.slick-slide > div > div:hover': {
						boxShadow: `0 0 0 2px ${colors.primary}`,
					},
					'@media (max-width: 576px)': {
						'.slick-track': {
							marginLeft: '15px',
						},
						'.slick-slide': {
							maxWidth: '240px !important',
						},
					},
				},
				CarouselButton: {},
				StandardCarouselButtonLeft: {
					'@media (max-width: 576px)': {
						display: 'none',
					},
					fontSize: 0,
					background: colors.white,
					height: '36px',
					borderRadius: '50px',
					width: '36px',
					top: '50%',
					transform: 'translateY(-50%)',
					left: '20px',
					zIndex: 10,
					transition: '.3s all',
					boxShadow: '2px 2px 30px 0 rgba(61, 61, 61, 0.4)',
					'&:hover': {
						backgroundColor: colors.primary + '!important',
						transition: '.3s all',
						'&:after': {
							borderColor: colors.white,
							transition: 'inherit',
						},
					},
					'&:after': {
						content: '""',
						transition: 'inherit',
						width: '10px',
						height: '10px',
						borderLeft: '2px solid',
						borderTop: '2px solid',
						borderColor: colors.red,
						position: 'absolute',
						transform: 'translateY(-50%) rotate(-45deg)',
						top: '50%',
						left: '14px',
					},
				},
				StandardCarouselButtonRight: {
					'@media (max-width: 576px)': {
						display: 'none',
					},
					fontSize: 0,
					background: colors.white,
					height: '36px',
					borderRadius: '50px',
					width: '36px',
					top: '50%',
					transform: 'translateY(-50%)',
					zIndex: 10,
					transition: '.3s all',
					boxShadow: '2px 2px 30px 0 rgba(61, 61, 61, 0.4)',
					'&:hover': {
						transition: '.3s all',
						backgroundColor: colors.primary + '!important',
						'&:after': {
							transition: 'inherit',
							borderColor: colors.white,
						},
					},
					'&:after': {
						content: '""',
						transition: 'inherit',
						width: '10px',
						height: '10px',
						borderLeft: '2px solid',
						borderTop: '2px solid',
						borderColor: colors.red,
						position: 'absolute',
						transform: 'translateY(-50%) rotate(135deg)',
						top: '50%',
						right: '14px',
					},
				},
				StandardCarouselImage: {
					width: '81px',
					height: '60px',
					maxHeight: '60px',
					paddingTop: '8px',
				},
				StandardCarouselValue: {},
				StandardCarouselTitle: {
					color: 'black !important',
				},
				PostcardCarouselContainer: {
					'.slick-slider': {
						'&:before': {
							display: 'none',
						},
						'&:after': {
							display: 'none',
						},
					},

					'.slick-track': {
						marginLeft: '20px',
						display: 'flex',
					},

					'.slick-slide': {
						width: '240px !important',
						marginRight: '12px',
					},
				},

				PostcardCarouselButtonRight: {
					width: '36px',
					height: '36px',
					borderRadius: '2px',
					boxShadow: '2px 2px 30px 0 rgba(61, 61, 61, 0.4)',
					backgroundColor: colors.white,
				},
				PostcardCarouselButtonLeft: {
					width: '36px',
					height: '36px',
					borderRadius: '2px',
					boxShadow: '2px 2px 30px 0 rgba(61, 61, 61, 0.4)',
					backgroundColor: colors.white,
				},
				PostcardSwipeItem: {
					display: 'flex',
					flexDirection: 'column',
					height: '200px !important',
					width: '240px !important',
					img: {
						height: '130px',
						objectFit: 'cover',
					},
				},
				PostcardCarouselTitle: {
					fontFamily: 'PTSans-Caption',
					fontSize: '17px',
					fontWeight: 'bold',
					color: colors.primary,
				},
				EmbeddedMapContainer: {},
				FormContainer: {},
				FormWrapper: {},
				FormHeader: {},
				FormButton: {},
				FormLabel: {},
				FormInput: {},
				FormFields: {},
				Form: {},
				GifImage: {},
				ImageContainer: {},
				ImageWrapper: {},
				Image: {},
				Messages: {
					maxWidth: '100%',
					overflowX: 'hidden',
					backgroundColor: colors.lightGray,
					padding: '30px 0 0px 0',
					paddingBottom: '6px',
					'&::-webkit-scrollbar': {
						width: '5px',
					},
					'&::-webkit-scrollbar-thumb': {
						background: colors.silver,
						borderRadius: '4px',
					},
				},
				MessagesGroupAvatar: {
					'&.user': {
						backgroundImage: `unset`,
						display: 'none',
						'&:after': {
							content: 'none',
						},
					},
					'&.bot': {
						backgroundImage: `url(${avatar})`,
						borderRadius: 0,
						backgroundSize: 'auto',
						backgroundPosition: 'center',
						backgroundColor: 'transparent',
						width: '21px',
						height: '17px',
						top: '-26px',
						left: '15px',
					},
				},
				MessagesGroup: {
					p: {
						whiteSpace: 'break-spaces',
					},
					ul: {
						listStyle: 'disc',
						paddingLeft: '15px',
						margin: '10px 0px',
					},
					ol: {
						paddingLeft: '15px',
						listStyle: 'decimal',
						margin: '10px 0px',
					},
					li: {
						fontFamily: '"Open Sans", sans-serif !important',
					},
					'&.user': {
						marginBottom: '9px',
					},
				},
				MessagesWithAvatar: {
					padding: '0px 20px 10px 20px',
					'&.user': {
						padding: '0px 20px 5px 20px',
					},
					'&.bot': {
						padding: '0px 20px 0px 20px',
					},
					'&.system': {
						'div > div > div': {
							alignSelf: 'center',
							paddingLeft: '45px',
							p: {
								textAlign: 'center',
								'&::before': {
									content: "'!'",
									position: 'absolute',
									top: '50%',
									backgroundColor: colors.red,
									width: '24px',
									height: '24px',
									borderRadius: '50%',
									transform: 'translateY(-50%)',
									left: '12px',
									color: colors.white,
									fontSize: '20px',
									lineHeight: 1.2,
									textAlign: 'center',
								},
							},
						},
					},
				},
				MessagesGroupMessages: {},
				MessagesGroupDate: {},
				OptionButton: {
					flex: 'unset',
					borderColor: colors.lightPrimary,
					border: `solid 1px ${colors.lightPrimary}`,
					boxShadow: 'unset',
					color: colors.lightPrimary,
					backgroundColor: colors.white,
					margin: '0 8px 8px 0',
					fontSize: stylesValue({ default: '1.25em', podyplomowe: '0.93em' }),
					fontWeight: 'bold',
					padding: '7px 22px',
					borderRadius: '0px',
					fontFamily: '"PT Sans Narrow", sans-serif !important',
					'&:not(:disabled):hover': {
						backgroundColor: colors.hoverPrimary,
						color: colors.lightPrimary,
					},
					'@media (max-width: 576px)': {},
				},
				OptionButtonDisabledSelected: {
					backgroundColor: colors.primary,
					borderColor: colors.primary,
					color: colors.white,
				},
				OptionTitle: {
					padding: '10px 30px',
					color: 'inherit',
					fontWeight: 'normal',
				},
				OptionContainer: {
					padding: 0,
					fontSize: stylesValue({ default: '1em', podyplomowe: '1em' }),
				},
				OptionWrapper: {
					flexDirection: 'row',
				},
				TextContainer: {
					postion: 'relative',
					marginTop: '3px',
					alignItems: 'flex-end',
					fontSize: stylesValue({ default: '1em', podyplomowe: '1em' }),
				},
				TextWrapper: {
					fontSize: stylesValue({ default: '1.08em', podyplomowe: '0.813em' }),
					color: colors.black,
					padding: '7px 16px 7px 16px',
					maxWidth: '100%',
					lineHeight: '140%',
					boxShadow: '0 0 1px 0 rgba(10, 31, 68, 0.08)',
					p: {
						fontFamily: '"Open Sans", sans-serif !important',
						margin: '10px 0',
					},

					a: {
						wordBreak: 'break-word',
					},
					'&.user': {
						fontSize: stylesValue({ default: '1.08em', podyplomowe: '0.813em' }),
						padding: '5px 15px 7px',
						background: colors.myMessageBackgroundColor,
						boxShadow: 'none',
						borderRadius: '2px 0px 2px 2px !important',
						borderTopRightRadius: 0,
						color: colors.white,
						textAlign: 'right',
						justifyContent: 'flex-end',
						maxWidth: '85%',
						border: `1px solid ${colors.myMessageBorderColor}`,
						'@media(max-width: 576px)': {
							maxWidth: '85%',
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: '-9px',
							right: '-1px',
							width: '0',
							height: '0',
							borderBottom: `8px solid ${colors.userTriangleBefore}`,
							borderLeft: '8px solid transparent',
						},
						p: {
							margin: '4px 0',
							color: `${colors.myMessageColor} `,
							textAlign: 'left',
						},
					},
					'&.bot': {
						fontSize: stylesValue({ default: '1em', podyplomowe: '1em' }),
						background: colors.white,
						boxShadow: `0 0 1px 0 ${colors.messageBotBoxShadow}`,
						border: `1px solid ${colors.botMessageBorderColor}`,
						color: colors.black,
						borderRadius: '0px 2px 2px 2px',
						borderTopLeftRadius: 0,
						flexDirection: 'column',
						padding: '5px 15px 7px',
						position: 'relative',
						width: '85%',
						'@media(max-width: 576px)': {
							width: '100%',
						},
						a: {
							fontSize: stylesValue({ default: '1em', podyplomowe: '1em' }),
							fontWeight: 600,
							color: colors.linkColor,
							textDecoration: 'none',
							'&:hover': {
								color: colors.primary,
								textDecoration: 'underline',
							},
							'&:active': {
								textDecoration: 'unset',
							},
						},

						p: {
							fontSize: stylesValue({ default: '1.08em', podyplomowe: '0.813em' }),
							margin: '4px 0',
							lineHeight: stylesValue({ default: '1.4em', podyplomowe: '1.4em' }),
						},

						ul: {
							fontSize: stylesValue({ default: '1.08em', podyplomowe: '0.813em' }),
						},

						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: '-9px',
							left: '-1px',
							width: '0',
							height: '0',
							borderBottom: `8px solid ${colors.botTriangleBefore}`,
							borderRight: '8px solid transparent',
						},
					},
				},
				UserIndicator: {
					color: colors.userIndicatorColor,
				},
				ThinkingContainer: {},
				ThinkingMessage: {
					color: colors.beton,
					border: `1px solid ${colors.botMessageBorderColor}`,
					background: colors.white,
					marginTop: '10px',
					paddingBottom: '8px',
					alignItems: 'center',
					borderRadius: 'unset',
					height: '30px',
					width: '30px',
					display: 'flex',
					justifyContent: 'flex-start',
					boxShadow: '0 0 1px 0 rgba(10, 31, 68, 0.08)',
					'&::after': {
						display: 'inline-block',
						color: colors.primary,
						content: '"..."',
						fontSize: '26px',
						marginLeft: '0',
						marginBottom: '0',
						height: '24px',
						letterSpacing: '4px',
						animationName: 'dotty2',
						animationDuration: '2s',
						animationIterationCount: 'infinite',
						animationTimingFunction: 'steps(1, end)',
						fontWeight: 'bold',
						marginTop: '8px',
						lineHeight: '0',
						overflow: 'hidden',
					},
					'&::before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: '-9px',
						left: '-1px',
						width: '0',
						height: '0',
						borderBottom: `8px solid ${colors.botTriangleBefore}`,
						borderRight: '8px solid transparent',
					},
				},
				VisitingCardButton: {},
				VisitingCardContainer: {},
				VisitingCardWrapper: {},
				YouTubeIframe: {},
				TextWithButtonsTextButtonList: {
					fontSize: stylesValue({ default: '1em', podyplomowe: '1em' }),
					span: {
						marginTop: '13px',
						color: `${colors.textWithButtonsListColor} !important`,
						fontSize: `${stylesValue({ default: '1.25em', podyplomowe: '0.94em' })} !important`,
						fontFamily: '"PT Sans Narrow", sans-serif !important',
						'&:hover': {
							fontWeight: 'bold',
							color: `${colors.primary} !important`,
							textDecoration: 'underline',
						},
						'&:active': {
							color: '#002043',
							textDecoration: 'unset',
						},
						'@media (max-width: 576px)': {
							marginTop: '16px',
						},
					},
				},
				TextWithButtonsTextButton: {
					border: 'unset',
					borderRadius: '0px',
					backgroundColor: colors.textWithButtonsBackgroundColor,
					'&:hover': {
						backgroundColor: colors.hoverPrimary,
					},
					'&:active': {
						border: `solid 1px ${colors.lightPrimary}`,
					},
					span: {
						fontSize: `${stylesValue({ default: '1.16em', podyplomowe: '0.87em' })} !important`,
						fontWeight: 'bold',
						color: `${colors.textWithButtonsColor} !important`,
						fontFamily: '"PT Sans Narrow", sans-serif !important',
						textAlign: 'center',
					},
				},
				TextWithButtonsButtonWrapper: {
					marginBottom: '10px',
					div: {
						fontSize: stylesValue({ default: '1em', podyplomowe: '1em' }),
					},
				},
				MessageContainer: {},
				MessageWrapper: {
					fontSize: stylesValue({ default: '1em', podyplomowe: '1em' }),
				},
			},
			SubmitButtonRtl: {},
			SubmitButton: {
				width: '23px',
				height: '20px',
				padding: '0',
				margin: '0 22px 0 0',
				backgroundColor: colors.white,
				backgroundImage: `url("${sendIcon}")`,
				backgroundSize: 'contain',
				'&:disabled': {
					backgroundImage: `url("${sendIconInactive}")`,
					cursor: 'default',
				},
				'@media (max-width: 576px)': {
					width: '30px',
					height: '20px',
				},
			},
			SubmitButtonIcon: {},
			PoweredBy: {
				borderTop: `1px solid ${colors.poweredByBorderTopColor}`,
				backgroundColor: colors.white,
				color: colors.poweredByColor,
				boxSizing: 'border-box',
				a: {
					color: colors.poweredByAColor,
					textDecoration: 'none',
					fontWeight: 'bold',
					paddingLeft: '3px',
				},
			},
		},
	}),
	{ colors, fonts }
);
