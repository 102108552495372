import { getPersistKeys } from '@Core/exports/storage/persistKeys';
import sendIcon from '@Core/assets/icons/send_icon.svg';
import { IAppConfig } from '@Types/appConfig';
import { WebStorage } from '@Types/storages';
import { IRequestOptions } from '@Types/requests';
import { OWNER } from '@Types/messages';

const options: IRequestOptions = {
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
	},
};

const projectId = 'actionBot';

const PERSIST_KEYS = getPersistKeys(projectId);

const appConfig: IAppConfig = {
	PROTECTED: {
		projectId,
		anonymize: false,
		agent: {
			available: false,
			usernameVisibleToAgent: 'Action Bot',
			defaultSkipAgentConnection: false,
		},
		debug: false,
		history: {
			enabled: true,
			storage: WebStorage.sessionStorage,
			key: 'actionbot',
		},
		messagesLimit: 150,
		sendMessageThreshold: 1000, //1s
		store: {
			preventSavingStoreToBrowserStorageWhenRedirectKeys: ['scrollToBottomRef'],
		},
		remoteConfig: {
			enabled: false,
		},
		connection: {
			engine: 'watson',
			watsonSource: 'actionbot',
			sendMessage: {
				url: 'https://actionbot-proxy-actionbot.12k7ipg0ukzd.eu-de.codeengine.appdomain.cloud/sandbox/{SESSION_ID}/{LANG}?version=2020-02-01',
				options,
			},
			getSessionId: {
				url: 'https://actionbot-proxy-actionbot.12k7ipg0ukzd.eu-de.codeengine.appdomain.cloud/sandbox?version=2020-02-01',
				withWelcomeMessage: false,
				forceGetSession: false,
				options,
			},
			sendMessageToAgent: {
				url: 'wss://ombot-test.eu-de.cf.appdomain.cloud/websocket',
				departament: 'asd', // depracated due to typo. Use "department" instead
				queueDepartament: null, // depracated due to typo. Use "queueDepartment" instead
				department: null,
				queueDepartment: null,
				sendInitResponse: true,
			},
			uploadFile: {
				enabled: false,
				url: '',
				showFileSize: false,
				maxFileSize: 2 * 1024 * 1024, // 2mb
			},
			extraPayloadData: {},
			history: {
				url: 'https://actionbot-demo.eu-de.mybluemix.net/history',
			},
			wsPingInterval: 30000,
		},
		poweredBy: {
			enabled: false,
		},
		captcha: {
			enabled: true,
			messagesInterval: 50,
			length: 5,
			fontColor: '#000000',
			fontSize: 26,
			fontFamily: 'Arial',
			charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
		},
	},
	PUBLIC: {
		rootElement: 'body',
		mountByDefault: true,
		minimizeAvailableOnlyAfterFirstResponse: false,
		skipGetSessionOnLoad: false,
		minimized: false,
		rtl: false,
		input: {
			maxLength: 300,
		},
		header: {
			title: 'chatbot',
		},
		carousel: {
			settings: {
				dots: true,
				infinite: true,
				speed: 300,
				slidesToShow: 1,
				centerMode: true,
				arrows: false,
				swipe: true,
			},
		},
		carouselPopup: {
			settings: {
				dots: false,
				infinite: false,
				speed: 300,
				slidesToShow: 2.4,
				centerMode: false,
				arrows: false,
				swipe: true,
			},
		},
		persistKeys: {
			...PERSIST_KEYS,
			[PERSIST_KEYS.usernameVisibleToAgent]: {
				storage: WebStorage.localStorage,
			},
			[PERSIST_KEYS.agentConnection]: {
				storage: WebStorage.localStorage,
			},
			[PERSIST_KEYS.sessionId]: {
				storage: WebStorage.sessionStorage,
			},
			[PERSIST_KEYS.unread]: {
				storage: WebStorage.localStorage,
			},
			[PERSIST_KEYS.redirectStorage]: {
				storage: WebStorage.localStorage,
			},
		},
		welcomeMessage: {
			context: {},
			text: '',
		},
		displayMessage: {
			default: {
				// TODO: remove it before production, we dont have rights to these pictures
				user: {
					avatar: 'https://docs.atlassian.com/aui/8.6.0/docs/images/avatar-person.svg',
				},
				bot: {
					name: 'ActionBot',
					avatar: 'https://cdn.icon-icons.com/icons2/1371/PNG/512/robot02_90810.png',
				},
				agent: {
					name: 'Agent',
					avatar: 'https://media.istockphoto.com/photos/can-i-be-of-assistance-picture-id171406243',
				},
			},
			avatar: {
				enabled: true,
				blackList: [OWNER.system],
			},
			date: {
				enabled: true,
				blackList: [OWNER.system],
			},
			printOptionSelected: false,
		},

		submitButton: {
			icon: sendIcon,
		},
		language: 'en',
		unread: {
			enabled: true,
			messageVisiblityDuration: 3500,
			resetUnreadCounter: false,
		},
		analytics: false,
		disablePageScrollOnMobileWhenMaximized: true,
		maximizeChatbotTime: 500,
		links: {
			openPdfInNewTab: true,
			newTabWhenRegexpNotMatch: 'generali.pl|staticdev',
		},
		menu: {
			enabled: false,
		},
		option: {
			hideOptionsAfterSelect: false,
		},
		message: {
			showUserIndicator: false,
		},
		thinkingAvatar: true,
		fullscreen: {
			enabled: false,
		},
		initialPopup: {
			oneTimeOnly: true,
		},
		userAttachment: {
			maxFileSize: 2 * 1024 * 1024,
			url: 'https://actionbot-demo.eu-de.mybluemix.net/test-upload-file',
			mail: 'xyz@xxx.pl',
		},
		WaitingMessage: {
			enabled: false,
			removeAfterMessageReceived: false,
			timeToShow: 7000,
		},
	},
	proxy: {},
};

export default appConfig;
