import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import { AppStoreConfig } from '@Core/types/appStore';

export class Service {
	config: AppStoreConfig;
	getAppStore: typeof getAppStore;
	getServices: typeof getServices;
	getCaseInsensitiveProp: (key: string) => (payload?: any) => any;
	constructor() {
		this.config = getAppStore().config;
		this.getAppStore = getAppStore;
		this.getServices = getServices;
		this.getCaseInsensitiveProp = function (key) {
			const keyInsensitive = key.toLowerCase();

			for (const classKey in this) {
				const classKeyInsensitive = classKey.toLowerCase();

				if (keyInsensitive === classKeyInsensitive) {
					return this[classKey];
				}
			}
		};
	}
}
