interface IUseAriaImg {
	'aria-label': string;
	alt: string;
	title: string;
	[key: string]: any;
}

export default function useAriaImg({ alt, ...props }): IUseAriaImg {
	return {
		'aria-label': alt,
		title: alt,
		alt,
		...props,
	};
}
