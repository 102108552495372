import { createAppConfig } from '@Core/exports/config';
import { WebStorage } from '@Core/types/storages';
import sendIcon from '@Core/assets/icons/send_icon.svg';
import env from '@Core/utils/env';

const isDev = env === 'dev';
const webEndpoint = isDev ? 'web/api-dev' : 'web/api';

export default createAppConfig({
	PROTECTED: {
		projectId: 'uniwersytet-slaski',
		anonymize: false,
		agent: {
			available: false,
			usernameVisibleToAgent: 'Action Bot',
			defaultSkipAgentConnection: false,
		},
		debug: isDev,
		history: {
			enabled: true,
			storage: WebStorage.sessionStorage,
			key: 'uniwersytet-slaski',
		},
		messagesLimit: 150,
		sendMessageThreshold: 1000,
		store: {
			preventSavingStoreToBrowserStorageWhenRedirectKeys: ['scrollToBottomRef'],
		},
		remoteConfig: {
			enabled: false,
		},
		connection: {
			engine: 'watson',
			watsonSource: 'actionbot',
			sendMessage: {
				url: `https://actionbot-proxy-us.12lrn9w809z6.eu-de.codeengine.appdomain.cloud/${webEndpoint}/{SESSION_ID}/{LANG}`,
			},
			getSessionId: {
				url: `https://actionbot-proxy-us.12lrn9w809z6.eu-de.codeengine.appdomain.cloud/${webEndpoint}`,
				forceGetSession: false,
				withWelcomeMessage: true,
			},
			sendMessageToAgent: {
				url: '',
				sendInitResponse: true,
			},
			uploadFile: {
				enabled: false,
				url: 'https://actionbot-demo.eu-de.mybluemix.net/upload_file',
				showFileSize: false,
				maxFileSize: 2097152,
			},
		},
		poweredBy: {
			enabled: true,
		},
	},
	PUBLIC: {
		rootElement: 'body',
		mountByDefault: true,
		minimizeAvailableOnlyAfterFirstResponse: false,
		skipGetSessionOnLoad: true,
		minimized: true,
		rtl: false,
		input: {
			maxLength: 300,
		},
		header: {
			title: 'Asystent',
		},
		carousel: {
			settings: {
				dots: true,
				infinite: false,
				speed: 300,
				slidesToShow: 1.6,
				centerMode: false,
				arrows: false,
				swipe: true,
			},
		},
		carouselPopup: {
			settings: {
				dots: false,
				infinite: false,
				speed: 300,
				slidesToShow: 2.4,
				centerMode: false,
				arrows: false,
				swipe: true,
			},
		},
		persistKeys: {},
		welcomeMessage: {
			context: {
				lang: document.documentElement?.lang?.split('-')?.[0] || 'pl',
			},
			text: '',
		},
		displayMessage: {
			default: {
				user: {
					avatar: '',
				},
				bot: {
					name: 'ActionBot',
					avatar: '',
				},
				agent: {
					name: 'Agent',
					avatar: '',
				},
			},
			avatar: {
				enabled: true,
				blackList: ['system'],
			},
			date: {
				enabled: false,
				blackList: ['system'],
			},
			printOptionSelected: true,
		},
		submitButton: {
			backgroundImage: `url(${sendIcon})`,
		},
		language: document.documentElement?.lang?.split('-')?.[0] || 'pl',
		unread: {
			enabled: true,
			messageVisiblityDuration: 3500,
		},
		analytics: false,
		disablePageScrollOnMobileWhenMaximized: true,
		links: {
			openPdfInNewTab: true,
			newTabWhenRegexpNotMatch: 'generali.pl|staticdev',
		},
		externalLibs: {
			disableTwemoji: true,
		},
		menu: {
			enabled: false,
		},
		option: {
			hideOptionsAfterSelect: false,
		},
		message: {
			showUserIndicator: true,
		},
		thinkingAvatar: true,
		fullscreen: {
			enabled: false,
		},
		initialPopup: {
			oneTimeOnly: true,
		},
	},
	proxy: {},
});
