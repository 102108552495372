import { AddAttachment, AddAttachmentInput } from './MessageInputAttachment.styled';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import { Message } from '@Core/utils/models/messages';
import { FileTypes } from '@Core/utils/consts';
import { bytesToReadable } from '@Core/utils/converters/bytesToReadable';
import debug from '@Core/utils/debug';
import { ChangeEvent } from 'react';
import { IMessage, OWNER } from '@Types/messages';
import useDictionary from '@Core/utils/language/useDictionary';

export default () => {
	const config = getAppStore().config;
	const attachmentInputId = 'actionbot-input-file';
	const { MessageService, AttachmentService } = getServices();
	const messageService = new MessageService();
	const attachmentService = new AttachmentService();
	const { fileTypes } = attachmentService;
	const acceptedExtensions = Object.values(FileTypes).join(', ');
	function handleSelectAttachment() {
		document.getElementById(attachmentInputId).click();
	}
	const { maxFileSize = 2 * 1024 * 1024, enabled: upladingEnabled } = config.PROTECTED.connection.uploadFile;

	let response;
	let reader;

	function isFileTypeCorrect(file: File) {
		return Object.values(fileTypes).includes(file.type as FileTypes);
	}

	const isAgentConnected = !!getAppStore().recipient.isAgent();

	const getThumbnail = (imageData: File) => {
		return new Promise((resolve, reject) => {
			if (imageData.type.includes('image')) {
				const imageDataDom = new Image();

				imageDataDom.onload = function () {
					const height = 175;
					const scale = imageDataDom.height > height ? height / imageDataDom.height : 1;

					const targetSizeW = imageDataDom.width * scale;
					const targetSizeH = imageDataDom.height * scale;

					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');

					canvas.width = targetSizeW;
					canvas.height = targetSizeH;

					ctx.drawImage(imageDataDom, 0, 0, targetSizeW, targetSizeH);

					resolve(canvas.toDataURL());
				};

				imageDataDom.src = URL.createObjectURL(imageData);
			} else {
				reject();
			}
		});
	};

	function sendFile(file: File) {
		function onError(e: unknown, message?: IMessage) {
			attachmentService.onError(e, message);
		}

		try {
			if (!isFileTypeCorrect(file)) {
				attachmentService.onIncorrectFileType();
				return;
			}
			if (file.size <= maxFileSize) {
				if (isAgentConnected) {
					const formData = new FormData();

					formData.append('file', file);
					formData.append('filename', file.name);

					try {
						// @ts-ignore depracated but try support
						formData.append('rid', getAppStore().agentConnection.value.websocket_session.room_id);
					} catch (e) {
						formData.append('rid', getAppStore().agentConnection.value.room_id);
					}

					const controller = new AbortController();
					const { signal } = controller;
					const url = !!upladingEnabled && config.PROTECTED.connection.uploadFile.url;

					const message = new Message({
						type: 'attachment',
						owner: OWNER.user,
						content: {
							sending: true,
							file: {
								name: file.name,
								type: file.type,
								size: file.size,
							},
						},
					});

					window.__actionBot = {
						...window.__actionBot,
						attachments: {
							...window.__actionBot.attachments,
							[message.id]: {
								abort: function () {
									controller.abort();
									messageService.removeMessageById(message.id),
										delete window.__actionBot.attachments[message.id];
								},
							},
						},
					};

					getThumbnail(file)
						.then((base64) => {
							message.content.file.base64 = base64;
						})
						.finally(() => {
							messageService.addMessage(message);

							if (url) {
								fetch(url, {
									signal,
									method: 'POST',
									body: formData,
								})
									.then((r) => {
										if (!r || !r.ok) {
											onError(r, message);
										} else {
											response = r;

											reader = response.body.getReader();
											let checkInterval = setInterval(() => {
												reader.read().then((result) => {
													if (result.done) {
														clearInterval(checkInterval);
														messageService.editMessageById(message.id, (message) => {
															message.content.sending = false;
															return message;
														});
													}
												});
											}, 10);
										}
									})
									.catch((e) => {
										onError(e);
									});
							}
						});
				} else {
					debug().error('not connected to agent');
				}
			} else {
				const { translate } = useDictionary();
				messageService.addMessage(
					new Message({
						type: 'text',
						owner: OWNER.user,
						content: translate('upload.error.file_to_big') + bytesToReadable(maxFileSize),
					})
				);
			}
		} catch (e) {
			debug().error(e);
		}
	}

	function handleAttachmentChanged(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files.length) {
			const file = event.target.files[0];
			sendFile(file);
		}
	}

	if (upladingEnabled && isAgentConnected) {
		return (
			<>
				<AddAttachmentInput
					accept={acceptedExtensions}
					type="file"
					id={attachmentInputId}
					onChange={handleAttachmentChanged}
				/>
				<AddAttachment onClick={handleSelectAttachment} />
			</>
		);
	} else {
		return null;
	}
};
