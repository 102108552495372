import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import { useMemo } from 'preact/hooks';

export default function ModalRenderer() {
	const ModalService = getServices('ModalService');
	const modalService = new ModalService();
	const currentModalName = getAppStore().modal.value;
	const ModalComponent = useMemo(() => modalService.getModalComponentByName(currentModalName), [currentModalName]);
	return <>{!!ModalComponent && <ModalComponent />}</>;
}
