import { Avatar } from './Avatar.styled';
import { getAppStore } from '@Core/store/App';
import { OWNER } from '@Types/messages';
import { IAgentInfo } from '@Types/appStore';

interface IMessagesGroupAvatar {
	owner: OWNER;
	agentInfo: IAgentInfo;
}

export default ({ owner, agentInfo }: IMessagesGroupAvatar) => {
	const store = getAppStore();
	const { botInfo } = store;
	const userAvatarUrl = store.config.PUBLIC.displayMessage.default.user.avatar;

	let avatarUrl = '';

	switch (owner) {
		case OWNER.bot:
			avatarUrl = botInfo.value && botInfo.value.avatar;
			break;
		case OWNER.agent:
			avatarUrl = agentInfo?.avatar || store.agentInfo.value?.avatar;
			break;
		case OWNER.user:
			avatarUrl = userAvatarUrl;
			break;
		default:
	}

	return <Avatar className={owner} avatarUrl={avatarUrl}></Avatar>;
};
