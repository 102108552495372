import { Message, MessagesGroup } from '@Core/utils/models/messages';
import resolveOptionBasedType from './helpers/resolveOptionBasedType';
import resolveCommands from './helpers/resolveCommands';
import mergeGenerics from './helpers/merge/mergeGenerics';
import { getAppStore } from '@Core/store/App';
import { OWNER } from '@Types/messages';
import { IAgentInfo } from '@Types/appStore';
import { IGeneric, IWatsonProxyResponse } from '@Types/watson';

interface ISendMessageAdapter {
	request: any; // TODO
	response: IWatsonProxyResponse;
}

export default function ({ request, response }: ISendMessageAdapter) {
	const recipient = getAppStore().recipient.value;

	let agentInfo: IAgentInfo;
	if (recipient === OWNER.agent) {
		agentInfo = getAppStore().agentInfo.value;
	}

	const group = new MessagesGroup({
		owner: recipient,
		messages: [],
		agentInfo,
	});

	function handleOptionBasedType(singleGeneric: IGeneric) {
		const message = resolveOptionBasedType({ singleGeneric, request });
		return message;
	}

	function handleCommands({ response, singleGeneric }: { response: IWatsonProxyResponse; singleGeneric: IGeneric }) {
		const message = resolveCommands({ response, singleGeneric });
		if (message) {
			group.messages.push(message);
		}
	}

	const delayed = [];
	let currentDelay = 0;
	let orginalDelay = 0;
	let typing;
	if (response && response.output && response.output.generic) {
		response.output.generic = mergeGenerics(response.output.generic);

		if (response.context) {
			getAppStore().watson.set((watson) => ({
				...watson,
				context: response.context,
			}));
		}

		response.output.generic.forEach((singleGeneric) => {
			const responseType = singleGeneric.response_type;
			let message = new Message({ owner: recipient, type: responseType });

			switch (responseType) {
				case 'text':
					message.content = singleGeneric.text;
					break;
				case 'image':
					message.content = singleGeneric;
					break;
				case 'pause':
					currentDelay += singleGeneric.time;
					orginalDelay = singleGeneric.time;
					typing = singleGeneric.typing;
					break;
				case 'suggestion':
					message.content = {
						options: singleGeneric.suggestions.map((singleSuggestion) => {
							return {
								label: singleSuggestion.label,
								value: singleSuggestion,
							};
						}),
						suggestionDescription: singleGeneric.title,
					};
					break;
				case 'option': {
					const optionBasedMessage = handleOptionBasedType(singleGeneric);
					message = { ...message, ...optionBasedMessage };
					break;
				}
				case 'command':
					handleCommands({ response, singleGeneric });
					break;
				default:
					return null;
			}

			if (!['pause', 'command'].includes(responseType)) {
				if (currentDelay > 0) {
					delayed.push({
						message,
						typing,
						orginalDelay,
						delay: currentDelay,
					});
				} else {
					group.messages.push(message);
				}
			}
		});
	}

	return { group, delayed };
}
