import { IStyledComponentWithTheme } from '@Types/themes';
import styled from 'styled-components';

export const Input = styled.textarea<IStyledComponentWithTheme & { type: string }>`
	${({ theme }) => ({
		height: '100%',
		resize: 'none',
		border: 'none',
		fontFamily: theme.fonts.primary,
		background: theme.colors.brownGrayTwo,
		overflow: 'hidden',
		'&:focus': {
			outline: 'none',
		},

		padding: '15px',
		width: 'calc(100% - 60px)',
		maxHeight: '60px',
		backgroundColor: 'transparent',
		color: theme.colors.colorBlack,
		fontSize: '14px',
		lineHeight: '170%',
		'&:not(:placeholder-shown) button': {
			backgroundColor: theme.colors.colorPrimary,
		},
		'&::placeholder': {
			color: theme.colors.colorBlack,
			fontSize: '15px',
			lineHeight: '30px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			opacity: '.6',
			transition: 'opacity .2s',
		},
		'&:focus::placeholder': {
			opacity: 0,
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			width: 'calc(100% - 80px)',
			fontSize: '16px !important',
			'&::placeholder': {
				paddingLeft: '15px',
				fontSize: '18px',
			},
		},
		...theme.elements.MessageInput,
	})};
`;

export const InputContainer = styled.div<IStyledComponentWithTheme & { loading: boolean }>`
	${({ theme, loading }) => ({
		display: 'flex',
		background: theme.colors.brownGrayTwo,
		alignItems: 'center',
		margin: '5px 12px 15px 12px',
		height: 'auto',
		minHeight: '55px',
		borderRadius: '15px',
		overflow: 'hidden',
		backgroundColor: 'transparent',
		transition: 'opacity 1s',
		opacity: loading ? 0 : 1,
		boxShadow: `0 0 5px ${theme.colors.boxShadow}`,
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			minHeight: '70px',
		},
		[`@media (min-width: ${theme.fullscreenParams.baseWidth}px)`]: {
			'&.fullscreen': {
				marginLeft: `calc(50% - (${theme.fullscreenParams.baseWidth / 2}px - 30px))`,
				marginRight: `calc(50% - (${theme.fullscreenParams.baseWidth / 2}px - 20px))`,
			},
		},
		...theme.elements.InputContainer,
	})};
`;

export const AddAttachment = styled.div`
	${({ theme }) => ({
		'&:after': {
			content: "'+'",
			color: theme.colors.brownGrayTwo,
			fontSize: '30px',
		},
		borderTop: 0,
		padding: '20px 15px',
		lineHeight: 0,
		cursor: 'pointer',
		border: 'unset',
		...theme.elements.AddAttachment,
	})};
`;

export const AddAttachmentInput = styled.input`
	${({ theme }) => ({
		display: 'none',
		...theme.elements.AddAttachmentInput,
	})};
`;
