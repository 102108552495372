import { getAppStore } from '@Core/store/App';
import useDictionary from '@Core/utils/language/useDictionary';
import { useState } from 'preact/hooks';
import {
	CaptchaContainer,
	CaptchaWrapper,
	CaptchaInput,
	CaptchaReloadButton,
	CaptchaInputCotainer,
	CaptchaSubmit,
	CaptchaError,
	CaptchaDescription,
} from './Captcha.styled';
import useCaptcha from './useCaptcha';

export default function Captcha({ children }) {
	const store = getAppStore();
	const { config, captcha } = store;
	const { value: captchaStore } = captcha;
	const captchaConfig = config.PROTECTED.captcha;

	const { CaptchaComponent, reloadCaptcha, validateCaptcha, deactivateCaptcha } = useCaptcha(captchaConfig);
	const { translate } = useDictionary();

	const [text, setText] = useState('');
	const [error, setError] = useState('');

	if (!captchaConfig.enabled || !captchaStore.isActive || store.minimized.value) {
		return children;
	}

	function validate() {
		const isValid = validateCaptcha(text);

		if (isValid) {
			setError('');
			setText('');
			deactivateCaptcha();
		} else {
			setText('');
			setError(translate('captcha.error.not-equal'));
			reloadCaptcha();
		}
	}
	const handleKeyUp = (e) => {
		if (e.keyCode === 13) {
			validate();
		}
	};
	return (
		<CaptchaContainer>
			<CaptchaDescription>{translate('captcha.description')}</CaptchaDescription>
			<CaptchaWrapper>
				<CaptchaComponent />
				<CaptchaReloadButton onClick={reloadCaptcha}>&#x21bb;</CaptchaReloadButton>
			</CaptchaWrapper>
			<CaptchaInputCotainer>
				<CaptchaInput value={text} onChange={(e) => setText(e.target.value)} onKeyUp={handleKeyUp} />
				<CaptchaSubmit disabled={!text} onClick={validate}>
					&#x27a4;
				</CaptchaSubmit>
			</CaptchaInputCotainer>
			{!!error && <CaptchaError>{error}</CaptchaError>}
		</CaptchaContainer>
	);
}
