import styled from 'styled-components';
import abLogo from '@Core/assets/icons/actionbot_logo.svg';

export const Title = styled.div`
	${({ theme }) => ({
		padding: '0 0 0 55px',
		fontSize: '15px',
		fontWeight: 'bold',
		lineHeight: '130%',
		whiteSpace: 'pre-wrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		color: theme.colors.colorPrimary,
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			padding: '0 40px 0 68px',
			fontSize: '18px',
			lineHeight: '110%',
		},
		...theme.elements.HeaderTitle,
	})};
`;

export const HeaderContainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		margin: 0,
		width: '100%',
		padding: '5px 20px',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
		zIndex: '1',
		minHeight: '60px',
		boxShadow: '0 5px 10px rgba(0,0,0, .2)',
		background: 'transparent',
		cursor: 'default',
		'&::before': {
			content: '""',
			position: 'absolute',
			top: '15px',
			left: '25px',
			display: 'block',
			height: '31px',
			width: '27px',
			backgroundImage: `url(${abLogo})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center center',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			minHeight: '76px',
			'&::before': {
				top: '20px',
				left: '28px',
				height: '40px',
				width: '35px',
			},
		},
		...theme.elements.HeaderContainer,
	})};
`;

export const HeaderIconContainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		gap: '20px',
		img: {
			cursor: 'pointer',
		},
		...theme.elements.HeaderIconContainer,
	})};
`;
