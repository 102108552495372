import { getServices } from '@Core/store/Services';
import { useMemo } from 'preact/hooks';
import ReactMarkdown from 'react-markdown';
import { TextContainer, TextWrapper, UserIndicator } from './Text.styled';
import { getAppStore } from '@Core/store/App';
import { IMessage, OWNER } from '@Types/messages';
import useDictionary from '@Core/utils/language/useDictionary';
import { replaceHTMLtags } from '@Core/utils/converters/replaceHTMLtags';
export interface ITextMessage {
	message: IMessage;
	isFirstMessage?: boolean;
}

export function Text({ message, isFirstMessage }: ITextMessage) {
	const text = message.content;
	const { MarkdownService } = getServices();
	const markdownService = new MarkdownService();
	const { translate } = useDictionary();
	const appConfig = getAppStore().config;
	const themeExtension = getAppStore().themeExtension.value;

	if (!text) {
		return null;
	}

	const isUserMessage = message.owner === OWNER.user;

	const markdownedContent = useMemo(() => {
		const replacedText = replaceHTMLtags(text, 'br', '\n');
		return (
			<TextWrapper className={message.owner}>
				<ReactMarkdown components={markdownService.getRenderers()}>{replacedText}</ReactMarkdown>
			</TextWrapper>
		);
	}, [message.content, themeExtension]);

	return (
		<TextContainer owner={message.owner}>
			{isUserMessage && appConfig.PUBLIC.message.showUserIndicator && isFirstMessage && (
				<UserIndicator>{translate('message.text.userIndicator')}</UserIndicator>
			)}
			{markdownedContent}
		</TextContainer>
	);
}

export default Text;
