import { Service } from '@Core/utils/services';
import { Message } from '@Core/utils/models/messages';
import { ApiContext } from '@Core/utils/models/watson';
import debug from '@Core/utils/debug';
import { getServices } from '@Core/store/Services';
import { IMessage, OWNER } from '@Types/messages';
import extractContextUserDefined from '@Core/utils/watson/extractContextUserDefined';
import { IBotEvent } from '@Types/events';

export type WatsonCommands =
	| 'setforminput'
	| 'redirect'
	| 'async'
	| 'scrollto'
	| 'simulateevent'
	| 'mouseeventelement'
	| 'mouseeventelementiframe'
	| 'textdirection'
	| 'handovertoagent'
	| 'carousel'
	| 'visitingcard'
	| 'permanentoption'
	| 'permamentoption'
	| 'imagegallery'
	| 'onlyoption'
	| 'optiononly'
	| 'button'
	| 'form'
	| 'formfields'
	| 'formdefaults'
	| 'formmerge'
	| 'setbotinfo'
	| 'youtube'
	| 'gif'
	| 'redirectwithmemory'
	| 'embeddedmap'
	| 'addcss'
	| 'changeelementprop'
	| 'minimizeclient'
	| 'systemmessage'
	| 'agentmessage'
	| 'survey'
	| 'attachment'
	| 'dispatchevent'
	| 'setlanguage'
	| 'validateform'
	| 'menu'
	| 'menuopen'
	| 'mainmenu'
	| 'icongrid'
	| 'remoteconfig'
	| 'fullscreen'
	| 'triggerpopup'
	| 'calendar'
	| 'closeinitialpopup'
	| 'textwithbuttons'
	| 'readmore'
	| 'messageattachment'
	| 'userattachment'
	| 'clearchat';
// ACTIONS from watson (special OPTION responses). Actions are not components
export default class ActionsService extends Service {
	setFormInput = (message: IMessage) => {
		const { formId, ...fields } = message.content;

		function _changeInputValue(input, value) {
			try {
				const lastValue = input.value;
				input.value = value;

				const event = new Event('input', { bubbles: true });
				// @ts-ignore: hack for older versions
				event.simulated = true; // hack React15

				const tracker = input._valueTracker; // hack React16

				if (tracker) {
					tracker.setValue(lastValue);
				}

				input.dispatchEvent(event);
			} catch (e) {
				debug().error(e);
			}
		}

		function _setValueByType(input, value) {
			if (typeof input === 'object') {
				if (input.type === 'checkbox') {
					_changeInputValue(input, value === 'true');
				} else {
					_changeInputValue(input, value);
				}
			}
		}

		for (const fieldName in fields) {
			try {
				const query = fieldName.split(';');
				const elements = document.querySelectorAll(formId ? `form#${formId} ` + query[0] : query[0]);
				const value = fields[fieldName];

				if (elements && query.length === 1) {
					const element = elements[0];
					_setValueByType(element, value);
				} else if (elements && query.length > 1) {
					const element = elements[query[1]];
					_setValueByType(element, value);
				}
			} catch (e) {
				debug().error(e);
			}
		}
	};

	scrollTo = (message: IMessage) => {
		const { query } = message.content;
		try {
			const element = document.querySelector(query);
			if (element) {
				setTimeout(() => {
					element.scrollIntoView({ behavior: 'smooth' });
				}, 100);
			}
		} catch (e) {
			debug().error(e);
		}
	};

	async = (message: IMessage) => {
		const { SchedulerService, ConnectionService } = this.getServices();
		const connectionService = new ConnectionService();
		const { time, request } = message.content;
		new SchedulerService({
			timeout: time,
			callback: () => {
				connectionService.sendMessage({ request }).catch(console.error);
			},
		});
	};

	mouseEventElement = (message: IMessage) => {
		this.simulateEvent(message);
	};

	simulateEvent = (message: IMessage) => {
		const { ...fields } = message.content;
		for (const query in fields) {
			try {
				const element = document.querySelector(query);
				const action = fields[query];
				if (element) {
					const event = new Event(action, { bubbles: true, cancelable: true });
					element.dispatchEvent(event);
					return null;
				}
			} catch (e) {
				debug().error(e);
			}
		}
	};

	mouseEventElementIframe = (message: IMessage) => {
		const { ...fields } = message.content;
		let iframe;
		for (const query in fields) {
			if (query === 'iframe') {
				iframe = document.querySelector(fields[query]);
			} else {
				try {
					const element = iframe.contentWindow.document.querySelector(query);
					const action = fields[query];
					if (element) {
						element[action]();
						return null;
					}
				} catch (e) {
					debug().error(e);
				}
			}
		}
	};

	setLanguage = (message: IMessage) => {
		const { language } = message.content;
		try {
			this.getAppStore().language.set(language);
		} catch (e) {
			console.error('Error: setLanguage', { language }, e);
		}
	};

	textDirection = (message: IMessage) => {
		const { ControlService } = this.getServices();
		const { direction } = message.content;
		const controlService = new ControlService();

		controlService.changeRtl(direction === 'rtl');
	};

	exists(actionName: string) {
		try {
			return typeof this.getCaseInsensitiveProp(actionName) === 'function';
		} catch (e) {
			return false;
		}
	}

	onlyOption = () => {
		this.getAppStore().isInputDisabled.set(true);
	};

	handleActionFromMessage(message: IMessage) {
		const actionName = message.type;

		if (this.exists(actionName)) {
			this.getServices('BehaviorService').dispatchEvent('action', {
				actionName,
				message,
			});
			return this.getCaseInsensitiveProp(actionName)(message);
		} else {
			return () => {
				debug().error(actionName + ' action not exists');
			};
		}
	}

	handoverToAgent = () => {
		const store = this.getAppStore();
		store.agentHandover.set(true);
		store.waitingForAgent.set(true);
	};

	extractAgentInfoFromContext = (message: IMessage) => {
		const context = message.content && message.content.context;

		if (context) {
			try {
				const { agentInfo } = extractContextUserDefined(context);
				if (agentInfo) {
					const {
						agent,
						agentName: name,
						agentNameFrom: nameFrom,
						agentNameTo: nameTo,
						agentAvatar: avatar,
					} = agentInfo;

					const newAgentInfo = {
						agent,
						name,
						nameFrom,
						nameTo,
						avatar,
					};

					Object.entries(newAgentInfo).forEach(([key, value]) => {
						if (!value) {
							delete newAgentInfo[key];
						}
					});

					if (Object.keys(newAgentInfo).length) {
						this.getAppStore().agentInfo.set((agentInfo) => ({
							...agentInfo,
							...newAgentInfo,
						}));
					}

					return { name, nameFrom, nameTo, avatar, agent };
				} else {
					return undefined;
				}
			} catch (e) {
				debug().error(e);
			}
		}
	};

	agentConnectionConnected = (message: IMessage) => {
		const ConnectionService = this.getServices('ConnectionService');

		ConnectionService.switchRecipientTo(OWNER.agent);
		this.getAppStore().waitingForAgent.set(false);
	};

	agentConnectionTransfered = (message: IMessage) => {
		return this.extractAgentInfoFromContext(message);
	};

	agentConnectionAgentData = (message: IMessage) => {
		return this.extractAgentInfoFromContext(message);
	};

	agentConnectionDisconnected = () => {
		const ConnectionService = this.getServices('ConnectionService');

		ConnectionService.switchRecipientTo(OWNER.bot);

		debug().log('@@@@@@@@@@ agentConnectionDisconnected');
	};

	agentConnectionClosed = () => {
		const { ConnectionService } = this.getServices();

		ConnectionService.switchRecipientTo(OWNER.bot);
		this.getAppStore().agentLeftChat.set(true);
		this.getAppStore().userSentFirstMessage.set(false);
		this.getAppStore().agentConnection.set((agentConnection) => {
			delete agentConnection?.websocket_session_id;
			delete agentConnection?.room_id;
			return agentConnection;
		});
		this.getAppStore().replyForFirstUserMessage.set(null);
		this.getAppStore().waitingForAgent.set(false);
		debug().log('@@@@@@@@@@ agentConnectionClosed');
	};

	agentNotConnected = () => {
		this.getAppStore().waitingForAgent.set(false);
		debug().log('@@@@@@@@@@ agentNotConnected');
	};

	setBotInfo = (message: IMessage) => {
		const botInfo = message.content;
		const botInfoStore = this.getAppStore().botInfo;

		botInfoStore.set((prevBotInfo) => ({
			...prevBotInfo,
			...botInfo,
		}));
	};

	redirect = (message: IMessage) => {
		const { url, newTab } = message.content;

		const RedirectService = this.getServices('RedirectService');
		const redirectService = new RedirectService();

		redirectService.redirectTo(url, { newTab });
	};

	redirectWithMemory = (message: IMessage) => {
		const { url, newTab, storage } = message.content;

		const RedirectService = this.getServices('RedirectService');
		const redirectService = new RedirectService();

		redirectService.redirectWithMemoryTo(url, { newTab, storage });
	};

	addcss = (message: IMessage) => {
		const { query, ...params } = message.content;
		try {
			const element = document.querySelector(query);
			if (element) {
				for (const param in params) {
					element.style[param] = params[param];
				}
			}
		} catch (e) {
			debug().error(e);
		}
	};

	changeelementprop = (message: IMessage) => {
		const { query, ...params } = message.content;
		try {
			const element = document.querySelector(query);
			if (element) {
				for (const param in params) {
					element.setAttribute(param, params[param]);
				}
			}
		} catch (e) {
			debug().error(e);
		}
	};

	minimizeclient = (message: IMessage) => {
		const { minimize } = message.content;
		const action = minimize === 'true' ? 'minimize' : 'maximize';
		setTimeout(() => {
			window.__actionBot[action]();
		}, 0);
	};

	agentmessage = (message: IMessage) => {
		const MessageService = this.getServices('MessageService');
		const messageService = new MessageService();

		if (Array.isArray(message.content?.options)) {
			message.content.options.forEach((singleOption) => {
				const agentMessage = new Message({
					owner: OWNER.agent,
					type: 'text',
					content: singleOption.value,
				});
				messageService.addMessage(agentMessage);
			});
		}
	};

	systemmessage = (message: IMessage) => {
		const MessageService = this.getServices('MessageService');
		const messageService = new MessageService();

		if (message.content && Array.isArray(message.content.options)) {
			message.content.options.map((singleOption) => {
				const systemMessage = new Message({
					owner: OWNER.system,
					type: 'text',
					content: singleOption.value,
				});
				messageService.addMessage(systemMessage);
			});
		}
	};

	command = (message: IMessage) => {
		const ConnectionService = this.getServices('ConnectionService');
		const connectionService = new ConnectionService();
		const agentCommand = message.content.command;

		const isConnectedEvent = agentCommand === 'connected';
		const isDisconnectedEvent = agentCommand === 'disconnected';
		const isClosedEvent = agentCommand === 'closed';
		const isNotConnectedEvent = agentCommand === 'not_connected';
		const isTransferedEvent = agentCommand === 'transfer';
		const isAgentDataEvent = agentCommand === 'agent_data';

		const eventData = {} as { [eventDataElement: string]: any };
		let agentInfo = {};

		if (isClosedEvent) {
			this.agentConnectionClosed();
			const { name } = this.agentConnectionAgentData(message) || {};
			if (name) {
				agentInfo = {
					agentName: name,
				};
			}
		}

		if (isNotConnectedEvent) {
			this.agentNotConnected();
		}

		if (isDisconnectedEvent) {
			this.agentConnectionDisconnected();
		}

		if (isConnectedEvent) {
			this.agentConnectionConnected(message);
			if (message.content?.initAgentMessage) {
				eventData.initAgentMessage = message.content.initAgentMessage;
			}
			const { name } = this.agentConnectionAgentData(message) || {};
			if (name) {
				agentInfo = {
					agentName: name,
				};
			}
		}

		if (isAgentDataEvent) {
			const { name } = this.agentConnectionAgentData(message) || {};
			if (name) {
				agentInfo = {
					agentName: name,
				};
			}
		}

		if (isTransferedEvent) {
			const { name, nameFrom, nameTo } = this.agentConnectionTransfered(message) || {};
			agentInfo = {
				agentName: name,
				agentNameFrom: nameFrom,
				agentNameTo: nameTo,
			};
		}

		const additionalInfo = {
			agentInfo: {},
		};

		Object.entries(agentInfo).forEach(([key, value]) => {
			if (!value) {
				delete agentInfo[key];
			}
		});

		if (Object.keys(agentInfo).length) {
			additionalInfo.agentInfo = agentInfo;
		} else {
			delete additionalInfo.agentInfo;
		}

		const context = new ApiContext({
			eventData: {
				eventName: agentCommand,
				...eventData,
			},
			...additionalInfo,
		});

		connectionService.sendMessage({ text: '', incognito: 'input', context }).catch(console.error);
	};

	dispatchEvent(message: IMessage) {
		const { eventName, ...eventData } = message.content;
		const event = document.createEvent('Event') as IBotEvent;
		event.initEvent(eventName, true, true);
		event.data = eventData;
		document.dispatchEvent(event);
	}

	validateForm = (message: IMessage) => {
		const { cta, ...fields } = message.content;

		const rules = {
			notEmpty: ({ input, validatorValue }) => {
				return !!input;
			},
			gt: ({ input, validatorValue }) => {
				try {
					return parseInt(input) > validatorValue;
				} catch (e) {
					console.error(e);
					return false;
				}
			},
			lt: ({ input, validatorValue }) => {
				try {
					return parseInt(input) < validatorValue;
				} catch (e) {
					console.error(e);
					return false;
				}
			},
		};

		function validate(input, validators) {
			let isOk = true;
			Object.entries(validators).forEach(([validatorName, validatorValue]) => {
				if (!rules[validatorName]({ input, validatorValue })) {
					isOk = false;
				}
			});
			return isOk;
		}

		// Waiting for the form to appear
		window.actionbotValidateFormInterval = setInterval(function () {
			const ctaElement = document.querySelector(cta);
			interface IFieldsElements {
				[fieldName: string]: {
					el: HTMLInputElement;
					validators: {
						input: string;
						validatorValue: string;
					}[];
				};
			}

			const fieldsElements: IFieldsElements = {};

			function validateAllFields() {
				const fieldsStatus = {
					allOk: true,
				};
				Object.entries(fieldsElements).forEach(([fieldName, { el, validators }]) => {
					const validationStatus = validate(el.value, validators);
					fieldsStatus[fieldName] = validationStatus;
					if (!validationStatus) {
						fieldsStatus.allOk = false;
					}
				});

				debug().log({ fieldsStatus });
				return fieldsStatus;
			}

			if (ctaElement) {
				clearInterval(window.actionbotValidateFormInterval);
				const ctaParent = ctaElement.parentNode;

				const ctaElementCopy = ctaElement.cloneNode(true);
				ctaElementCopy.onclick = undefined;

				ctaParent.insertBefore(ctaElementCopy, ctaElement.nextSibling);
				ctaElement.style.display = 'none';

				Object.entries(fields).forEach(([key, jsonString]) => {
					try {
						const jsonParsed = JSON.parse(jsonString as string);

						fieldsElements[key] = {
							el: document.querySelector(jsonParsed[0]),
							validators: jsonParsed[1],
						};
					} catch (e) {
						console.error(e);
					}
				});

				ctaElementCopy.addEventListener('click', () => {
					const validation = validateAllFields();
					const { ConnectionService } = getServices();
					const connectionService = new ConnectionService();

					if (validation.allOk) {
						connectionService
							.sendMessage({
								text: '$$validation_success$$',
								incognito: 'input',
							})
							.catch(console.error);
						ctaElement.click();
					} else {
						const context = new ApiContext({
							validation,
						});
						connectionService
							.sendMessage({
								text: '$$validation_failed$$',
								incognito: 'input',
								context,
							})
							.catch(console.error);
					}
				});
			}
		}, 1000);
	};

	mainmenu = (message: IMessage) => {
		const MenuService = this.getServices('MenuService');
		const menuService = new MenuService();
		menuService.saveMenuList(message);
	};

	menuopen = () => {
		const MenuService = this.getServices('MenuService');
		const menuService = new MenuService();
		menuService.openMenu();
	};

	remoteconfig = (message: IMessage) => {
		const RemoteConfigService = this.getServices('RemoteConfigService');
		const remoteConfigService = new RemoteConfigService();
		remoteConfigService.saveAndApply(message);
	};

	fullscreen = (message: IMessage) => {
		const { ThemeService } = this.getServices();
		const themeService = new ThemeService();
		const { active } = message.content;
		if (active !== undefined) {
			themeService.setFullScreen(active === 'true');
		}
	};

	triggerpopup = (message: IMessage) => {
		const MessageService = this.getServices('MessageService');
		const messageService = new MessageService();
		const popup = message.content.find((item) => item.label === 'popup');
		messageService.setInitialPopup(popup.value);
	};

	closeinitialpopup = () => {
		const store = this.getAppStore();
		store.initialPopup.set(null);
	};

	clearchat = (message: IMessage) => {
		const store = this.getAppStore();

		store.messages.set((messages) => {
			messages.length = 0;
			return [...messages];
		});

		if (message.content.withHistory === 'true') {
			const HistoryService = this.getServices('HistoryService');
			const historyService = new HistoryService();
			historyService.clearHistory();
		}
	};
}
