import { useEffect } from 'preact/hooks';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import { IMessage, IMessagesGroup, OWNER } from '@Types/messages';

export default function useUnread() {
	const store = getAppStore();
	const unreadConfig = store.config.PUBLIC.unread;
	if (!unreadConfig.enabled) {
		return null;
	}

	const UNREAD_MESSAGE_VISIBILITY_DURATION = unreadConfig.messageVisiblityDuration;
	const RESET_UNREAD_COUNTER = unreadConfig.resetUnreadCounter;

	const unreadState = () => getAppStore().unread.value;
	const { unreadMessages } = unreadState();
	const setUnread = store.unread.set;

	function setUnreadCount(unreadCount: number) {
		setUnread((unread) => ({
			...unread,
			unreadCount,
		}));
	}

	function clearUnreadMessagesInStore(newValue: IMessage[] = []) {
		setUnread((unread) => {
			return {
				...unread,
				unreadMessages: newValue,
			};
		});
	}

	function addUnreadMessagesToStore(unreadMessages: IMessage[]) {
		if (unreadMessages.length) {
			setUnread((unread) => ({
				...unread,
				unreadMessages: [...unread.unreadMessages, ...unreadMessages],
			}));
		}
	}

	function setUnreadMessagesInStore(unreadMessages: IMessage[]) {
		clearUnreadMessagesInStore(unreadMessages);
	}

	function removeUnreadMessagesByIdsFromStore(ids: number[]) {
		const unreadMessagesToSet = unreadState().unreadMessages.filter((u) => {
			return !ids.includes(u.id);
		});
		setUnreadMessagesInStore(unreadMessagesToSet);
	}

	function isFromUser(messageOrGroup: IMessage | IMessagesGroup) {
		return messageOrGroup && messageOrGroup.owner === OWNER.user;
	}

	function resetUnread() {
		setUnreadCount(0);
		clearUnreadMessagesInStore();
	}

	function addTimoutForClearUnreadMessages(messages: IMessage[]) {
		const ids = messages.map((m) => m.id);

		const timeout = setTimeout(() => {
			removeUnreadMessagesByIdsFromStore(ids);
			clearTimeout(timeout);
		}, UNREAD_MESSAGE_VISIBILITY_DURATION);
	}

	function addUnreadMessages(messages: IMessage[]) {
		const textMessages = messages.filter((m) => m.type === 'text');
		if (!textMessages.length) {
			return;
		}
		const unreadCount = unreadState().unreadCount;
		setUnreadCount(unreadCount + textMessages.length);
		addUnreadMessagesToStore(textMessages);

		addTimoutForClearUnreadMessages(textMessages);
	}

	useEffect(() => {
		if (unreadMessages && unreadMessages.length) {
			addTimoutForClearUnreadMessages(unreadMessages);
		} else if (RESET_UNREAD_COUNTER) {
			resetUnread();
		}
		const BehaviorService = getServices('BehaviorService');

		const addMessageEvent = BehaviorService.getEvent('addMessage');
		const addMessageGroupEvent = BehaviorService.getEvent('addMessageGroup');
		const maximizeEvent = BehaviorService.getEvent('maximize');

		function isMinimized() {
			return getAppStore().minimized.value;
		}

		function onAddMessage(e) {
			if (isMinimized()) {
				const message = e.data;
				if (!isFromUser(message)) {
					const messages = [message];
					addUnreadMessages(messages);
				}
			}
		}

		function onAddMessageGroup(e) {
			if (isMinimized()) {
				const messageGroup = e.data;
				if (!isFromUser(messageGroup)) {
					const messages = messageGroup.messages;
					addUnreadMessages(messages);
				}
			}
		}

		function onMaximize() {
			resetUnread();
		}

		document.addEventListener(addMessageEvent, onAddMessage);
		document.addEventListener(addMessageGroupEvent, onAddMessageGroup);
		document.addEventListener(maximizeEvent, onMaximize);

		return () => {
			removeEventListener(addMessageEvent, onAddMessage);
			removeEventListener(addMessageGroupEvent, onAddMessageGroup);
			removeEventListener(maximizeEvent, onMaximize);
		};
	}, []);
}
