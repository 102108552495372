import { Service } from '@Core/utils/services';
import getStorageByName from '@Core/utils/storage/getStorageByName';
import debug from '@Core/utils/debug';
import { ActionBotStorage, PersistKeyObject } from '@Types/storages';
import { IMessagesGroup } from '@Types/messages';
import { IAgentConnection } from '@Types/appStore';

interface IRedirectToParams {
	newTab?: boolean;
	withMemory?: boolean;
}
interface IRedirectWithMemoryToParams {
	newTab?: boolean;
	storage?: ActionBotStorage;
}
const tempRedirectStorageTypeKey = 'ab.storage.technical.tempRedirectStorageType';

export default class RedirectService extends Service {
	tempRedirectStorageType = () => ({
		get: () => getStorageByName('cookies').getItem(tempRedirectStorageTypeKey) as ActionBotStorage,
		set: (value: ActionBotStorage) => getStorageByName('cookies').setItem(tempRedirectStorageTypeKey, value),
		remove: () => getStorageByName('cookies').removeItem(tempRedirectStorageTypeKey),
	});

	getStorage = () => {
		const storageWhiteList = [
			'localStorage',
			'sessionStorage',
			'cookies',
			'cookiesExpired',
			'server',
		] as ActionBotStorage[];
		let forceStorage = this.tempRedirectStorageType().get();

		if (!storageWhiteList.includes(forceStorage)) {
			forceStorage = undefined;
		}
		const persistKeys = this.getAppStore().config.PUBLIC.persistKeys;
		const redirectStorageKey = persistKeys.redirectStorage as string;
		const persistKeyObject = persistKeys[redirectStorageKey] as PersistKeyObject;
		const storageName = persistKeyObject.storage || 'localStorage';
		const storage = getStorageByName(forceStorage || storageName);
		debug().log('_store', forceStorage, storage, redirectStorageKey);

		return { storageName, persistKeys, redirectStorageKey, storage };
	};

	_store = () => {
		const { redirectStorageKey, storage } = this.getStorage();

		return {
			set: (values) => {
				let valueToSave = values;
				if (typeof valueToSave === 'object') {
					valueToSave = JSON.stringify(values);
				}
				debug().log('_store.set', valueToSave);
				storage.setItem(redirectStorageKey, valueToSave);
			},
			get: () => {
				const data = storage.getItem(redirectStorageKey);
				debug().log('_store.get', data);
				try {
					return JSON.parse(data);
				} catch (e) {
					return data;
				}
			},
			clear: () => {
				storage.removeItem(redirectStorageKey);
				this.tempRedirectStorageType().remove();
			},
		};
	};

	_saveStore = (storage?: ActionBotStorage) => {
		const store = this.getAppStore();
		const { storageName, persistKeys, redirectStorageKey } = this.getStorage();
		const HistoryService = this.getServices('HistoryService');
		const historyService = new HistoryService();

		const { preventSavingStoreToBrowserStorageWhenRedirectKeys = [] } = store.config.PROTECTED.store;

		interface IValues {
			messages: IMessagesGroup[];
			agentConnection: IAgentConnection;
		}

		const values = {} as IValues;

		let skipMessagesHistory = false;

		debug().log('_saveStore', {
			store,
			persistKeys,
			redirectStorageKey,
			preventSavingStoreToBrowserStorageWhenRedirectKeys,
		});

		try {
			// max cookie store size is 4000B so we cant store messages. Save only last one with redirect
			if (['cookies', 'cookiesExpired'].includes(storageName)) {
				skipMessagesHistory = true;
			}
		} catch (e) {
			console.warn(e);
		}

		Object.keys(store).forEach((singleStoreKey) => {
			if (!preventSavingStoreToBrowserStorageWhenRedirectKeys.includes(singleStoreKey)) {
				if (skipMessagesHistory) {
					if (singleStoreKey === 'messages') {
						const messages = store.messages.value;
						if (messages.length) {
							values.messages = [messages[messages.length - 1]];
						}
						return;
					} else if (singleStoreKey === 'agentConnection') {
						const agentConnection = store.agentConnection.value;
						if (agentConnection) {
							values.agentConnection = {
								room_id: agentConnection.room_id,
								websocket_session_id: agentConnection.websocket_session_id,
							};
						}
						return;
					}
				}

				values[singleStoreKey] = store[singleStoreKey].value;
			}
		});

		this._store().set(values);
		return new Promise((resolve, reject) => {
			if (storage === 'server') {
				historyService.saveServerSideHistory({ history: values }).then(resolve).catch(reject);
			} else {
				resolve(undefined);
			}
		});
	};

	_loadStore = () => {
		const HistoryService = this.getServices('HistoryService');
		const historyService = new HistoryService();

		const store = this.getAppStore();

		function applySavedData(data) {
			debug().log('_loadStore', { store, data });

			Object.keys(store).forEach((singleStoreKey) => {
				const savedValue = data[singleStoreKey];
				const currentStore = store[singleStoreKey];
				try {
					if (savedValue) {
						if (typeof savedValue === 'object') {
							if (Array.isArray(savedValue)) {
								currentStore.set((currentStoreValue) => {
									currentStoreValue.splice(0, currentStoreValue.length);
									return [...currentStoreValue, ...savedValue];
								});
							} else {
								currentStore.set((currentStore) => ({
									...currentStore,
									...savedValue,
								}));
							}
						} else {
							currentStore.set(savedValue);
						}
					}
				} catch (e) {
					debug().error(e, 'cant load store ', singleStoreKey);
				}
			});
		}

		const afterLoad = (cb) => {
			setTimeout(() => {
				this._store().clear();
				cb?.();
			}, 1000);
		};

		return new Promise((resolve, reject) => {
			if (this.lastRedirectMode === 'server') {
				historyService
					.loadServerSideHistory()
					.then((r: { history: string }) => {
						const data = JSON.parse(r.history);
						applySavedData(data);
						afterLoad(() => resolve(data));
					})
					.catch(reject);
			} else {
				const data = this._store().get();
				if (data) {
					applySavedData(data);
				}
				afterLoad(() => resolve(data));
			}
		});
	};

	_showMessagesAfterRedirect = () => {
		debug().log('_showMessagesAfterRedirect');
		const messagesAfterRedirect = this.getAppStore().messagesAfterRedirect;

		const ConnectionService = this.getServices('ConnectionService');
		const connectionService = new ConnectionService();
		setTimeout(() => {
			connectionService.processMessages(messagesAfterRedirect.value);
			messagesAfterRedirect.set({ group: undefined, delayed: [] });
		}, 0);
	};

	redirectTo = (url: string, params: IRedirectToParams = {}) => {
		debug().log('redirectTo', { url, params });
		try {
			window.__actionBot.websocket.closeWS();
		} catch (e) {
			console.warn(e);
		}
		setTimeout(() => {
			this.getServices('BehaviorService').dispatchEvent('redirect', url);
			if (params && params.newTab) {
				window.open(url, '_blank') || window.location.replace(url);
			} else {
				if (params && params.withMemory) {
					const oldUrl = new URL(window.location.href);
					const newUrl = new URL(url);
					if (oldUrl.host === newUrl.host && oldUrl.pathname === newUrl.pathname) {
						window.location.assign(url);
						this.checkRedirectStatus();
					} else {
						window.location.assign(url);
					}
				} else {
					window.location.assign(url);
				}
			}
		}, 1000);
	};

	redirectWithMemoryTo = (url: string, params: IRedirectWithMemoryToParams = {}) => {
		const HistoryService = this.getServices('HistoryService');
		const historyService = new HistoryService();

		debug().log('redirectWithMemoryTo', { url, params });
		if (params && params.storage) {
			this.tempRedirectStorageType().set(params.storage);
		}
		this._saveStore(params.storage).then((r: { token: string }) => {
			debug().log({ r });
			const token = r?.token;
			debug().log('token', token);
			if (token) {
				getStorageByName('cookies').setItem(historyService.REDIRECT_HISTORY_TOKEN_KEY, token);
			}
			setTimeout(() => {
				this.redirectTo(url, { ...params, withMemory: true });
			}, 200);
		});
	};

	get lastRedirectMode() {
		const HistoryService = this.getServices('HistoryService');
		const historyService = new HistoryService();

		if (getStorageByName('cookies').getItem(historyService.REDIRECT_HISTORY_TOKEN_KEY)) {
			return 'server';
		} else if (this._store().get()) {
			return 'local';
		} else {
			return null;
		}
	}

	checkRedirectStatus = () => {
		const wasRedirected = !!this.lastRedirectMode;

		debug().log('checkRedirectStatus', { wasRedirected });
		if (wasRedirected) {
			this._loadStore().then((r) => {
				setTimeout(() => {
					this._showMessagesAfterRedirect();

					const ControlService = this.getServices('ControlService');
					const controlService = new ControlService();

					controlService.scrollToBottom();
				}, 0);
			});
		}
		return wasRedirected;
	};
}
