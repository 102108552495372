import { Service } from '@Core/utils/services';
import { Message, MessagesGroup } from '@Core/utils/models/messages';
import { OWNER } from '@Types/messages';
export default class BehaviorService extends Service {
	behavior;
	events;
	constructor(behavior) {
		super();
		if (behavior) {
			this.behavior = behavior;
			this.events = behavior.events;
			this.handleInactivity();
			this.registerEvents();
		}
	}

	handleInactivity = () => {
		const inactivity = this.behavior.inactivity;

		if (typeof inactivity === 'object') {
			const getAppStore = this.getAppStore;
			const getServices = this.getServices;
			const isInactive = () =>
				!getAppStore().messages.value.some((singleGroup) => singleGroup.owner === OWNER.user);

			Object.keys(inactivity).forEach((inactivityKey) => {
				const singleInactivity = inactivity[inactivityKey];

				const { delay = 0, callback = () => {} } = singleInactivity;

				const timeout = setTimeout(() => {
					if (isInactive()) {
						callback({
							getAppStore,
							getServices,
							models: { Message, MessagesGroup },
						});
					}
					clearTimeout(timeout);
				}, delay);
			});
		}
	};

	static getEvent = (eventName: string) => {
		return 'ab.event.' + eventName;
	};

	registerEvents = () => {
		if (!window.__actionBot.events) {
			window.__actionBot.events = {};
			Object.keys(this.events).forEach((eventName) => {
				const eventNameWithPrefix = BehaviorService.getEvent(eventName);
				const eventHandler = this.events[eventName];

				function handleEvent() {
					const event = window.__actionBot.events[eventName];
					eventHandler(event.data);
					setTimeout(() => {
						event.data = undefined;
					});
				}

				if (eventHandler) {
					const event = new Event(eventNameWithPrefix);
					if (!window.__actionBot.events[eventName]) {
						window.__actionBot.events[eventName] = event;
						document.removeEventListener(eventNameWithPrefix, handleEvent, false);
						document.addEventListener(eventNameWithPrefix, handleEvent, false);
					}
				}
			});
		}
	};

	static dispatchEvent = (name: string, data?: any) => {
		const event = window.__actionBot.events && window.__actionBot.events[name];
		if (event) {
			if (data) {
				event.data = data;
			}
			document.dispatchEvent(event);
		}
	};
}
