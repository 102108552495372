import styled from 'styled-components';

export const MessagesGroup = styled.div.attrs({ tabIndex: 0, role: 'group' })`
	${({ theme }) => ({
		flexDirection: 'column',
		display: 'block',
		animationName: 'showMessage',
		animationDuration: '.3s',
		animationIterationCount: '1',
		p: {
			whiteSpace: 'break-spaces',
		},
		ul: {
			listStyle: 'disc',
			paddingLeft: '30px',
		},
		ol: {
			paddingLeft: '30px',
		},
		...theme.elements.messages.MessagesGroup,
	})};
`;

export const MessagesWithAvatar = styled.div`
	${({ theme }) => ({
		flexDirection: 'row',
		textAlign: 'left',
		position: 'relative',
		display: 'block',
		padding: '20px 20px 10px 20px',
		'&.bot, &.agent': {
			marginLeft: '5px',
			'> div > div': {
				alignItems: 'flex-start',
			},
		},
		'&.user': {
			flexDirection: 'row-reverse',
			marginRight: '5px',
			textAlign: 'right',
			div: {
				color: theme.colors.colorPrimary,
			},
		},
		'&.system': {
			justifyContent: 'center',
			'div > div > div': {
				fontSize: '14px',
				color: 'black',
				backgroundColor: '#ebebeb',
				boxShadow: '0 0 4px#cccccc',
				padding: '6px 16px',
				lineHeight: '17px',
				maxWidth: '270px',
				p: {
					paddingLeft: '28px',
					'&::before': {
						content: "''",
						position: 'absolute',
						left: '10px',
						top: '50%',
						backgroundColor: '#e9008f',
						width: '24px',
						height: '24px',
						borderRadius: '50%',
						fontSize: '18px',
						transform: 'translateY(-50%)',
					},
					'&::after': {
						content: "''",
						position: 'absolute',
						left: '20px',
						top: '50%',
						width: '6px',
						height: '10px',
						borderBottom: '2px solid white',
						borderRight: '2px solid white',
						transform: 'translateY(-60%) rotate(45deg)',
					},
				},
			},
		},
		...theme.elements.messages.MessagesWithAvatar,
	})};
`;

export const Messages = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		...theme.elements.messages.MessagesGroupMessages,
	})};
`;

export const Date = styled.p`
	${({ theme }) => ({
		fontSize: '11px',
		marginLeft: '15px',
		marginTop: 0,
		...theme.elements.messages.MessagesGroupDate,
	})};
`;
