import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import debug from '@Core/utils/debug';
import { ReactNode } from 'react';

interface IDebug {
	children: ReactNode;
}

export default ({ children }: IDebug) => {
	const store = getAppStore();
	const services = getServices();

	debug().log({ store });
	debug().log({ services });

	return children as JSX.Element;
};
