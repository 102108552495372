import styled from 'styled-components';

export const ActionIcon = styled.div`
	${({ theme }) => ({
		cursor: 'pointer',
		transition: 'opacity 0.3s',
		'&:hover': {
			opacity: 1,
		},
		position: 'relative',
		display: 'block',
		width: '15px',
		height: '15px',
		opacity: 1,
		img: {
			display: 'none',
		},
		'&:after': {
			content: '"\\d7"',
			position: 'absolute',
			top: '1px',
			left: '-8px',
			color: theme.colors.colorPrimary,
			fontSize: '46px',
			lineHeight: '15px',
			fontWeight: 'normal',
			transition: 'color .3s',
		},
		'&:hover:after': {
			color: theme.colors.colorSecondary,
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			width: '25px',
			height: '25px',
			'&:after': {
				fontSize: '60px',
			},
		},
		...theme.elements.ActionIcon,
	})};
`;
