import { MenuContainer, MenuIcon } from './Menu.styled';
import MenuModal from './Modal/MenuModal';
import { getAppStore } from '@Core/store/App';

export interface IMessageMenuMessage {
	onOptionSelect: (command: string) => void;
}

export default ({ onOptionSelect }: IMessageMenuMessage) => {
	const store = getAppStore();
	const { value: isMainMenuActive, set: setIsMenuActive } = store.mainMenuActive;

	function toggleMenu() {
		setIsMenuActive((isActive) => !isActive);
	}
	return (
		<MenuContainer tabIndex={0} onBlur={() => setIsMenuActive(false)}>
			<MenuIcon onClick={toggleMenu} open={isMainMenuActive} />
			<MenuModal open={isMainMenuActive} toggleMenu={toggleMenu} onOptionSelect={onOptionSelect} />
		</MenuContainer>
	);
};
