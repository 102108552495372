import { IMessage } from '@Core/types/messages';
import {
	TextContainer,
	TextWrapper,
	MessageAttachmentFileContainer,
	MessageAttachmentFileIcon,
	MessageAttachmentFileName,
	MessageAttachmentFileDetails,
	MessageAttachmentDateDetails,
	MessageAttachmentsListWrapper,
	MessageAttachmentText,
	MessageAttachmentDate,
	MessageAttachmentTime,
} from './MessageAttachment.styled';
import messageAttachmentIcon from '@Core/assets/icons/messageAttachmentIcon.svg';
import { additionalZero } from '@Core/utils/date/readableDate';

interface IMessageAttachment {
	message: IMessage;
}
export default function MessageAttachment({ message }: IMessageAttachment) {
	const { content, owner } = message;
	const { text, attachments } = content;
	const attachmentsArray = JSON.parse(attachments);
	const date = new Date();

	const currentDate = `${additionalZero(date.getDate())}.${additionalZero(
		date.getMonth() + 1
	)}.${date.getFullYear()}`;
	const currentUTCTime = `${date.getUTCHours()}:${additionalZero(date.getUTCMinutes())}`;

	const AttachmentsList = () => {
		const attachments = attachmentsArray.map((attachment, index) => {
			return (
				<MessageAttachmentFileContainer key={index}>
					<MessageAttachmentFileIcon src={messageAttachmentIcon} />

					<MessageAttachmentFileDetails>
						<MessageAttachmentFileName href={attachment.url}>{attachment.name}</MessageAttachmentFileName>
						<MessageAttachmentDateDetails>
							<MessageAttachmentDate>{currentDate}</MessageAttachmentDate>
							<MessageAttachmentTime>{currentUTCTime} GMT</MessageAttachmentTime>
						</MessageAttachmentDateDetails>
					</MessageAttachmentFileDetails>
				</MessageAttachmentFileContainer>
			);
		});
		return <MessageAttachmentsListWrapper>{attachments}</MessageAttachmentsListWrapper>;
	};

	return (
		<TextContainer owner={owner}>
			<TextWrapper className={owner}>
				<MessageAttachmentText>{text}</MessageAttachmentText>
				<AttachmentsList />
			</TextWrapper>
		</TextContainer>
	);
}
