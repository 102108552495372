import { useEffect } from 'preact/hooks';
import { getServices } from '@Core/store/Services';

export function useDataLayerListener() {
	const { BehaviorService } = getServices();

	useEffect(() => {
		window.dataLayer = window.dataLayer || [];
		const dataLayerPush = window.dataLayer.push;
		window.dataLayer.push = function (...args) {
			BehaviorService.dispatchEvent('dataLayerPush', ...args);
			return dataLayerPush.apply(this, args);
		};
	}, []);
}
