import {
	ModernCarouselButtonLeft,
	ModernCarouselButtonRight,
	ModernCarouselContainer,
	ModernCarouselImage,
	ModernCarouselTitle,
	ModernCarouselValue,
	ModernSwipeItem,
	ModernClickFix,
} from './ModernCarousel.styled';
import Slider from 'react-slick';
import '@Core/assets/css/slick.css';
import '@Core/assets/css/slick-theme.css';
import { createRef } from 'preact';
import { useState } from 'preact/hooks';
import { getAppStore } from '@Core/store/App';
import ReactMarkdown from 'react-markdown';
import { getServices } from '@Core/store/Services';
import { ICarouselConfig } from '@Types/appConfig';
import { replaceHTMLtags } from '@Core/utils/converters/replaceHTMLtags';

interface IModernSlide {
	text: string;
	value: string;
	icon: string;
	price: string;
	title: string;
	subtitle: string;
}

interface IModernCarousel {
	slides: IModernSlide[];
	sliderConfig: ICarouselConfig;
	onOptionSelect: (slide: IModernSlide['value']) => void;
}

export default ({ slides, sliderConfig, onOptionSelect }: IModernCarousel) => {
	const sliderRef = createRef();
	const appConfig = getAppStore().config;
	const { settings } = sliderConfig ? sliderConfig : appConfig.PUBLIC.carousel;

	const [dragging, setDragging] = useState(false);
	const MarkdownService = getServices('MarkdownService');
	const markdownService = new MarkdownService();
	const [buttonPrevVisible, setButtonPrevVisible] = useState(false);
	const [buttonNextVisible, setButtonNextVisible] = useState(true);

	const changeEvents = {
		beforeChange: () => {
			setDragging(true);
		},
		afterChange: (currentSlide) => {
			setDragging(false);
			toggleSliderButtons(currentSlide);
		},
	};

	function toggleSliderButtons(currentSlide) {
		currentSlide == 0 ? setButtonPrevVisible(false) : setButtonPrevVisible(true);
		currentSlide == slides.length - 1 ? setButtonNextVisible(false) : setButtonNextVisible(true);
	}

	function handleClick(e, option) {
		dragging ? e.preventDefault : onOptionSelect(option);
	}

	if (slides) {
		const slidesComponents = slides.map((singleOption, index) => {
			const text = replaceHTMLtags(singleOption.text, 'br', '\n');
			return (
				<ModernSwipeItem key={index} onClick={(e) => handleClick(e, singleOption.value)}>
					<ModernClickFix>
						<ModernCarouselImage src={singleOption.icon} />
						<ModernCarouselValue>{singleOption.price}</ModernCarouselValue>
					</ModernClickFix>
					<ModernCarouselTitle>
						<p>{singleOption.subtitle}</p>
						<p>
							<b>{singleOption.title}</b>
						</p>
						<ReactMarkdown components={markdownService.getRenderers()}>{text}</ReactMarkdown>
					</ModernCarouselTitle>
				</ModernSwipeItem>
			);
		});

		return (
			<ModernCarouselContainer>
				<Slider {...settings} {...changeEvents} ref={sliderRef}>
					{slidesComponents}
				</Slider>
				{buttonPrevVisible && (
					<ModernCarouselButtonLeft onClick={() => sliderRef.current.slickPrev()}>
						&lt;
					</ModernCarouselButtonLeft>
				)}
				{buttonNextVisible && (
					<ModernCarouselButtonRight onClick={() => sliderRef.current.slickNext()}>
						&gt;
					</ModernCarouselButtonRight>
				)}
			</ModernCarouselContainer>
		);
	}
};
