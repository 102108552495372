import { createAppStore, getAppStore } from '@Core/exports/store/App';

export default createAppStore(({ useState, useStateWithKey, appStoreElement, persistValue }) => {
	const PLATFORM_PERSIST_KEYS = {
		currentRole: `ab.storage.uniwersytet-slaski.currentRole`,
		lastUsedRole: `ab.storage.uniwersytet-slaski.lastUsedRole`,
	};

	let DEFAULT_VALUE;
	const listOfContexts = ['student', 'doktorant', 'pracownik', 'kandydat', 'absolwent'];
	const link = window.location.href;

	listOfContexts.forEach((context) => {
		if (link.includes(context)) {
			DEFAULT_VALUE = context;
		}
	});

	const currentRole = useStateWithKey(
		PLATFORM_PERSIST_KEYS.currentRole,
		persistValue(PLATFORM_PERSIST_KEYS.currentRole, DEFAULT_VALUE)
	);

	const initialPopupEventSend = useStateWithKey(
		'initialPopupEventSend',
		persistValue('initialPopupEventSend', {
			studentPopupSend: false,
			kandydatPopupSend: false,
			pracownikPopupSend: false,
			doktorantPopupSend: false,
			absolwentPopupSend: false,
			defaultPopupSend: false,
		})
	);

	const lastUsedRole = useStateWithKey(
		PLATFORM_PERSIST_KEYS.lastUsedRole,
		persistValue(PLATFORM_PERSIST_KEYS.lastUsedRole, '')
	);

	return {
		currentRole: appStoreElement(currentRole),
		initialPopupEventSend: appStoreElement(initialPopupEventSend),
		lastUsedRole: appStoreElement(lastUsedRole),
	};
});
