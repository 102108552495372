import { getAppStore } from '@Core/store/App';
import { useState } from 'react';
import { createRef } from 'preact';
import Slider from 'react-slick';
import ReactMarkdown from 'react-markdown';
import { getServices } from '@Core/store/Services';
import { Message } from '@Core/exports/models';
import { replaceHTMLtags } from '@Core/utils/converters/replaceHTMLtags';

import {
	WithButtonsCarouselButtonContainer,
	WithButtonsCarouselButton,
	WithButtonsCarouselContainer,
	WithButtonsCarouselImage,
	WithButtonsCarouselRight,
	WithButtonsCarouselTable,
	WithButtonsCarouselText,
	WithButtonsCarouselSwipeItem,
	WithButtonsCarouselLeft,
	WithButtonsCarouselImageContainer,
} from './WithButtonsCarousel.styled';

export default ({ slides, sliderConfig, onOptionSelect }) => {
	let sliderRef = createRef();
	const appConfig = getAppStore().config;
	const { settings } = sliderConfig || appConfig.PUBLIC.carousel;
	const [dragging, setDragging] = useState(false);
	const [buttonPrevVisible, setButtonPrevVisible] = useState(false);
	const [buttonNextVisible, setButtonNextVisible] = useState(slides.length > 1);

	const arrowClassName = !settings.arrows && 'hidden';
	const { MarkdownService, ActionsService } = getServices();
	const markdownService = new MarkdownService();
	const actionsService = new ActionsService();

	const changeEvents = {
		beforeChange: () => {
			setDragging(true);
		},
		afterChange: (currentSlide) => {
			setDragging(false);
			toggleSliderButtons(currentSlide);
		},
	};

	function handleClick(e, option) {
		dragging ? e.preventDefault : handleAction(option);
	}

	function handleAction(option) {
		if (option && option.onClick) {
			const action = option.onClick.type;
			switch (action) {
				case 'action': {
					const { actionName, actionname, ...actionData } = option.onClick.data;
					const actionNameCaseInsensitive = actionName || actionname;
					if (actionNameCaseInsensitive && actionsService.exists(actionNameCaseInsensitive)) {
						const actionMessage = new Message({
							isAction: true,
							content: actionData,
						});
						actionsService.getCaseInsensitiveProp(actionNameCaseInsensitive)(actionMessage);
						break;
					}
					break;
				}
				default:
					onOptionSelect(option.onClick.data);
			}
		}
	}

	function toggleSliderButtons(currentSlide) {
		setButtonPrevVisible(currentSlide !== 0);
		setButtonNextVisible(currentSlide !== slides.length - 1);
	}

	function getTextComponent(texts) {
		return texts?.map((text) => {
			if (text) {
				const replacedText = replaceHTMLtags(text.value, 'br', '\n');
				const key = text.key;
				return (
					<tr key={key}>
						{key && <td>{key}</td>}
						<td>
							<ReactMarkdown components={markdownService.getRenderers()}>{replacedText}</ReactMarkdown>
						</td>
					</tr>
				);
			}
		});
	}

	function getButtonComponent(buttons) {
		return buttons?.map((button, index) => {
			if (button) {
				return (
					<WithButtonsCarouselButton key={index} style={button.style} onClick={(e) => handleClick(e, button)}>
						<ReactMarkdown components={markdownService.getRenderers()}>{button.text}</ReactMarkdown>
					</WithButtonsCarouselButton>
				);
			}
		});
	}

	function getImageComponent(image) {
		if (image) {
			return <WithButtonsCarouselImage src={image} />;
		}
	}

	if (slides) {
		const slidesComponents = slides.map((singleOption, index) => {
			const textComponent = getTextComponent(singleOption.text);
			const buttonComponent = getButtonComponent(singleOption.buttons);
			const imageComponent = getImageComponent(singleOption.image);

			return (
				<WithButtonsCarouselSwipeItem key={index} onClick={(e) => handleClick(e, singleOption.value)}>
					{imageComponent && (
						<WithButtonsCarouselImageContainer>{imageComponent}</WithButtonsCarouselImageContainer>
					)}
					{textComponent && <WithButtonsCarouselTable>{textComponent}</WithButtonsCarouselTable>}
					{buttonComponent && (
						<WithButtonsCarouselButtonContainer>{buttonComponent}</WithButtonsCarouselButtonContainer>
					)}
				</WithButtonsCarouselSwipeItem>
			);
		});

		return (
			<WithButtonsCarouselContainer>
				<Slider {...settings} {...changeEvents} ref={sliderRef}>
					{slidesComponents}
				</Slider>
				{buttonPrevVisible && (
					<WithButtonsCarouselLeft className={arrowClassName} onClick={() => sliderRef.current.slickPrev()}>
						&lt;
					</WithButtonsCarouselLeft>
				)}
				{buttonNextVisible && (
					<WithButtonsCarouselRight className={arrowClassName} onClick={() => sliderRef.current.slickNext()}>
						&gt;
					</WithButtonsCarouselRight>
				)}
			</WithButtonsCarouselContainer>
		);
	}
};
