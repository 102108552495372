import { createThemeExtension } from '@Core/exports/theme';
import avatar from '@Platform/assets/icons/ico_avatar_small_white_on_black.svg';
import sendIcon from '@Platform/assets/icons/ico_send_white.svg';
import avatarBig from '@Platform/assets/icons/ico_avatar_big_white_on_black.svg';
import { ITheme } from '@Core/types/themes';

export default createThemeExtension('whiteOnBlack', {
	colors: {
		primary: '#ffffff',
		lightPrimary: '#ffffff',
		hoverPrimary: '#000000',
		white: '#000000',
		shadow: 'rgba(32,37,43, .25)',
		beton: '#000000',
		gray: '#000000',
		cream: '#000000',
		lightGray: '#000000',
		silver: '#ffffff',
		red: '#ffffff',
		black: '#ffffff',
		botTriangleBefore: '#ffffff',
		userTriangleBefore: '#ffffff',
		myMessageColor: '#ffffff',
		myMessageBackgroundColor: '#000000',
		inputContainerBorderTopColor: '#ffffff',
		placeholderColor: '#ffffff',
		messageBotBoxShadow: '#000000',
		myMessageBorderColor: '#ffffff',
		botMessageBorderColor: '#ffffff',
		headerContainerColor: '#ffffff',
		headerContainerBackgroundColor: '#000000',
		poweredByColor: '#ffffff',
		poweredByBorderTopColor: '#ffffff',
		poweredByAColor: '#ffffff',
		linkColor: '#ffffff',
		textWithButtonsListColor: '#ffffff',
		headerContainerTitleColor: '#ffffff',
		textWithButtonsBackgroundColor: '#000000',
		textWithButtonsColor: '#ffffff',
		userIndicatorColor: '#ffffff',
	},
	elements: {
		BotWindow: {
			boxShadow: '0 4px 16px rgba(0, 0, 0, 0.25)',
			border: '1px solid white',
			'& .bot-chat-icon': {
				width: '50%',
			},
		},

		HeaderContainer: {
			boxShadow: 'none',
			borderBottom: '1px solid white',
		},

		HeaderIconContainer: {
			'div:after': {
				color: 'white',
			},
			'&:hover': {
				'div:after': {
					color: 'white',
				},
			},
		},

		MessageInput: {
			'&::placeholder': {
				opacity: 0.7,
			},
		},

		InitialPopupClose: {
			backgroundColor: '#000000 !important',
			div: {
				color: '#ffffff !important',
			},
			'&:hover': {
				backgroundColor: '#ffffff !important',
				div: {
					color: '#000000 !important',
				},
			},
		},

		BotChatIcon: {
			backgroundImage: `url("${avatarBig}")`,
			width: '100%',
			height: '100%',
		},

		BotTalkImage: {
			backgroundColor: 'unset',
			'@media (max-width: 576px)': {
				backgroundColor: 'unset',
			},
		},

		SubmitButton: {
			backgroundImage: `url("${sendIcon}")`,
			'&:disabled': {
				backgroundImage: `url("${sendIcon}")`,
				opacity: '0.6',
			},
		},

		messages: {
			MessagesGroupAvatar: {
				'&.bot': {
					backgroundImage: `url(${avatar})`,
				},
			},

			TextWithButtonsTextButton: {
				border: '1px solid #ffffff',
				'&:hover': {
					backgroundColor: '#ffffff',
					span: {
						color: '#000000 !important',
					},
				},
			},

			OptionButton: {
				'&:not(:disabled):hover': {
					backgroundColor: '#ffffff',
					color: '#000000',
				},
			},
		},
	},
} as ITheme);
