import { IBehaviorConfig } from '@Core/types/behaviorConfig';
import { useRef, useState } from 'preact/hooks';

const useScrollDownToUp = (behaviorConfig: IBehaviorConfig) => {
	const [hasScrolledDownToUp, setHasScrolledDownToUp] = useState(false);
	const scrolledDown = useRef(false);

	const handleFullScroll = () => {
		if (
			document.documentElement.scrollHeight <=
			window.innerHeight + window.scrollY + (behaviorConfig.scrollPage?.bottomScrollSpace || 0)
		) {
			scrolledDown.current = true;
		} else if (scrolledDown.current && window.scrollY < behaviorConfig.scrollPage?.topScrollSpace) {
			setHasScrolledDownToUp(true);
		}
	};

	return [hasScrolledDownToUp, handleFullScroll] as const;
};

export { useScrollDownToUp };
