import { getServices } from '@Core/store/Services';
import ReactMarkdown from 'react-markdown';
import {
	TextContainer,
	TextWrapper,
	UserIndicator,
	TextButton,
	ButtonWrapper,
	TextButtonList,
	ButtonContent,
} from './TextWithButtons.styled';
import { Message } from '@Core/utils/models/messages';
import { IMessage, OWNER } from '@Core/types/messages';
import { WatsonCommands } from '@Core/services/ActionsService';
import { useState } from 'preact/hooks';
import useAriaButton from '@Core/utils/aria/useAriaButton';
import useAriaOptions from '@Core/utils/aria/useAriaOptions';
import useDictionary from '@Core/utils/language/useDictionary';
import { replaceHTMLtags } from '@Core/utils/converters/replaceHTMLtags';
interface ITextWithButtons {
	message: IMessage;
}

export default function TextWithButtons({ message }: ITextWithButtons) {
	const { content, owner } = message;
	const { text, buttons, type, permanent } = content;
	const MarkdownService = getServices('MarkdownService');
	const markdownService = new MarkdownService();
	const { translate } = useDictionary();
	const replacedText = replaceHTMLtags(text, 'br', '\n');
	return (
		<TextContainer owner={owner}>
			{owner == 'user' && <UserIndicator>{translate('message.text.userIndicator')}</UserIndicator>}

			<TextWrapper className={owner}>
				<ReactMarkdown components={markdownService.getRenderers()}>{replacedText}</ReactMarkdown>
				<ButtonList buttons={buttons} type={type} permanent={permanent} />
			</TextWrapper>
		</TextContainer>
	);
}

interface IButtonList {
	buttons: string;
	type?: 'buttons' | 'list';
	permanent?: 'true' | 'false';
}

interface IButton {
	action: WatsonCommands;
	buttonText: string;
	message: string;
	[key: string]: any;
}

function ButtonList({ buttons, type, permanent }: IButtonList) {
	const { ActionsService, ConnectionService, MessageService } = getServices();
	const actionsService = new ActionsService();
	const connectionService = new ConnectionService();
	const messageService = new MessageService();
	const buttonsArray = JSON.parse(buttons);
	const isPermanent = permanent === 'true';
	const [activeButton, setActiveButton] = useState(-1);
	const ariaOptions = useAriaOptions();

	const buttonList = buttonsArray.map((button: IButton, index: number) => {
		const { action, buttonText, message, ...params } = button;
		function handleClick() {
			if (!isAnyButtonActive) {
				setActiveButton(index);
				if (action && actionsService.exists(action)) {
					const actionMessage = new Message({
						isAction: true,
						content: params,
					});

					actionsService.getCaseInsensitiveProp(action)(actionMessage);
				} else if (message) {
					const messageObject = new Message({ owner: OWNER.user, content: message, type: 'text' });
					messageService.addMessage(messageObject);
					connectionService.sendMessage({
						text: message,
					});
				}
			}
		}

		const ButtonComponent = type === 'list' ? TextButtonList : TextButton;
		const isButtonActive = activeButton === index || isPermanent || activeButton === -1;
		const isAnyButtonActive = activeButton !== -1 && !isPermanent;

		const buttonProps = useAriaButton({
			onClick: handleClick,
			name: '',
			disabled: !isButtonActive,
			key: index,
			tabIndex: 0,
			role: 'option',
		});

		return (
			<ButtonComponent
				key={index}
				isButtonActive={isButtonActive}
				isAnyButtonActive={isAnyButtonActive}
				{...buttonProps}
			>
				<ButtonContent>{buttonText}</ButtonContent>
			</ButtonComponent>
		);
	});

	return <ButtonWrapper {...ariaOptions}>{buttonList}</ButtonWrapper>;
}
