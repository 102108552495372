import BehaviorService from '@Core/services/BehaviorService';
import { IBehaviorConfig } from '@Core/types/behaviorConfig';

// Dispatch event when user has visited same page many times
export default function usePageHistory(behaviorConfig: IBehaviorConfig) {
	let pagesPathnames: string[] = JSON.parse(sessionStorage.getItem('pageHistory')) || [];
	// Only add the current page to the array if it's not already the last element
	if (pagesPathnames[pagesPathnames.length - 1] !== window.location.pathname) {
		pagesPathnames.push(window.location.pathname);
	}
	// Keep the array at the specified length
	if (pagesPathnames.length > (behaviorConfig.navigationIssue?.pagesInHistory || 10)) {
		pagesPathnames.shift();
	}

	sessionStorage.setItem('pageHistory', JSON.stringify(pagesPathnames));

	// Return the path of the page that exceeded the limit
	const pathname = hasPageRepeatNumberExceeded(pagesPathnames, behaviorConfig.navigationIssue?.pageRepeatNumber || 3);
	if (pathname) {
		setTimeout(() => {
			BehaviorService.dispatchEvent('navigationIssue');
			// Remove the path from the history
			pagesPathnames = pagesPathnames.filter((page) => page !== pathname);
			sessionStorage.setItem('pageHistory', JSON.stringify(pagesPathnames));
		}, 1000);
	}
}

function hasPageRepeatNumberExceeded(pagesPathnames: string[], pageLimit: number): string | null {
	const pageCounts = new Map<string, number>();

	for (const page of pagesPathnames) {
		const count = (pageCounts.get(page) || 0) + 1;
		pageCounts.set(page, count);
	}

	for (const [page, count] of Array.from(pageCounts.entries())) {
		if (count >= pageLimit) {
			return page;
		}
	}

	return null;
}
