import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import * as coreServices from '@Core/services/services';
import { getBridge } from '@Core/store/storeBridges';
import { ReactNode } from 'react';

export type Services = Partial<typeof coreServices>;
type ServiceNames = keyof Services;

type GetServices<TServiceName> = TServiceName extends undefined
	? Services
	: TServiceName extends ServiceNames
		? Services[TServiceName]
		: Services;

const ServicesStore = createContext({});

export function getServices<TServiceName extends ServiceNames = undefined>(
	serviceName?: TServiceName
): GetServices<TServiceName> {
	let services;

	try {
		services = useContext(ServicesStore);
	} catch (e) {
		services = getBridge('services');
	}

	if (serviceName && Object.prototype.hasOwnProperty.call(services, serviceName)) {
		return services[serviceName];
	}

	return services;
}

interface IServicesStoreProvider {
	children: ReactNode;
	services: Services;
}

export const ServicesStoreProvider = ({ children, services }: IServicesStoreProvider) => {
	function createServicesStore() {
		return {
			...coreServices,
			...services,
		};
	}

	return <ServicesStore.Provider value={createServicesStore()}>{children}</ServicesStore.Provider>;
};
