import { Button, ButtonTitle, ButtonContainer } from './Button.styled';
import { getServices } from '@Core/store/Services';
import { IMessage } from '@Types/messages';
import useAriaButton from '@Core/utils/aria/useAriaButton';

export interface IButtonMessage {
	message: IMessage;
}

export default ({ message }: IButtonMessage) => {
	const { value } = message.content;

	const ButtonMessageService = getServices('ButtonMessageService');
	const buttonMessageService = new ButtonMessageService({ message });

	const buttonProps = useAriaButton({
		onClick: buttonMessageService.onClick,
	});

	return (
		<ButtonContainer owner={message.owner}>
			<Button {...buttonProps}>
				<ButtonTitle>{value}</ButtonTitle>
			</Button>
		</ButtonContainer>
	);
};
