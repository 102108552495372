import { getAppStore } from '@Core/store/App';

export default function useAriaButton({ onClick, disabled = false, name = '', ...props }) {
	const lang = getAppStore().language.value;

	return {
		role: 'button',
		onClick,
		name,
		'aria-label': name,
		onKeyDown: ({ code }) => {
			if (['Enter', 'Space'].includes(code)) {
				onClick();
			}
		},
		'aria-disabled': !!disabled,
		disabled,
		lang,
		tabIndex: 0,
		...props,
	};
}
