import { getAppStore } from '@Core/store/App';
import { PoweredBy } from './PoweredBy.styled';

export default () => {
	const poweredByEnabled = getAppStore().config.PROTECTED.poweredBy.enabled;
	if (!poweredByEnabled) {
		return null;
	}

	return (
		<PoweredBy tabIndex={-1}>
			Powered by
			<a target="_blank" rel="noopener noreferrer" href="https://action.bot/">
				Actionbot
			</a>
		</PoweredBy>
	);
};
