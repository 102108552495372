import { IMessage } from '@Types/messages';
import { EmbeddedMapContainer } from './EmbeddedMap.styled';
import { getAppStore } from '@Core/store/App';

export interface IEmbeddedMapMessage {
	message: IMessage;
}

export default ({ message }: IEmbeddedMapMessage) => {
	const { value } = message.content;

	const isFullScreen = getAppStore().fullScreen.value;

	return <EmbeddedMapContainer src={value} className={isFullScreen ? 'fullscreen' : ''} />;
};
