import { Service } from '@Core/utils/services';
import theme from '@Theme';
import { getAppStore } from '@Core/store/App';

export default class ThemeService extends Service {
	getMobileBreakpoint = () => {
		let mobileBreakpoint = 576;
		const breakpointFromTheme = theme.breakpoints?.mobile;
		if (breakpointFromTheme) {
			const decimalString = breakpointFromTheme.replace(/\D/, '');
			if (decimalString) {
				mobileBreakpoint = parseInt(decimalString);
			}
		}
		return mobileBreakpoint;
	};

	setFullScreen = (active) => {
		getAppStore().fullScreen.set(active);
	};

	static getThemeExtension = () => {
		return getAppStore().themeExtension.value;
	};

	static setThemeExtension = (themeExtensionName: string) => {
		getAppStore().themeExtension.set(themeExtensionName);
	};
}
