import coreAppConfig from '@Core/config/app';
import deepMerge from '@Core/utils/objects/deepMerge';
import debug from '@Core/utils/debug';
import actionbotFile from '@Root/.actionbot/actionbot.json';
import { IAppConfig } from '@Types/appConfig';
import { IAppStore } from '@Types/appStore';

function _preConfig() {
	if (window.__actionBot) {
		window.__actionBot = {
			...window.__actionBot,
			version: actionbotFile.version,
		};
	}
	!window.__actionBot &&
		(window.__actionBot = {
			version: actionbotFile.version,
		} as Window['__actionBot']);
	!window.__actionBot.config &&
		(window.__actionBot.config = {
			PUBLIC: {},
			PROTECTED: {},
		});
	!window.__actionBot.theme && (window.__actionBot.theme = {});
	!window.__actionBot.dictionary && (window.__actionBot.dictionary = {});
}

export function createAppConfig<Type>(
	// @ts-ignore IT'S OK
	{ PROTECTED, PUBLIC, proxy } = {} as Type extends undefined ? IAppStore : Type
): IAppConfig {
	_preConfig();
	const publicConfigFromWindow = window.__actionBot.config.PUBLIC;

	// adjust input to proxy
	const platformAppConfig = {
		PROTECTED: PROTECTED,
		PUBLIC: deepMerge(PUBLIC, publicConfigFromWindow),
	};

	if (typeof proxy === 'object') {
		Object.keys(proxy).forEach((key) => {
			try {
				const pathCoreBased = key;
				const pathPlatformBased = proxy[key];

				const [protectionLevelCoreBased, propNameCoreBased] = pathCoreBased.split('.');
				const [protectionLevelPlatformBased, propNamePlatformBased] = pathPlatformBased.split('.');

				platformAppConfig[protectionLevelCoreBased][propNameCoreBased] =
					platformAppConfig[protectionLevelPlatformBased][propNamePlatformBased];

				delete platformAppConfig[protectionLevelPlatformBased][propNamePlatformBased];
			} catch (e) {
				debug().error(e);
			}
		});
	}

	const output = {
		PROTECTED: deepMerge(coreAppConfig.PROTECTED, platformAppConfig.PROTECTED),
		PUBLIC: deepMerge(coreAppConfig.PUBLIC, platformAppConfig.PUBLIC),
	};

	return output as IAppConfig;
}
