import { getServices } from '@Core/store/Services';
import { useRef, useState } from 'react';
import { IMessage } from '@Core/types/messages';
import {
	TextContainer,
	TextWrapper,
	DropFileArea,
	AttachmentFileInput,
	SendFileButton,
	AddedFile,
	UserAttachmentFileName,
	UserAttachmentDeleteIcon,
	AddedFilesWrapper,
	UserAttachmentFileIcon,
	UserAttachmentText,
	AttachmentLabel,
	AddAttachmentIcon,
	UserAttachmentLabelName,
	UserAttachmentFileIconWrapper,
} from './UserAttachment.styled';
import userAttachmentIcon from '@Core/assets/icons/userAttachmentIcon.svg';
import deleteAttachmentIcon from '@Core/assets/icons/deleteAttachmentIcon.svg';
import addAttachmentIcon from '@Core/assets/icons/addAttachmentIcon.svg';
import debug from '@Core/utils/debug';
import { getAppStore } from '@Core/store/App';
import useDictionary from '@Core/utils/language/useDictionary';
import { FileTypes } from '@Core/utils/consts';
import { Message } from '@Core/utils/models/messages';
import { OWNER } from '@Core/types/messages';
import { bytesToReadable } from '@Core/utils/converters/bytesToReadable';
interface IUserAttachment {
	message: IMessage;
}
export default function UserAttachment({ message }: IUserAttachment) {
	const { ControlService, MessageService, AttachmentService } = getServices();
	const config = getAppStore().config;
	const controlService = new ControlService();
	const messageService = new MessageService();
	const attachmentService = new AttachmentService();

	const { translate } = useDictionary();
	const { fileTypes } = attachmentService;
	const { maxFileSize = 2 * 1024 * 1024 } = config.PUBLIC.userAttachment;
	const { content, owner } = message;
	const { text, filesCount, buttonText, labelText } = content;

	const inputRef = useRef(null);
	const [fileList, setFileList] = useState<File[]>([]);
	const filesCountInt = parseInt(filesCount);
	const [isHovered, setIsHovered] = useState(false);
	const [isDeleteIconActive, setIsDeleteIconActive] = useState(true);
	const [isSendFileButtonVisible, setIsSendFileButtonVisible] = useState(true);
	const [isSendFileButtonActive, setIsSendFileButtonActive] = useState(true);

	const isFileAdded = (newFile) => {
		return fileList.some((file) => file.name === newFile.name);
	};

	const onFileDrop = (e) => {
		const newFile = e.target.files[0];
		if (newFile && !isFileAdded(newFile)) {
			setFileList((fileList) => {
				return [...fileList, newFile];
			});
			setTimeout(() => {
				controlService.scrollToBottom();
			}, 100);
		}
	};
	const fileRemove = (item) => {
		setFileList((fileList) => {
			return [...fileList.filter((file) => file !== item)];
		});
		inputRef.current.value = '';
		setIsSendFileButtonActive(true);
	};

	const isFilesTypeCorrect = (fileList) => {
		const acceptedFileTypes = Object.values(fileTypes);
		return fileList.every((file) => acceptedFileTypes.includes(file.type as FileTypes));
	};

	const getFilesSize = (fileList) => {
		return fileList.reduce((filesSize, file) => filesSize + file.size, 0);
	};

	const onSendFileButtonClick = () => {
		try {
			if (fileList.length === filesCountInt) {
				if (!isFilesTypeCorrect(fileList)) {
					attachmentService.onIncorrectFileType();
				} else if (getFilesSize(fileList) > maxFileSize) {
					messageService.addMessage(
						new Message({
							type: 'text',
							owner: OWNER.bot,
							content: translate('upload.error.file_to_big') + bytesToReadable(maxFileSize),
						})
					);
					setIsSendFileButtonActive(false);
				} else {
					setIsSendFileButtonActive(false);
					setIsDeleteIconActive(false);
					attachmentService.sendUserAttachment({ fileList, message }).then((success) => {
						if (success) {
							setIsSendFileButtonVisible(false);
						} else {
							setIsDeleteIconActive(true);
							setIsSendFileButtonActive(true);
						}
					});
				}
			}
		} catch (e) {
			debug().error(e);
		}
	};

	const Files = () => {
		return (
			<AddedFilesWrapper>
				{fileList.map((item) => (
					<AddedFile key={item.name}>
						<UserAttachmentFileIconWrapper>
							<UserAttachmentFileIcon src={userAttachmentIcon} />
						</UserAttachmentFileIconWrapper>
						<UserAttachmentFileName>{item.name}</UserAttachmentFileName>
						<UserAttachmentDeleteIcon
							src={deleteAttachmentIcon}
							isDeleteIconActive={isDeleteIconActive}
							onClick={isDeleteIconActive ? () => fileRemove(item) : null}
						/>
					</AddedFile>
				))}
			</AddedFilesWrapper>
		);
	};

	return (
		<TextContainer owner={owner}>
			<TextWrapper className={owner}>
				<UserAttachmentText>{text}</UserAttachmentText>
				<DropFileArea visible={fileList.length !== filesCountInt} isHovered={isHovered}>
					<AttachmentLabel>
						<AddAttachmentIcon src={addAttachmentIcon} />
						<UserAttachmentLabelName>
							{labelText ? labelText : translate('userAttachment.inputLabel')}
						</UserAttachmentLabelName>
					</AttachmentLabel>
					<AttachmentFileInput
						type="file"
						onChange={onFileDrop}
						onDragEnter={() => setIsHovered(true)}
						onDragLeave={() => setIsHovered(false)}
						onDrop={() => setIsHovered(false)}
						innerRef={inputRef}
					/>
				</DropFileArea>
				<Files />
				{isSendFileButtonVisible ? (
					<SendFileButton
						active={fileList.length === filesCountInt && isSendFileButtonActive}
						onClick={isSendFileButtonActive ? onSendFileButtonClick : null}
					>
						{buttonText ? buttonText : translate('userAttachment.sendButtonText')}
					</SendFileButton>
				) : null}
			</TextWrapper>
		</TextContainer>
	);
}
