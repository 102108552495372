import { Service } from '@Core/utils/services';

export default class CalendarService extends Service {
	types = {
		testReservation: {
			mode: 'all',
			get(message, params) {
				return new Promise((resolve, reject) => resolve);
			},
		},
	};

	requestsThreshold = 500;

	handle = (watsonMessage) => {
		return this.types[watsonMessage.content?.type];
	};

	onSelectDate: (arg: any) => void;

	onSelectTime: (arg: any) => void;

	getNextDates: (args: any) => void;

	getPrevDates: (args: any) => void;
}
