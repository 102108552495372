import { Service } from '@Core/utils/services';

const TOPIC_KEY = 'ab_topic';

export default class TopicService extends Service {
	createTopic = (input: string) => {
		const b64Topic = btoa(input);
		const b64TopicWithoutEquals = b64Topic.replace(/=/g, '');

		return `${TOPIC_KEY}=${b64TopicWithoutEquals}`;
	};

	getTopic = () => {
		const { searchParams } = new URL(window.location.href);
		const topic = searchParams.get(TOPIC_KEY);

		if (!topic) {
			return null;
		}

		try {
			return atob(topic);
		} catch (e) {
			console.error('Error during decoding b64 topic.', e);
			return null;
		}
	};

	clearTopic = () => {
		const url = new URL(window.location.href);
		url.searchParams.delete(TOPIC_KEY);
		// @ts-ignore
		history.replaceState(null, null, url);
	};
}
