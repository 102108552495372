import Option from '@Core/components/Messages/Option/Option';
import Text from '@Core/components/Messages/Text/Text';
import { getServices } from '@Core/store/Services';
import { Message } from '@Core/utils/models/messages';
import { IMessage, OWNER } from '@Types/messages';

export interface ISuggestionMessage {
	message: IMessage;
	isDisabled: boolean;
}

export default ({ message, isDisabled }: ISuggestionMessage) => {
	const { ConnectionService, MessageService } = getServices();
	const connectionService = new ConnectionService();
	const messageService = new MessageService();

	function handleOnClick(value) {
		// TODO
		const { group, delayed } = connectionService.convertResponseMessageByAdapter({
			response: value,
			request: null,
		});
		const userChoiceMessage = new Message({ owner: OWNER.user, type: 'text', content: value.label });
		messageService.addMessage(userChoiceMessage);
		setTimeout(() => {
			messageService.addMessageGroup(group);
			if (delayed.length) {
				connectionService.resolveDelayedMessages(delayed);
			}
		}, 0);
	}

	const textMsg = new Message({
		owner: OWNER.bot,
		content: message.content.suggestionDescription,
		type: 'text',
	});

	return (
		<>
			<Text message={textMsg} />
			<Option message={message} onOptionSelect={handleOnClick} isDisabled={isDisabled} />
		</>
	);
};
