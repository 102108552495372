import { AdapterService } from '@Core/exports/services';

export default class extends AdapterService {
	// singleGeneric - input
	// message - output Message model

	// @Core/utils/adapters/watson/helpers/resolveOptionBasedType.js
	resolveOptionBasedType = ({ singleGeneric, message, optionsArray, getOptionsAsObject }) => {
		const { title } = singleGeneric;
		switch (title) {
			case 'examplecomponent':
				message.content = getOptionsAsObject();
				return message;
			case 'exampleaction':
				message.content = getOptionsAsObject();
				message.isAction = true;
				return message;
			default:
				return false;
		}
	};

	// @Core/components/Messages/Messages.js
	getMessage = ({ message, messagesComponents }) => {
		switch (message.type) {
			case 'examplecomponent':
				messagesComponents.push(<p>exampleComponent</p>);
				return messagesComponents;
			default:
				return false;
		}
	};
}
