import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';

export const VisitingCardButton = styled.button`
	${({ theme }) => ({
		flex: '0 calc(50% - 10px)',
		margin: '2px 5px',
		padding: '5px 15px',
		borderRadius: '15px',
		border: `1px solid ${theme.colors.colorSecondary}`,
		backgroundColor: theme.colors.colorWhite,
		color: theme.colors.colorSecondary,
		boxShadow: `0 0 10px ${theme.colors.boxShadow}`,
		fontSize: '14px',
		fontWeight: 'light',
		transition: 'background-color .3s, color .3s, opacity .3s',
		'&:not(:disabled):hover': {
			color: theme.colors.colorWhite,
			backgroundColor: theme.colors.colorPrimary,
			borderColor: theme.colors.colorPrimary,
		},
		'&:disabled': {
			opacity: '.6',
		},
		'&:not(:disabled)': {
			cursor: 'pointer',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			flex: '0 calc(50% - 30px)',
			margin: '5px 15px',
			fontSize: '18px',
		},
		...theme.elements.messages.VisitingCardButton,
	})};
`;

export const VisitingCardContainer = styled(MessageContainer)`
	${({ theme }) => ({
		...theme.elements.messages.VisitingCardContainer,
	})};
`;

export const VisitingCardWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '25%',
		backgroundColor: theme.colors.veryLightPink,
		display: 'flex',
		flexWrap: 'wrap',
		margin: '0',
		padding: '10px 0',
		width: '100%',
		borderRadius: '0',
		background: 'transparent',
		...theme.elements.messages.VisitingCardWrapper,
	})};
`;
