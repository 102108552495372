import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';

export const TextContainer = styled(MessageContainer)`
	${({ theme }) => ({
		...theme.elements.messages.TextContainer,
	})};
`;
export const TextWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		backgroundColor: theme.colors.veryLightPink,
		a: {
			wordBreak: 'break-word',
		},
		padding: '4px 20px',
		borderRadius: '10px',
		color: theme.colors.colorSecondary,
		background: theme.colors.colorWhite,
		boxShadow: `0 0 18px ${theme.colors.boxShadow}`,
		fontSize: '15px',
		fontWeight: 'light',
		lineHeight: '130%',
		maxWidth: '90%',
		p: {
			textAlign: 'left',
			overflowWrap: 'anywhere',
			margin: '10px 0',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			fontSize: '18px',
		},
		...theme.elements.messages.TextWrapper,
	})};
`;

export const ShowMoreWrapper = styled.div`
	${({ theme }) => ({
		...theme.elements.messages.ShowMoreWrapper,
	})};
`;

export const TextShowMore = styled.p`
	${({ theme }) => ({
		...theme.elements.messages.TextShowMore,
	})};
`;
interface iButtonShowMore {
	showMore: boolean;
}
export const ButtonShowMore = styled.button<iButtonShowMore>`
	${({ theme, showMore }) => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '150px',
		height: '50px',
		borderRadius: '50px',
		backgroundColor: theme.colors.colorWhite,
		color: theme.colors.primary,
		fontSize: '14px',
		transition: ' background-color .3s,color .3s,opacity .3s',
		borderColor: theme.colors.primary,
		borderWidth: '1px',
		border: '1px solid #6C91FB',
		'&::before': {
			transform: showMore ? 'rotate(45deg)' : 'rotate(225deg)',
			content: '""',
			display: 'block',
			width: '12px',
			height: '12px',
			border: `3px solid ${theme.colors.primary}`,
			borderRight: 'none',
			borderBottom: 'none',
			verticalAlign: 'top',
			transition: '0.2s',
			marginRight: ' 10px',
			marginTop: showMore ? '3px' : '-5px',
		},
		...theme.elements.messages.ButtonShowMore,
	})};
`;
