import {
	StandardCarouselButtonLeft,
	StandardCarouselButtonRight,
	StandardCarouselContainer,
	StandardCarouselImage,
	StandardCarouselTitle,
	StandardCarouselValue,
	SwipeItem,
	ClickFix,
} from './StandardCarousel.styled';
import Slider from 'react-slick';
import '@Core/assets/css/slick.css';
import '@Core/assets/css/slick-theme.css';
import { createRef } from 'preact';
import { useState } from 'preact/hooks';
import { getAppStore } from '@Core/store/App';
import { ICarouselConfig } from '@Types/appConfig';

interface IStandardSlide {
	title: string;
	subtitle: string;
	value: string;
	icon: string;
	price: string;
}

interface IStandardCarousel {
	slides: IStandardSlide[];
	sliderConfig: ICarouselConfig;
	onOptionSelect: (slide: IStandardSlide['value']) => void;
}

export default ({ slides, sliderConfig, onOptionSelect }: IStandardCarousel) => {
	let sliderRef = createRef();
	const appConfig = getAppStore().config;
	const { settings } = sliderConfig ? sliderConfig : appConfig.PUBLIC.carousel;
	const [dragging, setDragging] = useState(false);
	const arrowClassName = !settings.arrows && 'hidden';

	const changeEvents = {
		beforeChange: () => {
			setDragging(true);
		},
		afterChange: () => {
			setDragging(false);
		},
	};

	function handleClick(e, option) {
		dragging ? e.preventDefault : onOptionSelect(option);
	}

	if (slides) {
		const slidesComponents = slides.map((singleOption, index) => {
			return (
				<SwipeItem key={index} onClick={(e) => handleClick(e, singleOption.value)}>
					<ClickFix>
						<StandardCarouselImage src={singleOption.icon} />
						<StandardCarouselValue>{singleOption.price}</StandardCarouselValue>
					</ClickFix>
					<StandardCarouselTitle>
						<p>{singleOption.subtitle}</p>
						<p>
							<b>{singleOption.title}</b>
						</p>
					</StandardCarouselTitle>
				</SwipeItem>
			);
		});

		return (
			<StandardCarouselContainer>
				<Slider {...settings} {...changeEvents} ref={sliderRef}>
					{slidesComponents}
				</Slider>
				<StandardCarouselButtonLeft className={arrowClassName} onClick={() => sliderRef.current.slickPrev()}>
					&lt;
				</StandardCarouselButtonLeft>
				<StandardCarouselButtonRight className={arrowClassName} onClick={() => sliderRef.current.slickNext()}>
					&gt;
				</StandardCarouselButtonRight>
			</StandardCarouselContainer>
		);
	}
};
