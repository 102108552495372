import { IStyles } from '@Types/css';
import styled from 'styled-components';

interface IMenuModal extends IStyles {
	open: boolean;
}

export const MenuModal = styled.div`
	${({ theme, open }: IMenuModal) => ({
		width: '100%',
		transform: open ? 'scale(1)' : 'scale(0)',
		transformOrigin: 'bottom left',
		opacity: open ? '1' : '0',
		position: 'absolute',
		right: '0',
		bottom: '80px',
		padding: '37px 18px 18px 18px',
		boxShadow: '0 -5px 10px -5px #cdc8ec',
		backgroundColor: '#fff',
		transition: 'all .2s',
		margin: '38px, 18px',
		display: 'flex',
		flexWrap: 'wrap',
		gap: '8px',
		zIndex: '1000',
		maxHeight: 'calc(100% - 150px)',
		overflowY: 'auto',
		scrollbarWidth: 'thin',
		[`@media (min-width: ${theme.fullscreenParams.baseWidth}px)`]: {
			'&.fullscreen': {
				paddingLeft: `calc(50% - (${theme.fullscreenParams.baseWidth / 2}px - 30px))`,
				paddingRight: `calc(50% - (${theme.fullscreenParams.baseWidth / 2}px - 20px))`,
			},
		},

		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			bottom: '90px',
		},
		...theme.elements.MenuModal,
	})};
`;

export const MenuBoxContainer = styled.div`
	${({ theme }) => ({
		flex: 'calc(50% - 5px)',
		alignItems: 'stretch',
		...theme.elements.MenuBoxContainer,
	})};
`;

export const MenuBox = styled.div`
	${({ theme }) => ({
		boxShadow: '0 0 20px 0 #cdc8ec',
		padding: '15px',
		backgroundColor: '#fff',
		borderRadius: '12px',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: '100%',
		img: {
			width: 'auto',
			height: '25px',
			marginRight: '5px',
		},
		p: {
			color: '#0a1246',
			fontFamily: 'Barlow',
			fontSize: '14px',
			fontWeight: '600',
			textAlign: 'left',
		},
		'&:hover': {
			opacity: '0.7',
		},
		...theme.elements.MenuBox,
	})};
`;
