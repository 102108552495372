import { Service } from '@Core/utils/services';

export default class ModalService extends Service {
	Modals = {};
	getModalComponentByName = (name) => {
		return this.Modals[name];
	};
	showModal = (name) => {
		this.getAppStore().modal.set(name);
	};
	hideModal = () => {
		this.getAppStore().modal.set(null);
	};
}
