import { getAppStore } from '@Core/store/App';
import Option, { IOptionMessage } from '@Core/components/Messages/Option/Option';
import { ISingleOptionParsed } from '@Types/watson';
import { useEffect } from 'preact/hooks';

export default ({ message, onOptionSelect }: IOptionMessage) => {
	const {
		messages: { value: messagesGroups },
		isInputDisabled: { set: setIsInputDisabled },
	} = getAppStore();

	function handleOnClick(value: ISingleOptionParsed['value']) {
		for (const messageGroup of messagesGroups) {
			for (const message of messageGroup.messages) {
				if (message.type === 'onlyoption') {
					message.isMessageWithActionDone = true;
				}
			}
		}

		setIsInputDisabled(false);
		onOptionSelect(value);
	}

	useEffect(() => {
		const lastMessagesGroupMessages = messagesGroups[messagesGroups.length - 1].messages;
		const isOnlyOptionInLastMessageGroup = lastMessagesGroupMessages.some(
			(message) => message.type === 'onlyoption'
		);
		if (isOnlyOptionInLastMessageGroup) {
			setIsInputDisabled(true);
		}
	}, []);

	return <Option message={message} onOptionSelect={handleOnClick} isDisabled={message.isMessageWithActionDone} />;
};
