import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';

export const TextContainer = styled(MessageContainer)`
	${({ theme }) => ({
		...theme.elements.messages.TextContainer,
	})};
`;
export const TextWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		fontSize: '14px',
		...theme.elements.messages.TextWrapper,
	})};
`;

export const WaitingMessageText = styled.p`
	${({ theme }) => ({
		margin: '10px 0',
		...theme.elements.messages.WaitingMessageText,
	})};
`;
