import ActionBot from '@Core/exports/components/ActionBot';
import platformConfig from '@Platform/config/app';
import platformTheme from '@Platform/theme/theme';
import BotMinimized from '@Platform/components/BotMinimized/BotMinimized';
import platformDictionary from '@Platform/dictionary/dictionary';
import platformBehavior from '@Platform/config/behavior';
import platformAppStore from '@Platform/config/appStore';
import * as platformServices from '@Platform/services/services';
import './assets/css/animations.css';
import { useEffect } from 'react';
import contrastYellow from './theme/contrastYellow';
import whiteOnBlack from './theme/whiteOnBlack';
import blackOnWhite from './theme/blackOnWhite';
import yellowOnBlack from './theme/yellowOnBlack';
import nightContrast from './theme/nightContrast';
import { getAppStore } from '@Core/store/App';
const podyplomoweUrl = 'studiapodyplomowe.us.edu.pl';
const katalogUrl = 'katalog.us.edu.pl';
const mainUrl = 'us.edu.pl';

export default () => {
	const bodyClasslist = document.querySelector('body').classList;
	const store = getAppStore();
	const themeExtension = store.themeExtension;
	const divBodyIDClasslist = document.querySelector('#bodyID')?.classList;
	const botWIndow = document.querySelector('.actionBotWindow');
	const katalogDivClassList = document.querySelector('#katalogID')?.classList;

	function clearBigeeClasses() {
		Array.from(document.querySelectorAll(' .actionBotWindow [class*=bigge]')).forEach((el) => {
			const classesToRemove = Array.from(el.classList).filter((c) => c.includes('bigge'));
			classesToRemove.forEach((classToRemove) => el.classList.remove(classToRemove));
		});
	}

	function clearColorClasses() {
		Array.from(document.querySelectorAll(' .actionBotWindow, .actionBotWindow [class*=yellow]')).forEach((el) => {
			const classesToRemove = Array.from(el.classList).filter((c) => c.includes('yellow'));
			classesToRemove.forEach((classToRemove) => el.classList.remove(classToRemove));
		});
		Array.from(document.querySelectorAll(' .actionBotWindow, .actionBotWindow [class*=black]')).forEach((el) => {
			const classesToRemove = Array.from(el.classList).filter((c) => c.includes('black'));
			classesToRemove.forEach((classToRemove) => el.classList.remove(classToRemove));
		});
	}

	function setThemeOnMainPage() {
		if (bodyClasslist.contains('night_contrast_js')) {
			themeExtension.set('nightContrast');
		} else if (bodyClasslist.contains('white_contrast_js')) {
			themeExtension.set('blackOnWhite');
		} else if (bodyClasslist.contains('black_contrast_js')) {
			themeExtension.set('whiteOnBlack');
		} else if (bodyClasslist.contains('yellow_black_contrast_js')) {
			themeExtension.set('contrastYellow');
		} else if (bodyClasslist.contains('black_yellow_contrast_js')) {
			themeExtension.set('yellowOnBlack');
		}
	}

	function setThemeOnPodyplomowePage() {
		if (bodyClasslist.contains('yellow')) {
			themeExtension.set('contrastYellow');
		} else if (bodyClasslist.contains('black')) {
			themeExtension.set('yellowOnBlack');
		} else {
			themeExtension.set('theme');
		}
	}

	function setThemeOnKatalogPage() {
		if (divBodyIDClasslist.contains('kd')) {
			themeExtension.set('theme');
		} else if (divBodyIDClasslist.contains('kn')) {
			themeExtension.set('nightContrast');
		} else if (divBodyIDClasslist.contains('kbc')) {
			themeExtension.set('whiteOnBlack');
		} else if (divBodyIDClasslist.contains('kcb')) {
			themeExtension.set('blackOnWhite');
		} else if (divBodyIDClasslist.contains('kcz')) {
			themeExtension.set('contrastYellow');
		} else if (divBodyIDClasslist.contains('kzc')) {
			themeExtension.set('yellowOnBlack');
		}
	}

	function checkFontSizeClasses() {
		if (katalogDivClassList.contains('fm')) {
			botWIndow.classList.add('fm');
			botWIndow.classList.remove('fp', 'fz');
		} else if (katalogDivClassList.contains('fp')) {
			botWIndow.classList.add('fp');
			botWIndow.classList.remove('fm', 'fz');
		} else if (katalogDivClassList.contains('fz')) {
			botWIndow.classList.add('fz');
			botWIndow.classList.remove('fm', 'fp');
		}
	}

	useEffect(() => {
		const fontUrl =
			'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=PT+Sans+Caption:wght@400;700&family=PT+Sans+Narrow:wght@400;700&family=PT+Sans:wght@400;700&display=swap';
		if (!document.querySelector(`[href="${fontUrl}"]`)) {
			const link = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = fontUrl;
			document.querySelector('head').append(link);
		}

		if (window.location.host.includes(mainUrl)) {
			setTimeout(() => {
				setThemeOnMainPage();
			}, 1000);
		}

		if (window.location.host.includes(podyplomoweUrl)) {
			const buttonBig = document.querySelector('.big_bt');
			const buttonBigger = document.querySelector('.bigger_bt');
			const buttonThemeYellowOnBlack = document.querySelector('.hi_bt');
			const buttonThemeContrastYellow = document.querySelector('.by_bt');
			const buttonThemeDefault = document.querySelector('.back_bt');

			['click', 'touchend'].forEach((event) => {
				[buttonBig, buttonBigger].forEach((el) => {
					el.addEventListener(event, () => {
						setTimeout(() => {
							clearBigeeClasses();
						}, 0);
					});
				});
			});

			['click', 'touchend'].forEach((event) => {
				buttonThemeYellowOnBlack.addEventListener(event, () => {
					setTimeout(() => {
						clearColorClasses();
						themeExtension.set('yellowOnBlack');
					}, 0);
				});
			});

			['click', 'touchend'].forEach((event) => {
				buttonThemeContrastYellow.addEventListener(event, () => {
					setTimeout(() => {
						clearColorClasses();
						themeExtension.set('contrastYellow');
					}, 0);
				});
			});

			['click', 'touchend'].forEach((event) => {
				buttonThemeDefault.addEventListener(event, () => {
					setTimeout(() => {
						clearColorClasses();
						themeExtension.set('theme');
					}, 0);
				});
			});

			setTimeout(() => {
				clearColorClasses();
				setThemeOnPodyplomowePage();
			}, 1000);
		}

		if (window.location.host.includes(katalogUrl)) {
			const buttonNeutral = document.querySelector('.fm-ikona');
			const buttonBig = document.querySelector('.fz-ikona');
			const buttonBigger = document.querySelector('.fp-ikona');
			const contrastContener = document.querySelector('.kontrast-kontener');

			[buttonNeutral, buttonBig, buttonBigger].forEach((button) => {
				button.addEventListener('click', () => {
					setTimeout(() => {
						checkFontSizeClasses();
					}, 0);
				});
			});

			checkFontSizeClasses();

			contrastContener.addEventListener('click', () => {
				setTimeout(() => {
					setThemeOnKatalogPage();
				}, 0);
			});
			setThemeOnKatalogPage();
		}
	}, []);

	return (
		<div>
			<ActionBot
				themeExtensions={[contrastYellow, whiteOnBlack, blackOnWhite, yellowOnBlack, nightContrast]}
				theme={platformTheme}
				config={platformConfig}
				services={platformServices}
				dictionary={platformDictionary}
				behavior={platformBehavior}
				appStore={platformAppStore}
				MinimizedComponent={BotMinimized}
			/>
		</div>
	);
};
