import styled from 'styled-components';

export const EmbeddedMapContainer = styled.iframe`
	${({ theme }) => ({
		height: '17.5rem',
		position: 'relative',
		left: '-25px',
		width: 'calc(100% + 45px)',
		marginTop: '20px',
		marginBottom: '20px',
		'&.fullscreen': {
			height: '35rem',
		},
		...theme.elements.messages.EmbeddedMapContainer,
	})};
`;
