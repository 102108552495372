import botIcon from '@Core/assets/icons/electronics.svg';
import Header from '@Core/components/Header/Header';
import Messages from '@Core/components/Messages/Messages';
import MessageInput from '@Core/components/MessageInput/MessageInput';
import { BotWindow, RootContainer } from './ActionBot.styled';
import { getAppStore } from '@Core/store/App';
import { useEffect, useState, useRef } from 'preact/hooks';
import { getServices } from '@Core/store/Services';
import { Message } from '@Core/utils/models/messages';
import '@Core/assets/css/reset.css';
import '@Core/assets/css/animations.css';
import BotMinimized, { IBotMinimized } from '@Core/components/BotMinimized/BotMinimized';
import useUnread from '@Core/utils/hooks/useUnread';
import debug from '@Core/utils/debug';
import { IMessageComponents, OWNER } from '@Types/messages';
import { ChangeEventHandler, FC, KeyboardEventHandler } from 'react';
import { IBehaviorConfig } from '@Types/behaviorConfig';
import PoweredBy from '@Core/components/PoweredBy/PoweredBy';
import ModalRenderer from '../ModalRenderer/ModalRenderer';
import useDictionary from '@Core/utils/language/useDictionary';
import useShortcuts from '@Core/utils/useShortcuts';

interface IActionbot {
	behavior: IBehaviorConfig;
	MinimizedComponent: FC<IBotMinimized>;
	MessagesCustomContainer: FC<any>;
	components: IMessageComponents;
	minimizedComponentProps?: IBotMinimized['minimizedComponentProps'] & {
		[key: string]: any;
	};
}

interface ISend {
	message?: Message;
	messageFromSelect?: boolean;
	messageRequest?: {
		text: string;
	};
}

export default ({
	behavior,
	MinimizedComponent = BotMinimized,
	minimizedComponentProps,
	MessagesCustomContainer,
	components = {},
}: IActionbot) => {
	const store = getAppStore();
	const messages = store.messages.value;
	const { config } = store;

	const {
		MessageService,
		ConnectionService,
		HistoryService,
		RedirectService,
		BehaviorService,
		AnonymizeService,
		ThemeService,
	} = getServices();

	const messageService = new MessageService();
	const connectionService = new ConnectionService();
	const historyService = new HistoryService();
	const redirectService = new RedirectService();
	const anonymizeService = new AnonymizeService();
	const themeService = new ThemeService();

	const minimized = store.minimized.value;
	const setMinimized = store.minimized.set;

	const {
		input: { value: inputText, set: setInputText },
	} = store;

	const [sendMessageThresholdLock, setSendMessageThresholdLock] = useState(false);
	const [scrollHeight, setScrollHeight] = useState(0);

	useEffect(() => {
		const wasRedirected = redirectService.checkRedirectStatus();
		debug().log('wasRedirected', typeof wasRedirected, wasRedirected);
		window.__actionBot = window.__actionBot || ({} as Window['__actionBot']);
		window.__actionBot.minimize = minimize;
		window.__actionBot.maximize = maximize;

		const historyMessages = historyService.loadHistory();
		if (wasRedirected && store.config.PROTECTED.agent.available) {
			try {
				window.__actionBot.getWebSocket().openWS();
			} catch (e) {
				console.error(e);
			}
		}

		if (historyMessages) {
			debug().log('if (historyMessages)', { historyMessages });
			messageService.setMessage(historyMessages);
		} else if (!config.PUBLIC.skipGetSessionOnLoad) {
			debug().log('!config.PUBLIC.skipGetSessionOnLoad');
			connectionService.getWelcomeMessage();
		}
	}, []);

	useEffect(() => {
		const { disablePageScrollOnMobileWhenMaximized, maximizeChatbotTime } = config.PUBLIC;
		if (disablePageScrollOnMobileWhenMaximized) {
			const widowWidth = window.innerWidth;
			const MOBILE = themeService.getMobileBreakpoint();
			if (widowWidth <= MOBILE || minimized) {
				if (minimized) {
					if (document.documentElement.classList.contains('disablePageScroll')) {
						document.documentElement.classList.remove('disablePageScroll');
						window.scrollTo(0, scrollHeight);
					}
				} else {
					setTimeout(() => {
						document.documentElement.classList.add('disablePageScroll');
					}, maximizeChatbotTime);
				}
			}
			setScrollHeight(window.scrollY);
		}
	}, [minimized]);

	useRef(useUnread());

	useEffect(() => {
		messages.forEach((group, groupIndex) => {
			if (groupIndex < messages.length - 1) {
				group.isLastGroup = false;
			}

			group.messages.forEach((message, messageIndex) => {
				if (message.existsWhenLast) {
					if (!(groupIndex === messages.length - 1 && messageIndex === group.messages.length - 1)) {
						messageService.removeMessageById(message.id);
					}
				}
			});
		});

		BehaviorService.dispatchEvent('messagesChanged', messages);
		historyService.saveHistory(messages);
	}, [messages]);

	function maximize() {
		setMinimized(false);
		BehaviorService.dispatchEvent('maximize');
	}

	function minimize() {
		if (getAppStore().minimizeAvailable.value) {
			setMinimized(true);
			BehaviorService.dispatchEvent('minimize');
		}
	}

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => setInputText(e.target.value);

	function clearInput() {
		setInputText('');
		//blur only on mobile
		if (window.innerWidth <= themeService.getMobileBreakpoint()) {
			const activeElement = document.activeElement;
			if (activeElement instanceof HTMLElement) {
				activeElement.blur();
			}
		}
	}

	function handleOptionSelect(text: string) {
		const message = new Message({ type: 'text', content: text, owner: OWNER.user });
		const messageRequest = { text };
		send({ message, messageRequest, messageFromSelect: true });
	}

	function send({ message, messageRequest, messageFromSelect }: ISend = {}) {
		debug().log('send');
		if (message && messageRequest) {
			// send custom msg from args
			if (!messageFromSelect || config.PUBLIC.displayMessage.printOptionSelected) {
				messageService.addMessage(message);
			}

			connectionService.sendMessage(messageRequest).catch(console.error);
		} else if (inputText) {
			// send msg from input
			if (inputText.replace(/\s/g, '').length) {
				const anonymized = anonymizeService.doAnonymizationOnMessage({ text: inputText });

				if (anonymized) {
					const { anonymizedText, values } = anonymized;
					let textToSend = anonymizedText;
					let textToShow = anonymizedText;

					for (const anonymizerName in values) {
						const currentAnonymizer = values[anonymizerName];
						const anonymizerPlaceholder =
							AnonymizeService.getAnonymizedVariableName(anonymizerName).placeholder;

						currentAnonymizer.valueToSend.forEach((item) => {
							const replacer = item.replace(/\$\$/g, '$$$$$');
							textToSend = textToSend.replace(anonymizerPlaceholder, replacer);
						});

						currentAnonymizer.valueToShow.forEach((item) => {
							const replacer = item.replace(/\$\$/g, '$$$$$');
							textToShow = textToShow.replace(anonymizerPlaceholder, replacer);
						});
					}

					messageService.addMessage(new Message({ type: 'text', content: textToShow, owner: OWNER.user }));
					connectionService.sendMessage({ text: textToSend }).catch(console.error);
				} else {
					messageService.addMessage(new Message({ type: 'text', content: inputText, owner: OWNER.user }));
					connectionService.sendMessage({ text: inputText }).catch(console.error);
				}
			}
			clearInput();
		}
	}

	const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			const target = event.target as HTMLInputElement;
			if (target.value.length > 0 && !sendMessageThresholdLock) {
				setSendMessageThresholdLock(true);
				send();
			}
		}
	};

	const botWindowRef = useRef() as any;
	useShortcuts({ botWindowRef });

	useEffect(() => {
		let timeout: NodeJS.Timeout | null = null;
		const treshold = store.sendMessageThreshold.value;
		if (sendMessageThresholdLock) {
			timeout = setTimeout(() => {
				setSendMessageThresholdLock(false);
			}, treshold);
		}
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [sendMessageThresholdLock]);

	const minimizedClass = minimized ? 'minimized' : 'maximized';

	const MessageInputComponent = components.MessageInput || MessageInput;

	const isFullScreen = store.fullScreen.value;
	const { align } = store.position.value;

	const { translate } = useDictionary();
	return (
		<RootContainer className="ActionBot">
			<MinimizedComponent
				className={minimizedClass}
				minimized={minimized}
				botIcon={botIcon}
				maximize={maximize}
				onOptionSelect={handleOptionSelect}
				minimizedComponentProps={minimizedComponentProps}
			/>
			<BotWindow
				tabIndex={0}
				align={align}
				ref={botWindowRef}
				aria-keyshortcuts="alt+c"
				aria-hidden={minimized}
				aria-label={translate('aria.root_container')}
				role="application"
				className={`${store.rtl.value ? 'rtl ' + minimizedClass : minimizedClass} ${
					isFullScreen ? 'fullscreen' : ''
				}`}
			>
				{!minimized && (
					<>
						<Header minimized={minimized} minimize={minimize} />
						<Messages
							onOptionSelect={handleOptionSelect}
							MessagesCustomContainer={MessagesCustomContainer}
							components={components}
						/>
						<MessageInputComponent
							value={inputText}
							onKeyPress={handleKeyPress}
							onSubmit={send}
							onChange={handleChange}
							onOptionSelect={handleOptionSelect}
						/>
						<PoweredBy />
					</>
				)}
			</BotWindow>
			<ModalRenderer />
		</RootContainer>
	);
};
