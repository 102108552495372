import styled from 'styled-components';
import abLogo from '@Core/assets/icons/actionbot_logo.svg';
import { IStyles } from '@Types/css';

interface IAvatar extends IStyles {
	avatarUrl: string;
}

export const Avatar = styled.div<IAvatar>`
	${({ theme, avatarUrl }) => ({
		borderRadius: '100%',
		backgroundImage: `url(${avatarUrl})`,
		position: 'absolute',
		zIndex: '100',
		top: '0',
		left: '8px',
		width: '45px',
		height: '45px',
		minWidth: '40px',
		minHeight: '40px',
		margin: '0',
		backgroundColor: theme.colors.colorAvatarBg,
		backgroundPosition: '9px center',
		backgroundSize: '25px 25px',
		backgroundRepeat: 'no-repeat',
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			width: '45px',
			height: '45px',
			backgroundSize: '25px 25px',
			backgroundPosition: '9px center',
		},
		'&:not(.agent)': {
			backgroundImage: `url(${abLogo})`,
		},
		'&.agent': {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
		},
		'&.user': {
			right: '10px',
			left: 'auto',
			backgroundColor: theme.colors.colorAvatarBg,
			backgroundImage: 'none',
			textAlign: 'center',
			'&:after': {
				content: '"U"',
				left: 'auto',
				backgroundImage: 'none',
				textAlign: 'center',
				fontWeight: 'bold',
				fontSize: '25px',
				lineHeight: '45px',
				color: theme.colors.colorPrimary,
			},
		},
		...theme.elements.messages.MessagesGroupAvatar,
	})};
`;
