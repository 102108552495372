import { useState } from 'preact/hooks';
import {
	Form,
	FormButton,
	FormContainer,
	FormFields,
	FormHeader,
	FormInput,
	FormLabel,
	FormWrapper,
} from './Form.styled';
import { ApiContext } from '@Core/utils/models/watson';
import { getServices } from '@Core/store/Services';
import { IMessage } from '@Types/messages';

export interface IFormMessage {
	message: IMessage;
}

export default ({ message }: IFormMessage) => {
	const MessageService = getServices('MessageService');
	const messageService = new MessageService();

	const { id, description, button, fields } = message.content;

	interface ISum {
		[name: string]: any;
	}
	interface ICurrent {
		name: string;
		defaultValue: any;
	}

	const defaultInputValues = fields.reduce((sum: ISum, current: ICurrent) => {
		return {
			...sum,
			[current.name]: current.defaultValue,
		};
	}, {});

	const [inputValues, setInputValues] = useState(defaultInputValues);

	const isDisabled = message.isSent;

	function setInput({ name, event }) {
		const value = event.target.value;
		setInputValues((inputValues) => ({
			...inputValues,
			[name]: value,
		}));
	}

	function onSubmit() {
		const { ConnectionService } = getServices();
		const connectionService = new ConnectionService();

		messageService.editMessageById(message.id, (message) => {
			message.isSent = true;
			return message;
		});

		const context = new ApiContext({
			forms: {
				[id]: inputValues,
			},
		});

		connectionService.sendMessage({ text: '', context }).catch(console.error);
	}

	return (
		<FormContainer owner={message.owner}>
			<FormWrapper>
				{description && <FormHeader>{description}</FormHeader>}
				<Form id={id}>
					{fields.map((singleField) => {
						const { name, displayName } = singleField;
						return (
							<FormFields key={name}>
								<FormLabel>{displayName}</FormLabel>
								<FormInput
									disabled={isDisabled}
									onChange={(event) => setInput({ name, event })}
									id={name}
									value={inputValues[name]}
								/>
							</FormFields>
						);
					})}
				</Form>
				<FormButton onClick={onSubmit} disabled={isDisabled}>
					{button}
				</FormButton>
			</FormWrapper>
		</FormContainer>
	);
};
