import { useState, useEffect } from 'preact/hooks';
import { BotMinimizedContainer, BotTalkImage, BotChatIcon } from './BotMinimized.styled';
import { getAppStore } from '@Core/exports/store/App';
import { getServices } from '@Core/exports/store/Services';
import BotIcon from '@Platform/assets/icons/avatar_white.svg';
import InitialPopup from '@Platform/components/InitialPopup/InitialPopup';
import AnimationService from '@Platform/services/AnimationService';
import useAriaButton from '@Core/utils/aria/useAriaButton';
import useDictionary from '@Core/utils/language/useDictionary';
import { IAppStorePlatform } from '@Platform/types';
import { OWNER } from '@Core/types/messages';

export default ({ className, minimized, maximize, botIcon }) => {
	const [active, setActive] = useState(true);
	const [hover, setHover] = useState(false);

	const { ConnectionService, MessageService } = getServices();
	const store = getAppStore() as IAppStorePlatform;
	const connectionService = new ConnectionService();
	const animationService = new AnimationService();
	const messageService = new MessageService();

	useEffect(() => {
		const newActive = !minimized;
		if (active !== newActive) {
			setActive(newActive);
		}

		if (getAppStore().initialPopup.value && !minimized) {
			getAppStore().initialPopup.set(undefined);
		}
	}, [minimized]);

	useEffect(() => {
		hover ? animationService.startBotTalkImageShaking() : animationService.stopBotTalkImageShaking();
	}, [hover]);

	function clearMessages() {
		store.messages.set((messages) => {
			messages.length = 0;
			return messages;
		});
	}

	function handleClick() {
		if (minimized) {
			if (store.lastUsedRole.value !== store.currentRole.value) {
				clearMessages();
				connectionService.sendEvent('roleChanged', { data: store.currentRole.value }, true);
			}
			store.lastUsedRole.set(store.currentRole.value);

			maximize();
			store.initialPopupSeen.set(true);
		} else {
			try {
				window.__actionBot.minimize();
			} catch (e) {
				console.error(e);
			}
		}
	}

	const { translate } = useDictionary();

	const ariaButtonProps = useAriaButton({
		onClick: handleClick,
		name: translate(minimized ? 'aria.button.bot_minimized_minimized' : 'aria.button.bot_minimized_maximized'),
	});

	useEffect(() => {
		const messagesGroups = messageService.messages;
		const lastGroup = messagesGroups[messagesGroups.length - 1];

		if (messagesGroups.length && lastGroup.owner === OWNER.bot) {
			const lastElement = document.querySelector(
				'[class*="MessagesGroupstyled__MessagesGroup"]:last-child'
			) as HTMLElement;
			if (lastElement) {
				setTimeout(() => {
					lastElement.focus();
				}, 2000);
			}
		}
	}, [messageService.messages]);

	return (
		<>
			<BotMinimizedContainer
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				active={active && !minimized}
				className={className}
				{...ariaButtonProps}
			>
				<BotTalkImage id="bot-talk-image">
					<BotChatIcon src={BotIcon} className="bot-chat-icon" />
				</BotTalkImage>
			</BotMinimizedContainer>
			{
				<div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
					<InitialPopup maximize={maximize} />
				</div>
			}
		</>
	);
};
