import { Message } from '@Core/utils/models/messages';
import mergeFormData from './merge/mergeFormData';
import { getServices } from '@Core/store/Services';
import { getAppStore } from '@Core/store/App';
import debug from '@Core/utils/debug';
import { OWNER } from '@Types/messages';
import { IGeneric, WatsonResponseType } from '@Types/watson';
import { WatsonCommands } from '@Core/services/ActionsService';

interface IResolveOptionBasedType {
	singleGeneric: IGeneric;
	request?: any; //TODO
}

export default function ({ singleGeneric, request }: IResolveOptionBasedType) {
	const AdapterService = getServices('AdapterService');
	const adapterService = new AdapterService();
	const title = singleGeneric.title?.toLowerCase() as WatsonCommands;
	const description = singleGeneric.description;
	const recipient = getAppStore().recipient.value;

	const message = new Message({ owner: recipient, type: title });

	const optionsArray = (() => {
		// = [{ label, value }]
		try {
			return singleGeneric.options.map((singleOption) => {
				const option = {
					label: singleOption.label,
					value: undefined,
				};
				if (singleOption?.value?.input?.text) {
					option.value = singleOption.value.input.text;
				}
				return option;
			});
		} catch (e) {
			return [];
		}
	})();

	function getOptionsAsObject() {
		// = {label: value}
		try {
			return optionsArray.reduce((sum, current) => {
				sum[current.label] = current.value;
				return sum;
			}, {});
		} catch (e) {
			return {};
		}
	}

	switch (title) {
		case 'setforminput':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'redirect':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'async':
			message.isAction = true;
			message.content = getOptionsAsObject();
			message.content.request = request;
			break;
		case 'scrollto':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'simulateevent':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'mouseeventelement':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'mouseeventelementiframe':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'textdirection':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'handovertoagent':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'carousel':
			message.content = {
				options: optionsArray,
			};
			break;
		case 'visitingcard':
			message.content = getOptionsAsObject();
			break;
		case 'permanentoption':
		case 'permamentoption':
			message.type = 'permanentoption';
			message.content = {
				description,
				options: optionsArray,
			};
			break;

		case 'imagegallery':
			message.type = 'imagegallery';
			message.content = {
				description,
				options: optionsArray,
			};
			break;
		case 'button':
			message.content = getOptionsAsObject();
			break;
		case 'onlyoption':
		case 'optiononly':
			message.type = 'onlyoption';
			message.isMessageWithAction = true;
			message.isMessageWithActionDone = false;
			message.content = {
				description,
				options: optionsArray,
			};
			break;
		case 'form':
			message.content = {
				description,
				...getOptionsAsObject(),
			};
			break;
		case 'formfields':
			message.content = {
				fields: getOptionsAsObject(),
			};
			break;
		case 'formdefaults':
			message.content = {
				defaults: getOptionsAsObject(),
			};
			break;
		case 'formmerge':
			message.content = mergeFormData(singleGeneric.merge);
			break;
		case 'setbotinfo':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'youtube':
			message.content = getOptionsAsObject();
			break;
		case 'gif':
			message.content = getOptionsAsObject();
			break;
		case 'redirectwithmemory':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'embeddedmap':
			message.content = getOptionsAsObject();
			break;
		case 'addcss':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'changeelementprop':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'minimizeclient':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'agentmessage':
			message.owner = OWNER.agent;
			message.content = {
				options: optionsArray,
			};
			message.isAction = true;
			break;
		case 'systemmessage':
			message.owner = OWNER.system;
			message.content = {
				options: optionsArray,
			};
			message.isAction = true;
			break;
		case 'survey':
			message.content = getOptionsAsObject();
			message.description = description;
			break;
		case 'attachment':
			message.content = getOptionsAsObject();
			break;
		case 'dispatchevent':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'setlanguage':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'validateform':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'menu':
			message.type = 'menu';
			message.content = {
				description,
				options: optionsArray,
			};
			break;
		case 'menuopen':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'mainmenu':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'fullscreen':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;
		case 'icongrid':
			message.type = 'icongrid';
			message.content = {
				description,
				options: optionsArray,
			};
			break;
		case 'remoteconfig':
			message.isAction = true;
			message.content = optionsArray;
			break;
		case 'triggerpopup':
			message.isAction = true;
			message.content = optionsArray;
			break;
		case 'calendar':
			message.content = getOptionsAsObject();
			break;
		case 'closeinitialpopup':
			message.isAction = true;
			break;
		case 'textwithbuttons':
			message.content = getOptionsAsObject();
			break;
		case 'readmore':
			message.content = getOptionsAsObject();
			break;
		case 'messageattachment':
			message.content = getOptionsAsObject();
			break;
		case 'userattachment':
			message.content = getOptionsAsObject();
			break;
		case 'clearchat':
			message.isAction = true;
			message.content = getOptionsAsObject();
			break;

		default:
			try {
				const platformBased = adapterService.resolveOptionBasedType({
					singleGeneric,
					message,
					optionsArray,
					getOptionsAsObject,
				});
				if (!platformBased) {
					// standard option
					message.content = {
						title,
						description,
						options: optionsArray,
					};
					message.type = 'option';
				}
			} catch (e) {
				debug().error(e);
			}
	}

	return message;
}
