import isMobileOrTablet from '@Core/utils/mobileAndTabletDetection';
import { ITheme, IThemeBreakpoints, IThemeColors, IThemeFonts, IThemeFullscreenParams } from '@Types/themes';

const isMobileDevice = isMobileOrTablet(window);

const colors: IThemeColors = {
	colorWhite: '#fff',
	colorBlack: '#333',
	colorPrimary: '#1061f7',
	colorSecondary: '#f20a8b',
	colorDarkBlue: '#00154e',
	colorLightBlue: '#6C91FB',
	colorGray: '#ddd',
	colorAvatarBg: '#f0f0f0',
	mainShadow: 'rgba(16,97,247,0.5)',
	boxShadow: '#cccccc',
};

const font: IThemeFonts = {
	primaryFont: 'Barlow',
};

const breakpoints: IThemeBreakpoints = {
	tablet: '1000px',
	mobile: '576px',
};

const fullscreenParams: IThemeFullscreenParams = {
	baseWidth: 1000,
};

export const coreBasicTheme = {
	isMobileDevice,
	breakpoints,
	fullscreenParams,
	colors: {
		brownGrayTwo: '#9b9b9b',
		veryLightPink: '#dddddd',
		white: '#ffffff',
		whiteGradient: '#eeeeee',
		...colors,
	},
	fonts: {
		primary: 'Arial',
		...font,
	},
	elements: {
		BotWindow: {},
		BotWindowAlignLeft: {},
		BotWindowAlignRight: {},
		BotIcon: {},
		BotMinimizedContainer: {},
		UnreadCounter: {},
		NewMinimizedMessages: {},
		NewMinimizedMessage: {},
		BusyIndicatorElement: {},
		BusyIndicatorContainer: {},
		BusyIndicatorText: {},
		ActionIcon: {},
		HeaderTitle: {},
		HeaderContainer: {},
		HeaderIconContainer: {},
		InitialPopupContainer: {},
		InitialPopupContainerAlignLeft: {},
		InitialPopupContainerAlignRight: {},
		InitialPopupContainerIsDragging: {},
		InitialPopupContainerIsNotDragging: {},
		InitialPopupContainerTopStyles: {},
		InitialPopupContainerTopAndLeftStyles: {},
		InitialPopupSlideContainer: {},
		InitialPopupSlide: {},
		PopupCarouselButton: {},
		PopupCarouselButtonLeft: {},
		PopupCarouselButtonRight: {},
		InitialPopupClose: {},
		InitialPopupResize: {},
		InitialPopupHeader: {},
		InitialPopupButton: {},
		InitialPopupImage: {},
		MenuContainer: {},
		MenuIcon: {},
		MenuModal: {},
		MenuBoxContainer: {},
		MenuBox: {},
		MessageInput: {},
		InputContainer: {},
		AddAttachment: {},
		AddAttachmentInput: {},
		messages: {
			AttachmentContainer: {},
			AttachmentWrapper: {},
			AttachmentImageWrapper: {},
			AttachmentImage: {},
			AttachmentImageFile: {},
			AttachmentFileIcon: {},
			AttachmentUrlWrapperComponentA: {},
			AttachmentFileInfo: {},
			AttachmentFileInfoName: {},
			AttachmentFileInfoSize: {},
			AttachmentSending: {},
			AttachmentSendingAbort: {},
			Button: {},
			ButtonTitle: {},
			ButtonContainer: {},
			ModernSwipeItem: {},
			ModernCarouselContainer: {},
			ModernCarouselButton: {},
			ModernCarouselButtonLeft: {},
			ModernCarouselButtonRight: {},
			ModernCarouselImage: {},
			ModernCarouselValue: {},
			ClickFix: {},
			ModernCarouselTitle: {},
			PostcardSwipeItem: {},
			PostcardCarouselContainer: {},
			PostcardCarouselButton: {},
			PostcardCarouselButtonLeft: {},
			PostcardCarouselButtonRight: {},
			PostcardCarouselImage: {},
			PostcardCarouselValue: {},
			ClickField: {},
			PostcardCarouselTitle: {},
			ShopSwipeItem: {},
			ShopCarouselContainer: {},
			ShopCarouselButton: {},
			ShopCarouselButtonLeft: {},
			ShopCarouselButtonRight: {},
			ShopCarouselImage: {},
			ShopCarouselValue: {},
			ShopCarouselTitle: {},
			SwipeItem: {},
			CarouselContainer: {},
			CarouselButton: {},
			StandardCarouselButtonLeft: {},
			StandardCarouselButtonRight: {},
			StandardCarouselImage: {},
			StandardCarouselValue: {},
			StandardCarouselTitle: {},
			WithButtonsCarouselSwipeItem: {},
			WithButtonsCarouselContainer: {},
			WithButtonsCarouselStylePrimary: {},
			WithButtonsCarouselStyleSecondary: {},
			WithButtonsCarouselCarouselButton: {},
			WithButtonsCarouselLeft: {},
			WithButtonsCarouselRight: {},
			WithButtonsCarouselImage: {},
			WithButtonsCarouselImageContainer: {},
			WithButtonsCarouselValue: {},
			WithButtonsCarouselText: {},
			WithButtonsCarouselTable: {},
			WithButtonsCarouselButtonContainer: {},
			WithButtonsCarouselButton: {},
			EmbeddedMapContainer: {},
			FormContainer: {},
			FormWrapper: {},
			FormHeader: {},
			FormButton: {},
			FormLabel: {},
			FormInput: {},
			FormFields: {},
			Form: {},
			GifImage: {},
			IconGrid: {
				IconGridContainer: {},
				GridLabel: {},
				GridContainer: {},
				GridItem: {},
			},
			ImageFullScreen: {},
			ImageContainer: {},
			ImageWrapper: {},
			ImageTitle: {},
			ImageDescription: {},
			Image: {},
			MessageMenu: {
				MenuContainer: {},
				MenuBoxContainer: {},
				MenuBox: {},
			},
			Messages: {},
			MessageContainer: {},
			MessageWrapper: {},
			MessagesGroupAvatar: {},
			MessagesGroup: {},
			MessagesWithAvatar: {},
			MessagesGroupMessages: {},
			MessagesGroupDate: {},
			OptionButton: {},
			OptionButtonSelected: {},
			OptionButtonDisabledSelected: {},
			OptionButtonDisabledNotSelected: {},
			OptionTitle: {},
			OptionContainer: {},
			OptionWrapper: {},
			Survey: {},
			SurveyDescription: {},
			SurveyButton: {},
			TextContainer: {},
			TextWrapper: {},
			UserIndicator: {},
			ThinkingContainer: {},
			ThinkingMessage: {},
			VisitingCardButton: {},
			VisitingCardContainer: {},
			VisitingCardWrapper: {},
			YouTubeIframe: {},
			CalendarContainer: {},
			CalendarSelectionDatesContainer: {},
			CalendarNavigationDates: {},
			CalendarNextDates: {},
			CalendarPrevDates: {},
			CalendarDateContainer: {},
			CalendarHoursContainer: {},
			CalendarHoursItem: {},
			CalendarDateItem: {},
			CalendarDateItemSelected: {},
			CalendarHoursItemInactive: {},
			CalendarHoursItemSelected: {},
			CalendarContainerDisabled: {},
			TextWithButtonsTextWrapper: {},
			TextWithButtonsTextButton: {},
			TextWithButtonsTextButtonList: {},
			TextWithButtonsButtonWrapper: {},
			TextWithButtonsUserIndicator: {},
			TextWithButtonsButtonContent: {},
			TextButtonListIsButtonActiveHover: {},
			TextButtonListButtonInactive: {},
			TextButtonListIsAnyButtonActive: {},
			TextButtonListIsAnyButtonInactive: {},
			TextButtonIsButtonActiveHover: {},
			TextButtonButtonInactive: {},
			TextButtonIsAnyButtonActive: {},
			TextButtonIsAnyButtonInactive: {},
			ShowMoreWrapper: {},
			TextShowMore: {},
			ButtonShowMore: {},
			DropFileArea: {},
			AttachmentFileInput: {},
			SendFileButton: {},
			AddedFile: {},
			UserAttachmentFileName: {},
			UserAttachmentDeleteIcon: {},
			UserAttachmentDeleteIconActiveStyle: {},
			UserAttachmentDeleteIconInactiveStyle: {},
			AddedFilesWrapper: {},
			UserAttachmentFileIcon: {},
			UserAttachmentText: {},
			AttachmentLabel: {},
			AddAttachmentIcon: {},
			UserAttachmentLabelName: {},
			UserAttachmentFileIconWrapper: {},
			MessageAttachmentFileContainer: {},
			MessageAttachmentFileIcon: {},
			MessageAttachmentFileName: {},
			MessageAttachmentFileDetails: {},
			MessageAttachmentDateDetails: {},
			MessageAttachmentsListWrapper: {},
			MessageAttachmentText: {},
			MessageAttachmentDate: {},
			MessageAttachmentTime: {},
			WaitingMessageText: {},
		},
		SubmitButtonRtl: {},
		SubmitButton: {},
		SubmitButtonIcon: {},
		PoweredBy: {},
		ModalBackground: {},
		ModalWrapper: {},
		Content: {},
		CloseModal: {},
		BotChatIcon: {},
		DraggableBox: {},
		CaptchaContainer: {},
		CaptchaReloadButton: {},
		CaptchaWrapper: {},
		CaptchaInputCotainer: {},
		CaptchaInput: {},
		CaptchaSubmit: {},
		CaptchaError: {},
		CaptchaDescription: {},
	},
};

export default coreBasicTheme as ITheme;
