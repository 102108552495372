import styled from 'styled-components';

import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';
import { IStyles } from '@Types/css';

interface IImageContainer extends IStyles {
	fullScreen?: boolean;
}

export const ImageContainer = styled<IImageContainer>(MessageContainer)`
	${({ theme, fullScreen }) => {
		const fullScreenStyles = fullScreen
			? {
					position: 'fixed',
					left: 0,
					top: 0,
					width: '100vw',
					height: '100vh',
					justifyContent: 'center',
					backgroundColor: 'black',
					overflow: 'auto',
					zIndex: 999999999999,
					borderRadius: 0,
				}
			: {};

		return {
			display: 'grid',
			gridTemplateColumns: '1fr 1fr',
			gridColumnGap: '15px',
			marginTop: '0',
			...fullScreenStyles,
			...theme.elements.messages.ImageContainer,
			...theme.elements.messages.ImageFullScreen,
		};
	}};
`;

export const ImageWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		backgroundColor: theme.colors.veryLightPink,
		padding: '0',
		width: '100%',
		maxWidth: 'initial',
		borderRadius: '2px',
		overflow: 'hidden',
		cursor: 'pointer',
		background: 'transparent',
		...theme.elements.messages.ImageWrapper,
		img: {
			width: '100%',
		},
	})};
`;

export const Image = styled.img`
	${({ theme }) => ({
		width: '100%',
		maxWidth: 'initial',
		maxHeight: 'initial',
		...theme.elements.messages.Image,
	})};
`;
