import styled from 'styled-components';

export const AddAttachment = styled.div`
	${({ theme }) => ({
		'&:after': {
			content: "'+'",
			color: theme.colors.brownGrayTwo,
			fontSize: '30px',
		},
		borderTop: 0,
		padding: '20px 15px',
		lineHeight: 0,
		cursor: 'pointer',
		border: 'unset',
		...theme.elements.AddAttachment,
	})};
`;

export const AddAttachmentInput = styled.input`
	${({ theme }) => ({
		display: 'none',
		...theme.elements.AddAttachmentInput,
	})};
`;
