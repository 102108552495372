import { Service } from '@Core/utils/services';
import { ApiContext } from '@Core/utils/models/watson';
import { getAppStore } from '@Core/store/App';
import { IBotEvent } from '@Types/events';

export default class RequestTemplateService extends Service {
	welcomeMessageByLanguage() {
		const config = getAppStore().config;
		const welcomeMessage = config.PUBLIC.welcomeMessage;

		const context = new ApiContext(welcomeMessage.context);

		return {
			text: welcomeMessage.text || '',
			context,
		};
	}

	submitContextMessage(event) {
		const context = new ApiContext({
			eventData: {
				eventName: event.type,
				formId: event.target.id,
				inputName: document.activeElement.getAttribute('value'),
				value: event.target.value,
			},
		});
		return {
			text: '$$formEvent$$',
			context,
		};
	}

	loadContextMessage(event: IBotEvent) {
		const context = new ApiContext({
			eventData: {
				eventName: event.type,
				url: event.target.URL,
				oldUrl: document.referrer,
			},
		});
		return {
			text: '$$windowEvent$$',
			context,
		};
	}

	focusContextMessage(event: IBotEvent) {
		const context = new ApiContext({
			eventData: {
				eventName: event.type,
				formId: event.target.form.id,
				inputName: event.target.name ? event.target.name : event.target.id,
				value: event.target.value,
			},
		});

		return {
			text: '$$formEvent$$',
			context,
		};
	}

	pageSpendTimeContextMessage() {
		const context = new ApiContext({
			eventData: {
				pageSpendTime: true,
			},
		});

		return {
			text: '',
			context,
		};
	}
}
