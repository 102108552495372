import { getAppStore } from '@Core/store/App';
import { Service } from '@Core/utils/services';
import { ReactNode } from 'react';

// https://github.com/remarkjs/react-markdown#use-custom-renderers-syntax-highlight

export interface IMarkdownLinkRenderer {
	href?: string;
	children: ReactNode;
}

export default class MarkdownService extends Service {
	getRenderers = () => ({
		p: ({ children }) => {
			return <p>{children}</p>;
		},
		a: ({ href, children }: IMarkdownLinkRenderer) => {
			const RedirectService = this.getServices('RedirectService');
			const redirectService = new RedirectService();
			const {
				config: {
					PUBLIC: {
						links: { openPdfInNewTab, newTabWhenRegexpNotMatch },
					},
				},
			} = getAppStore();
			const _openPdfInNewTab = openPdfInNewTab && href?.match(/(.pdf)$/g);
			const _newTabWhenRegexpNotMatch =
				newTabWhenRegexpNotMatch && !href.match(new RegExp(newTabWhenRegexpNotMatch, 'g'));
			return (
				<a
					href={href}
					onClick={(e) => {
						if (href && !href.match(/^((mailto|callto|tel|skype|sms|fax|):)/g)) {
							e.preventDefault();
							setTimeout(() => {
								redirectService._saveStore();
								if (_openPdfInNewTab || _newTabWhenRegexpNotMatch) {
									window.open(href, '_blank');
								} else {
									redirectService.redirectTo(href);
								}
							}, 0);
						}
					}}
				>
					{children}
				</a>
			);
		},
	});
}
