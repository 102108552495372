import { SubmitButton, SubmitButtonIcon } from './SubmitButton.styled';
import { getAppStore } from '@Core/store/App';
import { MouseEventHandler } from 'react';
import useAriaButton from '@Core/utils/aria/useAriaButton';
import useDictionary from '@Core/utils/language/useDictionary';
import useAriaImg from '@Core/utils/aria/useAriaImg';

interface ISubmitButton {
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default ({ onClick }: ISubmitButton) => {
	const store = getAppStore();
	const { translate } = useDictionary();

	const isDisabled = store.isInputDisabled.value || !store.input.value;
	const submitButtonIcon = store.config.PUBLIC.submitButton.icon;

	const submitButtonIconProps = useAriaImg({
		alt: translate('aria.img.submit_button'),
	});

	const submitButtonProps = useAriaButton({
		onClick,
		name: translate('aria.button.submit_button'),
		disabled: isDisabled,
	});

	return (
		<SubmitButton {...submitButtonProps}>
			<SubmitButtonIcon {...submitButtonIconProps} src={submitButtonIcon} />
		</SubmitButton>
	);
};
