import getBrowserLanguage from '@Core/utils/language/getBrowserLanguage';

export const isRtl = (lang?: string) => {
	const rltLanguage = [
		'ar',
		'ar-AE',
		'ar-BH',
		'ar-DZ',
		'ar-EG',
		'ar-IQ',
		'ar-JO',
		'ar-KW',
		'ar-LB',
		'ar-LY',
		'ar-MA',
		'ar-OM',
		'ar-QA',
		'ar-SA',
		'ar-SY',
		'ar-TN',
		'ar-YE',
		'az',
		'az-AZ',
		'dv',
		'dv-MV',
		'he',
		'he-IL',
		'syr',
		'syr-SY',
		'ur',
		'ur-PK',
	];
	return rltLanguage.includes(lang || getBrowserLanguage());
};
