import { useState } from 'preact/hooks';
import { SurveyButton } from '@Core/components/Messages/Survey/Survey.styled';
import { MouseEventHandler } from 'react';

export interface ISurveyThumb {
	onSelect?: MouseEventHandler<HTMLSpanElement>;
	isSelected?: boolean;
	img: string;
	imgSelected: string;
	valueSet?: boolean;
}

export default ({ onSelect, isSelected, img, imgSelected, valueSet }: ISurveyThumb) => {
	const [isHovered, setIsHovered] = useState(false);

	function handleMouseEnter() {
		if (!valueSet) {
			setIsHovered(true);
		}
	}

	function handleMouseLeave() {
		if (!valueSet) {
			setIsHovered(false);
		}
	}

	return (
		<SurveyButton onMouseOver={handleMouseEnter} onMouseOut={handleMouseLeave} onClick={onSelect}>
			<img src={isSelected || isHovered ? imgSelected : img} />
		</SurveyButton>
	);
};
