import { getAppStore } from '@Core/store/App';
import { Language } from '@Types/languages';
import getLanguage from '@Core/utils/language/getLanguage';
import { getServices } from '@Core/store/Services';

interface IWatsonContextSkills {
	'main skill': {
		user_defined: {
			source?: string;
			lang?: Language;
			[key: string]: any;
		};
	};
}

export class ApiContext {
	skills?: IWatsonContextSkills;
	constructor(userDefined) {
		const { TopicService, ConnectionService } = getServices();
		const topicService = new TopicService();
		const topic = topicService.getTopic();

		const store = getAppStore();
		this.skills = {
			'main skill': {
				user_defined: {
					source: store.config.PROTECTED.connection.watsonSource,
					lang: getLanguage() || 'en',
					url: window.location.href.substr(0, 1024),
					topic,
					...ConnectionService.getExtraContextParams(),
					...userDefined,
				},
			},
		};
	}
}
