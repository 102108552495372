import { IBehaviorConfig } from '@Core/types/behaviorConfig';
import { useRef, useState } from 'preact/hooks';

const useNervousScrolling = (behaviorConfig: IBehaviorConfig) => {
	const [isNervousScrolling, setIsNervousScrolling] = useState(false);
	const scrollCounter = useRef(0);
	const scrollDirection = useRef(0);
	const lastScrollPosition = useRef(0);

	const incrementScrollCounter = () => {
		// Get direction of scroll by comparing current scroll position with previous scroll position
		const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
		const direction = currentScrollPosition > lastScrollPosition.current ? 1 : -1;
		lastScrollPosition.current = currentScrollPosition;

		// If direction of scroll has changed, increment scroll counter
		if (direction !== scrollDirection.current) {
			scrollDirection.current = direction;
			scrollCounter.current = scrollCounter.current + 1;
			if (scrollCounter.current >= (behaviorConfig.nervousScrolling?.directionChanges || 5)) {
				setIsNervousScrolling(true);
			} else {
				setTimeout(() => {
					scrollCounter.current = 0;
				}, behaviorConfig.nervousScrolling?.executingTime || 1000);
			}
		}
	};

	return [isNervousScrolling, incrementScrollCounter] as const;
};

export { useNervousScrolling };
