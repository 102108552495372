import useDictionary from '../language/useDictionary';

interface IUseAriaOptions {
	'aria-label': string;
	role: string;
	[key: string]: any;
}

export default function useAriaOptions({ ...props } = {}): IUseAriaOptions {
	const { translate } = useDictionary();

	return {
		'aria-label': translate('aria.options.list'),
		role: 'listbox',
		...props,
	};
}
