import styled from 'styled-components';

export const CaptchaContainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
		...theme.elements.CaptchaContainer,
	})};
`;

export const CaptchaReloadButton = styled.button`
	${({ theme }) => ({
		all: 'unset',
		fontSize: '20px',
		marginLeft: '20px',
		cursor: 'pointer',
		userSelect: 'none',
		...theme.elements.CaptchaReloadButton,
	})};
`;

export const CaptchaWrapper = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
		...theme.elements.CaptchaWrapper,
	})};
`;

export const CaptchaInputCotainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '8px',
		...theme.elements.CaptchaInputCotainer,
	})};
`;

export const CaptchaInput = styled.input`
	${({ theme }) => ({
		all: 'unset',
		border: '1px solid gray',
		borderRadius: '4px 0 0 4px',
		padding: '8px',
		width: '100%',
		'&:focus': {
			outline: 'none !important',
		},
		...theme.elements.CaptchaInput,
	})};
`;

export const CaptchaSubmit = styled.button`
	${({ theme }) => ({
		all: 'unset',
		fontSize: '14px',
		padding: '4px 8px',
		userSelect: 'none',
		height: '36px',
		cursor: 'pointer',
		borderRadius: '0 4px 4px 0',
		color: theme.colors.white,
		background: theme.colors.primary,
		'&:disabled': {
			filter: 'grayscale(1)',
		},
		...theme.elements.CaptchaSubmit,
	})};
`;

export const CaptchaError = styled.p`
	${({ theme }) => ({
		marginTop: '8px',
		fontSize: '12px !important',
		color: 'red !important',
		...theme.elements.CaptchaError,
	})};
`;

export const CaptchaDescription = styled.p`
	${({ theme }) => ({
		marginBottom: '8px',
		fontSize: '12px !important',
		...theme.elements.CaptchaDescription,
	})};
`;
