import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';

export const TextContainer = styled(MessageContainer)`
	${({ theme }) => ({
		...theme.elements.messages.TextContainer,
	})};
`;
export const TextWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		backgroundColor: theme.colors.veryLightPink,
		a: {
			wordBreak: 'break-word',
		},
		padding: '4px 20px',
		borderRadius: '10px',
		color: theme.colors.colorSecondary,
		background: theme.colors.colorWhite,
		boxShadow: `0 0 18px ${theme.colors.boxShadow}`,
		fontSize: '15px',
		fontWeight: 'light',
		lineHeight: '130%',
		maxWidth: '90%',
		p: {
			textAlign: 'left',
			overflowWrap: 'anywhere',
			margin: '10px 0',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			fontSize: '18px',
		},
		...theme.elements.messages.TextWrapper,
	})};
`;

export const MessageAttachmentFileContainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		margin: '5px 0',
		...theme.elements.messages.MessageAttachmentFileContainer,
	})};
`;
export const MessageAttachmentFileIcon = styled.img`
	${({ theme }) => ({
		width: '32px',
		marginRight: '10px',
		...theme.elements.messages.MessageAttachmentFileIcon,
	})};
`;

export const MessageAttachmentFileDetails = styled.div`
	${({ theme }) => ({
		display: 'flex',
		width: '100% !important',
		flexDirection: 'column',
		...theme.elements.messages.MessageAttachmentFileDetails,
	})};
`;
export const MessageAttachmentFileName = styled.a`
	${({ theme }) => ({
		textDecoration: 'none',
		width: 'fit-content',
		whiteSpace: 'nowrap',

		...theme.elements.messages.MessageAttachmentFileName,
	})};
`;
export const MessageAttachmentDateDetails = styled.div`
	${({ theme }) => ({
		display: 'flex',
		justifyContent: 'space-between',
		textDecoration: 'none',
		margin: '0px !important',
		color: `${theme.colors.gray} !important`,
		...theme.elements.messages.MessageAttachmentDateDetails,
	})};
`;
export const MessageAttachmentsListWrapper = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		margin: '0px 0px 10px',
		overflow: 'hidden',
		...theme.elements.messages.MessageAttachmentsListWrapper,
	})};
`;
export const MessageAttachmentText = styled.p`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		margin: '0px 0px 10px',
		...theme.elements.messages.MessageAttachmentText,
	})};
`;
export const MessageAttachmentTime = styled.p`
	${({ theme }) => ({
		margin: '0px !important',
		...theme.elements.messages.MessageAttachmentTime,
	})};
`;
export const MessageAttachmentDate = styled.p`
	${({ theme }) => ({
		margin: '0px !important',
		...theme.elements.messages.MessageAttachmentDate,
	})};
`;
