import { createThemeExtension } from '@Core/exports/theme';
import avatar from '@Platform/assets/icons/ico_avatar_small_black_on_white.svg';
import sendIcon from '@Platform/assets/icons/ico_send_black.svg';
import avatarBig from '@Platform/assets/icons/ico_avatar_big_black_on_yellow.svg';
import { ITheme } from '@Core/types/themes';

export default createThemeExtension('contrastYellow', {
	colors: {
		primary: '#000000',
		lightPrimary: '#0d0c0e',
		hoverPrimary: '#f7ff02',
		white: '#f7ff02',
		shadow: 'rgba(32,37,43, .25)',
		beton: '#000000',
		gray: '#f7ff02',
		cream: '#f7ff02',
		lightGray: '#f7ff02',
		silver: '#f7ff02',
		red: '#f7ff02',
		black: '#000000',
		botTriangleBefore: '#000000',
		userTriangleBefore: '#000000',
		myMessageColor: '#000000',
		myMessageBackgroundColor: '#f7ff02',
		inputContainerBorderTopColor: '#000000',
		placeholderColor: '#000000',
		messageBotBoxShadow: '#000000',
		myMessageBorderColor: '#000000',
		botMessageBorderColor: '#000000',
		headerContainerColor: '#000000',
		headerContainerBackgroundColor: '#f7ff02',
		poweredByColor: '#000000',
		poweredByBorderTopColor: '#000000',
		poweredByAColor: '#000000',
		linkColor: '#000000',
		textWithButtonsListColor: '#000000',
		headerContainerTitleColor: '#000000',
		textWithButtonsBackgroundColor: '#f7ff02',
		textWithButtonsColor: '#000000',
		userIndicatorColor: '#000000',
	},
	elements: {
		BotWindow: {
			boxShadow: '0 4px 16px rgba(0, 0, 0, 0.25)',
			border: '1px solid black',
		},
		HeaderContainer: {
			boxShadow: 'none',
			borderBottom: '1px solid black',
		},

		HeaderIconContainer: {
			'div:after': {
				color: 'black',
			},
			'&:hover': {
				'div:after': {
					color: 'black',
				},
			},
		},

		MessageInput: {
			'&::placeholder': {
				opacity: 0.7,
			},
		},

		InitialPopupClose: {
			backgroundColor: '#f7ff02 !important',
			div: {
				color: '#000000 !important',
			},
			'&:hover': {
				backgroundColor: '#000000 !important',
				div: {
					color: '#f7ff02 !important',
				},
			},
		},

		SubmitButton: {
			backgroundImage: `url("${sendIcon}")`,
			'&:disabled': {
				backgroundImage: `url("${sendIcon}")`,
				opacity: '0.6',
			},
		},

		BotChatIcon: {
			backgroundImage: `url("${avatarBig}")`,
			width: '100%',
			height: '100%',
		},

		BotTalkImage: {
			backgroundColor: 'unset',
			'@media (max-width: 576px)': {
				backgroundColor: 'unset',
			},
		},
		messages: {
			MessagesGroupAvatar: {
				'&.bot': {
					backgroundImage: `url(${avatar})`,
				},
			},

			TextWithButtonsTextButton: {
				border: '1px solid black',
				'&:hover': {
					backgroundColor: '#000000',
					span: {
						color: '#f7ff02 !important',
					},
				},
			},

			OptionButton: {
				'&:not(:disabled):hover': {
					backgroundColor: '#000000',
					color: '#f7ff02',
				},
			},
		},
	},
} as ITheme);
