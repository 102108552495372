import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';
import { OWNER } from '@Types/messages';
import { IStyledComponentWithTheme } from '@Types/themes';

export const ImageContainer = styled(MessageContainer)<
	IStyledComponentWithTheme & { fullScreen: boolean; image: string; owner: OWNER }
>`
	${({ theme, fullScreen, image }) => {
		const fullScreenStyles = fullScreen
			? {
					position: 'fixed',
					left: 0,
					top: 0,
					width: '100vw',
					height: '100vh',
					justifyContent: 'center',
					backgroundColor: 'black',
					overflow: 'auto',
					zIndex: 999999999999,
					borderRadius: 0,
					backgroundImage: `url(${image})`,
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					...theme.elements.messages.ImageFullScreen,
				}
			: {};
		return fullScreen
			? {
					...fullScreenStyles,
				}
			: {
					marginTop: '0',
					...theme.elements.messages.ImageContainer,
				};
	}};
`;

export const ImageWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		backgroundColor: theme.colors.veryLightPink,
		padding: '0',
		width: '100%',
		maxWidth: 'initial',
		borderRadius: '10px',
		overflow: 'hidden',
		background: 'transparent',
		display: 'flex',
		flexDirection: 'column',
		fontSize: '16px',
		...theme.elements.messages.ImageWrapper,
	})};
`;

export const ImageTitle = styled.p`
	${({ theme }) => ({
		fontWeight: 'bold !important',
		fontSize: '18px !important',
		marginBottom: '10px',
		marginTop: '10px',
		textAlign: 'center',
		...theme.elements.messages.ImageTitle,
	})};
`;

export const ImageDescription = styled.p`
	${({ theme }) => ({
		fontSize: '14px !important',
		marginBottom: '15px',
		...theme.elements.messages.ImageDescription,
	})};
`;

export const Image = styled.img<IStyledComponentWithTheme & { fullScreen: boolean; owner: OWNER }>`
	${({ theme, fullScreen, owner }) => ({
		width: '100%',
		maxWidth: owner === OWNER.agent ? '150px' : 'initial',
		maxHeight: 'initial',
		borderRadius: '15px',
		display: fullScreen ? 'none' : 'unset',
		...theme.elements.messages.Image,
	})};
`;
