import {
	ShopCarouselButtonLeft,
	ShopCarouselButtonRight,
	ShopCarouselContainer,
	ShopCarouselImage,
	ShopCarouselTitle,
	ShopSwipeItem,
	ClickFix,
} from './ShopCarousel.styled';

import Slider from 'react-slick';
import '@Core/assets/css/slick.css';
import '@Core/assets/css/slick-theme.css';
import { createRef } from 'preact';
import { useState } from 'preact/hooks';
import { getAppStore } from '@Core/store/App';
import { ICarouselConfig } from '@Types/appConfig';

interface IShopSlide {
	title: string;
	image?: string;
	url?: string;
	price: string;
}

interface IShopCarousel {
	slides: IShopSlide[];
	sliderConfig: ICarouselConfig;
	onOptionSelect: (slide: IShopSlide['title']) => void;
}

export default ({ slides, sliderConfig, onOptionSelect }: IShopCarousel) => {
	let sliderRef = createRef();

	const appConfig = getAppStore().config;

	sliderConfig = {
		settings: {
			variableWidth: true,
			...appConfig.PUBLIC.carousel.settings,
		},
	};

	const { settings } = sliderConfig ? sliderConfig : appConfig.PUBLIC.carousel;

	const [dragging, setDragging] = useState(false);

	const changeEvents = {
		beforeChange: () => {
			setDragging(true);
		},

		afterChange: () => {
			setDragging(false);
		},
	};

	function handleClick(e, option) {
		dragging ? e.preventDefault : onOptionSelect(option);
	}

	if (slides) {
		const slidesComponents = slides.map(({ title, image, price, url }, index) => {
			const imageUrl = image || url;
			return (
				<ShopSwipeItem key={index} onClick={(e) => handleClick(e, title)}>
					<ClickFix>
						<ShopCarouselImage src={imageUrl} />
					</ClickFix>

					{(title || price) && (
						<ShopCarouselTitle>
							{!!title && <p>{title}</p>}

							{!!price && (
								<p>
									<b>{price}</b>
								</p>
							)}
						</ShopCarouselTitle>
					)}
				</ShopSwipeItem>
			);
		});

		return (
			<ShopCarouselContainer>
				<Slider {...settings} {...changeEvents} ref={sliderRef}>
					{slidesComponents}
				</Slider>

				<ShopCarouselButtonLeft onClick={() => sliderRef.current.slickPrev()}>&lt;</ShopCarouselButtonLeft>

				<ShopCarouselButtonRight onClick={() => sliderRef.current.slickNext()}>&gt;</ShopCarouselButtonRight>
			</ShopCarouselContainer>
		);
	}
};
