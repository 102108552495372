import { BusyIndicatorElement, BusyIndicatorContainer, BusyIndicatorText } from './BusyIndicator.styled';
import useDictionary from '@Core/utils/language/useDictionary';

export default function BusyIndicator() {
	const { translate } = useDictionary();
	return (
		<BusyIndicatorContainer>
			<BusyIndicatorElement>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</BusyIndicatorElement>
			<BusyIndicatorText>{translate('busy_indicator.text')}</BusyIndicatorText>
		</BusyIndicatorContainer>
	);
}
