import { IStyledComponentWithTheme } from '@Core/types/themes';
import styled from 'styled-components';

export const CalendarContainer = styled.div<IStyledComponentWithTheme & { isDisabled }>`
	${({ theme, isDisabled }) => {
		const calendarDisabledStyles = isDisabled
			? {
					cursor: 'default',
					pointerEvents: 'none',
					...theme.elements.messages.CalendarContainerDisabled,
				}
			: {};
		return {
			...theme.elements.messages.CalendarContainer,
			...calendarDisabledStyles,
		};
	}};
`;
export const CalendarDateContainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		...theme.elements.messages.CalendarDateContainer,
	})};
`;

export const CalendarDateItem = styled.button<IStyledComponentWithTheme & { isSelected; isDisabledElement? }>`
	${({ theme, isSelected, isDisabledElement }) => {
		const isSelectedStyles = isSelected
			? {
					backgroundColor: theme.colors.colorSecondary,
					color: theme.colors.colorWhite,
					...theme.elements.messages.CalendarDateItemSelected,
				}
			: {};
		const isDisabledStyles = isDisabledElement
			? {
					'&:hover': {
						color: 'unset',
						backgroundColor: 'unset !important',
						borderColor: 'unset',
						cursor: 'default',
					},
					'& span': {
						color: 'gray !important',
					},
				}
			: {};
		return {
			flexDirection: 'column',
			display: 'flex',
			alignItems: 'center',
			backgroundColor: theme.colors.colorWhite,
			color: theme.colors.colorSecondary,
			justifyContent: 'center',
			fontFamily: theme.fonts.primary,
			border: `1px solid ${theme.colors.colorSecondary}`,
			margin: '5px 6px',
			height: '50px',
			width: '50px',
			textAlign: 'center',
			padding: '5px',
			fontWeight: 'light',
			borderRadius: '5px',
			transition: 'background-color .3s, color .3s, opacity .3s',
			'&:not(:disabled):hover': {
				color: theme.colors.colorWhite,
				backgroundColor: theme.colors.colorPrimary,
				borderColor: theme.colors.colorPrimary,
			},
			...theme.elements.messages.CalendarDateItem,
			...isSelectedStyles,
			...isDisabledStyles,
		};
	}};
`;

export const CalendarHoursContainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		...theme.elements.messages.CalendarHoursContainer,
	})};
`;
export const CalendarHoursItem = styled.button<IStyledComponentWithTheme & { isSelected; isInactive }>`
	${({ theme, isSelected, isInactive }) => {
		const isInactiveStyles = isInactive
			? {
					pointerEvents: 'none',
					cursor: 'none',
					backgroundColor: 'gray',
					...theme.elements.messages.CalendarHoursItemInactive,
				}
			: {};
		const isSelectedStyles = isSelected
			? {
					color: theme.colors.colorWhite,
					backgroundColor: theme.colors.colorSecondary,
					cursor: 'none',
					...theme.elements.messages.CalendarHoursItemSelected,
				}
			: {};

		return {
			border: '1px solid black',
			margin: '3px',
			padding: '3px',
			borderRadius: '5px',
			width: '48%',
			cursor: 'pointer',
			height: '40px',
			lineHeight: '16px',
			fontFamily: theme.fonts.primary,
			fontWize: '16px',
			fontWeight: '500',
			'&:not(:disabled):hover': {
				color: theme.colors.colorWhite,
				backgroundColor: theme.colors.colorPrimary,
				borderColor: theme.colors.colorPrimary,
			},
			...theme.elements.messages.CalendarHoursItem,
			...isInactiveStyles,
			...isSelectedStyles,
		};
	}};
`;

export const CalendarSelectionDatesContainer = styled.div<IStyledComponentWithTheme & { isPrevButtonVisible }>`
	${({ theme, isPrevButtonVisible }) => ({
		display: 'flex',
		justifyContent: isPrevButtonVisible ? 'space-between' : 'flex-end',
		flexDirection: 'row',
		padding: '10px 5px',
		...theme.elements.messages.CalendarSelectionDatesContainer,
	})};
`;

export const CalendarNavigationDates = styled.div<IStyledComponentWithTheme & { isRequestBlocked }>`
	${({ theme, isRequestBlocked }) => ({
		span: {
			margin: '0 2px',
			cursor: 'pointer',
			color: isRequestBlocked ? 'rgba(29, 29, 78, 0.3) !important' : 'black',
		},
		...theme.elements.messages.CalendarNavigationDates,
	})};
`;

export const CalendarNextDates = styled(CalendarNavigationDates)`
	${({ theme }) => ({
		...theme.elements.messages.CalendarNextDates,
	})};
`;

export const CalendarPrevDates = styled(CalendarNavigationDates)`
	${({ theme }) => ({
		...theme.elements.messages.CalendarPrevDates,
	})};
`;
