import { Message } from '@Core/utils/models/messages';
import { getServices } from '@Core/store/Services';
import debug from '@Core/utils/debug';
import { OWNER } from '@Types/messages';
import { IGeneric, IWatsonProxyResponse } from '@Types/watson';

interface IResolveCommands {
	response: IWatsonProxyResponse;
	singleGeneric: IGeneric;
}

export default function ({ response, singleGeneric }: IResolveCommands) {
	const { AdapterService, BehaviorService } = getServices();
	const adapterService = new AdapterService();
	const text = singleGeneric.text;
	const context = response.context;

	const message = new Message({ owner: OWNER.system, content: '' });
	let ignore = false;

	BehaviorService.dispatchEvent('command', text);

	switch (text) {
		case 'connected':
			message.content = {
				command: text,
				initAgentMessage: singleGeneric.initAgentMessage,
				context,
			};
			message.type = 'command';
			message.isAction = true;
			break;
		case 'transfer':
		case 'disconnected':
		case 'closed':
		case 'not_connected':
		case 'agent_data':
			message.content = {
				command: text,
				context,
			};
			message.type = 'command';
			message.isAction = true;
			break;
		case 'ignore':
			ignore = true;
			break;
		default:
			try {
				adapterService.resolveCommands({ singleGeneric, message });
			} catch (e) {
				debug().error(e);
			}
	}

	return ignore ? false : message;
}
