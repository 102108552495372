interface ISingleFormData {
	type: 'form' | 'formFields' | 'formDefaults';
	content: {
		id: string;
		description: string;
		button: string;
		fields: string[];
		defaults: {
			[id: string]: string;
		};
	};
}

export default function mergeFormData(formData: ISingleFormData[]) {
	const mergedFormData = {
		id: 'unknown',
		fields: [],
		button: 'ok',
		description: undefined,
	};

	const formDefinition = formData.find((singleFormData) => singleFormData.type === 'form');

	if (formDefinition && formDefinition.content) {
		mergedFormData.id = formDefinition.content.id;
		mergedFormData.description = formDefinition.content.description;
		mergedFormData.button = formDefinition.content.button;

		const formFieldsDefinition = formData.find((singleFormData) => singleFormData.type === 'formFields');
		if (formFieldsDefinition && formFieldsDefinition.content) {
			Object.keys(formFieldsDefinition.content.fields).forEach((displayName) => {
				const name = formFieldsDefinition.content.fields[displayName];
				mergedFormData.fields.push({
					name,
					displayName,
				});
			});

			const formDefaultsDefinition = formData.find((singleFormData) => singleFormData.type === 'formDefaults');
			if (formDefaultsDefinition && formDefaultsDefinition.content) {
				Object.keys(formDefaultsDefinition.content.defaults).forEach((name) => {
					const defaultValue = formDefaultsDefinition.content.defaults[name];
					mergedFormData.fields.forEach((singleField) => {
						if (singleField.name === name) {
							singleField.defaultValue = defaultValue;
						}
					});
				});
			}
		}
	}

	return mergedFormData;
}
