import useAriaButton from '@Core/utils/aria/useAriaButton';
import useAriaImg from '@Core/utils/aria/useAriaImg';
import useDictionary from '@Core/utils/language/useDictionary';
import { ActionIcon as ActionIconStyled } from './ActionIcon.styled';

interface IActionIcon {
	onClick: () => void;
	src: string;
}

const ActionIcon = ({ onClick, src, ...props }: IActionIcon) => {
	const { translate } = useDictionary();

	const ariaButtonProps = useAriaButton({
		onClick,
		name: translate('aria.button.close_button'),
	});

	const { alt, ...ariaImgProps } = useAriaImg({
		alt: translate('aria.img.close_button'),
	});

	return (
		<ActionIconStyled {...props} tabIndex={0} {...ariaButtonProps}>
			<img alt={alt} {...ariaImgProps} src={src} />
		</ActionIconStyled>
	);
};

export default ActionIcon;
