import { Service } from '@Core/utils/services';
import { getAppStore } from '@Core/store/App';
import { IMessage } from '@Types/messages';

export default class MenuService extends Service {
	saveMenuList = (message: IMessage) => {
		const store = getAppStore();
		try {
			store.mainMenu.set(JSON.parse(message.content.menuList));
		} catch (e) {
			console.error(e);
		}
	};

	openMenu = () => {
		const store = getAppStore();
		store.mainMenuActive.set(true);
	};
}
