import { getAppStore } from '@Core/store/App';
import { useEffect, useRef } from 'react';

export default function useShortcuts({ botWindowRef }) {
	const { value: minimized, set: setMinimized } = getAppStore().minimized;

	const minimizedRef = useRef(minimized);

	useEffect(() => {
		minimizedRef.current = minimized;
	}, [minimized]);

	useEffect(() => {
		function handleShortcuts(e) {
			const minimized = minimizedRef.current;

			switch (e.key) {
				case 'Escape':
					if (!minimized) {
						setMinimized(true);
						window.focus();
					}
					break;
				case 'c':
				case 'C':
					if (e.altKey && minimized) {
						setMinimized(false);
						botWindowRef.current.base.focus();
					}
					break;
				default:
			}
		}

		document.addEventListener('keydown', handleShortcuts);
	}, []);
}
