import {
	PostcardCarouselButtonLeft,
	PostcardCarouselButtonRight,
	PostcardCarouselContainer,
	PostcardCarouselImage,
	PostcardCarouselTitle,
	PostcardSwipeItem,
	ClickField,
} from './PostcardCarousel.styled';
import Slider from 'react-slick';
import '@Core/assets/css/slick.css';
import '@Core/assets/css/slick-theme.css';
import { createRef } from 'preact';
import { useState } from 'preact/hooks';
import { getAppStore } from '@Core/store/App';
import ReactMarkdown from 'react-markdown';
import { getServices } from '@Core/store/Services';
import { Message } from '@Core/utils/models/messages';
import { ICarouselConfig } from '@Types/appConfig';
import { replaceHTMLtags } from '@Core/utils/converters/replaceHTMLtags';

interface IPostcardSlide {
	text: string;
	value: string;
	icon: string;
	price: string;
	title: string;
	subtitle: string;
}

interface IPostcardCarousel {
	slides: IPostcardSlide[];
	sliderConfig: ICarouselConfig;
	onOptionSelect: (slide: IPostcardSlide['value']) => void;
}

export default ({ slides, sliderConfig, onOptionSelect }: IPostcardCarousel) => {
	const sliderRef = createRef();
	const appConfig = getAppStore().config;
	const { settings } = sliderConfig ? sliderConfig : appConfig.PUBLIC.carousel;
	const [dragging, setDragging] = useState(false);
	const MarkdownService = getServices('MarkdownService');
	const markdownService = new MarkdownService();
	const [buttonPrevVisible, setButtonPrevVisible] = useState(false);
	const [buttonNextVisible, setButtonNextVisible] = useState(true);

	const changeEvents = {
		beforeChange: () => {
			setDragging(true);
		},
		afterChange: (currentSlide) => {
			setDragging(false);
			toggleSliderButtons(currentSlide);
		},
	};

	function toggleSliderButtons(currentSlide) {
		currentSlide == 0 ? setButtonPrevVisible(false) : setButtonPrevVisible(true);
		currentSlide == slides.length - 1 ? setButtonNextVisible(false) : setButtonNextVisible(true);
	}

	function handleClick(e, option) {
		if (option.action == 'redirect') {
			const ActionsService = getServices('ActionsService');
			const actionsService = new ActionsService();
			const message = new Message({
				content: {
					url: option.value,
					...option,
				},
			});
			actionsService.redirectWithMemory(message);
		} else {
			dragging ? e.preventDefault : onOptionSelect(option.value);
		}
	}

	if (slides) {
		const slidesComponents = slides.map((singleOption, index) => {
			const text = replaceHTMLtags(singleOption.text, 'br', '\n');
			return (
				<PostcardSwipeItem key={index}>
					<PostcardCarouselImage src={singleOption.icon} />
					<PostcardCarouselTitle>
						<ReactMarkdown components={markdownService.getRenderers()}>{text}</ReactMarkdown>
					</PostcardCarouselTitle>
					<ClickField onClick={(e) => handleClick(e, singleOption)} />
				</PostcardSwipeItem>
			);
		});

		return (
			<PostcardCarouselContainer>
				<Slider {...settings} {...changeEvents} ref={sliderRef}>
					{slidesComponents}
				</Slider>
				{buttonPrevVisible && (
					<PostcardCarouselButtonLeft onClick={() => sliderRef.current.slickPrev()}>
						&lt;
					</PostcardCarouselButtonLeft>
				)}
				{buttonNextVisible && (
					<PostcardCarouselButtonRight onClick={() => sliderRef.current.slickNext()}>
						&gt;
					</PostcardCarouselButtonRight>
				)}
			</PostcardCarouselContainer>
		);
	}
};
