import { getAppStore } from '@Core/store/App';

export default function debug() {
	function placeholderFunction() {}
	const placeholder = {
		log: placeholderFunction,
		error: placeholderFunction,
		warn: placeholderFunction,
		info: placeholderFunction,
	};

	try {
		const isDebbugEnabled = getAppStore().config.PROTECTED.debug;
		return isDebbugEnabled ? console : placeholder;
	} catch (e) {
		return placeholder;
	}
}
