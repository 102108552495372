import BusyIndicator from '@Core/components/BusyIndicator/BusyIndicator';
import { Message } from '@Core/exports/models';
import { getServices } from '@Core/store/Services';
import { IMessage, OWNER } from '@Core/types/messages';
import { useEffect, useState } from 'preact/hooks';
import {
	CalendarContainer,
	CalendarDateContainer,
	CalendarDateItem,
	CalendarHoursContainer,
	CalendarHoursItem,
	CalendarNextDates,
	CalendarPrevDates,
	CalendarSelectionDatesContainer,
} from './Calendar.styled';
import useDictionary from '@Core/utils/language/useDictionary';

export interface ICalendarMessage {
	message: IMessage;
	isDisabled: boolean;
}

export default ({ message, isDisabled }: ICalendarMessage) => {
	const { CalendarService, MessageService, ControlService } = getServices();
	const calendarService = new CalendarService();
	const messageService = new MessageService();
	const { mode, get } = calendarService.handle(message);

	const [reservations, setReservations] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedTime, setSelectedTime] = useState(null);
	const [startQueryDate, setStartQueryDate] = useState(null);
	const [firstReservationDate, setFirstReservationDate] = useState(null);
	const [isRequestBlocked, setIsRequestBlocked] = useState(false);
	const [slide, setSlide] = useState(0);
	const [slides, setSlides] = useState(0);
	const [elementsInSlider, setElementsInSlider] = useState(0);
	const { translate } = useDictionary();
	useEffect(() => {
		setIsRequestBlocked(true);
		get(message, { startDate: startQueryDate }).then((response) => {
			setReservations(response.returnData);
			setElementsInSlider(response.elementsInSlider);
			const items = response.returnData?.data;
			const date = items?.[0]?.date;

			date && onSelectDateItem(date);
			date && !firstReservationDate && setFirstReservationDate(date);

			setSlides(
				response.length <= response.elementsInSlider
					? 0
					: Math.ceil(response.length / response.elementsInSlider) - 1
			);
			setTimeout(() => {
				setIsRequestBlocked(false);
			}, calendarService.requestsThreshold);
		});
	}, [startQueryDate]);

	useEffect(() => {
		getHoursItems();
		selectedDate && calendarService.onSelectDate({ mode, date: selectedDate });
	}, [selectedDate]);

	useEffect(() => {
		selectedTime && calendarService.onSelectTime({ mode, date: selectedDate, time: selectedTime });
	}, [selectedTime]);

	useEffect(() => {
		scrollBottom();
	}, [reservations, selectedDate]);

	function scrollBottom() {
		const controlService = new ControlService();
		controlService.scrollToBottom();
	}

	function onSelectDateItem(date) {
		setSelectedTime(null);
		setSelectedDate(date);
	}

	function onSelectHourItem(time) {
		setSelectedTime(time);
		messageService.addMessage(
			new Message({ owner: OWNER.user, content: selectedDate + ', ' + time, type: 'text' })
		);
	}

	function formatDay(day) {
		const days = translate('calendarComponent.week')
			.slice(1, -1)
			.split(', ')
			.map((day) => day.replace(/'/g, ''));
		if (day > 6) {
			day = day - days.length;
		}
		return days[day];
	}

	function getDateItems() {
		const items = reservations?.data;
		const disabledDays = [];
		firstReservationDate && !selectedDate && onSelectDateItem(firstReservationDate);

		if (items) {
			const disabledDaysLength = elementsInSlider - items.length;
			const lastItem = items[items.length - 1].date;

			for (let i = 1; i <= disabledDaysLength; i++) {
				disabledDays.push({
					day: formatDay(new Date(lastItem).getUTCDay() + i),
					date: new Date(lastItem).getUTCDate() + i,
				});
			}
		}

		return (
			<>
				{items?.map(({ date }, index) => {
					return (
						<CalendarDateItem
							key={index}
							isSelected={date === selectedDate}
							onClick={() => !isDisabled && onSelectDateItem(date)}
						>
							<p>{formatDay(new Date(date).getUTCDay())}</p>
							<span>{new Date(date).getUTCDate()}</span>
						</CalendarDateItem>
					);
				})}

				{disabledDays?.map(({ date, day }, index) => {
					return (
						<CalendarDateItem
							key={index}
							isDisabledElement={true}
							isSelected={date === selectedDate}
							onClick={() => isDisabled && onSelectDateItem(date)}
						>
							<p>{day}</p>
							<span>{date}</span>
						</CalendarDateItem>
					);
				})}
			</>
		);
	}

	function getHoursItems() {
		const items = reservations?.data;
		return (
			<>
				{items
					?.filter((item) => item.date === selectedDate)
					.map(({ hours }) =>
						hours?.map((hour, index) => {
							return (
								<CalendarHoursItem
									key={index}
									isInactive={hour.avaliable}
									isSelected={hour.from === selectedTime}
									onClick={() => !isDisabled && onSelectHourItem(hour.from)}
								>
									{hour.from} {hour.to ? ' - ' + hour.to : ''}
								</CalendarHoursItem>
							);
						})
					)}
			</>
		);
	}

	function isDataLoaded() {
		return reservations;
	}

	function getNextDates() {
		setSlide((prevState) => prevState + 1);
		const items = reservations?.data;
		const lastItemDate = items?.[items?.length - 1]?.date;

		if (lastItemDate) {
			const date = new Date(lastItemDate);
			date.setDate(date.getDate() + 1);
			setStartQueryDate(date);
		}
	}

	function getPrevDates() {
		setSlide((prevState) => prevState + -1);
		const items = reservations?.data;
		const firstItemDate = items?.[0]?.date;

		if (firstItemDate) {
			const date = new Date(firstItemDate);
			if (items.length) {
				date.setDate(date.getDate() - elementsInSlider);
			}
			setStartQueryDate(date);
		}
	}
	return (
		<CalendarContainer isDisabled={isDisabled}>
			{!isDisabled && !isDataLoaded() && <BusyIndicator />}
			{isDataLoaded() && reservations.data?.length > 0 && (
				<>
					<CalendarSelectionDatesContainer isPrevButtonVisible={slide > 0}>
						{slide > 0 && (
							<CalendarPrevDates
								isRequestBlocked={isRequestBlocked}
								onClick={() => !isDisabled && !isRequestBlocked && getPrevDates()}
							>
								<span>&lt;</span>
								<span>{translate('calendarComponent.previousDays')}</span>
							</CalendarPrevDates>
						)}
						{slide < slides ? (
							<CalendarNextDates
								isRequestBlocked={isRequestBlocked}
								onClick={() => !isDisabled && !isRequestBlocked && getNextDates()}
							>
								<span>{translate('calendarComponent.nextDays')}</span>
								<span>&gt;</span>
							</CalendarNextDates>
						) : null}
					</CalendarSelectionDatesContainer>

					<CalendarDateContainer>{getDateItems()}</CalendarDateContainer>

					<CalendarHoursContainer>{getHoursItems()}</CalendarHoursContainer>
				</>
			)}
		</CalendarContainer>
	);
};
