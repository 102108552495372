import {
	InitialPopupContainer,
	InitialPopupClose,
	InitialPopupHeader,
	InitialPopupButton,
	InitialPopupResize,
	InitialPopupImage,
	InitialPopupSlide,
	InitialPopupSlideContainer,
	PopupCarouselButtonLeft,
	PopupCarouselButtonRight,
	InitialPopupButtonsContainer,
} from './InitialPopup.styled';
import { useState } from 'preact/hooks';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import ReactMarkdown from 'react-markdown';
import { Message } from '@Core/utils/models/messages';
import iconResizeUp from '@Core/assets/icons/icon_resize_up.svg';
import Slider from 'react-slick';
import '@Core/assets/css/slick.css';
import '@Core/assets/css/slick-theme.css';
import { createRef } from 'preact';
import useDictionary from '@Core/utils/language/useDictionary';
import debug from '@Core/utils/debug';
import { replaceHTMLtags } from '@Core/utils/converters/replaceHTMLtags';

export interface IInitialPopup {
	maximize: () => void;
}

export default ({ maximize }: IInitialPopup) => {
	const { ConnectionService, ActionsService, ThemeService } = getServices();
	const connectionService = new ConnectionService();
	const actionsService = new ActionsService();
	const themeService = new ThemeService();
	const store = getAppStore();
	const initialPopupValue = store.initialPopup.value;
	const appConfig = getAppStore().config;
	const { settings } = appConfig.PUBLIC.carouselPopup;
	const sliderRef = createRef();
	const [dragging, setDragging] = useState(false);
	const [buttonPrevVisible, setButtonPrevVisible] = useState(false);
	const [buttonNextVisible, setButtonNextVisible] = useState(true);

	const changeEvents = {
		beforeChange: () => {
			setDragging(true);
		},
		afterChange: (currentSlide) => {
			setDragging(false);
			toggleSliderButtons(currentSlide);
		},
	};

	function toggleSliderButtons(currentSlide) {
		debug().log('IN TOGGLE', currentSlide, buttonPrevVisible, initialPopupValue?.messages?.length - 2);
		currentSlide == 0 ? setButtonPrevVisible(false) : setButtonPrevVisible(true);
		currentSlide == initialPopupValue?.messages?.length - 2
			? setButtonNextVisible(false)
			: setButtonNextVisible(true);
	}

	const handleClick = ({ action, command }) => {
		if (action === 'maximize') {
			maximize();
		} else if (action && actionsService.exists(action)) {
			const actionMessage = new Message({
				isAction: true,
			});

			actionsService.getCaseInsensitiveProp(action)(actionMessage);
		} else if (command) {
			maximize();
			connectionService.sendMessage({ text: command });
		}
	};

	const handleClose = () => {
		store.config.PUBLIC.initialPopup.oneTimeOnly && store.initialPopupSeen.set(true);
	};

	const handleResizeClick = () => {
		initialPopupValue.maximizeMessage &&
			connectionService.sendMessage({ text: initialPopupValue.maximizeMessage, incognito: 'input' });
		maximize();
		themeService.setFullScreen(true);
	};

	function renderCarousel() {
		return (
			<>
				<InitialPopupSlideContainer>
					<Slider {...settings} {...changeEvents} ref={sliderRef}>
						{initialPopupValue?.messages?.map((item, index) => (
							<InitialPopupSlide key={index} onClick={() => handleClick(item)}>
								<img src={item.img} />
								<p>{item.text}</p>
							</InitialPopupSlide>
						))}
					</Slider>
					{buttonPrevVisible && (
						<PopupCarouselButtonLeft onClick={() => sliderRef.current.slickPrev()}>
							&lt;
						</PopupCarouselButtonLeft>
					)}
					{buttonNextVisible && (
						<PopupCarouselButtonRight onClick={() => sliderRef.current.slickNext()}>
							&gt;
						</PopupCarouselButtonRight>
					)}
				</InitialPopupSlideContainer>
				{buttonPrevVisible && (
					<PopupCarouselButtonLeft onClick={() => sliderRef.current.slickPrev()}>
						&lt;
					</PopupCarouselButtonLeft>
				)}
				{buttonNextVisible && (
					<PopupCarouselButtonRight onClick={() => sliderRef.current.slickNext()}>
						&gt;
					</PopupCarouselButtonRight>
				)}
			</>
		);
	}

	function renderImages() {
		return initialPopupValue?.messages?.map((item, index) => (
			<InitialPopupImage key={index} img={item.img} onClick={() => handleClick(item)}>
				<div>{item.text}</div>
			</InitialPopupImage>
		));
	}

	function renderContent() {
		return (
			<div>
				<InitialPopupButtonsContainer>
					{initialPopupValue?.messages?.map((item, index) => (
						<InitialPopupButton key={index} onClick={() => handleClick(item)}>
							{item.text}
						</InitialPopupButton>
					))}
				</InitialPopupButtonsContainer>
			</div>
		);
	}

	const { translate } = useDictionary();

	if (initialPopupValue && !store.initialPopupSeen.value) {
		const header = replaceHTMLtags(initialPopupValue.header, 'br', '\n');
		return (
			<InitialPopupContainer tabIndex={1} role="alert" aria-label={translate('aria.initial-popup')}>
				<InitialPopupClose onClick={handleClose}>
					<div aria-hidden="true">+</div>
				</InitialPopupClose>
				{store.config.PUBLIC.fullscreen.enabled && (
					<InitialPopupResize onClick={handleResizeClick}>
						<img src={iconResizeUp} />
					</InitialPopupResize>
				)}
				<InitialPopupHeader>
					<ReactMarkdown>{header}</ReactMarkdown>
				</InitialPopupHeader>
				{(() => {
					if (initialPopupValue) {
						switch (initialPopupValue.type) {
							case 'carousel':
								return renderCarousel();
							case 'images':
								return renderImages();
							default:
								return renderContent();
						}
					}
				})()}
			</InitialPopupContainer>
		);
	}
};
