// Platform dictionary definition

import { createDictionary, PlatformDictionary } from '@Core/exports/dictionary';

const platformDictionary: PlatformDictionary = {
	'aria.initial-popup-close': {
		pl: 'Zamknij komunikat czatbota',
		en: 'Close the chatbot popup',
	},
};

export default createDictionary(platformDictionary);
