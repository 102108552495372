import { IBehaviorConfig } from '@Core/types/behaviorConfig';
import { useRef, useState } from 'preact/hooks';

const useNervousClicking = (behaviorConfig: IBehaviorConfig) => {
	const [isNervousClicking, setIsNervousClicking] = useState(false);
	const clickCounter = useRef(0);

	const incrementClickCounter = () => {
		clickCounter.current = clickCounter.current + 1;
		if (clickCounter.current >= (behaviorConfig.nervousClicking?.clickCounter || 5)) {
			setIsNervousClicking(true);
		} else {
			setTimeout(() => {
				clickCounter.current = 0;
			}, behaviorConfig.nervousClicking?.executingTime || 1000);
		}
	};

	return [isNervousClicking, incrementClickCounter] as const;
};

export { useNervousClicking };
