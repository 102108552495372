import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';
import { OWNER } from '@Types/messages';
import { IStyledComponentWithTheme } from '@Types/themes';

export const TextContainer = styled(MessageContainer)<IStyledComponentWithTheme & { owner: OWNER }>`
	${({ theme, owner }) => ({
		...theme.elements.messages.TextContainer,
	})};
`;

export const TextWrapper = styled(MessageWrapper).attrs({ role: 'paragraph' })`
	${({ theme }) => ({
		backgroundColor: theme.colors.veryLightPink,
		a: {
			wordBreak: 'break-word',
		},
		padding: '10px 25px',
		borderRadius: '10px',
		color: theme.colors.colorSecondary,
		background: theme.colors.colorWhite,
		boxShadow: `0 0 18px ${theme.colors.boxShadow}`,
		fontSize: '15px',
		fontWeight: 'light',
		lineHeight: '130%',
		maxWidth: '90%',
		p: {
			textAlign: 'left',
			overflowWrap: 'anywhere',
			margin: '10px 0',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			fontSize: '18px',
		},
		...theme.elements.messages.TextWrapper,
	})};
`;

export const UserIndicator = styled.div`
	${({ theme }) => ({
		fontSize: '13px',
		color: '#393a3b',
		marginBottom: '4px',
		...theme.elements.messages.UserIndicator,
	})};
`;
