import { MessagesGroup, MessagesWithAvatar, Messages, Date as DateComponent } from './MessagesGroup.styled';
import { getDate } from '@Core/utils/date/readableDate';
import Avatar from './Avatar/Avatar';
import { getAppStore } from '@Core/store/App';
import debug from '@Core/utils/debug';
import { ReactNode } from 'react';
import { IMessagesGroup } from '@Types/messages';
import useDictionary from '@Core/utils/language/useDictionary';

interface IMessagesGroupComponent {
	children: ReactNode;
	group: IMessagesGroup;
}

export default ({ children, group }: IMessagesGroupComponent) => {
	const { owner, messages, withoutAvatar, withoutDate, agentInfo } = group;
	let lastMessageDate =
		(messages && messages[messages.length - 1] && messages[messages.length - 1].date) || new Date();

	if (!(lastMessageDate instanceof Date)) {
		try {
			lastMessageDate = new Date(lastMessageDate);
		} catch (e) {
			debug().log(e);
		}
	}

	const displayMessageConfig = getAppStore().config.PUBLIC.displayMessage;

	const allowAvatar =
		displayMessageConfig.avatar?.enabled && !displayMessageConfig.avatar?.blackList?.includes(owner);

	const allowDate = displayMessageConfig.date?.enabled && !displayMessageConfig.date?.blackList?.includes(owner);

	const readableDateValue = getDate(lastMessageDate);

	const { translate } = useDictionary();

	const ariaLabel = translate(`aria.message.sent-by.${owner}`, { readableDateValue });

	return (
		<MessagesGroup aria-label={ariaLabel} className={owner}>
			<MessagesWithAvatar className={owner}>
				{allowAvatar && !withoutAvatar && <Avatar owner={owner} agentInfo={agentInfo} />}
				<Messages>
					{children}
					{allowDate && !withoutDate && (
						<DateComponent aria-hidden={true} className={owner}>
							{readableDateValue}
						</DateComponent>
					)}
				</Messages>
			</MessagesWithAvatar>
		</MessagesGroup>
	);
};
