import { IMessage } from '@Types/messages';
import { ISingleOptionParsed } from '@Types/watson';
import { ImageContainer, ImageWrapper } from './ImageGallery.styled';
import { getServices } from '@Core/store/Services';

export interface IImageGalleryMessage {
	message: IMessage;
	onOptionSelect: (selected: IImageData['title']) => void;
}

interface IImageData {
	url: string;
	title?: string;
}

export default ({ message, onOptionSelect }: IImageGalleryMessage) => {
	let images: IImageData[] = [];
	const { ControlService } = getServices();
	const controlService = new ControlService();

	message.content.options.forEach((msg: ISingleOptionParsed) => {
		if (msg.label === 'images') {
			try {
				images = JSON.parse(msg.value);
			} catch (e) {
				console.error(e);
			}
		}
	});

	function handleClick(e, option) {
		onOptionSelect(option);
	}

	return (
		<ImageContainer fullScreen={false}>
			{images.map((image, index) => {
				return (
					<ImageWrapper key={index}>
						<img
							src={image.url}
							onLoad={() => controlService.scrollToBottom()}
							onClick={(e) => handleClick(e, image.title)}
						/>
					</ImageWrapper>
				);
			})}
		</ImageContainer>
	);
};
