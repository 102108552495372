import { useState } from 'preact/hooks';
import { SurveyContainer, SurveyDescription } from './Survey.styled';

import Thumb, { ISurveyThumb } from './surveyButtons/Thumb';
import thumbUpImage from '@Core/assets/icons/thumbUp.svg';
import thumbUpSelectedImage from '@Core/assets/icons/thumbUpSelected.svg';
import thumbDownImage from '@Core/assets/icons/thumbDown.svg';
import thumbDownSelectedImage from '@Core/assets/icons/thumbDownSelected.svg';
import { IMessage } from '@Types/messages';

interface ISurveyButtons {
	[buttonName: string]: {
		component: typeof Thumb;
		props: ISurveyThumb;
	};
}

const surveyButtons: ISurveyButtons = {
	thumbUp: {
		component: Thumb,
		props: {
			img: thumbUpImage,
			imgSelected: thumbUpSelectedImage,
		},
	},
	thumbDown: {
		component: Thumb,
		props: {
			img: thumbDownImage,
			imgSelected: thumbDownSelectedImage,
		},
	},
};

export interface ISurveyMessage {
	message: IMessage;
	onSelect: (value: string) => void;
}

export default ({ message, onSelect }: ISurveyMessage) => {
	const { position = 'left', ...buttons } = message.content;
	const question = message.description;
	const [surveyValue, setSurveyValue] = useState(undefined);

	function handleSelect(value: string) {
		if (surveyValue === undefined) {
			setSurveyValue(value);
			onSelect(value);
		}
	}

	function getButtonsComponents() {
		const output = [];
		if (typeof buttons === 'object') {
			Object.keys(buttons).forEach((buttonName) => {
				if (surveyButtons[buttonName]) {
					const value = buttons[buttonName];
					const SingleButton = surveyButtons[buttonName];
					output.push(
						<SingleButton.component
							key={'survey-item' + buttonName}
							isSelected={value === surveyValue}
							onSelect={() => handleSelect(value)}
							{...SingleButton.props}
							valueSet={surveyValue !== undefined}
						/>
					);
				}
			});
		}
		return output;
	}

	return (
		<SurveyContainer position={position}>
			<SurveyDescription>{question}</SurveyDescription>
			<div>{getButtonsComponents()}</div>
		</SurveyContainer>
	);
};
