import { Service } from '@Core/utils/services';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import debug from '@Core/utils/debug';
import { getUID } from '@Core/utils/hooks/useUID';
import extractContextUserDefined from '@Core/utils/watson/extractContextUserDefined';
import { AppStoreConfig } from '@Types/appStore';
import { IWebsocketMessage, IWebsocketMessageData } from '@Types/websocket';
import { useEffect, useMemo, useState } from 'preact/hooks';
import useWebsocket from '@Core/utils/hooks/useWebsocket';
// test fetch on IE

interface ISendUserInfoToAgent {
	escalate?: boolean;
	prefix?: string;
	handover?: string;
}

export default class AgentConnectionService extends Service {
	getWebSocket;
	constructor(getWebSocket) {
		super();
		this.getWebSocket = getWebSocket;
	}

	static createWebsocketLayer = () => {
		const WebsocketLayer = () => {
			const store = getAppStore();
			const { config } = store;

			const websocketEnabled = config.PROTECTED.agent && config.PROTECTED.agent.available;
			if (!websocketEnabled) return;

			const userSentFirstMessage = store.userSentFirstMessage.value;
			const skipAgentConnection = store.skipAgentConnection.value;

			const { sendMessageToAgent } = config.PROTECTED.connection;

			const [isWSOpened, setIsWSOpened] = useState(false);

			const getWebSocket = useMemo(
				() =>
					useWebsocket({
						url: sendMessageToAgent.url,
						onMessage: (message) => {
							AgentConnectionService.onMessage(message);
						},
						onError: (error) => {
							AgentConnectionService.onError(error);
						},
						onClose: () => {
							setIsWSOpened(false);
							AgentConnectionService.onClose;
						},
						onOpen: () => {
							setIsWSOpened(true);
							AgentConnectionService.onOpen();
						},
					}),
				[]
			);

			const agentConnectionService = new AgentConnectionService(getWebSocket);

			useEffect(() => {
				if (isWSOpened) {
					agentConnectionService.sendUserInfoToAgent();
				}
			}, [isWSOpened]);

			useEffect(() => {
				if (store.agentHandover.value) {
					agentConnectionService.sendHandoverToAgent();
				}
			}, [store.agentHandover.value]);

			useEffect(() => {
				if (isWSOpened && store.agentLeftChat.value) {
					agentConnectionService.closeConnectionToAgent();
				}
			}, [store.agentLeftChat.value]);

			useEffect(() => {
				// Give 1 sec for every retry
				setTimeout(() => {
					if (!isWSOpened && skipAgentConnection === false) {
						if (!isWSOpened && userSentFirstMessage) {
							debug().log('&&& SHOULD OPEN CONNECTION TO AGENT');
							debug().log('websocket.isWSOpened', isWSOpened);
							agentConnectionService.openConnectionToAgent();
						}
					}
					if (!userSentFirstMessage) {
						store.replyForFirstUserMessage.set(undefined);
					}
				}, 1000);
			}, [isWSOpened, userSentFirstMessage, skipAgentConnection]);

			return null;
		};

		return WebsocketLayer;
	};

	static generateUsername = (config: AppStoreConfig) => {
		// generate username visible in the rocket.chat
		let name = '';
		const number = String(Math.random()).substr(-4, 4);
		if (config.PROTECTED.agent && config.PROTECTED.agent.usernameVisibleToAgent) {
			name = config.PROTECTED.agent.usernameVisibleToAgent;
		}
		const finalName = name + ` (${number})`;

		getAppStore().usernameVisibleToAgent.set(finalName);

		return finalName;
	};

	static get usernameVisibleToAgent() {
		const store = getAppStore();
		const usernameVisibleToAgent = store.usernameVisibleToAgent.value;

		return usernameVisibleToAgent || AgentConnectionService.generateUsername(store.config);
	}

	static get websocketSessionId() {
		const agentConnection = getAppStore().agentConnection.value;
		return agentConnection && agentConnection.websocket_session_id;
	}

	openConnectionToAgent = () => {
		try {
			this.getWebSocket().openWS();
			getAppStore().agentLeftChat.set(false);
		} catch (e) {
			debug().error('catched', e);
		}
	};

	sendUserInfoToAgent = ({ escalate, prefix = '', handover }: ISendUserInfoToAgent = {}) => {
		const checkIfFirsMessageDoneInterval = setInterval(() => {
			if (this.getAppStore().replyForFirstUserMessage.value) {
				clearInterval(checkIfFirsMessageDoneInterval);
				const websocketConfig = getAppStore().config.PROTECTED.connection.sendMessageToAgent;
				const initObj = {
					departament: websocketConfig.department,
					queueDepartament: websocketConfig.queueDepartment,
				};

				const { room_id, websocket_session_id } = getAppStore().agentConnection.value || {};
				const watsonSessionId = getAppStore().sessionId.value;

				debug().log('sendUserInfoToAgent', room_id, websocket_session_id, watsonSessionId);
				const payload = {
					...initObj,
					initResponse: undefined,
					name: prefix + AgentConnectionService.usernameVisibleToAgent,
					escalate,
					prefix,
					handover,
					room_id,
					websocket_session_id,
					session_id: watsonSessionId,
					initMessage: window.__actionBot.initMessage,
					uid: getUID(),
					watson: getAppStore().watson.value,
				};

				window.__actionBot.initMessage = '';

				const store = getAppStore();
				if (store.config.PROTECTED.connection?.sendMessageToAgent?.sendInitResponse) {
					payload.initResponse = window.__actionBot.initResponse;
					setTimeout(() => {
						window.__actionBot.initResponse = '';
					}, 1000);
				}

				this.getWebSocket().sendWS(JSON.stringify(payload));
				this.pingWs();
			}
		}, 1000);
	};

	sendHandoverToAgent = () => {
		const checkIfFirsMessageDoneInterval = setInterval(() => {
			if (this.getAppStore().replyForFirstUserMessage.value) {
				clearInterval(checkIfFirsMessageDoneInterval);

				this.sendUserInfoToAgent({
					escalate: true,
					prefix: '🚩 ',
					handover: 'chatbotHandover',
				});
			}
		}, 1000);
	};

	pingWs = () => {
		window.__actionBot = {
			...window.__actionBot,
		};

		window.__actionBot.pingInterval = setInterval(() => {
			if (this.getWebSocket().isWSOpened && AgentConnectionService.websocketSessionId) {
				const { websocket_session_id, room_id } = getAppStore().agentConnection.value || {};
				const session_id = getAppStore().sessionId.value;
				const uid = getUID();

				this.getWebSocket().sendWS(
					JSON.stringify({
						ping: websocket_session_id,
						websocket_session_id,
						session_id,
						room_id,
						uid,
					})
				);
			}
		}, getAppStore().config.PROTECTED.connection.wsPingInterval);
	};

	closeConnectionToAgent = () => {
		this.getWebSocket().closeWS();
		this.getAppStore().agentHandover.set(false);
		try {
			clearInterval(window.__actionBot.pingInterval);
		} catch (e) {
			debug().error(e);
		}
	};

	static onOpen = () => {};

	static onClose = (msg) => {};

	static onError = (msg) => {};

	static onMessage = (message: IWebsocketMessage) => {
		let response = {} as IWebsocketMessageData;

		try {
			response = JSON.parse(message.data);
		} catch (error) {
			debug().error(error);
		}
		//CBO-707 scrollFix: front-end version - 0.23.0, back-end version (omantel) 0.0.121
		if (response.pong && !response.websocket_session_id) return;

		if (response.pong && response.websocket_session_id) {
			const { websocket_session_id } = getAppStore().agentConnection.value;
			if (response.websocket_session_id !== websocket_session_id) {
				getAppStore().agentConnection.set((agentConnection) => ({
					...agentConnection,
					websocket_session_id: response.websocket_session_id,
				}));
			}
		}

		if (response.context) {
			const store = getAppStore();
			const waitingForAgent = store.waitingForAgent.value;

			if (waitingForAgent) {
				const userDefined = extractContextUserDefined(response.context);
				if (!userDefined.eventData || (userDefined.eventData && !userDefined.eventData.waitingForAgent)) {
					store.waitingForAgent.set(false);
				}
			}

			store.watson.set((watson) => ({
				...watson,
				context: response.context,
			}));
		}

		if (response.enabled) {
			//
			// initial message from rocket chat
			//
			getAppStore().agentConnection.set((agentConnection) => ({
				...agentConnection,
				...response,
			}));

			if (response.websocket_session_id) {
				getAppStore().agentConnection.set((agentConnection) => ({
					...agentConnection,
					websocket_session_id: response.websocket_session_id,
				}));
			}
		} else {
			//
			// next messages from watson
			//
			const { ConnectionService } = getServices();
			const connectionService = new ConnectionService();

			connectionService.saveInDataLayer(response);
			const converted = connectionService.convertResponseMessageByAdapter({ response } as any);
			connectionService.processMessages(converted);
		}
	};
}
