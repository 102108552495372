import { IMessage } from '@Types/messages';
import { OptionButton, OptionContainer, OptionTitle, OptionWrapper } from './Option.styled';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import { ISingleOptionParsed } from '@Types/watson';
import useAriaOptions from '@Core/utils/aria/useAriaOptions';
import { useState } from 'preact/hooks';
export interface IOptionMessage {
	message: IMessage;
	onOptionSelect: (value: ISingleOptionParsed['value']) => void;
	isDisabled?: boolean;
	type?: 'permanent';
	isPermanent?: boolean;
}

export default ({ message, onOptionSelect, isDisabled: forceDisabled, isPermanent }: IOptionMessage) => {
	const appConfig = getAppStore().config;
	const { MessageService } = getServices();
	const ariaOptions = useAriaOptions();
	const [selectedItem, setSelectedItem] = useState(null);
	const store = getAppStore();
	const { config } = store;
	const messageService = new MessageService();
	const printOptionSelected = config.PUBLIC.displayMessage.printOptionSelected;

	let isDisabled = selectedItem;

	if (forceDisabled !== undefined && printOptionSelected) {
		isDisabled = forceDisabled;
	}

	if (isPermanent) {
		isDisabled = false;
	}

	const handleClick = ({ value }: ISingleOptionParsed) => {
		if (appConfig.PUBLIC.option.hideOptionsAfterSelect) {
			messageService.editMessageById(message.id, (message) => {
				message.isHidden = true;
				return message;
			});
		}
		setSelectedItem(value);
		onOptionSelect(value);
	};

	if (message.content && !message.isHidden && Array.isArray(message.content.options)) {
		const description = <OptionTitle>{message.content.description}</OptionTitle>;
		const options = message.content.options.map((singleOption: ISingleOptionParsed, index) => {
			return (
				<OptionButton
					key={index}
					role="option"
					onClick={() => handleClick(singleOption)}
					disabled={isDisabled}
					isSelected={selectedItem === singleOption.value}
				>
					{singleOption.label}
				</OptionButton>
			);
		});

		return (
			<OptionContainer {...ariaOptions} owner={message.owner}>
				<OptionWrapper>
					{message.content.description && description}
					{options}
				</OptionWrapper>
			</OptionContainer>
		);
	}
};
