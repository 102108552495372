import { Service } from '@Core/utils/services';
import { IMessage } from '@Types/messages';
import { Message } from '@Core/exports/models';
import { RemoteConfigData } from '@Core/types/appStore';
import { getAppStore } from '@Core/store/App';

export default class RemoteConfigService extends Service {
	get isEnabled() {
		return !!this.config.PROTECTED.remoteConfig?.enabled;
	}

	get isLoadingActive() {
		if (this.isEnabled) {
			return !this.getAppStore().remoteConfig.value?.data?.length;
		} else {
			return false;
		}
	}

	get remoteConfig() {
		return this.getAppStore().remoteConfig.value;
	}

	setData(data: RemoteConfigData) {
		this.getAppStore().remoteConfig.set((remoteConfig) => ({
			...remoteConfig,
			data,
		}));
	}

	saveAndApply(message: IMessage) {
		if (this.isEnabled && Array.isArray(message?.content)) {
			const content = message.content;
			this.setData(content);
			this.apply(content);
		}
	}

	apply(data?: RemoteConfigData) {
		if (this.isEnabled) {
			let dataToApply = data;
			if (!dataToApply) {
				const { data } = this.remoteConfig;
				dataToApply = data;
			}

			if (Array.isArray(data)) {
				const { MenuService } = this.getServices();
				const menuService = new MenuService();

				data.forEach(({ label, value }) => {
					const _label = label.toLowerCase();

					switch (_label) {
						case 'mainmenu':
							menuService.saveMenuList(
								new Message({
									content: {
										menuList: value,
									},
								})
							);
							break;
						case 'initialpopup': {
							const store = getAppStore();
							try {
								store.initialPopup.set(JSON.parse(value));
							} catch (e) {
								console.error(e);
							}
							break;
						}
						default:
							return;
					}
				});
			}
		}
	}
}
