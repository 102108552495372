import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';
import { IStyledComponentWithTheme, ITheme } from '@Types/themes';
import { OWNER } from '@Types/messages';

export const OptionButton = styled.button<ITheme & { isSelected?: boolean }>`
	${({ theme, isSelected }) => {
		const selectedStyles = isSelected
			? {
					color: theme.colors.colorWhite,
					backgroundColor: theme.colors.colorPrimary,
					borderColor: theme.colors.colorPrimary,
					...theme.elements.messages.OptionButtonSelected,
				}
			: {};

		const disabledStyles = isSelected
			? {
					...theme.elements.messages.OptionButtonDisabledSelected,
				}
			: {
					opacity: '.6',
					...theme.elements.messages.OptionButtonDisabledNotSelected,
				};

		return {
			flex: '0 calc(50% - 10px)',
			margin: '2px 5px',
			padding: '5px 15px',
			borderRadius: '15px',
			fontFamily: theme.fonts.primary,
			border: `1px solid ${theme.colors.colorSecondary}`,
			backgroundColor: theme.colors.colorWhite,
			color: theme.colors.colorSecondary,
			boxShadow: `0 0 10px ${theme.colors.boxShadow}`,
			fontSize: '14px',
			fontWeight: 'light',
			transition: 'background-color .3s, color .3s, opacity .3s',
			'&:not(:disabled):hover': {
				color: theme.colors.colorWhite,
				backgroundColor: theme.colors.colorPrimary,
				borderColor: theme.colors.colorPrimary,
			},
			'&:disabled': disabledStyles,
			'&:not(:disabled)': {
				cursor: 'pointer',
			},
			[`@media (max-width: ${theme.breakpoints.mobile})`]: {
				flex: '0 calc(50% - 30px)',
				margin: '5px 15px',
				fontSize: '18px',
			},
			...theme.elements.messages.OptionButton,
			...selectedStyles,
		};
	}};
`;

export const OptionTitle = styled.div`
	${({ theme }) => ({
		marginBottom: '1rem',
		fontWeight: 'bold',

		marginTop: '10px',
		fontSize: '16px',
		color: theme.colors.colorSecondary,
		flex: '0 100%',
		...theme.elements.messages.OptionTitle,
	})};
`;

export const OptionContainer = styled(MessageContainer)<IStyledComponentWithTheme & { owner: OWNER }>`
	${({ theme }) => ({
		...theme.elements.messages.OptionContainer,
	})};
`;

export const OptionWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		marginRight: '25%',
		backgroundColor: theme.colors.veryLightPink,
		display: 'flex',
		flexWrap: 'wrap',
		margin: '0',
		padding: '10px 0',
		width: '100%',
		borderRadius: '0',
		background: 'transparent',
		...theme.elements.messages.OptionWrapper,
	})};
`;
