import { IMessage } from '@Types/messages';
import { ISingleOptionParsed } from '@Types/watson';
import { GridContainer, IconGridContainer, GridItem } from './IconGrid.styled';

export interface IIconGridMessage {
	message: IMessage;
}

interface IGridItem {
	img: string;
	label: string;
}

export default ({ message }: IIconGridMessage) => {
	if (message.content && Array.isArray(message.content.options)) {
		let gridList: IGridItem[] = [];
		let label = '';

		try {
			message.content.options.forEach((msg: ISingleOptionParsed) => {
				if (msg.label === 'icons') {
					gridList = JSON.parse(msg.value);
				}
				if (msg.label === 'label') {
					label = msg.value;
				}
			});
		} catch (e) {
			console.error(e);
		}

		const grid = gridList.map((item, index) => (
			<GridItem key={index}>
				<img src={item.img} />
				<p>{item.label}</p>
			</GridItem>
		));

		return (
			<IconGridContainer>
				<p>{label}</p>
				<GridContainer>{grid}</GridContainer>
			</IconGridContainer>
		);
	}
};
