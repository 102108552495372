const dictionary = {
	'messageInput.textarea.placeholder': {
		pl: 'Napisz tutaj swoje pytanie',
		en: 'Write your question here.',
		ar: 'اكتب سؤالك هنا.',
	},
	'message.text.userIndicator': {
		pl: 'Ty',
		en: 'You',
		ar: 'أنت',
	},
	'agent.status.not_connected': {
		pl: 'Przepraszam, nikogo tam nie ma. Zacznijmy od nowa, a agent dołączy, gdy tylko będzie mógł.',
		en: "Sorry, nobody is there. Let's start over and the agent will join as soon as he can.",
		ar: 'آسف ، لا أحد هناك. لنبدأ من جديد وسينضم الوكيل بأسرع ما يمكن.',
	},
	'upload.error.not_supported_format': {
		pl: 'Proszę załadować plik we wspieranym formacie: ',
		en: 'Please use one of supported formats: ',
		ar: 'الرجاء استخدام أحد التنسيقات المدعومة:',
	},
	'upload.error.file_to_big': {
		pl: 'Plik jest za duży, maksymalny rozmiar pliku to: ',
		en: 'File is too big. Max file size is: ',
		ar: 'الملف كبير جدا. الحجم الأقصى للملف هو:',
	},
	'upload.error.not_sent': {
		pl: 'Plik nie został wysłany',
		en: 'File not sent',
		ar: 'لم يتم إرسال الملف',
	},
	'busy_indicator.text': {
		pl: 'Wczytywanie...',
		en: 'Loading...',
		ar: 'تحميل...',
	},
	connection_error: {
		pl: 'Coś poszło nie tak',
		en: 'Something gone wrong',
		ar: 'حدث خطأ ما',
	},
	'aria.img.submit_button': {
		pl: 'Ikona przycisku wysyłającego',
		en: "Send button's icon",
	},
	'aria.img.fullscreen_button': {
		pl: 'Ikon przełączania czatu w tryb pełnoenkranowy',
		en: "Switching chat into fullscreen mode button's icon",
	},
	'aria.button.submit_button': {
		pl: 'Wysyślij wiadomość',
		en: 'Send message',
	},
	'aria.button.close_button': {
		pl: 'Zamyknij czat',
		en: 'Close chat',
	},
	'aria.img.close_button': {
		pl: 'Ikona przycisku zamykającego czat',
		en: "Close chat button's icon",
	},
	'aria.button.bot_minimized_minimized': {
		pl: 'Pokaż okno czatu',
		en: "Show chat's window",
	},
	'aria.button.bot_minimized_maximized': {
		pl: 'Schowaj okno czatu',
		en: "Hide chat's window",
	},
	'aria.root_container': {
		pl: 'Okno czatu',
		en: "chat's window",
	},
	'aria.options.list': {
		pl: 'Lista opcji do wyboru',
		en: 'List of options',
	},
	'aria.message.sent-by.user': {
		pl: 'Wiadomości wysłane przez Ciebie o {readableDateValue}',
		en: 'Message sent by you at {readableDateValue}',
	},
	'aria.message.sent-by.bot': {
		pl: 'Wiadomości wysłane przez czatbota o {readableDateValue}',
		en: 'Messages sent by chatbot at {readableDateValue}',
	},
	'aria.message.sent-by.agent': {
		pl: 'Wiadomości wysłane przez konsultanta o {readableDateValue}',
		en: 'Messages sent by consultant at {readableDateValue}',
	},
	'aria.message.sent-by.system': {
		pl: 'Wiadomości systemowa wysłane o {readableDateValue}',
		en: 'System messages sent at {readableDateValue}',
	},
	'aria.chat.is-writing': {
		pl: 'Poczekaj na odpowiedź',
		en: 'Wait for response',
	},
	'aria.initial-popup': {
		pl: 'Czatbot komunikat',
		en: "Chatbot's welcome message",
	},
	'aria.message-sent': {
		pl: 'Wysłano wiadomość: "{content}"',
		en: 'Message sent: "{content}"',
	},
	'aria.new-messages': {
		pl: 'Pojawiły się nowe wiadomości.',
		en: 'There are new messages.',
	},
	'readMore.textExpand': {
		pl: 'Czytaj więcej',
		en: 'Read more',
		ar: 'اقرأ أكثر',
	},
	'readMore.textExpanded': {
		pl: 'Zwiń',
		en: 'Collapse',
		ar: 'انهدام',
	},
	'userAttachment.inputLabel': {
		pl: 'Dodaj załącznik',
		en: 'Add an attachment',
		ar: 'إضافة مرفق',
	},
	'userAttachment.sendButtonText': {
		pl: 'Wyślij',
		en: 'Send',
		ar: 'إرسال',
	},
	'captcha.error.not-equal': {
		pl: 'Wpisany tekst różni się od tego z obrazka',
		en: 'Text entered is different than the one in the picture',
		ar: 'النص الذي تم إدخاله مختلف عن الموجود في الصورة',
	},
	'captcha.description': {
		pl: 'Przepisz tekst z obrazka',
		en: 'Please type the characters you see in the picture below',
		ar: 'الرجاء كتابة الأحرف التي تراها في الصورة أدناه',
	},
	'calendarComponent.nextDays': {
		pl: 'Następne dni',
		en: 'Next days',
		ar: 'الايام القادمة',
	},
	'calendarComponent.previousDays': {
		pl: 'Poprzednie dni',
		en: 'Previous days',
		ar: 'أيام سابقة',
	},
	'calendarComponent.week': {
		pl: `['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So']`,
		en: `['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat']`,
		ar: `['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت']`,
	},
	'waitingMessage.text': {
		pl: `Staram się odpowiedzieć na Twoje pytanie. Poczekaj jeszcze chwilę.`,
		en: `I am trying to answer your question. Wait a little longer.`,
		ar: `أحاول الإجابة على سؤالك. انتظر اكثر قليلا.`,
	},
};

export type CoreDictionary = typeof dictionary;

export default dictionary as CoreDictionary;
