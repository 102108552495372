import resolveOptionBasedType from '@Core/utils/adapters/watson/helpers/resolveOptionBasedType';
import { getServices } from '@Core/store/Services';
import { IGeneric } from '@Types/watson';

export default function mergeGenerics(genericArray: IGeneric[]) {
	const output = [];
	const AdapterService = getServices('AdapterService');
	const adapterService = new AdapterService();
	const { mergers: mergersFromService, check: checkFromService } = adapterService.mergeGenerics();

	const mergers = {
		form: [],
		...mergersFromService,
	};

	const parents = Object.keys(mergers);

	genericArray.forEach((singleGeneric) => {
		const { title } = singleGeneric;

		function skip() {
			output.push(singleGeneric);
		}

		function pushMergedTitle() {
			if (parents.includes(title)) {
				output.push(title);
			}
		}

		const checkedFromService = checkFromService({
			singleGeneric,
			mergers,
			skip,
			resolveOptionBasedType,
		});

		if (checkedFromService) {
			pushMergedTitle();
		} else {
			if (singleGeneric.response_type === 'option') {
				pushMergedTitle();
				// form
				if (['form', 'formFields', 'formDefaults'].includes(title)) {
					mergers.form.push(resolveOptionBasedType({ singleGeneric }));
				} else {
					skip();
				}
			} else {
				skip();
			}
		}
	});

	return output.map((singleOutput) => {
		// if singleOutput is parentName string
		if (parents.includes(singleOutput) && mergers[singleOutput] && mergers[singleOutput].length) {
			return {
				response_type: 'option',
				merge: mergers[singleOutput],
				options: [],
				title: singleOutput + 'Merge',
			};
		} else {
			return singleOutput;
		}
	});
}
