import styled from 'styled-components';

export const IconGridContainer = styled.div`
	${({ theme }) => ({
		width: '100%',
		borderRadius: '16px',
		backgroundColor: '#fff',
		boxShadow: '0 0 18px #cccccc',
		padding: '20px 12px',
		margin: '5px 0',
		fontSize: '17px',
		fontFamily: 'Barlow',
		fontWeight: 'bold',
		color: '#0a1246',
		...theme.elements.messages.IconGrid.IconGridContainer,
	})};
`;

export const GridLabel = styled.p`
	${({ theme }) => ({
		fontSize: '17px',
		fontFamily: 'Barlow',
		fontWeight: 'bold',
		color: '#0a1246',
		...theme.elements.messages.IconGrid.GridLabel,
	})};
`;

export const GridContainer = styled.div`
	${({ theme }) => ({
		width: '100%',
		display: 'grid',
		margin: '30px 0 10px 0',
		padding: '0 10px',
		gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
		columnGap: '10px',
		rowGap: '30px',
		...theme.elements.messages.IconGrid.GridContainer,
	})};
`;

export const GridItem = styled.div`
	${({ theme }) => ({
		fontSize: '13px',
		fontFamily: 'Barlow',
		fontWeight: 'normal',
		textAlign: 'center',
		color: '#0a1246',
		img: {
			width: '62.5%',
			height: 'auto',
		},
		p: {
			marginTop: '5px',
		},
		...theme.elements.messages.IconGrid.GridItem,
	})};
`;
