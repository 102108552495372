import { IStyles } from '@Types/css';
import { OWNER } from '@Types/messages';
import styled from 'styled-components';

export const Messages = styled.div`
	${({ theme }) => ({
		flex: '1',
		overflowY: 'auto',
		marginTop: '0',
		fontWeight: 'normal',
		padding: '20px 0',
		background: theme.colors.colorWhite,
		scrollbarColor: 'dark',
		scrollbarWidth: '4px',
		'&.fullscreen': {
			padding: `20px calc(50% - (${theme.fullscreenParams.baseWidth / 2}px))`,
		},
		'&::-webkit-scrollbar': {
			width: '10px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.colors.colorGray,
			borderRadius: '4px',
		},
		...theme.elements.messages.Messages,
	})};
`;

function getAlignByOwner(owner) {
	if (owner === OWNER.bot) {
		return 'flex-start';
	} else if (!owner) {
		return 'center';
	} else {
		return 'baseline';
		//return 'flex-end';
	}
}

interface IMessageContainer extends IStyles {
	owner: OWNER;
}

export const MessageContainer = styled.div<IMessageContainer>`
	${({ theme, owner }) => ({
		display: 'flex',
		flexDirection: 'column',
		fontSize: '0.8rem',
		alignItems: getAlignByOwner(owner),
		paddingRight: 0,
		borderRadius: '10px',
		...theme.elements.messages.MessageContainer,
	})};
`;

export const MessageWrapper = styled.div.attrs({ tabIndex: 0 })`
	${({ theme }) => ({
		display: 'flex',
		margin: '5px 0',
		padding: '8px 15px',
		borderRadius: '10px',
		position: 'relative',
		background: theme.colors.brownGrayTwo,
		'&:before,&:after': {
			display: 'none',
		},
		...theme.elements.messages.MessageWrapper,
	})};
`;
