function cookiesStorage({ expiresAfterReload }: { expiresAfterReload: boolean }) {
	let expirationDate;
	const dateExpired = 'Thu Jan 1 2000 00:00:00';
	const dateNotExpired = 'Thu Jan 1 2099 00:00:00';

	if (expiresAfterReload) {
		expirationDate = dateExpired;
	} else {
		expirationDate = dateNotExpired;
	}

	function _getAllCookies() {
		return Object.fromEntries(
			document.cookie.split('; ').map((c) => {
				const [key, ...v] = c.split('=');
				return [key, v.join('=')];
			})
		);
	}

	function _getDomain() {
		return window.location.hostname;
		// let _domain = window.location.hostname.split('.');
		// if (_domain.length > 2) {
		// 	_domain.shift();
		// }
		// return _domain.join('.');
	}

	const domain = _getDomain();

	function _createCookieString({ itemName, itemValue, expirationDate, domain }) {
		return `${itemName}=${itemValue}; expires=${expirationDate}; path=/; domain=${domain}`;
	}

	function getItem(itemName: string) {
		return _getAllCookies()[itemName];
	}

	function setItem(itemName: string, itemValue: any) {
		return (document.cookie = _createCookieString({ itemName, itemValue, expirationDate, domain }));
	}

	function removeItem(itemName) {
		document.cookie = _createCookieString({ itemName, itemValue: '', expirationDate: dateExpired, domain });
	}

	return {
		getItem,
		setItem,
		removeItem,
	};
}

export default function (storageName = 'sessionStorage') {
	switch (storageName) {
		case 'localStorage':
			return localStorage;
		case 'cookiesExpired':
			return cookiesStorage({ expiresAfterReload: true });
		case 'cookies':
			return cookiesStorage({ expiresAfterReload: false });
		default:
			return sessionStorage;
	}
}
