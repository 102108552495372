export const URL_REGEX = new RegExp(
	'/((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[-;:&=\\+\\$,\\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\\w]+@)[A-Za-z0-9.-]+)((?:\\/[\\+~%\\/.\\w-_]*)?\\??(?:[-\\+=&;%@.\\w_]*)#?(?:[\\w]*))?)/'
);

export enum FileTypes {
	jpg = 'image/jpg',
	jpeg = 'image/jpeg',
	png = 'image/png',
	gif = 'image/gif',
	pdf = 'application/pdf',
	doc = 'application/doc',
	docx = 'application/docx',
	txt = 'text/plain',
	xml = 'text/xml',
	odt = 'application/vnd.oasis.opendocument.text',
	zip = 'application/x-zip-compressed',
	rar = 'application/x-rar-compressed',
	csv = '.csv',
	xls = 'application/vnd.ms-excel',
	xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}
