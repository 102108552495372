import styled from 'styled-components';
import { keyframes } from 'styled-components';

const BusyIndicatorAnimation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

export const BusyIndicatorElement = styled.div`
	${({ theme }) => ({
		display: 'inline-block',
		position: 'relative',
		width: '25px',
		height: '25px',
		marginBottom: '20px',
		' div': {
			margin: 0,
			boxSizing: 'border-box',
			display: 'block',
			position: 'absolute',
			width: '25px',
			height: '25px',
			border: `3px solid ${theme.colors.colorPrimary}`,
			borderRadius: '50%',
			animation: `${BusyIndicatorAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
			borderColor: `${theme.colors.colorPrimary} transparent transparent transparent`,
			'&:nth-child(1)': {
				animationDelay: '-0.45s',
			},
			'&:nth-child(2)': {
				animationDelay: '-0.3s',
			},
			'&:nth-child(3)': {
				animationDelay: '-0.15s',
			},
		},
		...theme.elements.BusyIndicatorElement,
	})};
`;
export const BusyIndicatorContainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		...theme.elements.BusyIndicatorContainer,
	})};
`;

export const BusyIndicatorText = styled.p`
	${({ theme }) => ({
		...theme.elements.BusyIndicatorText,
	})};
`;
