import { Service } from '@Core/utils/services';

export default class AdapterService extends Service {
	// singleGeneric - input
	// message - output Message model

	/**
	 * extends @Core/utils/adapters/watson/helpers/resolveOptionBasedType.js
	 */
	resolveOptionBasedType = ({ singleGeneric, message, optionsArray, getOptionsAsObject }) => {
		// do smth
		return null;
	};

	/**
	 * extends @Core/utils/adapters/watson/helpers/resolveCommands.js
	 */
	resolveCommands = ({ singleGeneric, message }) => {
		// do smth
	};

	/**
	 * extends @Core/components/Messages/Messages.js
	 */

	getMessage = ({ message, messagesComponents, onOptionSelect }) => {
		// do smth
		return null;
	};

	/**
	 * extends  @Core/utils/adapters/watson/helpers/merge/mergeGenerics.js
	 */
	mergeGenerics = () => {
		return {
			mergers: {},
			check: ({ singleGeneric, mergers, skip, resolveOptionBasedType }) => {
				// do smth
				return null;
			},
		};
	};
}
