import { Service } from '@Core/utils/services';
import { Message, MessagesGroup } from '@Core/utils/models/messages';
import sendMessageWatsonAdapter from '@Core/utils/adapters/watson/sendMessage';
import getSessionIdWatsonAdapter from '@Core/utils/adapters/watson/getSessionId';
import { urlReplacer } from '@Core/utils/url';
import { getAppStore } from '@Core/store/App';
import { ApiContext } from '@Core/utils/models/watson';
import debug from '@Core/utils/debug';
import { OWNER } from '@Types/messages';
import { getUID } from '@Core/utils/hooks/useUID';
import { Incognito } from '@Types/appStore';
import extractContextUserDefined from '@Core/utils/watson/extractContextUserDefined';
import { getServices } from '@Core/store/Services';
import useDictionary from '@Core/utils/language/useDictionary';

interface ISendMessagePayload {
	text?: string;
	context?: ApiContext;
	request?: RequestInit;
	incognito?: Incognito;
	skipCaptcha?: boolean;
}

interface IPrepareRequestPayload {
	text: ISendMessagePayload['text'];
	context?: ISendMessagePayload['context'];
	bypass_watson?: boolean;
}

export default class ConnectionService extends Service {
	constructor() {
		super();
		window.__actionBot.sendMessage = (args: ISendMessagePayload) => {
			const MessageService = this.getServices('MessageService');
			const messageService = new MessageService();
			const message = new Message({
				owner: OWNER.user,
				type: 'text',
				content: args.text,
			});
			this.getAppStore().incognito.set(args.incognito);

			messageService.addMessage(message);
			this.sendMessage(args).catch(console.error);
		};
	}

	connection = this.config.PROTECTED.connection;
	sessionInvalidCode = 404;
	sessionInvalidError = 'Invalid Session';
	maxReconnects = 3;
	reconnectCount = 0;

	get services() {
		const RequestTemplateService = this.getServices('RequestTemplateService');
		const MessageService = this.getServices('MessageService');
		return {
			requestTemplateService: new RequestTemplateService(),
			messageService: new MessageService(),
		};
	}

	get adapters() {
		const engine = this.connection.engine;

		switch (engine) {
			default:
				return {
					sendMessage: sendMessageWatsonAdapter,
					getSessionId: getSessionIdWatsonAdapter,
				};
		}
	}

	_handleSkipEventsParamInContext = (response) => {
		try {
			const store = this.getAppStore();
			const skipEvents = response?.context?.skills?.['main skill']?.user_defined?.skipEvents;

			if (typeof skipEvents === 'boolean' && store.skipEvents.value !== skipEvents) {
				store.skipEvents.set(skipEvents);
			} else if (skipEvents === undefined && store.skipEvents.value !== false) {
				store.skipEvents.set(false);
			}
		} catch (error) {
			console.warn(error);
		}
	};

	convertResponseMessageByAdapter({ request, response }) {
		this._handleSkipEventsParamInContext(response);

		const converted = this.adapters.sendMessage({ request, response });

		const store = this.getAppStore();
		const replyForFirstUserMessage = store.replyForFirstUserMessage;
		const userSentFirstMessage = store.userSentFirstMessage.value;

		window.__actionBot.initResponse = response;
		if (!replyForFirstUserMessage.value && userSentFirstMessage) {
			const messages = converted.group.messages;
			debug().log({ response, messages });
			replyForFirstUserMessage.set({
				response,
				messages,
			});
		}

		return converted;
	}

	convertResponseSessionByAdapter({ response }) {
		return this.adapters.getSessionId({ response });
	}

	prepareMessagesForRedirect = ({ group, delayed }) => {
		const store = this.getAppStore();
		const REDIRECT = 'redirectwithmemory';
		let redirectMessage;
		let delayCorrection = 0;
		const redirectOffset = 1000;

		let groupAfterRedirect = {
			messages: undefined,
		};
		const delayedAfterRedirect = [];

		const createMessageGroupAfterRedirect = () => {
			if (!groupAfterRedirect.messages) {
				groupAfterRedirect = new MessagesGroup({
					owner: store.recipient.value,
					messages: [],
				});
			}
		};

		if (group && group.messages) {
			group.messages = group.messages
				.map((singleMessage) => {
					if (redirectMessage) {
						createMessageGroupAfterRedirect();
						groupAfterRedirect.messages.push(singleMessage);
					} else {
						if (singleMessage.type.toLowerCase() === REDIRECT) {
							createMessageGroupAfterRedirect();
							redirectMessage = singleMessage;
						} else {
							return singleMessage;
						}
					}
				})
				.filter((g) => g);
		}

		delayed = delayed
			.map((singleDelayed) => {
				if (redirectMessage) {
					singleDelayed.delay = singleDelayed.delay - delayCorrection;
					delayedAfterRedirect.push(singleDelayed);
				} else {
					if (singleDelayed.message.type.toLowerCase() === REDIRECT) {
						delayCorrection = singleDelayed.delay;
						redirectMessage = singleDelayed;
					} else {
						return singleDelayed;
					}
				}
			})
			.filter((d) => d);

		store.messagesAfterRedirect.set((messagesAfterRedirect) => {
			let { group, delayed } = messagesAfterRedirect;

			delayed.splice(0, delayed.length);

			group = { ...group, ...groupAfterRedirect };
			delayed = [...delayed, ...delayedAfterRedirect];

			return {
				...messagesAfterRedirect,
				group,
				delayed,
			};
		});

		if (redirectMessage) {
			if (typeof redirectMessage.delay === 'number') {
				const { delay, message } = redirectMessage;
				store.thinking.set(true);
				setTimeout(() => {
					store.thinking.set(false);
					this.services.messageService.addMessage(message);
				}, delay);
			} else {
				setTimeout(() => {
					this.services.messageService.addMessage(redirectMessage);
				}, redirectOffset);
			}
		}

		return { group, delayed };
	};

	processMessages = (groupAndDelayed) => {
		setTimeout(() => {
			const { group, delayed } = this.prepareMessagesForRedirect(groupAndDelayed);
			this.services.messageService.addMessageGroup(group);
			if (delayed.length) {
				this.resolveDelayedMessages(delayed);
			}
			getAppStore().incognito.set(undefined);
		}, 0);
	};

	saveInDataLayer = (result) => {
		const store = this.getAppStore();
		if (store.analytics.value) {
			window.dataLayer = window.dataLayer || [];
			const data = {
				event: 'watsonResponse',
				intent: result.output.intents[0],
				responses: result.output.generic,
			};
			window.dataLayer.push(data);
		}
		return result;
	};

	static switchRecipientTo = (recipient = OWNER.bot) => {
		getAppStore().recipient.set(recipient);
	};

	_prepareRequest = ({ text, context }: IPrepareRequestPayload) => {
		const store = this.getAppStore();
		const waitingForAgent = store.waitingForAgent.value;

		if (waitingForAgent && !context) {
			text = '';
			context = new ApiContext({
				eventData: {
					waitingForAgent: true,
				},
			});
		}

		let agentParams = {};
		if (store.config.PROTECTED.agent && store.config.PROTECTED.agent.available) {
			agentParams = {};
			if (store.agentConnection.value) {
				const { room_id, websocket_session_id } = store.agentConnection.value;
				agentParams = {
					...agentParams,
					room_id,
					websocket_session_id,
				};
			}
		}

		let textWithoutEnters = '';
		try {
			if (typeof text === 'string') {
				textWithoutEnters = text.replace(/(\r\n|\n|\r)/, '');
			} else if (typeof text === 'object') {
				textWithoutEnters = text[store.language.value];
			}
		} catch (e) {
			debug().error(e);
			debug().log({ text, context });
		}

		const body = {
			input: {
				text: textWithoutEnters,
			},
			context: undefined,
			uid: getUID(),
			...agentParams,
			...this.config.PROTECTED.connection.extraPayloadData,
		};

		if (context) {
			body.context = context;
		}

		if (!body.context) {
			delete body.context;
		}

		return JSON.stringify(body);
	};

	_isSuccess = (result) => {
		return !(result.code === this.sessionInvalidCode && result.error === this.sessionInvalidError);
	};

	_isSessionValid = ({ result, request }) => {
		if (this._isSuccess(result)) {
			this.reconnectCount = 0;
			return result;
		} else {
			if (this.reconnectCount < this.maxReconnects) {
				return this.getSessionId().then(() => {
					this.reconnectCount++;
					return this.sendMessage({ request }).catch(console.error);
				});
			} else {
				const { translate } = useDictionary();
				const errorMessage = new Message({
					owner: OWNER.system,
					type: 'text',
					content: translate('connection_error'),
				});
				this.services.messageService.addMessage(errorMessage);
				this.reconnectCount = 0;
			}
		}
	};

	_handleSessionUpdate = ({ result }) => {
		const { value: sessionIdFromStore, set } = this.getAppStore().sessionId;
		if (sessionIdFromStore !== result.session_id) {
			if (result.session_id) {
				set(result.session_id);
			}
		}
		return result;
	};

	handleActionbotUser = (response) => {
		if (response?.actionbotUser) {
			getAppStore().actionbotUser.set((a) => ({
				...a,
				...response.actionbotUser,
			}));
		}
		return response;
	};

	getSessionId = () => {
		const { url, withWelcomeMessage, forceGetSession, options } = this.connection.getSessionId;
		const { value: actionbotUser } = this.getAppStore().actionbotUser;
		const { set: setSessionId, value: sessionIdFromStore } = this.getAppStore().sessionId;
		const { value: messages } = this.getAppStore().messages;
		let sessionId = null;
		let requestOptions = null;

		const message = this.services.requestTemplateService.welcomeMessageByLanguage();
		if (withWelcomeMessage && !actionbotUser.bypass_watson) {
			requestOptions = {
				...options,
				body: this._prepareRequest({ text: message.text, context: message.context }),
			};
			this.handleFirstMessage(message);
		} else {
			requestOptions = {
				...options,
				body: JSON.stringify({
					context: new ApiContext({}),
					...this.config.PROTECTED.connection.extraPayloadData,
				}),
			};
		}

		const parsedContext = JSON.parse(requestOptions.body)?.context;
		if (parsedContext) {
			const contextData = extractContextUserDefined(parsedContext);

			if (contextData?.topic) {
				const { TopicService } = getServices();
				const topicService = new TopicService();
				topicService.clearTopic();
			}
		}

		requestOptions = this.includeIdentifiers(requestOptions);

		if (forceGetSession || !sessionIdFromStore || !messages.length) {
			return fetch(url, requestOptions || options)
				.then((res) => res.json())
				.then(this.handleActionbotUser)
				.then(this.extractRoomId)
				.then((response) => {
					sessionId = this.convertResponseSessionByAdapter({ response });
					setSessionId(sessionId);
					return response;
				})
				.then((result) => this.convertResponseMessageByAdapter({ request: null, response: result }))
				.then(this.processMessages)
				.then(() => {
					setTimeout(() => {
						getAppStore().minimizeAvailable.set(true);
					}, 300);
				})
				.then(() => {
					getAppStore().receivedSessionId.set(true);
					return sessionId;
				})
				.then(() => {
					setTimeout(() => {
						getAppStore().minimizeAvailable.set(true);
					}, 300);
				})
				.catch((error) => {
					debug().error('getSessionId Error:', error);
				});
		} else {
			return new Promise((resolve) => resolve({ sessionIdFromStore }));
		}
	};

	resolveDelayedMessages = (delayed) => {
		const store = this.getAppStore();
		const thinking = store.thinking;
		setTimeout(() => {
			delayed.forEach((delayedMessage) => {
				setTimeout(() => {
					if (delayedMessage.typing) {
						thinking.set(true);
					}
				}, delayedMessage.delay - delayedMessage.orginalDelay);

				setTimeout(() => {
					thinking.set(false);
					this.services.messageService.addMessage(delayedMessage.message);
				}, delayedMessage.delay);
			});
		}, 2000);
	};

	handleSkipAgentConnection = (response) => {
		try {
			const skipAgentConnection = response.context.skills['main skill'].user_defined.skipAgentConnection;
			if (typeof skipAgentConnection === 'boolean') {
				this.getAppStore().skipAgentConnection.set(skipAgentConnection);
			}
		} catch (e) {
			console.warn(e);
		}
		return response;
	};

	handleFirstMessage = ({ text, context }) => {
		try {
			const userSentFirstMessage = this.getAppStore().userSentFirstMessage.value;
			let isNotCommand = true;
			const isNotSentFirstMessage = !userSentFirstMessage;

			try {
				const isCommand = context.skills['main skill'].user_defined?.eventData?.eventName;
				isNotCommand = !isCommand && !text.includes('$$');
			} catch (e) {
				console.warn(e);
			}
			debug().log('isNotCommand', isNotCommand, { text, context });

			if (isNotSentFirstMessage && isNotCommand) {
				window.__actionBot.initMessage = text;
				this.getAppStore().userSentFirstMessage.set(true);
			}
		} catch (e) {
			debug().error('catched', e);
		}
	};

	includeIdentifiers(request) {
		function getRoomId() {
			try {
				return getAppStore().agentConnection?.value?.room_id;
			} catch (e) {
				console.warn(e);
			}
		}
		const body = request.body;
		const identifiers = {
			uid: getUID(),
			room_id: getRoomId(),
		};
		if (typeof body === 'string') {
			try {
				request.body = JSON.stringify({
					...JSON.parse(body),
					...identifiers,
				});
			} catch (e) {
				console.warn(e);
			}
		} else if (typeof body === 'object') {
			request.body = {
				...body,
				...identifiers,
			};
		}
		return request;
	}

	extractRoomId = (response) => {
		const room_id = response?.room_id;
		if (room_id) {
			this.getAppStore().agentConnection.set((agentConnection) => ({
				...agentConnection,
				room_id,
			}));
		}
		return response;
	};

	sendMessage = ({
		text,
		context = new ApiContext({}),
		request,
		incognito,
		skipCaptcha = false,
	}: ISendMessagePayload) => {
		return new Promise((resolve, reject) => {
			window.__actionBot.initMessage = text;
			this.getAppStore().incognito.set(incognito);
			this.handleFirstMessage({ text, context });

			let { url } = this.connection.sendMessage;
			const { options } = this.connection.sendMessage;

			const store = getAppStore();

			const { enabled: isCaptchaEnabled } = store.config.PROTECTED.captcha;
			const { isActive: isCaptchaActive } = store.captcha.value;

			if (isCaptchaEnabled && !skipCaptcha) {
				store.captcha.set((captcha) => ({
					...captcha,
					counter: captcha.counter + 1,
				}));

				if (isCaptchaActive) {
					const error = 'Fill the captcha first!';
					console.error(error);

					return reject(error);
				}
			}

			const { value: sessionId } = store.sessionId;
			const requestOptions = request || {
				...options,
				body: this._prepareRequest({ text, context }),
			};

			request = this.includeIdentifiers(request || requestOptions);

			let response;
			const _sendMessage = (sessionId?: string) => {
				url = urlReplacer(url, {
					SESSION_ID: sessionId,
					LANG: 'en',
				}).replace('undefined/', '');
				const contextData = extractContextUserDefined(context);

				if (contextData.topic) {
					const { TopicService } = getServices();
					const topicService = new TopicService();
					topicService.clearTopic();
				}

				fetch(url, requestOptions)
					.then((result) => {
						response = result.json();
						return response;
					})
					.then(this.extractRoomId)
					.then(this.handleSkipAgentConnection)
					.then((result) => this._handleSessionUpdate({ result }))
					.then((result) => this.saveInDataLayer(result))
					.then((result) =>
						this.convertResponseMessageByAdapter({
							request: requestOptions,
							response: result,
						})
					)
					.then(this.processMessages)
					.then((result) => {
						resolve({
							url,
							requestOptions,
							response,
							result,
						});
					})
					.catch((error) => {
						debug().error(error);
						const { translate } = useDictionary();
						const errorMessage = new Message({
							owner: OWNER.system,
							type: 'text',
							content: translate('connection_error'),
						});
						this.services.messageService.addMessage(errorMessage);
						reject({
							url,
							requestOptions,
							response,
							error,
						});
					});
			};

			_sendMessage(sessionId);
		});
	};

	getWelcomeMessage() {
		const message = this.services.requestTemplateService.welcomeMessageByLanguage();
		this.sendMessage(message).catch(console.error);
	}

	sendEvent(eventName: string, data?: object, withoutText?: boolean, forceSendEvent = false, skipCaptcha = true) {
		const skipEvents = this.getAppStore().skipEvents.value;

		if (!skipEvents || forceSendEvent) {
			const context = new ApiContext({
				eventData: {
					eventName,
					...data,
				},
			});
			debug().log(
				'Sending event:',
				eventName,
				'with data:',
				data,
				'withoutText:',
				withoutText,
				'forceSendEvent:',
				forceSendEvent
			);
			if (withoutText) {
				this.sendMessage({ context, incognito: 'input', skipCaptcha });
			} else {
				this.sendMessage({ context, text: '$$actionbot_event$$', incognito: 'input', skipCaptcha });
			}
		} else {
			debug().warn('Event skipped:', eventName);
		}
	}

	static getExtraContextParams() {
		return {};
	}
}
