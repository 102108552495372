import { Service } from '@Core/utils/services';
import debug from '@Core/utils/debug';
export default class SchedulerService extends Service {
	_scheduler: ReturnType<typeof setTimeout>;

	constructor({ timeout, callback }: { timeout: string; callback: () => void }) {
		super();
		try {
			const parsedTimeout = parseInt(timeout);

			if (typeof parsedTimeout === 'number') {
				this._scheduler = setTimeout(() => {
					typeof callback === 'function' && callback();
				}, parsedTimeout * 1000);
			}
		} catch (e) {
			debug().error(e);
		}
	}

	closeScheduler() {
		clearTimeout(this._scheduler);
	}
}
