import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';

export const TextContainer = styled(MessageContainer)`
	${({ theme }) => ({
		...theme.elements.messages.TextContainer,
	})};
`;
export const TextWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		backgroundColor: theme.colors.veryLightPink,
		a: {
			wordBreak: 'break-word',
		},
		padding: '4px 20px',
		borderRadius: '10px',
		color: theme.colors.colorSecondary,
		background: theme.colors.colorWhite,
		boxShadow: `0 0 18px ${theme.colors.boxShadow}`,
		fontSize: '15px',
		fontWeight: 'light',
		lineHeight: '130%',
		width: '100% !important',
		p: {
			textAlign: 'left',
			overflowWrap: 'anywhere',
			margin: '10px 0',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			fontSize: '18px',
		},
		...theme.elements.messages.TextWrapper,
	})};
`;
interface IDropFileArea {
	visible: boolean;
	isHovered: boolean;
}
export const DropFileArea = styled.div<IDropFileArea>`
	${({ theme, visible, isHovered }) => {
		return {
			display: visible ? 'flex' : 'none',
			position: 'relative',
			border: '2px dotted grey',
			height: '50px',
			alignItems: 'center',
			cursor: 'pointer',
			opacity: isHovered ? 0.6 : 1,
			'&:hover': {
				opacity: '0.6',
			},
			...theme.elements.messages.DropFileArea,
		};
	}};
`;

export const AttachmentFileInput = styled.input`
	${({ theme }) => ({
		opacity: '0',
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: '0px',
		left: '0px',
		cursor: 'pointer',
		...theme.elements.messages.AttachmentFileInput,
	})};
`;

export const AddedFilesWrapper = styled.div`
	${({ theme }) => ({
		...theme.elements.messages.AddedFilesWrapper,
	})};
`;
export const AddedFile = styled.div`
	${({ theme }) => ({
		border: '1px solid grey',
		height: '50px',
		display: 'flex',
		alignItems: 'center',
		width: ' 100%',
		margin: '10px 0',
		...theme.elements.messages.AddedFile,
	})};
`;
export const AddAttachmentIcon = styled.img`
	${({ theme }) => ({
		margin: '0 10px',
		...theme.elements.messages.AddAttachmentIcon,
	})};
`;
export const UserAttachmentLabelName = styled.p`
	${({ theme }) => ({
		...theme.elements.messages.UserAttachmentLabelName,
	})};
`;

export const UserAttachmentFileIcon = styled.img`
	${({ theme }) => ({
		width: '30px',
		marginLeft: '10px !important',
		...theme.elements.messages.UserAttachmentFileName,
	})};
`;
export const AttachmentLabel = styled.div`
	${({ theme }) => ({
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		...theme.elements.messages.AttachmentLabel,
	})};
`;

export const UserAttachmentFileName = styled.p`
	${({ theme }) => ({
		width: '65%',
		marginLeft: '10px !important',
		whiteSpace: 'nowrap !important',
		overflow: 'hidden',
		marginRight: '10px !important',
		...theme.elements.messages.UserAttachmentFileName,
	})};
`;
export const UserAttachmentFileIconWrapper = styled.div`
	${({ theme }) => ({
		height: '100%',
		display: 'flex',
		...theme.elements.messages.UserAttachmentFileIconWrapper,
	})};
`;

interface IUserAttachmentDeleteIcon {
	isDeleteIconActive: boolean;
}
export const UserAttachmentDeleteIcon = styled.img<IUserAttachmentDeleteIcon>`
	${({ theme, isDeleteIconActive }) => {
		const userAttachmentDeleteIconStyle = isDeleteIconActive
			? {
					opacity: '1',
					'&:hover': {
						cursor: 'pointer',
					},
					...theme.elements.messages.UserAttachmentDeleteIconActiveStyle,
				}
			: {
					opacity: '0.4',
					'&:hover': {
						cursor: 'default',
					},
					...theme.elements.messages.UserAttachmentDeleteIconInactiveStyle,
				};
		return {
			...theme.elements.messages.UserAttachmentDeleteIcon,
			...userAttachmentDeleteIconStyle,
		};
	}};
`;
interface ISendFileButton {
	active: boolean;
}
export const SendFileButton = styled.button<ISendFileButton>`
	${({ theme, active }) => {
		const activeStyles = active
			? {
					opacity: 1,
					backgroundColor: theme.colors.primary,
					'&:hover': {
						cursor: 'pointer',
					},
				}
			: null;
		return {
			opacity: 0.5,
			marginTop: '10px',
			width: '140px',
			background: 'lightgrey',
			borderColor: 'lightgrey',
			borderStyle: 'unset',
			marginBottom: '10px',
			padding: '10px',
			cursor: 'default',
			...theme.elements.messages.SendFileButton,
			...activeStyles,
		};
	}};
`;
export const UserAttachmentText = styled.p`
	${({ theme }) => ({
		...theme.elements.messages.UserAttachmentText,
	})};
`;
