import styled from 'styled-components';

export const PoweredBy = styled.div`
	${({ theme }) => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		fontFamily: theme.fonts.primary,
		color: '#aeaeae',
		height: '27px',
		lineHeight: '28px',
		backgroundColor: '#fff',
		width: '100%',
		borderTop: '1px solid #f4f7ff',
		a: {
			color: '#a4a3a5',
			textDecoration: 'none',
			fontWeight: 'bold',
			paddingLeft: '3px',
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			display: 'none',
		},
		...theme.elements.PoweredBy,
	})};
`;
