import { createBehavior } from '@Core/exports/behavior';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import { IAppStorePlatform } from '@Platform/types';

export default createBehavior({
	inactivity: {},
	events: {
		load: () => {
			const store = getAppStore() as IAppStorePlatform;

			const currentUrl = window.location.href;
			const { ConnectionService } = getServices();
			const connectionService = new ConnectionService();

			function clearMessages() {
				store.messages.set((messages) => {
					messages.length = 0;
					return messages;
				});
			}

			function handlePopup(popupType: string, role: string) {
				const isAnyUserMessage = store.messages.value.some((i) => i.owner === 'user');

				if (!isAnyUserMessage) {
					clearMessages();
					store.lastUsedRole.set(role);
				}

				store.initialPopupEventSend.set({
					...store.initialPopupEventSend.value,
					[popupType]: true,
				});
				store.initialPopupSeen.set(false);
				connectionService.sendEvent('initialPopup', {}, true);
			}

			function getRoleAndPopupSeenKey(url: string): { role: string; popupSeenKey: string } | null {
				if (url.includes('doktorant')) return { role: 'doktorant', popupSeenKey: 'doktorantPopupSend' };
				if (url.includes('pracownik')) return { role: 'pracownik', popupSeenKey: 'pracownikPopupSend' };
				if (url.includes('kandydat') || url.includes('katalog'))
					return { role: 'kandydat', popupSeenKey: 'kandydatPopupSend' };
				if (url.includes('podyplomowe') || url.includes('studiapodyplomowe'))
					return { role: 'absolwent', popupSeenKey: 'absolwentPopupSend' };
				if (url.includes('student')) return { role: 'student', popupSeenKey: 'studentPopupSend' };
				return { role: 'default', popupSeenKey: 'defaultPopupSend' };
			}

			const roleAndPopupSeenKey = getRoleAndPopupSeenKey(currentUrl);

			if (roleAndPopupSeenKey) {
				const { role, popupSeenKey } = roleAndPopupSeenKey;
				store.currentRole.set(role);
				if (!store.initialPopupEventSend.value[popupSeenKey]) {
					handlePopup(popupSeenKey, role);
				}
			}

			const { ThemeService } = getServices();
			const contrasts = {
				start: '',
				night: 'nightContrast',
				white: 'blackOnWhite',
				black: 'whiteOnBlack',
				yellow_black: 'contrastYellow',
				black_yellow: 'yellowOnBlack',
			};

			Object.entries(contrasts).forEach(([pageContrast, themeExtension]) => {
				const contrastButton = document.querySelector(`[data-contrast="${pageContrast}"]`);

				if (contrastButton) {
					['click', 'touchstart'].forEach((event) => {
						contrastButton.addEventListener(event, () => ThemeService.setThemeExtension(themeExtension));
					});
				}
			});
		},
	},
});
