import { TextContainer, TextWrapper, WaitingMessageText } from './WaitingMessage.styled';
import { IMessage } from '@Core/types/messages';
import useDictionary from '@Core/utils/language/useDictionary';
import { getAppStore } from '@Core/store/App';
import { useEffect } from 'react';
import { getServices } from '@Core/store/Services';

export interface IWaitingMessage {
	message: IMessage;
}

export default function WaitingMessage({ message }: IWaitingMessage) {
	const { MessageService } = getServices();
	const store = getAppStore();

	const { translate } = useDictionary();
	const messageService = new MessageService();
	const { owner } = message;
	const { removeAfterMessageReceived } = store.config.PUBLIC.WaitingMessage;
	const messages = store.messages.value;
	const messageText = translate('waitingMessage.text');

	useEffect(() => {
		const lastGroup = messages[messages.length - 1];
		const lastGroupMessages = lastGroup.messages;
		if (removeAfterMessageReceived && lastGroupMessages.length > 1) {
			lastGroupMessages.filter((message) => {
				if (message.id == message.id) {
					messageService.removeMessageById(message.id);
				}
			});
		}
	}, [messages]);

	return (
		<TextContainer owner={owner}>
			<TextWrapper className={owner}>
				<WaitingMessageText>{messageText}</WaitingMessageText>
			</TextWrapper>
		</TextContainer>
	);
}
