import { WatsonCommands } from '@Core/services/ActionsService';
import { IAgentInfo } from '@Types/appStore';
import { IMessage, IMessagesGroup, MessageConstructor, MessagesGroupConstructor, MessageType } from '@Types/messages';

let messageIDCounter = 1;

export function generateId(): number {
	return new Date().getTime() + ++messageIDCounter;
}

export class Message implements IMessage {
	date: IMessage['date'];
	owner: IMessage['owner'];
	type: MessageType | WatsonCommands;
	content: IMessage['content'];
	isAction: IMessage['isAction'];
	id: IMessage['id'];
	isMessageWithAction: IMessage['isMessageWithAction'];
	isMessageWithActionDone: IMessage['isMessageWithActionDone'];
	description: IMessage['description'];

	constructor({ owner, type, content, isAction = false, id }: MessageConstructor) {
		this.date = new Date();
		this.owner = owner;
		this.type = type;
		this.content = content;
		this.isAction = isAction;
		this.id = id || generateId();
	}
}

export class MessagesGroup implements IMessagesGroup {
	owner: IMessagesGroup['owner'];
	messages: IMessagesGroup['messages'];
	isLastGroup: IMessagesGroup['isLastGroup'];
	agentInfo: IAgentInfo;

	constructor({ messages, owner, agentInfo }: MessagesGroupConstructor) {
		this.owner = owner;
		this.messages = messages;
		this.isLastGroup = true;
		this.agentInfo = agentInfo;
	}
}
