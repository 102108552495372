import StandardCarousel from '@Core/components/Messages/Carousel/Standard/StandardCarousel';
import ShopCarousel from '@Core/components/Messages/Carousel/Shop/ShopCarousel';
import ModernCarousel from '@Core/components/Messages/Carousel/Modern/ModernCarousel';
import PostcardCarousel from '@Core/components/Messages/Carousel/Postcard/PostcardCarousel';
import debug from '@Core/utils/debug';
import { IMessage } from '@Types/messages';
import { ICarouselConfig } from '@Types/appConfig';
import WithButtonsCarousel from './WithButtons/WithButtonsCarousel';

export interface ICarouselMessage {
	message: IMessage;
	onOptionSelect: (payload: any) => void;
}

type CarouselType = 'shop' | 'modern' | any;

export default function ({ message, onOptionSelect }: ICarouselMessage) {
	let type: CarouselType;
	let sliderConfig: ICarouselConfig;
	let slides = [];

	try {
		message.content.options.forEach((msg) => {
			if (msg.label === 'type') {
				type = msg.value;
			}
			if (msg.label === 'config') {
				sliderConfig = JSON.parse(msg.value);
			}
			if (msg.label === 'slide') {
				slides = slides.concat(JSON.parse(msg.value));
			}
		});
	} catch (e) {
		debug().error(e);
		return null;
	}

	function getCarousel() {
		switch (type) {
			case 'shop':
				return <ShopCarousel slides={slides} sliderConfig={sliderConfig} onOptionSelect={onOptionSelect} />;
			case 'modern':
				return <ModernCarousel slides={slides} sliderConfig={sliderConfig} onOptionSelect={onOptionSelect} />;
			case 'postcard':
				return <PostcardCarousel slides={slides} sliderConfig={sliderConfig} onOptionSelect={onOptionSelect} />;
			case 'withButtons':
				return (
					<WithButtonsCarousel slides={slides} sliderConfig={sliderConfig} onOptionSelect={onOptionSelect} />
				);
			default:
				return <StandardCarousel slides={slides} sliderConfig={sliderConfig} onOptionSelect={onOptionSelect} />;
		}
	}

	return getCarousel();
}
