import styled from 'styled-components';
import { getAppStore } from '@Core/store/App';
import { IStyledComponentWithTheme } from '@Core/types/themes';
import { IAppStore } from '@Core/types/appStore';

interface IBotWindow extends IStyledComponentWithTheme {
	align: IAppStore['position']['value']['align'];
}

export const BotWindow = styled.div<IBotWindow>`
	${({ theme, align }) => {
		const isRtl = getAppStore().rtl.value;

		const alignStyles =
			align === 'left'
				? {
						left: '5vw',
						...theme.elements.BotWindowAlignLeft,
					}
				: {
						right: '5vw',
						...theme.elements.BotWindowAlignRight,
					};

		return {
			direction: isRtl ? 'rtl' : 'ltr',
			position: 'fixed',
			right: '5vw',
			bottom: '5vh',
			maxWidth: '50%',
			maxHeight: '90vh',
			display: 'flex',
			flexDirection: 'column',
			alignContent: 'space-between',
			transformOrigin: `bottom ${align}`,
			fontFamily: theme.fonts.primary,
			'&.fullscreen': {
				width: '100%',
				height: '100vh',
				maxWidth: '100%',
				maxHeight: '100vh',
				top: 0,
				left: 0,
				borderRadius: 0,
			},
			'&.minimized': {
				display: 'none',
			},
			'& *': {
				boxSizing: 'border-box',
			},
			borderRadius: '20px',
			overflow: 'hidden',
			background: theme.colors.colorWhite,
			boxShadow: `0 0 20px ${theme.colors.mainShadow}`,
			'p, ul, ol, li, span': {
				fontFamily: theme.fonts.primaryFont,
				fontSize: 'inherit',
				fontWeight: 'inherit',
				lineHeight: 'inherit',
				color: 'inherit',
			},
			zIndex: 1000,
			width: '320px',
			height: '620px',
			[`@media (max-width: ${theme.breakpoints.mobile})`]: {
				position: 'fixed',
				top: '0',
				left: '0',
				right: '0',
				width: '100%',
				height: '100%',
				maxWidth: '100vw',
				maxHeight: '100vh',
				transform: 'none',
				borderRadius: '0',
			},
			'&.rtl': {
				'.scroll--content': {
					marginRight: '0',
					'> div > div.bot > div.bot > div.bot': {
						right: '10px',
						left: 'auto',
					},
					'> div > div.user > div.user > div.user': {
						right: 'auto',
						left: '10px',
					},
				},
				'.scroll--bar': {
					right: 'auto',
					left: '0',
				},
				'> div:first-child': {
					'&:before': {
						left: 'auto',
						right: '15px',
					},
					'> div:first-child': {
						padding: '0 55px 0 0',
					},
				},
				iframe: {
					left: 'auto',
					right: '-20px',
				},
			},
			...theme.elements.BotWindow,
			...alignStyles,
		};
	}};
`;

export const BotIcon = styled.img`
	${({ theme }) => ({
		position: 'fixed',
		bottom: '5vh',
		right: '5vw',
		cursor: 'pointer',
		transition: 'opacity .3s',
		'&:hover': {
			opacity: 0.75,
		},
		...theme.elements.BotIcon,
	})};
`;

export const RootContainer = styled.div``;
