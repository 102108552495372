export enum Events {
	action = 'action',
	command = 'command',
	messagesChanged = 'messagesChanged',
	load = 'load',
	minimize = 'minimize',
	maximize = 'maximize',
	addMessage = 'addMessage',
	addMessageGroup = 'addMessageGroup',
	redirect = 'redirect',
	pageSpendTime = 'pageSpendTime',
	pageLoad = 'pageLoad',
	formFocus = 'formFocus',
	formSubmit = 'formSubmit',
	scrollPage = 'scrollPage',
	nervousScrolling = 'nervousScrolling',
	nervousClicking = 'nervousClicking',
	dataLayerPush = 'dataLayerPush',
	navigationIssue = 'navigationIssue',
}

interface IBotEventTarget extends EventTarget {
	URL: string;
	form?: {
		id: string;
	};
	id: string;
	value: any;
	name: string;
}
export interface IBotEvent extends Event {
	data: any;
	target: IBotEventTarget;
}
