import { IAppStoreProvider } from '@Core/store/App';

export { getAppStore } from '@Core/store/App';

/*
 * creatorFunction ({ useState, useStateWithKey, appStoreElement, persistValue }) => ({storeKey: store, ...})
 */

export const createAppStore = (creatorFunction: IAppStoreProvider['platformAppStore']) => {
	return creatorFunction;
};
