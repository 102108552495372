import styled from 'styled-components';

export const MenuContainer = styled.div`
	${({ theme }) => ({
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		gap: '8px',
		marginBottom: '10px',
		...theme.elements.messages.MessageMenu.MenuContainer,
	})};
`;

export const MenuBoxContainer = styled.div`
	${({ theme }) => ({
		flex: 'calc(50% - 5px)',
		...theme.elements.messages.MessageMenu.MenuBoxContainer,
	})};
`;

export const MenuBox = styled.div`
	${({ theme }) => ({
		boxShadow: '0 0 20px 0 #cdc8ec',
		padding: '15px',
		backgroundColor: '#fff',
		borderRadius: '12px',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		img: {
			width: 'auto',
			height: '25px',
			marginRight: '5px',
		},
		p: {
			color: '#0a1246',
			fontFamily: 'Barlow',
			fontSize: '14px',
			fontWeight: '600',
			textAlign: 'left',
		},
		'&:hover': {
			opacity: '0.7',
		},
		...theme.elements.messages.MessageMenu.MenuBox,
	})};
`;
