let poly = require('preact-cli/src/lib/webpack/polyfills');
import { render } from 'preact';

// @ts-ignore
import Platform from '@Platform/index.tsx';
// @ts-ignore
import PlatformConfig from '@Platform/config/app';
import PlatformBuildConfig from '@Platform/config/build';

function getRootElement() {
	try {
		return PlatformConfig.PUBLIC.rootElement;
	} catch (e) {
		return 'body';
	}
}

const stylesFromCssFiles = document.createElement('link');
stylesFromCssFiles.type = 'text/css';
stylesFromCssFiles.rel = 'stylesheet';
stylesFromCssFiles.href = PlatformBuildConfig.publicPath + 'actionbot-styles.css';
document.head.appendChild(stylesFromCssFiles);

const className = 'actionBotWindow';
const container = document.createElement('div');
container.classList.add(className);
document.querySelector(getRootElement()).appendChild(container);

const root = document.querySelector('.' + className);

function mountActionBot() {
	render(<Platform />, root);
}

function unmountActionBot() {
	try {
		clearInterval(window.__actionBot.pingInterval);
	} catch (e) {
		console.warn(e);
	}
	render(null, root);
}

window.__actionBot = {
	...window.__actionBot,
	mounting: {
		root,
		mount: mountActionBot,
		unmount: unmountActionBot,
	},
};

if (PlatformConfig.PUBLIC.mountByDefault) {
	mountActionBot();
}
