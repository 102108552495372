// useDictionary hook that provides translate function

import { DictionaryKey, getDictionary } from '@Core/store/Dictionary';

export default function useDictionary() {
	const dictionary = getDictionary();

	function translate(key: DictionaryKey, params?: object) {
		const translations = dictionary[key];
		if (!translations) {
			return key;
		}

		const randomIndex = Array.isArray(translations) ? Math.floor(Math.random() * translations.length) : 0;
		let translated = Array.isArray(translations) ? translations[randomIndex] : translations;

		if (typeof params === 'object' && !Array.isArray(params)) {
			Object.entries(params).forEach(([paramName, paramValue]) => {
				translated = translated.replace(new RegExp(`{${paramName}}`, 'g'), paramValue);
			});
			return translated;
		}
		return translated;
	}

	return {
		translate,
	};
}
