import debug from '@Core/utils/debug';
const REPLACER = 'x';

export default function replaceBetweenFirstAndLast(input: string, replacer: string = REPLACER) {
	try {
		if (!input || input.length === 0) {
			return '';
		}
		let lastWhiteChar = '';
		const whiteChars = input.match(/(\s|\.|,|\n)/g);
		if (whiteChars) {
			lastWhiteChar = whiteChars[whiteChars.length - 1] || '';
		}

		let output = input;
		const first = input.match(/[\d\S]{1}/)?.[0] || '';

		const last = input.match(/[\d\S]$/)?.[0] || '';

		output = output.replace(/[\d\S]/g, replacer).replace(new RegExp(`${replacer}{1}`), first);
		const lastIndex = output.length - 1;
		output = output.substring(0, lastIndex) + last;

		return output + lastWhiteChar;
	} catch (e) {
		debug().error(e);
		return '';
	}
}
