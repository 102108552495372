import styled from 'styled-components';

export const WithButtonsCarouselSwipeItem = styled.div`
	${({ theme }) => ({
		display: 'flex !important',
		flexDirection: 'column',
		position: 'relative',
		marginRight: '1.2rem',
		height: '450px',
		minHeight: '450px',
		marginLeft: '1.2rem',
		'&:focus': {
			outline: 'none',
		},
		float: 'none',
		margin: '0',
		paddingBottom: '20px',
		borderRadius: '10px',
		border: 'none',
		background: theme.colors.colorWhite,
		color: theme.colors.carousel,
		boxShadow: '5px 5px 8px 2px rgba(233,0,143,0.15)',
		'> div:first-child': {
			padding: '15px 15px 10px 15px',
			color: theme.colors.colorPrimary,
			fontSize: '20px',
			fontWeight: 'bold',
			img: {
				margin: 0,
			},
			div: {
				margin: 0,
			},
		},
		...theme.elements.messages.WithButtonsCarouselSwipeItem,
	})};
`;

export const WithButtonsCarouselContainer = styled.div`
	${({ theme }) => ({
		position: 'relative',
		left: '-23px',
		width: 'calc(100% + 95px)',
		margin: '0',
		paddingBottom: '0',
		borderRadius: '10px',
		'.slick-dots': {
			display: 'none !important',
		},
		'.slick-track': {
			display: 'flex',
			flexDirection: 'row',
		},
		'.slick-slide': {
			[`@media (max-width: ${theme.breakpoints.mobile})`]: {
				maxWidth: '150px !important',
			},
		},
		'.slick-slide > div': {
			margin: '10px',
			height: '100%',
			borderRadius: '10px',
			transition: 'box-shadow .3s',
			[`@media (max-width: ${theme.breakpoints.mobile})`]: {
				width: '130px',
			},
		},
		'.slick-slide > div > div': {
			display: 'flex !important',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		'.slick-slider:before': {
			left: '0',
			background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
		},
		'.slick-slider:after': {
			right: '0',
			background: 'linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
		},
		...theme.elements.messages.WithButtonsCarouselContainer,
	})};
`;

export const WithButtonsCarouselCarouselButton = styled.div`
	${({ theme }) => ({
		'&.hidden': {
			overflow: 'hidden',
		},
		padding: '5px 15px',
		borderRadius: '15px',
		border: theme.colors.white,
		backgroundColor: theme.colors.whiteGradient,
		fontSize: '0.7rem',
		transition: 'background-color .3s, color .3s',
		position: 'absolute',
		bottom: 0,
		...theme.elements.messages.WithButtonsCarouselCarouselButton,
	})};
`;

export const WithButtonsCarouselLeft = styled(WithButtonsCarouselCarouselButton)`
	${({ theme }) => ({
		left: '2rem',
		cursor: 'pointer',
		...theme.elements.messages.WithButtonsCarouselLeft,
	})};
`;

export const WithButtonsCarouselRight = styled(WithButtonsCarouselCarouselButton)`
	${({ theme }) => ({
		right: '61px',
		cursor: 'pointer',
		...theme.elements.messages.WithButtonsCarouselRight,
	})};
`;

export const WithButtonsCarouselImage = styled.img`
	${({ theme }) => ({
		width: '70%',
		height: 'auto',
		borderRadius: '16px 16px 0 0',
		display: 'inline !important',
		...theme.elements.messages.WithButtonsCarouselImage,
	})};
`;

export const WithButtonsCarouselImageContainer = styled.div`
	${({ theme }) => ({
		...theme.elements.messages.WithButtonsCarouselImageContainer,
	})};
`;

export const WithButtonsCarouselValue = styled.div`
	${({ theme }) => ({
		display: 'inline',
		float: 'right',
		margin: '1rem',
		fontWeight: 'bold',
		...theme.elements.messages.WithButtonsCarouselValue,
	})};
`;

export const WithButtonsCarouselText = styled.div`
	${({ theme, style }) => ({
		float: 'center',
		textAlign: 'center',
		position: 'relative',
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		fontSize: '17px',
		p: {
			margin: '4px',
		},
		...theme.elements.messages.WithButtonsCarouselText,
		...resolveStyleType({ style, theme }),
	})};
`;

export const WithButtonsCarouselTable = styled.table`
	${({ theme }) => ({
		borderSpacing: '5px 1px',
		borderCollapse: 'separate',
		margin: '15px 12px',
		'td, p': {
			fontSize: '14px !important',
			fontWeight: 'normal !important',
			lineHeight: '20px !important',
			letterSpacing: '0',
		},
		tr: {
			'td:first-child': {
				fontWeight: '600 !important',
				fontSize: '11px !important',
				textTransform: 'uppercase',
				whiteSpace: 'nowrap',
			},
			'td:nth-child(2)': {
				paddingLeft: '5px',
				lineHeight: '20px !important',
			},
		},
		...theme.elements.messages.WithButtonsCarouselTable,
	})};
`;

export const WithButtonsCarouselButtonContainer = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexGrow: '1',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'center',
		paddingBottom: '10px',
		paddingTop: '7px',
		...theme.elements.messages.WithButtonsCarouselButtonContainer,
	})};
`;

export const WithButtonsCarouselButton = styled.div`
	${({ theme, style }) => ({
		height: '32px',
		borderRadius: '100px',
		fontFamily: ' Barlow',
		fontWeight: '700',
		fontSize: '12px',
		color: '#2D63FB',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
		margin: '5px 0',
		width: '200px',
		padding: '0 20px',
		maxWidth: 'calc(100% - 20px)',
		p: {
			textAlign: 'center',
			color: '#fff',
		},
		'&:hover': {
			backgroundColor: '#182B60',
		},
		...theme.elements.messages.WithButtonsCarouselButton,
		...resolveStyleType({ style, theme }),
	})};
`;

function resolveStyleType({ style, theme }) {
	const styles = {
		primary: {
			...theme.elements.messages.WithButtonsCarouselStylePrimary,
		},
		secondary: {
			...theme.elements.messages.WithButtonsCarouselStyleSecondary,
		},
		title: {
			...theme.elements.messages.WithButtonsCarouselTextTitle,
		},
		subtitle: {
			...theme.elements.messages.WithButtonsCarouselTextSubtitle,
		},
		description: {
			...theme.elements.messages.WithButtonsCarouselTextDescription,
		},
	};

	return styles[style];
}
