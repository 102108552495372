import { Service } from '@Core/utils/services';
import debug from '@Core/utils/debug';
import getStorageByName from '@Core/utils/storage/getStorageByName';
import { IMessagesGroup } from '@Types/messages';
import { WebStorage } from '@Core/types/storages';

interface IServerSideHistoryConnection {
	type: 'save' | 'get';
	history?: object;
	token?: string;
}
export default class HistoryService extends Service {
	get isEnabled() {
		return this.config.PROTECTED.history.enabled;
	}

	getKey(prefix: string) {
		return `${this.config.PROTECTED.history.key}${prefix ? '_' + prefix : ''}`;
	}

	historyKey = this.getKey('history');
	timeKey = this.getKey('time');
	storage = getStorageByName(this.config.PROTECTED.history.storage);

	saveHistory(messagesGroups: IMessagesGroup[]) {
		if (this.isEnabled && messagesGroups.length) {
			try {
				this.storage.setItem(this.historyKey, JSON.stringify(messagesGroups));
			} catch (e) {
				debug().error(e);
			}
		}
	}

	loadHistory() {
		if (this.isEnabled) {
			try {
				return JSON.parse(this.storage.getItem(this.historyKey));
			} catch (e) {
				debug().error(e);
				return undefined;
			}
		}
	}

	REDIRECT_HISTORY_TOKEN_KEY = 'ab.storage.technical.redirectHistoryToken';

	serverSideHistoryConnection = ({ type, history, token }: IServerSideHistoryConnection) => {
		const store = this.getAppStore();

		return new Promise((resolve, reject) => {
			const historyEndpoint = store.config.PROTECTED.connection.history.url;

			return fetch(historyEndpoint, {
				method: 'POST',
				body: JSON.stringify({
					type: type,
					history,
					token,
				}),
			})
				.then((r) => r.json())
				.then(resolve)
				.catch(reject);
		});
	};

	saveServerSideHistory = ({ history }: { history: object }) => {
		return this.serverSideHistoryConnection({ type: 'save', history });
	};

	loadServerSideHistory = () => {
		const { REDIRECT_HISTORY_TOKEN_KEY } = this;
		const cookies = getStorageByName('cookies');

		return this.serverSideHistoryConnection({
			type: 'get',
			token: cookies.getItem(REDIRECT_HISTORY_TOKEN_KEY),
		}).then((response) => {
			cookies.removeItem(REDIRECT_HISTORY_TOKEN_KEY);
			return response;
		});
	};

	clearHistory = () => {
		const store = this.getAppStore();
		const { enabled, storage, key } = store.config.PROTECTED.history;
		if (enabled) {
			switch (storage) {
				case WebStorage.sessionStorage:
					sessionStorage.setItem(key + '_history', null);
					break;
				case WebStorage.localStorage:
					localStorage.setItem(key + '_history', null);
					break;
				default:
					break;
			}
		}
	};
}
