import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';
import { OWNER } from '@Types/messages';
import { IStyledComponentWithTheme } from '@Types/themes';

export const AttachmentContainer = styled.div<IStyledComponentWithTheme & { owner: OWNER }>`
	${({ theme, owner }) => ({
		display: 'flex',
		justifyContent: owner === OWNER.agent || owner === OWNER.bot ? 'flex-start' : 'flex-end',
		marginBottom: '10px',
		marginTop: '8px',
		position: 'relative',
		...theme.elements.messages.AttachmentContainer,
	})};
`;

export const AttachmentWrapper = styled.div`
	${({ theme }) => ({
		float: 'right',
		border: '1px solid lightgray',
		borderRadius: '15px',
		...theme.elements.messages.AttachmentWrapper,
	})};
`;

export const AttachmentImageWrapper = styled.div`
	${({ theme }) => ({
		float: 'right',
		position: 'relative',
		borderRadius: '15px',
		...theme.elements.messages.AttachmentImageWrapper,
	})};
`;

export const AttachmentImage = styled.img`
	${({ theme }) => ({
		maxWidth: '15.5rem',
		maxHeight: '12.5rem',
		...theme.elements.messages.AttachmentImage,
	})};
`;

export const AttachmentFile = styled.div`
	${({ theme }) => ({
		padding: '10px',
		borderRadius: '8px',
		display: 'flex',
		flexDirection: 'row',
		position: 'relative',
		...theme.elements.messages.AttachmentImage,
	})};
`;

export const AttachmentImageFile = styled.img`
	${({ theme }) => ({
		maxHeight: '200px',
		maxWidth: '230px',
		minHeight: '80px',
		minWidth: '100px',
		borderRadius: '15px',
		...theme.elements.messages.AttachmentImageFile,
	})};
`;

export const AttachmentFileIcon = styled.img`
	${({ theme }) => ({
		maxWidth: '15.5rem',
		maxHeight: '12.5rem',
		...theme.elements.messages.AttachmentFileIcon,
	})};
`;

export const AttachmentUrlWrapperComponentA = styled.a`
	${({ theme }) => ({
		color: 'unset',
		textDecoration: 'unset',
		...theme.elements.messages.AttachmentUrlWrapperComponentA,
	})};
`;

export const AttachmentFileInfo = styled.div`
	${({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '10px',
		alignItems: 'flex-start',
		textAlign: 'left',
		minWidth: '110px',
		justifyContent: 'center',
		...theme.elements.messages.AttachmentFileInfo,
	})};
`;

export const AttachmentFileInfoName = styled.p<IStyledComponentWithTheme & { fileSize?: number }>`
	${({ theme, fileSize }) => ({
		fontSize: '13px',
		marginBottom: fileSize !== undefined ? '5px' : 0,
		fontWeight: 'bold',
		maxWidth: '170px',
		...theme.elements.messages.AttachmentFileInfoName,
	})};
`;

export const AttachmentFileInfoSize = styled.p`
	${({ theme }) => ({
		opacity: 0.5,
		fontSize: '13px',
		maxWidth: '170px',
		...theme.elements.messages.AttachmentFileInfoSize,
	})};
`;

export const AttachmentSending = styled.div`
	${({ theme }) => ({
		position: 'absolute !important',
		width: '100%',
		height: '100%',
		backgroundColor: '#00000066',
		zIndex: 1,
		top: 0,
		left: 0,
		borderRadius: '15px',

		'&:after': {
			content: "''",
			width: '20px',
			height: '20px',
			borderRadius: '100%',
			border: '3px solid #ffffff66',
			borderTop: '3px solid white',
			position: 'absolute',
			left: '50%',
			top: '50%',
			zIndex: 2,
			transform: 'translate(-50%,-50%)',
			animationName: 'loader',
			animationIterationCount: 'infinite',
			animationDuration: '1s',
		},
		...theme.elements.messages.AttachmentSending,
	})};
`;

export const AttachmentSendingAbort = styled.div`
	${({ theme }) => ({
		position: 'absolute',
		width: '40px',
		height: '40px',
		backgroundColor: '#000000aa',
		zIndex: 3,
		top: '5px',
		right: '5px',
		borderRadius: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&:before': {
			content: "''",
			position: 'absolute',
			width: '15px',
			height: '1px',
			backgroundColor: 'white',
			transform: 'rotate(135deg)',
		},
		'&:after': {
			content: "''",
			position: 'absolute',
			width: '15px',
			height: '1px',
			backgroundColor: 'white',
			transform: 'rotate(45deg)',
		},
		...theme.elements.messages.AttachmentSendingAbort,
	})};
`;
