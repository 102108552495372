import { MenuContainer, MenuBoxContainer, MenuBox } from './MessageMenu.styled';
import { useState } from 'preact/hooks';
import { Message } from '@Core/utils/models/messages';
import { getServices } from '@Core/store/Services';
import { getAppStore } from '@Core/store/App';

interface IMessageMenu {
	menu?: Array<IMenuItem>;
	onOptionSelect: (command: string) => void;
	clickHandler?: (payload: { action: string; command: string }) => void;
}

interface IMenuItem {
	command?: string;
	action?: string;
	img: string;
	text: string;
}

export default ({ menu, onOptionSelect, clickHandler }: IMessageMenu) => {
	const [isActive, setIsActive] = useState(true);
	const ActionsService = getServices('ActionsService');
	const actionsService = new ActionsService();

	function handleClick({ action, command }: IMenuItem) {
		setIsActive(false);
		clickHandler && clickHandler({ action, command });
		if (action && actionsService.exists(action)) {
			const actionMessage = new Message({
				isAction: true,
			});
			actionsService.getCaseInsensitiveProp(action)(actionMessage);
		} else {
			onOptionSelect(command);
		}
		setTimeout(() => setIsActive(true), 1000);
	}

	const menuList = menu || getAppStore().mainMenu.value;
	console.error(menuList);
	if (menuList) {
		const menuElements = menuList.map((item, index) => (
			<MenuBoxContainer key={index}>
				<MenuBox onClick={() => isActive && handleClick(item)}>
					<img src={item.img} />
					<p>{item.text}</p>
				</MenuBox>
			</MenuBoxContainer>
		));

		return <MenuContainer>{menuElements}</MenuContainer>;
	}
};
