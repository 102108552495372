import iconMinimalize from '@Core/assets/icons/minimalize.svg';
import iconResizeUp from '@Core/assets/icons/icon_resize_up.svg';
import iconResizeDown from '@Core/assets/icons/icon_resize_down.svg';
import { HeaderContainer, Title, HeaderIconContainer } from './Header.styled';
import ActionIcon from './ActionIcon/ActionIcon';
import { getAppStore } from '@Core/store/App';
import { getServices } from '@Core/store/Services';
import useAriaImg from '@Core/utils/aria/useAriaImg';
import useDictionary from '@Core/utils/language/useDictionary';

interface IHeader {
	minimize: () => void;
	minimized: boolean;
}

export default function ({ minimize, minimized }: IHeader) {
	const config = getAppStore().config;
	const { title } = config.PUBLIC.header;
	const { ThemeService } = getServices();
	const themeService = new ThemeService();
	const isFullscreen = getAppStore().fullScreen.value;
	const { translate } = useDictionary();

	const { alt, ...fullScreenImgProps } = useAriaImg({
		onClick: () => themeService.setFullScreen(!isFullscreen),
		src: isFullscreen ? iconResizeDown : iconResizeUp,
		alt: translate('aria.img.fullscreen_button'),
		tabIndex: 0,
	});

	return (
		<HeaderContainer>
			<Title>{title}</Title>
			<HeaderIconContainer>
				{config.PUBLIC.fullscreen.enabled && <img alt={alt} {...fullScreenImgProps} />}
				<ActionIcon aria-keyshortcuts="Escape" onClick={minimize} src={iconMinimalize} />
			</HeaderIconContainer>
		</HeaderContainer>
	);
}
