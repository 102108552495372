import { Service } from '@Core/utils/services';
import debug from '@Core/utils/debug';
import replaceBetweenFirstAndLast from '@Core/utils/anonymize/replaceBetweenFirstAndLast';

export default class AnonymizeService extends Service {
	static getAnonymizedVariableName = (name: string) => {
		return {
			placeholder: ` __anonymized_${name}__ `,
			placeholdersRegExp: /([__anonymized_][\S]+__)/g,
			toSend: ` $$anonymized_${name}$$ `,
		};
	};
	anonymizers = {
		// THE ORDER MATTERS
		phone: {
			description: 'Phone number',
			regExp: /[\(]?[+]?([0-9]{2,3})?[\)]?[\s]?[0-9]{2,3}[-\s\.]?[0-9]{2,3}[-\s\.]?[0-9]{2,3}/gm,
			valueToSend: (match) => AnonymizeService.getAnonymizedVariableName('phone').toSend,
			valueToShow: (match) => {
				try {
					return replaceBetweenFirstAndLast(match[0].trim());
				} catch (e) {
					debug().error(e);
					return '';
				}
			},
		},
	};
	doAnonymizationOnMessage = ({ text }) => {
		const anonymizeAvailable = this.getAppStore().config.PROTECTED.anonymize;

		const output = {
			anonymizedText: text,
			values: {},
		};

		if (!anonymizeAvailable) {
			return null;
		}

		const anonymizers = this.anonymizers;
		const anonymizerTypes = Object.keys(anonymizers);

		anonymizerTypes.forEach((anonymizer) => {
			if (anonymizers[anonymizer]) {
				const _anonymizer = anonymizers[anonymizer];
				const placeholdersRegExp =
					AnonymizeService.getAnonymizedVariableName('nameAndSurname').placeholdersRegExp;

				let valueMatch;
				if (typeof _anonymizer.regExp === 'function') {
					valueMatch = _anonymizer.regExp(output.anonymizedText.replace(placeholdersRegExp, ''));
				} else {
					valueMatch = output.anonymizedText.replace(placeholdersRegExp, '').match(_anonymizer.regExp);
				}

				const anonymizerPlaceholder = AnonymizeService.getAnonymizedVariableName(anonymizer).placeholder;

				if (Array.isArray(valueMatch)) {
					valueMatch.forEach((singleMatch) => {
						output.anonymizedText = output.anonymizedText.replace(singleMatch, anonymizerPlaceholder);

						if (!output.values[anonymizer]) {
							output.values[anonymizer] = {
								valueToSend: [],
								valueToShow: [],
							};
						}

						output.values[anonymizer].valueToSend.push(
							_anonymizer.valueToSend([singleMatch], output.anonymizedText)
						);
						output.values[anonymizer].valueToShow.push(
							_anonymizer.valueToShow([singleMatch], output.anonymizedText)
						);
					});
				}
			}
		});

		return output;
	};
}
