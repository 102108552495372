export function getPersistKeys(projectId: string) {
	const PERSIST_KEYS = {
		rtl: `ab.storage.${projectId}.rtl`,
		agentConnection: `ab.storage.${projectId}.agentConnection`,
		agentInfo: `ab.storage.${projectId}.agentInfo`,
		botInfo: `ab.storage.${projectId}.botInfo`,
		usernameVisibleToAgent: `ab.storage.${projectId}.usernameVisibleToAgent`,
		language: `ab.storage.${projectId}.language`,
		unread: `ab.storage.${projectId}.unread`,
		sessionId: `ab.storage.${projectId}.sessionId`,
		redirectStorage: `ab.storage.${projectId}.redirectStorage`,
		remoteConfig: `ab.storage.${projectId}.remoteConfig`,
		initialPopupSeen: `ab.storage.${projectId}.initialPopupSeen`,
		modalMinimized: `ab.storage.${projectId}.modalMinimize`,
		themeExtension: `ab.storage.${projectId}.themeExtension`,
		actionbotUser: `ab.storage.${projectId}.actionbotUser`,
		captcha: `ab.storage.${projectId}.captcha`,
		skipEvents: `ab.storage.${projectId}.skipEvents`,
	};

	return {
		[PERSIST_KEYS.rtl]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.redirectStorage]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.language]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.agentInfo]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.botInfo]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.usernameVisibleToAgent]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.agentConnection]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.sessionId]: {
			storage: 'sessionStorage',
		},
		[PERSIST_KEYS.unread]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.remoteConfig]: {
			storage: 'sessionStorage',
		},
		[PERSIST_KEYS.initialPopupSeen]: {
			storage: 'sessionStorage',
		},
		[PERSIST_KEYS.modalMinimized]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.actionbotUser]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.captcha]: {
			storage: 'localStorage',
		},
		[PERSIST_KEYS.skipEvents]: {
			storage: 'sessionStorage',
		},
		...PERSIST_KEYS,
	};
}
