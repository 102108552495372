export default function deepMerge(...objects: any[]) {
	const isObject = (obj) => obj && obj !== null && obj !== undefined && typeof obj === 'object';
	objects = objects.filter(isObject);
	try {
		return objects.reduce((prev, obj) => {
			Object.keys(obj).forEach((key) => {
				const pVal = prev[key];
				const oVal = obj[key];

				if (Array.isArray(pVal) && Array.isArray(oVal)) {
					const merge = Array.from(new Set([...pVal, ...oVal]));
					prev[key] = merge;
				} else if (isObject(pVal) && isObject(oVal)) {
					prev[key] = deepMerge(pVal, oVal);
				} else {
					prev[key] = oVal;
				}
			});

			return prev;
		}, {});
	} catch (e) {
		console.error(e, { objects });
		return objects.reduce((sum, current) => ({ ...sum, ...current }));
	}
}
