import {
	AttachmentSendingAbort,
	AttachmentImageWrapper,
	AttachmentSending,
	AttachmentImageFile,
	AttachmentImage,
	AttachmentContainer,
	AttachmentWrapper,
	AttachmentFile,
	AttachmentFileIcon,
	AttachmentFileInfo,
	AttachmentFileInfoName,
	AttachmentFileInfoSize,
	AttachmentUrlWrapperComponentA,
} from './Attachment.styled';
import { FileTypes } from '@Core/utils/consts';
import { bytesToReadable } from '@Core/utils/converters/bytesToReadable';
import iconPdf from '@Core/assets/icons/attachment-pdf.svg';
import iconWord from '@Core/assets/icons/attachment-word.svg';
import iconText from '@Core/assets/icons/attachment-text.svg';
import iconCompressed from '@Core/assets/icons/attachment-compressed.svg';
import iconExcel from '@Core/assets/icons/attachment-excel.svg';
import { getAppStore } from '@Core/store/App';
import { IMessage } from '@Types/messages';

export interface IAttachmentMessage {
	message: IMessage;
}

export default ({ message }: IAttachmentMessage) => {
	const { owner } = message;
	const { file, sending } = message.content;
	const store = getAppStore();

	function getShowFileSize() {
		try {
			return store.config.PROTECTED.connection.uploadFile.showFileSize;
		} catch (e) {
			return false;
		}
	}

	const attachmentTypes = {
		image: [FileTypes.png, FileTypes.jpg, FileTypes.jpeg, FileTypes.gif],
		pdf: [FileTypes.pdf],
		word: [FileTypes.doc, FileTypes.docx, FileTypes.odt],
		text: [FileTypes.txt, FileTypes.xml],
		compressed: [FileTypes.zip, FileTypes.rar],
		excel: [FileTypes.csv, FileTypes.xls, FileTypes.xlsx],
	};

	function getIcon() {
		const type = getFileType();
		const icons = {
			pdf: iconPdf,
			word: iconWord,
			text: iconText,
			compressed: iconCompressed,
			excel: iconExcel,
		};
		if (icons[type]) {
			return icons[type];
		} else {
			return icons.text;
		}
	}

	function getFileType() {
		const fileType = file.type;
		let isOfType;

		Object.keys(attachmentTypes).forEach((key) => {
			const valuesArray = attachmentTypes[key];

			if (valuesArray.includes(fileType)) {
				isOfType = key;
			}
		});

		return isOfType;
	}

	function isFileOfType(type) {
		return getFileType() === type;
	}

	// function handleAbort() {
	// 	try {
	// 		window.__actionBot.attachments[message.id].abort();
	// 	} catch (e) {}
	// }

	const Loader = () => {
		return (
			!!sending && (
				<AttachmentSending>
					{/* TODO: canceled request is marked as 'canceled' in browser but rocket still receiving file */}
					{/* <AttachmentSendingAbort onClick={handleAbort}/> */}
				</AttachmentSending>
			)
		);
	};

	const AttachmentUrlWrapperComponent = ({ children }) => {
		if (file.url) {
			return (
				<AttachmentUrlWrapperComponentA
					download={file.name}
					href={file.url}
					target="_blank"
					rel="noopener noreferrer"
				>
					{children}
				</AttachmentUrlWrapperComponentA>
			);
		} else {
			return children;
		}
	};

	return (
		<AttachmentContainer owner={owner}>
			{!isFileOfType('image') && (
				<AttachmentWrapper>
					<AttachmentUrlWrapperComponent>
						<AttachmentFile>
							<AttachmentFileIcon src={getIcon()} />
							<AttachmentFileInfo>
								<AttachmentFileInfoName>{file.name}</AttachmentFileInfoName>
								{!!getShowFileSize() && !!file.size && (
									<AttachmentFileInfoSize>{bytesToReadable(file.size)}</AttachmentFileInfoSize>
								)}
							</AttachmentFileInfo>
							<Loader />
						</AttachmentFile>
					</AttachmentUrlWrapperComponent>
				</AttachmentWrapper>
			)}
			{isFileOfType('image') && (
				<AttachmentImageWrapper>
					<AttachmentImageFile src={file.base64} />
					<Loader />
				</AttachmentImageWrapper>
			)}
		</AttachmentContainer>
	);
};
