import { useEffect, useRef, useState } from 'preact/hooks';
import { Text } from './Text/Text';
import Image from './Image/Image';
import Option from './Option/Option';
import Thinking from './Thinking/Thinking';
import { Messages } from './Messages.styled';
import { getServices } from '@Core/store/Services';
import { getAppStore } from '@Core/store/App';
import Carousel from '@Core/components/Messages/Carousel/Carousel';
import VisitingCard from '@Core/components/Messages/VisitingCard/VisitingCard';
import OnlyOption from '@Core/components/Messages/OnlyOption/OnlyOption';
import PermanentOption from '@Core/components/Messages/PermanentOption/PermanentOption';
import ImageGallery from '@Core/components/Messages/ImageGallery/ImageGallery';
import Form from '@Core/components/Messages/Form/Form';
import Suggestion from '@Core/components/Messages/Suggestion/Suggestion';
import MessagesGroup from '@Core/components/Messages/MessagesGroup/MessagesGroup';
import YouTube from '@Core/components/Messages/YouTube/YouTube';
import Gif from '@Core/components/Messages/Gif/Gif';
import Button from '@Core/components/Messages/Button/Button';
import EmbeddedMap from '@Core/components/Messages/EmbededMap/EmbeddedMap';
import Survey from '@Core/components/Messages/Survey/Survey';
import Attachment from '@Core/components/Messages/Attachment/Attachment';
import MessageMenu from '@Core/components/Messages/MessageMenu/MessageMenu';
import IconGrid from '@Core/components/Messages/IconGrid/IconGrid';
import BusyIndicator from '@Core/components/BusyIndicator/BusyIndicator';
import Calendar from '@Core/components/Messages/Calendar/Calendar';
import TextWithButtons from '@Core/components/Messages/TextWithButtons/TextWithButtons';
import ReadMore from '@Core/components/Messages/ReadMore/ReadMore';
import MessageAttachment from '@Core/components/Messages/MessageAttachment/MessageAttachment';
import UserAttachment from '@Core/components/Messages/UserAttachment/UserAttachment';
import WaitingMessage from '@Core/components/Messages/WaitingMessage/WaitingMessage';
import Captcha from '@Core/components/Captcha/Captcha';
import debug from '@Core/utils/debug';
import { IMessageComponents, OWNER } from '@Types/messages';
import { FC } from 'react';
import { LiveMessenger } from 'react-aria-live';
import useDictionary from '@Core/utils/language/useDictionary';
import { Message } from '@Core/exports/models';

interface IMessagesRoot {
	onOptionSelect?: (payload: any) => void;
	MessagesCustomContainer: FC<any>;
	components?: IMessageComponents;
}

const DefaultMessagesCustomContainer = ({ children }) => children;

export default function ({
	onOptionSelect,
	MessagesCustomContainer = DefaultMessagesCustomContainer,
	components,
}: IMessagesRoot) {
	const { MessageService, ControlService, AdapterService, RemoteConfigService } = getServices();
	const store = getAppStore();
	const messageService = new MessageService();
	const controlService = new ControlService();
	const adapterService = new AdapterService();
	const remoteConfigService = new RemoteConfigService();

	adapterService.resolveCommands;

	const messagesGroups = messageService.messages;
	let speakAssertive;

	let containerRef = useRef();

	const { translate } = useDictionary();

	const [requestIsPending, setRequestIsPending] = useState(false);

	// handle scroll on messages update
	const lastGroup = messagesGroups[messagesGroups.length - 1];
	const lastGroupMessages = lastGroup ? lastGroup.messages : [];

	useEffect(() => {
		setTimeout(() => {
			controlService.scrollToBottom();
		}, 200);
	}, [messagesGroups, lastGroupMessages]);

	useEffect(() => {
		if (store.thinking.value) {
			setTimeout(() => {
				controlService.scrollToBottom();
			}, 200);
		}
	}, [store.thinking]);

	useEffect(() => {
		let waitingMessageTimeout = null;

		const handleTimeout = () => {
			setRequestIsPending(true);
		};

		if (messagesGroups.length) {
			const lastGroup = messagesGroups[messagesGroups.length - 1];
			if (lastGroup.owner === OWNER.user) {
				const { content, id } = lastGroup.messages[0];
				speakAssertive(translate('aria.message-sent', { content }), id);
				clearTimeout(waitingMessageTimeout);
				if (store.config.PUBLIC.WaitingMessage.enabled) {
					waitingMessageTimeout = setTimeout(handleTimeout, store.config.PUBLIC.WaitingMessage.timeToShow);
				}
			} else {
				speakAssertive(translate('aria.new-messages'), messagesGroups.length);
				clearTimeout(waitingMessageTimeout);
				setRequestIsPending(false);
			}
		}
		return () => {
			clearTimeout(waitingMessageTimeout);
		};
	}, [messagesGroups]);

	useEffect(() => {
		if (requestIsPending) {
			messageService.addMessage(
				new Message({
					type: 'waitingmessage',
					owner: OWNER.bot,
				})
			);
		}
	}, [requestIsPending]);

	useEffect(() => {
		controlService.registerScrollToBottomRef(containerRef);
	}, []);

	function getMessage() {
		if (messagesGroups) {
			const groups = [];

			messagesGroups.forEach((singleGroup, groupIndex) => {
				const messages = singleGroup.messages;
				const isLastGroup = singleGroup.isLastGroup;
				const messagesComponents = [];

				messages.forEach((message, index) => {
					const { id } = message;
					switch (message.type) {
						case 'text': {
							const TextComponent = components.Text || Text;
							messagesComponents.push(
								<TextComponent message={message} key={id} isFirstMessage={index === 0} />
							);
							break;
						}
						case 'image': {
							const ImageComponent = components.Image || Image;
							messagesComponents.push(<ImageComponent message={message} key={id} />);
							break;
						}
						case 'option': {
							const OptionComponent = components.Option || Option;
							messagesComponents.push(
								<OptionComponent
									message={message}
									onOptionSelect={onOptionSelect}
									isDisabled={!isLastGroup}
									key={id}
								/>
							);
							break;
						}
						case 'onlyoption': {
							const OnlyOptionComponent = components.OnlyOption || OnlyOption;
							messagesComponents.push(
								<OnlyOptionComponent message={message} onOptionSelect={onOptionSelect} key={id} />
							);
							break;
						}
						case 'permanentoption': {
							const PermanentOptionComponent = components.PermanentOption || PermanentOption;
							messagesComponents.push(
								<PermanentOptionComponent message={message} onOptionSelect={onOptionSelect} key={id} />
							);
							break;
						}
						case 'carousel': {
							const CarouselComponent = components.Carousel || Carousel;
							messagesComponents.push(
								<CarouselComponent message={message} onOptionSelect={onOptionSelect} key={id} />
							);
							break;
						}
						case 'imagegallery': {
							const ImageGalleryComponent = components.ImageGallery || ImageGallery;
							messagesComponents.push(
								<ImageGalleryComponent onOptionSelect={onOptionSelect} message={message} key={id} />
							);
							break;
						}
						case 'visitingcard': {
							const VisitingCardComponent = components.VisitingCard || VisitingCard;
							messagesComponents.push(
								<VisitingCardComponent message={message} onOptionSelects={onOptionSelect} key={id} />
							);
							break;
						}
						case 'formmerge': {
							const FormComponent = components.Form || Form;
							messagesComponents.push(<FormComponent message={message} key={id} />);
							break;
						}
						case 'suggestion': {
							const SuggestionComponent = components.Suggestion || Suggestion;
							messagesComponents.push(
								<SuggestionComponent message={message} isDisabled={!isLastGroup} key={id} />
							);
							break;
						}
						case 'youtube': {
							const YouTubeComponent = components.YouTube || YouTube;
							messagesComponents.push(<YouTubeComponent message={message} key={id} />);
							break;
						}
						case 'gif': {
							const GifComponent = components.Gif || Gif;
							messagesComponents.push(<GifComponent message={message} key={id} />);
							break;
						}
						case 'button': {
							const ButtonComponent = components.Button || Button;
							messagesComponents.push(<ButtonComponent message={message} key={id} />);
							break;
						}
						case 'embeddedmap': {
							const EmbeddedMapComponent = components.EmbeddedMap || EmbeddedMap;
							messagesComponents.push(<EmbeddedMapComponent message={message} key={id} />);
							break;
						}
						case 'survey': {
							const SurveyComponent = components.Survey || Survey;
							messagesComponents.push(
								<SurveyComponent onSelect={onOptionSelect} message={message} key={id} />
							);
							break;
						}
						case 'attachment': {
							const AttachmentComponent = components.Attachment || Attachment;
							messagesComponents.push(<AttachmentComponent message={message} key={id} />);
							break;
						}
						case 'menu': {
							const MenuComponent = components.MessageMenu || MessageMenu;
							singleGroup.withoutAvatar = true;
							singleGroup.withoutDate = true;
							messagesComponents.push(<MenuComponent onOptionSelect={onOptionSelect} key={id} />);
							break;
						}
						case 'icongrid': {
							const IconGridComponent = components.IconGrid || IconGrid;
							messagesComponents.push(<IconGridComponent message={message} key={id} />);
							break;
						}
						case 'calendar': {
							const CalendarComponent = components.Calendar || Calendar;
							messagesComponents.push(
								<CalendarComponent message={message} key={id} isDisabled={!isLastGroup} />
							);
							break;
						}
						case 'textwithbuttons':
							messagesComponents.push(<TextWithButtons key={id} message={message} />);
							return messagesComponents;
						case 'readmore':
							messagesComponents.push(<ReadMore key={id} message={message} />);
							return messagesComponents;
						case 'messageattachment':
							messagesComponents.push(<MessageAttachment key={id} message={message} />);
							return messagesComponents;
						case 'userattachment':
							messagesComponents.push(<UserAttachment key={id} message={message} />);
							return messagesComponents;
						case 'waitingmessage':
							messagesComponents.push(<WaitingMessage key={id} message={message} />);
							return messagesComponents;

						default:
							try {
								// custom definitions from @Platform
								const platformBased = adapterService.getMessage({
									message,
									messagesComponents,
									onOptionSelect,
								});
								if (!platformBased) {
									debug().log('Unexpected response type');
								}
							} catch (e) {
								debug().error(e);
							}
					}
				});
				groups.push(
					<MessagesGroup group={singleGroup} key={`messagesGroup-${groupIndex}`}>
						{messagesComponents}
					</MessagesGroup>
				);
			});

			const getThinking = () => {
				return (
					store.thinking.value && (
						<MessagesGroup
							group={{
								owner: store.recipient.value,
								withoutAvatar: isThinkingWithoutAvatar(),
							}}
						>
							<Thinking />
						</MessagesGroup>
					)
				);
			};

			const isThinkingWithoutAvatar = () => {
				const messagesValue = store.messages.value;
				if (!store.config.PUBLIC.thinkingAvatar) {
					return true;
				} else if (messagesValue?.length > 0) {
					return messagesValue[messagesValue.length - 1].owner !== OWNER.user;
				} else return false;
			};

			return [groups, getThinking()];
		} else {
			return [];
		}
	}

	const isFullScreen = getAppStore().fullScreen.value;

	return (
		<MessagesCustomContainer>
			<LiveMessenger>
				{({ announceAssertive }) => {
					speakAssertive = announceAssertive;
				}}
			</LiveMessenger>
			<Messages innerRef={containerRef} className={isFullScreen ? 'fullscreen' : ''}>
				<Captcha>{remoteConfigService.isLoadingActive ? <BusyIndicator /> : getMessage()}</Captcha>
			</Messages>
		</MessagesCustomContainer>
	);
}
