import styled from 'styled-components';
import { MessageContainer, MessageWrapper } from '@Core/components/Messages/Messages.styled';

export const FormContainer = styled(MessageContainer)`
	${({ theme }) => ({
		...theme.elements.messages.FormContainer,
	})};
`;

export const FormWrapper = styled(MessageWrapper)`
	${({ theme }) => ({
		display: 'inline-block',
		marginRight: '25%',
		backgroundColor: theme.colors.veryLightPink,
		...theme.elements.messages.FormWrapper,
	})};
`;

export const FormHeader = styled.div`
	${({ theme }) => ({
		fontWeight: 'bold',
		marginBottom: '.5rem',
		...theme.elements.messages.FormHeader,
	})};
`;

export const FormButton = styled.button`
	${({ theme }) => ({
		padding: '5px 15px',
		borderRadius: '15px',
		border: theme.colors.white,
		backgroundColor: theme.colors.whiteGradient,
		transition: 'background-color .3s, color .3s',
		bottom: 0,
		marginTop: '.5rem',
		float: 'right',
		'&:not(:disabled):hover': {
			color: theme.colors.white,
			backgroundColor: theme.colors.veryLightPink,
			borderColor: theme.colors.veryLightPink,
			cursor: 'pointer',
		},
		...theme.elements.messages.FormButton,
	})};
`;

export const FormLabel = styled.label`
	${({ theme }) => ({
		display: 'block',
		...theme.elements.messages.FormLabel,
	})};
`;

export const FormInput = styled.input`
	${({ theme }) => ({
		display: 'block',
		// float: 'right',
		...theme.elements.messages.FormInput,
	})};
`;

export const FormFields = styled.div`
	${({ theme }) => ({
		display: 'block',
		margin: '8px 0',
		...theme.elements.messages.FormFields,
	})};
`;

export const Form = styled.form`
	${({ theme }) => ({
		...theme.elements.messages.Form,
	})};
`;
