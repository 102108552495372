export function bytesToReadable(bytes: number) {
	const kb = bytes / 1024;
	const mb = kb / 1024;

	function round(value: number) {
		return value.toFixed(1);
	}

	if (kb < 1) {
		return bytes + ' B';
	} else if (mb < 1) {
		return round(kb) + ' KB';
	} else {
		return round(mb) + ' MB';
	}
}
