import styled from 'styled-components';

export const SwipeItem = styled.div`
	${({ theme }) => ({
		position: 'relative',
		marginRight: '1.2rem',
		marginLeft: '1.2rem',
		'&:focus': {
			outline: 'none',
		},
		float: 'none',
		margin: '0',
		height: '140px',
		borderRadius: '10px',
		border: 'none',
		background: theme.colors.colorWhite,
		color: theme.colors.carousel,
		cursor: 'pointer',
		boxShadow: `0 0 10px ${theme.colors.boxShadow}`,
		'> div:first-child': {
			padding: '15px 15px 10px 15px',
			color: theme.colors.colorPrimary,
			fontSize: '20px',
			fontWeight: 'bold',
			div: {
				margin: 0,
			},
		},
		'> div:last-child': {
			padding: '10px',
			textAlign: 'left !important',
			color: theme.colors.colorDarkBlue,
			fontWeight: 'bold',
			'> p:first-child': {
				marginBottom: '6px',
				fontSize: '12px',
				lineHeight: '100%',
			},
			'> p:last-child': {
				fontSize: '14px',
				fontWeight: 'normal',
				lineHeight: '16px',
			},
			[`@media (max-width: ${theme.breakpoints.mobile})`]: {
				padding: '0 10px 10px 10px',
			},
		},
		[`@media (max-width: ${theme.breakpoints.mobile})`]: {
			width: '130px !important',
			height: '130px',
		},
		...theme.elements.messages.SwipeItem,
	})};
`;

export const StandardCarouselContainer = styled.div`
	${({ theme }) => ({
		position: 'relative',
		left: '-25px',
		width: 'calc(100% + 45px)',
		margin: '0',
		paddingBottom: '0',
		borderRadius: '10px',
		'.slick-dots': {
			display: 'none !important',
		},
		'.slick-track': {
			display: 'flex',
			flexDirection: 'row',
		},
		'.slick-slide': {
			[`@media (max-width: ${theme.breakpoints.mobile})`]: {
				maxWidth: '150px !important',
			},
		},
		'.slick-slide > div': {
			margin: '10px',
			height: '100%',
			borderRadius: '10px',
			transition: 'box-shadow .3s',
			'&:hover': {
				boxShadow: `0 0 0 2px ${theme.colors.colorPrimary}`,
			},
			[`@media (max-width: ${theme.breakpoints.mobile})`]: {
				width: '130px',
			},
		},
		'.slick-slide > div > div': {
			display: 'flex !important',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		'.slick-slider:before, .slick-slider:after': {
			content: '""',
			position: 'absolute',
			zIndex: 10,
			top: '0',
			bottom: '0',
			width: '15px',
			pointerEvents: 'none',
		},
		'.slick-slider:before': {
			left: '0',
			background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
		},
		'.slick-slider:after': {
			right: '0',
			background: 'linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
		},
		...theme.elements.messages.CarouselContainer,
	})};
`;

export const CarouselButton = styled.div`
	${({ theme }) => ({
		padding: '5px 15px',
		borderRadius: '15px',
		border: theme.colors.white,
		backgroundColor: theme.colors.whiteGradient,
		fontSize: '0.7rem',
		transition: 'background-color .3s, color .3s',
		position: 'absolute',
		bottom: 0,
		'&:not(:disabled):hover': {
			color: theme.colors.white,
			backgroundColor: theme.colors.veryLightPink,
			borderColor: theme.colors.veryLightPink,
			cursor: 'pointer',
		},
		...theme.elements.messages.CarouselButton,
	})};
`;

export const StandardCarouselButtonLeft = styled(CarouselButton)`
	${({ theme }) => ({
		left: '2rem',
		...theme.elements.messages.StandardCarouselButtonLeft,
	})};
`;

export const StandardCarouselButtonRight = styled(CarouselButton)`
	${({ theme }) => ({
		right: '2rem',
		...theme.elements.messages.StandardCarouselButtonRight,
	})};
`;

export const StandardCarouselImage = styled.img`
	${({ theme }) => ({
		width: '3rem',
		height: '3rem',
		display: 'inline !important',
		margin: '5px 10px 0px 10px',
		...theme.elements.messages.StandardCarouselImage,
	})};
`;

export const StandardCarouselValue = styled.div`
	${({ theme }) => ({
		display: 'inline',
		float: 'right',
		margin: '1rem',
		fontWeight: 'bold',
		...theme.elements.messages.StandardCarouselValue,
	})};
`;

export const ClickFix = styled.div`
	${({ theme }) => ({
		position: 'absolute',
		left: 0,
		top: 0,
		...theme.elements.messages.ClickFix,
	})};
`;

export const StandardCarouselTitle = styled.div`
	${({ theme }) => ({
		float: 'center',
		textAlign: 'center',
		// this is fix form non clickable img
		position: 'absolute',
		left: 0,
		top: 0,
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'flex-end',
		paddingBottom: '20px',
		// end fix
		p: {
			margin: '4px',
		},
		...theme.elements.messages.StandardCarouselTitle,
	})};
`;
