import styled from 'styled-components';
import menuIcon from '@Core/assets/icons/menu.svg';
import menuIconOpen from '@Core/assets/icons/menu_close.svg';
import menuIconDark from '@Core/assets/icons/menu_open_dark.svg';
import menuIconOpenDark from '@Core/assets/icons/menu_close_dark.svg';
import { IStyles } from '@Types/css';
export const MenuContainer = styled.div`
	${({ theme }) => ({
		width: '20px',
		minWidth: '20px',
		height: '20px',
		marginLeft: '10px',
		...theme.elements.MenuContainer,
	})};
`;

interface IMenuIconStyled extends IStyles {
	open: boolean;
}

export const MenuIcon = styled.div`
	${({ theme, open }: IMenuIconStyled) => ({
		backgroundImage: open ? `url(${menuIconOpen})` : `url(${menuIcon})`,
		width: '100%',
		height: '100%',
		backgroundSize: 'contain',
		cursor: 'pointer',
		'&:hover': {
			backgroundImage: open ? `url(${menuIconOpenDark})` : `url(${menuIconDark})`,
			opacity: '0.8',
		},
		...theme.elements.MenuIcon,
	})};
`;
