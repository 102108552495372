import getBrowserLanguage from './getBrowserLanguage';
import { getAppStore } from '@Core/store/App';

export default function getLanguage() {
	try {
		const {
			config,
			language: { value: languageFromStore },
		} = getAppStore();

		return languageFromStore || config.PUBLIC.language || getBrowserLanguage();
	} catch (e) {
		return getBrowserLanguage();
	}
}
