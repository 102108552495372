import { getAppStore } from '@Core/store/App';
import { IAppConfig } from '@Core/types/appConfig';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';

export default function useCaptcha({
	length = 5,
	fontColor = '#000000',
	fontSize = 26,
	fontFamily = 'Arial',
	charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
	enabled = false,
	messagesInterval = 50,
}: IAppConfig['PROTECTED']['captcha'] = {}) {
	const store = getAppStore();
	const { captcha, isInputDisabled } = store;
	const { value: captchaStore, set: setCaptchaStore } = captcha;
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [captchaText, setCaptchaText] = useState('');
	const { isActive, counter } = captchaStore;

	function reloadCaptcha() {
		let text = '';

		for (let i = 0, n = charset.length; i < length; ++i) {
			text += charset.charAt(Math.floor(Math.random() * n));
		}

		setCaptchaText(text);
	}

	function validateCaptcha(text) {
		return captchaText === text;
	}

	function activateCaptcha() {
		reloadCaptcha();
		setCaptchaStore((captchaStore) => ({
			...captchaStore,
			isActive: true,
		}));
		isInputDisabled.set(true);
	}

	function deactivateCaptcha() {
		setCaptchaStore((captchaStore) => ({
			...captchaStore,
			counter: 0,
			isActive: false,
		}));
		isInputDisabled.set(false);
	}

	useEffect(() => {
		if (enabled) {
			if (counter >= messagesInterval) {
				activateCaptcha();
			}
		}
	}, [counter]);

	useEffect(() => {
		setTimeout(() => {
			if (captchaText) {
				try {
					const canvas = canvasRef.current;
					const ctx = canvas.getContext('2d');

					const lineHeight = fontSize * 1.5;

					ctx.canvas.width = length * fontSize;
					ctx.canvas.height = lineHeight;

					ctx.fillStyle = 'transparent';
					ctx.fillRect(0, 0, canvas.width, canvas.height);

					ctx.textBaseline = 'middle';
					ctx.font = `italic ${fontSize}px ${fontFamily}`;
					ctx.fillStyle = fontColor;

					ctx.strokeStyle = fontColor;

					// fill text
					for (let i = 0; i < length; i++) {
						const x = fontSize * i;
						const y = Math.round(Math.random() * (fontSize - fontSize / 2) + fontSize / 2);

						ctx.fillText(captchaText[i], x, y);
					}

					// fill lines
					for (let i = 0; i < 2; i += 1) {
						ctx.beginPath();
						ctx.moveTo(Math.random() * 250, Math.random() * 50);
						ctx.lineTo(Math.random() * 250, Math.random() * 50);
						ctx.stroke();
					}
				} catch (e) {
					console.error(e);
					deactivateCaptcha();
				}
			}
		}, 0);
	}, [captchaText]);

	const CaptchaComponent = useMemo(() => () => <canvas ref={canvasRef} />, []);

	return { CaptchaComponent, reloadCaptcha, validateCaptcha, isActive, activateCaptcha, deactivateCaptcha };
}
