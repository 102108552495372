import { useEffect, useMemo, useState } from 'preact/hooks';
import {
	BotMinimizedContainer,
	NewMinimizedMessage,
	NewMinimizedMessages,
	UnreadCounter,
	BoxElementWrapper,
	BotChatIcon,
} from './BotMinimized.styled';
import { getAppStore } from '@Core/exports/store/App';
import Draggable from 'react-draggable';
import InitialPopup from '@Core/components/InitialPopup/InitialPopup';
import { OWNER } from '@Core/types/messages';
import { getServices } from '@Core/store/Services';
import isMobileOrTablet from '@Core/utils/mobileAndTabletDetection';

export interface IBotMinimized {
	className: string;
	minimized: boolean;
	botIcon: string;
	maximize: () => void;
	onOptionSelect: (text: string) => void;
	minimizedComponentProps?: {
		elementWidth?: number;
		elementHeight?: number;
		positionRight?: number;
		positionBottom?: number;
		onHoverIn?: () => void;
		onHoverOut?: () => void;
	};
}

export default ({ className, minimized, maximize, minimizedComponentProps = {} }: IBotMinimized) => {
	const store = getAppStore();
	const { ConnectionService } = getServices();
	const connectionService = new ConnectionService();
	const messages = store.messages.value;

	const { unreadCount, unreadMessages } = store.unread.value;
	const { enabled } = store.config.PUBLIC.dragging;
	const {
		elementWidth = 70,
		elementHeight = 70,
		positionRight = 70,
		positionBottom = 70,
		onHoverIn,
		onHoverOut,
	} = minimizedComponentProps;

	const SIZE_HALF = elementWidth / 2;

	const [active, setActive] = useState(true);
	const [userSentMessage, setUserSentMessage] = useState(false);
	const [hover, setHover] = useState(false);
	const [isResize, setIsResize] = useState(false);
	const [screenWidth, setScreenWidth] = useState(0);

	useEffect(() => {
		setScreenWidth(window.innerWidth);
	}, []);

	useEffect(() => {
		const _userSentMessage = messages.some((i) => i.owner === OWNER.user);
		if (userSentMessage !== _userSentMessage) {
			setUserSentMessage(_userSentMessage);
		}
	}, [messages]);

	useEffect(() => {
		const newActive = !minimized;

		if (active !== newActive) {
			setActive(newActive);
		}

		if (store.initialPopup.value && !minimized) {
			store.initialPopup.set(undefined);
		}
	}, [minimized]);

	useEffect(() => {
		hover ? onHoverIn?.() : onHoverOut?.();
	}, [hover]);

	function handleClick() {
		if (minimized) {
			if (!messages.length) {
				connectionService.getWelcomeMessage();
			}
			maximize();
		} else {
			try {
				window.__actionBot.minimize();
			} catch (e) {
				console.error(e);
			}
		}
		store.initialPopupSeen.set(true);
	}

	const [stateXY, setStateXY] = useState({ lastX: 0, lastY: 0 });

	function onStart({ x, y }) {
		setStateXY({ lastX: x, lastY: y });
		setTransition(false);

		store.position.set((p) => ({
			...p,
			isDragging: true,
		}));
	}

	function onStop({ x, y }) {
		setTransition(true);
		if (stateXY.lastX === x && stateXY.lastY === y) {
			handleClick();
		}

		const { innerWidth, innerHeight } = window;
		const align = (innerWidth - positionRight * 2) / 2 < -(x - SIZE_HALF) ? 'left' : 'right';

		let xAligned = 0;
		let xOffsetAligned = 0;

		if (align === 'right') {
			xAligned = innerWidth - SIZE_HALF;
			xOffsetAligned = 0;
		} else {
			xAligned = 0;
			xOffsetAligned = elementWidth - innerWidth + 15 + positionRight * 2;
		}

		store.position.set(() => ({
			x: xAligned,
			y: innerHeight + y,
			offsetX: xOffsetAligned,
			offsetY: y,
			align,
			isDragging: false,
		}));
	}

	// @ts-ignore
	const { offsetX = 0, offsetY = 0 } = store.position.value;

	const draggableDomElement = document.querySelector('.box.react-draggable') as HTMLElement;

	function setTransition(isTransition) {
		if (draggableDomElement) {
			draggableDomElement.style.transition = isTransition ? 'transform .5s' : undefined;
		}
	}
	window.addEventListener('resize', () => {
		setIsResize(true);
	});

	useEffect(() => {
		if (
			isResize &&
			store.position.value.y !== 0 &&
			((isMobileOrTablet(window) && screenWidth !== window.innerWidth) || !isMobileOrTablet(window))
		) {
			store.position.set(() => ({
				x: 0,
				y: 0,
				offsetX: 0,
				offsetY: 0,
				align: 'right',
				isDragging: false,
			}));
		}
		setIsResize(false);
	}, [isResize]);

	const MinimizedMessages = useMemo(
		() => () => {
			if (unreadMessages.length && minimized) {
				return (
					<NewMinimizedMessages>
						{unreadMessages.map((singleUnread) => {
							if (singleUnread.type === 'text') {
								return (
									<NewMinimizedMessage key={singleUnread.id}>
										{singleUnread.content}
									</NewMinimizedMessage>
								);
							}
						})}
					</NewMinimizedMessages>
				);
			}
		},
		[unreadMessages]
	);

	const BotContainer = useMemo(
		() => () => {
			return (
				<BotMinimizedContainer
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					className={className}
					active={active && !minimized}
					userSentMessage={userSentMessage}
					elementWidth={elementWidth}
					elementHeight={elementHeight}
					positionRight={positionRight}
					positionBottom={positionBottom}
				>
					<MinimizedMessages />
					<UnreadCounter unreadCount={unreadCount}>{unreadCount}</UnreadCounter>
					<BotChatIcon id="bot-chat-icon" onClick={enabled ? null : handleClick}></BotChatIcon>
					{minimized && (
						<div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
							<InitialPopup maximize={maximize} />
						</div>
					)}
				</BotMinimizedContainer>
			);
		},
		[active, userSentMessage, minimized, unreadMessages]
	);

	return enabled ? (
		<Draggable
			onStart={(e, data) => onStart(data)}
			onStop={(e, data) => {
				onStop(data);
			}}
			position={{
				x: offsetX,
				y: offsetY,
			}}
			bounds={{
				left: -window.innerWidth + elementWidth + 15 + positionRight * 2,
				right: 0,
				bottom: 0,
				top: -window.innerHeight + elementHeight + positionBottom * 2,
			}}
			handle="#bot-chat-icon"
		>
			<BoxElementWrapper className="box">
				<BotContainer />
			</BoxElementWrapper>
		</Draggable>
	) : (
		<BotContainer />
	);
};
